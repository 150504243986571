import React, { Component } from 'react';
import { Card } from 'antd';
import styles from './index.module.less';
import { base } from "@/src/api/base";

class FriendshipLink extends Component {
  state = {
    BrowserType: "PC", // PC Mobile 浏览器类型 手机或者pc端
  };
  componentDidMount() {
    window.addEventListener("resize", this.resizeBind);
    this.resizeBind();
    // this.ListenStore()
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeBind);
  }
  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== "Mobile") {
        this.setState({
          BrowserType: "Mobile",
        });
      }
    } else {
      if (!this.state.BrowserType !== "PC") {
        this.setState({
          BrowserType: "PC",
        });
      }
    }
  };
  render() {
    let loading = true
    if (this.props.loading !== undefined) {
      loading = this.props.loading
    }
    var { BrowserType } = this.state
    // if (this.props.BrowserType !== undefined) {
    //   BrowserType=this.props.BrowserType
    // }
    return (
      BrowserType == "PC" ? (
        <div className={styles.FriendshipLinkBox}>
          <Card title={<div >友情链接</div>} bordered={false} loading={loading} >
            <div className={BrowserType === "PC" ? styles.FriendshipLink + " " + styles.FriendshipLinkPC : styles.FriendshipLink + " " + styles.FriendshipLinkMobile} >
              {
                this.props.FriendshipLinkList.map((item, index) => {
                  return (
                    <div key={item.id} >
                      <a href={item.url ? item.url : "javascript:;"} alt={item.name} title={item.name} target="_blank">
                        <img src={`${base.OssAddress}${item.pic}`} alt={item.name}></img>
                      </a>
                    </div>
                  )
                })
              }
            </div>
          </Card>
        </div>
      ) : (
          <div className={styles.FriendshipLinkBox}>
            <div className={styles.header}> 
            <span className={styles.tit}>友情链接</span>
            </div>
            <div className={styles.FriendshipLink+" "+ styles.FriendshipLinkMobile} >
              {
                this.props.FriendshipLinkList.map((item, index) => {
                  return (
                    <div key={item.id} >
                      <a href={item.url ? item.url : "javascript:;"} alt={item.name} title={item.name} target="_blank">
                        <img src={`${base.OssAddress}${item.pic}`} alt={item.name}></img>
                      </a>
                    </div>
                  )
                })
              }
            </div>
          </div >
        )
    )
  }
}
export default FriendshipLink;