//home.js
import React, { Component } from 'react';
import styles from './index.module.less';
import { Skeleton, Avatar, Col, Pagination , Button } from 'antd';
import API from '@/src/api/Home';
import { base } from '@/src/api/base'
import { tools } from '@/src/lib/tools';

export default class Content extends Component {

  state = {
    dataSource: [],     // 表格数据
    limit: 10,           // 分页每页显示条数
    offset: 0,
    total: 0,
    loading: false,
    BrowserType: 'PC', // PC Mobile 浏览器类型 手机或者pc端
    Recommend: [],
    right: {
      data:[], 
      limit: 10,           // 分页每页显示条数
      offset: 0,
      total: 0,
    }
  }

  componentDidMount() {

    this.GetData(this.props.keywords)

    // window.addEventListener('resize', this.resizeBind.bind(this))
    // this.resizeBind.bind(this)
    window.addEventListener('resize', this.resizeBind)
    this.resizeBind()

  }

  componentWillReceiveProps(props) {

    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });

    this.setState({
      offset: 0,
      dataSource: [],
    }, () => this.GetData(props.keywords))

  }

  componentWillUnmount() {
    // window.removeEventListener('resize', this.resizeBind.bind(this))
    window.removeEventListener('resize', this.resizeBind)
  }

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== 'Mobile') {
        this.setState({
          BrowserType: 'Mobile',
        });
      }
    } else {
      if (!this.state.BrowserType !== 'PC') {
        this.setState({
          BrowserType: 'PC',
        });
      }
    }
    console.log(this.state.BrowserType)
  }

  GetData = async (keywords) => {

    if (this.state.right.offset < 0 || this.state.loading) {
      return
    }
    const { offset, limit } = this.state.right;

    if(0 == this.state.limit){
      return;
    }

    let _params = {
      limit: limit || 10,
      offset,
    }

    await this.setState({ loading: true });
    await API.QueryExpertTypes(_params).then(res => {
      
      console.log('返回：'+ JSON.stringify(res))
      if (res.status === 200) {
        
        let dataSource1 = res.data.list;
        if(!dataSource1){
          return;
        }

        let data = this.state.right.data || [];

        data = data.concat(dataSource1)

        this.setState({right: { data, offset: res.data.offset,limit:10, total: res.data.total }, loading: false });
      } else {
        
        this.setState({ right:{offset: -1}, loading: false });
        // notification.error({
        //   message: '查询失败！',
        //   description: res.msg,
        // });
      }
      this.setState({ loading: false });
    }, () => {
      this.setState({ loading: false });
    })

    if(this.state.dataSource && this.state.dataSource.length > 0){
      return;
    }

    await API.QueryExperts(_params).then(res => {
      if (res.status === 200) {
        let dataSource1 = res.data.list.map((item2, index) => {
          item2.id = item2.id + '-' + tools.random(10000, 99999)
          if (index === 0 && item2.title_bar_no === '1001') {
            item2.BoldTitle = true
          }
          item2.title_bar_no = _params.title_bar_no
          return item2
        });

        let dataSource = this.state.dataSource

        dataSource = dataSource.concat(dataSource1)
        if (offset === 0 && dataSource.length > 0) {
          this.setState({
            Recommend: dataSource.filter((item, index) => {
              if (index < 2 ) {
                delete dataSource[index]
              }
              
              return index < 2
            })
          })
        }


        // dataSource = [...dataSource , this.state.dataSource]

        this.setState({ dataSource, offset: res.data.offset, loading: false , total: res.data.total });
      } else {
        
        this.setState({ offset: -1, loading: false });
        // notification.error({
        //   message: '查询失败！',
        //   description: res.msg,
        // });
      }
      this.setState({ loading: false });
    }, () => {
      this.setState({ loading: false });
    })


  }


  render() {
    const {
      Recommend,
      loading,
      BrowserType,
      right,
    } = this.state;
    const {
      offset = 0,
      limit = 10,
      total = 0,
      data = []
    } = right;
    
    return (
      <div className={BrowserType === 'PC' ? styles.MoreArticleContentBox : styles.MoreArticleContentBoxMO}>
        <div className={styles.content}>
          <Col xs={24} sm={24} md={6} >
            {
              Recommend && Recommend.length > 0 ?
                Recommend.map(item => {
                  return (
                    <div className={styles.left_div} key={item.id} >
                      <div>
                        {/* <img src={!item.head_url.startsWith('http') ? base.OssAddress + item.head_url : item.real_name} alt="支付" /> */}
                        {/* <Avatar style={{ width: '100%' }} shape="square" size={ BrowserType === 'PC' ? 225 : 310} src={item.head_url !== undefined && !item.head_url.startsWith('http') ? base.OssAddress + item.head_url : item.head_url}  /> */}
                        <img src={item.head_url !== undefined && !item.head_url.startsWith('http') ? base.OssAddress + item.head_url : item.head_url} alt=""/>
                      </div>
                      <div className={styles.ta2}>
                        {item.real_name}
                      </div>
                      <div className={styles.ta3}>
                        <span>
                          专家介绍：{item.resume}
                    </span>
                      </div>
                    </div>
                  )
                })
                : null
            }
            <div style={{textAlign: 'center',marginTop: '1rem'}} >
              <Button type="primary" href="/#/register4" >成为专家</Button>
            </div>
          </Col>

          <Col xs={24} sm={24} md={18} className={styles.right_div} >
            <div>
              {
                data.map((item , index) => {
                  return (
                    <Skeleton active key={item.id} loading={false} >

                      <div className={styles.ArtucleBox} >
                        <div className={styles.head_url}>
                          {/* <img src={base.OssAddress + item.pic} alt={item.title} /> */}
                          <Avatar shape="square" size={114} src={!item.head_url.startsWith('http') ? base.OssAddress + item.head_url : item.head_url}  />
                          {/*<span className={styles.ta}> 专家： {item.type_name}</span> */}
                        </div>
                        <div className={styles.ArtucleBoxRight}>
                          <div className={styles.Title} >
                            <span className={styles.ta1}>{item.type_name}</span>
                            <span className={styles.decs} >
                              {item.introduction}
                          </span>
                          </div>

                          <div>
                            专家人数：{item.count}
                        </div>

                        </div>

                      </div>

                    </Skeleton>
                  )
                })
              }
            </div>

            <div style={{ textAlign: 'right'}}>
            {
              offset === -1 ?
              null
               : <Pagination size="small" total={total} pageSize={10}
               onChange={ (page, pageSize) => {
                 this.GetData();
                //  this.setState({right:{offset: page*pageSize }},this.GetData)
               }}
                />
            }
              
            </div>
          </Col>

        </div>
      </div>
    )
  }

}
