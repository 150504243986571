import React, { Component } from 'react';
import styles from './index.module.less';
import { base } from '@/src/api/base'
import store from '@/src/redux/store.js';
import { Affix } from 'antd';
class AdAround extends Component {

  state = {
    IsShow: false ,
    AdvertisementData: [] ,
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(this.ListenStore.bind(this))
    window.addEventListener('resize', this.resizeBind)
    this.resizeBind()
    this.ListenStore()
  }
  componentWillUnmount() {
    // 对 store 变化取消监听
    this.unsubscribe();
    window.removeEventListener('resize', this.resizeBind)
  }

  ListenStore = () => {
    let AdAround=[]
    if(this.props.position==="left"){
      AdAround =  store.getState().AdAroundL
    }else{
      AdAround=  store.getState().AdAroundR
    }
    if (!AdAround || AdAround === {} || AdAround.length === undefined || AdAround.length === 0) {
      return
    }
    this.setState({
      AdvertisementData: AdAround,
    });
  }

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.IsShow) {
        this.setState({
          IsShow: false,
        });
      }
    }
  }

  Close = (item) => {
    // console.log(item);
    var AdvertisementData=this.state.AdvertisementData.filter((item1)=>{
      return item1.id!=item.id
    })
    this.setState({
      AdvertisementData,
    })
  }

  render() {
    // let loading = false
    // if (this.props.loading !== undefined) {
    //   loading = this.props.loading
    // }

    // let IsShow = this.state.IsShow
    // if (this.props.IsShow !== undefined) {
    //   IsShow = this.props.IsShow
    // }
    return (

      this.state.AdvertisementData.length > 0 ?
      <Affix offsetTop={0}>
        <div className={this.props.position==="left" ?styles.AdvertisementBox+" "+styles.adAroundL:styles.AdvertisementBox+" "+styles.adAroundR}>
          {
            this.state.AdvertisementData.map( (item,index) => {
              return(
                  item.isClose!=1 && item.status==2?
                  <div className={styles.adItem} key={index}>
                      <a  href={item.url?item.url:"javascript:;"}  alt={item.name} target="_blank">
                        <div className={styles.adImgBox}>
                            <img className={styles.img}  src={`${base.OssAddress}${item.pic}`} alt={item.name}/>
                            {
                              item.note==="closeShow"?
                              <div className={styles.ts}>广告</div>:null
                            }
                          
                        </div>
                      </a>  
                    {
                      item.note==="closeShow"?
                      <div onClick={()=>{this.Close(item,index)}} className={styles.adCloseBox}>关闭</div>:null
                    }
                    
                  </div>:null
              )
            })
          }
        </div > 
    </Affix>: null
    )
  }
}
export default AdAround;