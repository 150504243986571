import React, { Component } from 'react';
import { Card, BackTop, Icon } from 'antd';
import styles from './index.module.less';
import store from '@/src/redux/store.js';
import Advertisement from '@/src/components/Advertisement';
import API from '@/src/api/Home'
import { HashRouter } from 'react-router-dom'
import { tools } from "@/src/lib/tools";
const router = new HashRouter()

class PageFooter extends Component {

  state = {
    BrowserType: 'PC', // PC Mobile 浏览器类型 手机或者pc端
    FooterNavList: [],
    FriendshipLinkList: [],
  }

  constructor() {
    super()

    const list = this.ListenStore(1)

    this.state = {
      ...this.state,
      FooterNavList: list
    }

  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(this.ListenStore.bind(this));
    window.addEventListener("resize", this.resizeBind);
    this.resizeBind.bind(this);
    this.ListenStore();
    if (!tools.IsPC()) {
      this.setState({
        BrowserType: "Mobile"
      });
    }
  }
  componentWillUnmount() {
    // 对 store 变化取消监听
    this.unsubscribe(this.ListenStore.bind(this));
    window.removeEventListener("resize", this.resizeBind);
  }

  unsubscribe = null
  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== 'Mobile') {
        this.setState({
          BrowserType: 'Mobile',
        });
      }
    } else {
      if (!this.state.BrowserType !== 'PC') {
        this.setState({
          BrowserType: 'PC',
        });
      }
    }
  };
  ListenStore = (type) => {
    let { TdTitleBar } = store.getState()

    if (!TdTitleBar || TdTitleBar === {} || TdTitleBar.length === undefined || TdTitleBar.length === 0) {
      return []
    }
    const list = TdTitleBar.filter(item => {
      return item.title_bar_no === '1007' || item.title_bar_no === '1008'
    })

    if (type === 1) {
      return list
    }

    this.setState(Object.assign({}, this.state, {
      FooterNavList: list
    }));

    return []
  }

  GetFriendshipLinkList = () => {
    API.QueryFriendlyLink({}).then(res => {
      if (res.status === 200) {
        this.setState({
          FriendshipLinkList: res.data.list,
        })
      } else {

      }

    })
  }

  // 点击底部导航栏
  FooterNavClick = (item) => {
    // 调路由 skip_url
    // router.history.push(`${item.skip_url}${item.title_bar_no}`)
    window.open(`#${item.skip_url}${item.title_bar_no}`, "_blank");
  }
  render() {

    const { FriendshipLinkList, FooterNavList, BrowserType } = this.state

    let showFriendshipLink = false
    if (this.props.showFriendshipLink !== undefined) {
      showFriendshipLink = this.props.showFriendshipLink
    }

    let showFooterNavList = true
    if (this.props.showFooterNavList !== undefined) {
      showFooterNavList = this.props.showFooterNavList
    }

    let colorE9f1fc = true
    if (this.props.colorE9f1fc !== undefined) {
      colorE9f1fc = this.props.colorE9f1fc
    }
    let changdu = false
    if (this.props.changdu !== undefined) {
      changdu = true
    }

    return (
      <div style={{ width: '100% !important', margin: '0 !important' }} className={`${styles.FooterBox} ${this.state.BrowserType !== 'PC' ? styles.MobileFooterBox : null} `} >


        {/* 友情链接 */}
        {showFriendshipLink && FriendshipLinkList.length > 0 ?
          <Card title={<div >友情链接</div>} bordered={false} >
            <div className={styles.FriendshipLink} >
              {
                FriendshipLinkList.map((item, index) => {
                  return (
                    <div key={item.id} >
                      <a href={item.url ? item.url : "javascript:void(0)"} alt={item.name} title={item.name} >
                        <pre> {item.name} {index < FriendshipLinkList.length - 1 ? <span>|</span> : ''}</pre>
                      </a>
                    </div>
                  )
                })
              }
            </div>
          </Card> : null

        }





        {/* 地址等 */}
        <div className={BrowserType == "PC" ? styles.FooterAddress : styles.FooterAddressMo}>
          <div className={styles.footerBackground}>
            <div className={styles.FooterTop}>
              <div className={styles.FooterContent}>
                <div className={styles.left}>
                  <a href="#">
                    <img
                      className={styles.logo}
                      src={require("../../assets/home/bottom/logo.png")}
                      alt="民航安保网"
                    />
                  </a>
                </div>
                <div className={styles.right}>
                  {/* 关于我们等 */}
                  {
                    showFooterNavList && FooterNavList.length > 0 ?
                      <div className={styles.commonContent}>
                        {
                          FooterNavList.map((item, index) => {
                            return (
                              <div key={item.id} className={styles.FooterNavContent} onClick={() => this.FooterNavClick(item)} >
                                <div> {item.name}  <span className={styles.line}>|</span> </div>
                              </div>
                            )
                          })
                        }
                        <div className={styles.FooterNavContent}>
                          关注我们
                          <img src={require("../../assets/home/bottom/wx.png")} className={styles.wxIcon}></img>
                        </div>
                        <div className={styles.qrIconBox} ref={dom => { this.qrIconBox = dom; }}>
                          <img src={require("../../assets/home/bottom/qrCode.jpg")} className={styles.qrIcon}></img>
                          <Icon type="caret-down" className={styles.dowmIcon} />
                        </div>
                      </div>
                      : null
                  }
                </div>
              </div>
            </div>
            <div className={styles.FooterCenter}>
              <div>北京航安云网络科技有限公司</div>
              <div>地址：北京市顺义区天柱东路润通大厦A座2层</div>
              <div>电话： 010-84456090</div>
              <div>邮箱： min_hanganbao@163.com</div>
            </div>
          </div>
          <div className={styles.FooterBottom}>
            <div className={styles.copyRight}>Copyright@2007-2020</div>
            <div><a style={{ color: "white" }} href="https://beian.miit.gov.cn/">备案号： 京ICP备20015681号</a></div>
          </div>
        </div>


        {/* 悬浮广告 */}
        <Advertisement IsShow={this.props.IsAdvertisementShow} />

        {/* 回到顶部 */}
        {/* <BackTop /> */}
        <BackTop>
          {/* <Icon type="up" /> */}
        </BackTop>

      </div>
    )
  }
}

export default PageFooter;