//home.js
import React, { Component } from 'react';
import styles from './index.module.less';
import API from '../../api/api';
import store from '@/src/redux/store.js';
import { setUserInfo } from '@/src/redux/actions/userInfo';
import { Form, Icon, Input, Button, Col , Checkbox } from 'antd';
import { message, Spin } from 'antd';
import { HashRouter } from 'react-router-dom'
import LoginHeader from '@/src/components/LoginHeader'
import PageFooter from '@/src/components/PageFooter';

const router = new HashRouter()
export default class Login extends Component {
    state = { loading: false };
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <Spin spinning={this.state.loading} delay={10} tip="登录中" >
                    <div className="LoginBox box-margin-top">
                        <LoginHeader title="登录" />
                        <div className={styles.ContentBox}>
                            <Col xs={0} sm={0} md={12}  >
                                <div className={styles.LeftBox}>
                                    <div className={styles.ta1}>
                                        <img src={require('../../assets/dl.png')} alt="民航安保网" />
                                        <h2>机场安保网</h2>
                                        <h3>jichanganbowang</h3>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={23} sm={23} md={20} style={{margin: '0 auto'}} >
                                <div className={styles.RightBox} >
                                    <Form className="login-form" >
                                        <div style={{marginBottom: "1.5rem"}}>
                                            <h2>用户登录</h2>
                                        </div>
                                        <Form.Item>
                                            {getFieldDecorator('phone', {
                                                rules: [{ required: true, message: '请输入手机号!' }],
                                            })(
                                                <Input
                                                    type='tel'
                                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                    placeholder="手机号"
                                                />,
                                            )}
                                        </Form.Item>
                                        <Form.Item>
                                            {getFieldDecorator('pass_word', {
                                                rules: [{ required: true, message: '请填写密码!' }],
                                            })(
                                                <Input
                                                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                    type="password"
                                                    placeholder="密码"
                                                />,
                                            )}
                                        </Form.Item>
                                        <Form.Item>
                                            {getFieldDecorator('remember', {
                                                valuePropName: 'checked',
                                                initialValue: true,
                                            })(<Checkbox>下次自动登录</Checkbox>)}
                                            <a className="login-form-forgot" href="/#/ForgetPwd">
                                                忘记密码
                                            </a>
                                            <a href="/#/register1" style={{float: "right"}} >立即注册</a>
                                            <div style={{marginTop: "1.5rem"}} >
                                                <Button type="primary" onClick={this.Login} className="login-form-button" block> 登 录 </Button>
                                            </div>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </Col>
                        </div>
                        <PageFooter IsAdvertisementShow={false} />
                    </div>
                </Spin>
            </div>
        )
    }

    Login = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // console.log('Received values of form: ', values);
                this.setState({ loading: true });
                API.accountLogin(values).then(res => {
                    debugger
                    if (res.status === 200) {
                        
                        let exp_time_day = 1 
                        if (values.remember) {
                            exp_time_day = 3// 记住我
                        }
                        store.dispatch(setUserInfo(res.data,exp_time_day))
                        // tools.setCookie("super_farm_token", res.data.super_farm_token, 7);
                        // tools.setCookie("user_info", res.data, 7);
                        message.success("登录成功！");
                        router.history.push('/')

                    } else {
                        message.error(res.msg);
                    }
                    this.setState({ loading: false });
                }).catch( () => {
                    this.setState({ loading: false });
                })
            }
        });
    }
}

Login = Form.create({})(Login);