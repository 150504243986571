//home.js
import React, { Component } from 'react';
import styles from './home.module.less';
// import API from '../../api/api';
import PageHeader from '../components/pageHeader';
import HomeContent from './content';
import PageFooter from '../components/pageFooter';

export default class Home extends Component {
  render() {
    return (
      <div className={styles.HomeBox}>
        <PageHeader isHome={true}/>
        <HomeContent />
        <PageFooter colorE9f1fc={false} />
      </div>
    )
  }

}
