import * as actionTypes from '../constants/index';
import { local } from '../../lib/tools'

const setUserInfo = (data,time) => {
    local.set(actionTypes.SET_USERINFO,data,time)
    return {
        type: actionTypes.SET_USERINFO,
        data
    }
};
export {setUserInfo};
