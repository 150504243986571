//home.js
import React, { Component } from "react";
import styles from "./index.module.less";
import API from "../../api/api";
import store from "@/src/redux/store.js";
import { setUserInfo } from "@/src/redux/actions/userInfo";
import { Form, Icon, Input, Button, Col, Checkbox, Avatar } from "antd";
import { message, Spin } from "antd";
import { HashRouter } from "react-router-dom";
import PageHeader from "@/src/components/PageHeader";
import { tools } from "@/src/lib/tools";
const router = new HashRouter();

export default class Login extends Component {
  state = {
    loading: false,
    BrowserType: "PC", // PC Mobile 浏览器类型 手机或者pc端
    isEntryForm: false,
  };
  componentDidMount() {
    window.addEventListener("resize", this.resizeBind);
    this.resizeBind();
    if (!tools.IsPC()) {
      this.setState({
        BrowserType: "Mobile",
      });
    }



    this.changePath()
  }

  //根据url中的type判断是否需要该变目标路径
  changePath=()=>{
      //获取url中的type并跳转
      const par = this.props.location.search;
      let name, value,parmas ={};
      let num = par.indexOf("?");
      let str = par.substr(num + 1);
      let arr = str.split("&"); 
      console.log(arr);
      for (let i = 0; i < arr.length; i++) {
        num = arr[i].indexOf("=");
        if (num > 0) {
          name = arr[i].substring(0, num);
          value = arr[i].substr(num + 1);
          parmas[name] = value;
        }
      }
      if (parmas.type) {
        this.setState({ isEntryForm: parmas.type });
      } else {
        this.setState({ isEntryForm: false });
      }
  }


  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeBind);
  }
  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== "Mobile") {
        this.setState({
          BrowserType: "Mobile",
        });
      }
    } else {
      if (!this.state.BrowserType !== "PC") {
        this.setState({
          BrowserType: "PC",
        });
      }
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { BrowserType, isEntryForm } = this.state;
    return (
      <div>
        <Spin spinning={this.state.loading} delay={10} tip="登录中">
          {BrowserType == "PC" ? (
            <div className={styles.LoginBox}>
              <div className={styles.ContentBox}>
                <div className={styles.header}>
                  <Col xs={24} sm={24} md={20}>
                    <div className={styles.ta1}>
                      <Col xs={24} sm={24} md={12}>
                        <div className={styles.LeftBox}>
                          <div className={styles.ta11}>
                            <a href="/#/">
                              <div>
                                <img
                                  src={require("../../assets/logo2020-05-18.png")}
                                  alt="民航安保网"
                                />
                              </div>
                            </a>
                            {/* <h3>  | 登录</h3> */}
                          </div>
                        </div>
                      </Col>
                      {/* <Col xs={24} sm={24} md={12}  >
                                            <div style={{ float: 'right', marginRight: '5rem' }}>
                                                <a href="/#/register1">没有账号？ 立即注册</a>|<a href="/#/">首页</a>
                                            </div>
                                        </Col> */}
                    </div>
                  </Col>
                </div>

                <div className={styles.LoginModelBox}>
                  <Col xs={22} sm={22} md={20}>
                    <div className={styles.LoginModel}>
                      <Col xs={23} sm={23} md={7} style={{}}>
                        <div className={styles.RightBox}>
                          <Form className="login-form">
                            <div
                              style={{
                                marginBottom: "1rem",
                                textAlign: "center",
                              }}
                            >
                              <div>
                                <Avatar icon="user" size={64} />
                              </div>
                              <div style={{ marginTop: "0.6rem" }}>
                                用户登录
                              </div>
                            </div>
                            <Form.Item>
                              {getFieldDecorator("phone", {
                                rules: [
                                  { required: true, message: "请输入手机号!" },
                                ],
                              })(
                                <Input
                                  type="tel"
                                  prefix={
                                    <Icon
                                      type="user"
                                      style={{ color: "rgba(0,0,0,.25)" }}
                                    />
                                  }
                                  placeholder="手机号"
                                />
                              )}
                            </Form.Item>
                            <Form.Item>
                              {getFieldDecorator("pass_word", {
                                rules: [
                                  { required: true, message: "请填写密码!" },
                                ],
                              })(
                                <Input
                                  prefix={
                                    <Icon
                                      type="lock"
                                      style={{ color: "rgba(0,0,0,.25)" }}
                                    />
                                  }
                                  type="password"
                                  placeholder="密码"
                                />
                              )}
                            </Form.Item>
                            <Form.Item>
                              {getFieldDecorator("remember", {
                                valuePropName: "checked",
                                initialValue: true,
                              })(<Checkbox>下次自动登录</Checkbox>)}
                              <a
                                className="login-form-forgot"
                                href="/#/ForgetPwd"
                              >
                                忘记密码
                              </a>
                              <a
                                href={
                                  isEntryForm
                                    ? "/#/register1?type="+isEntryForm
                                    : "/#/register1"
                                }
                                style={{ float: "right" }}
                              >
                                立即注册
                              </a>
                              {/* <div style={{ marginTop: "0.5rem" }} > */}
                              <Button
                                type="primary"
                                onClick={this.Login}
                                className="login-form-button"
                                block
                              >
                                {" "}
                                登 录{" "}
                              </Button>
                              {/* </div> */}
                            </Form.Item>
                          </Form>
                        </div>
                      </Col>
                    </div>
                  </Col>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.LoginBoxMo}>
              <img
                className={styles.bg}
                src={require("../../assets/login/mo_bg.png")}
                alt=""
              />
              <PageHeader current="/login" showNav={true} showSearch={false} />
              <div className={styles.content}>
                <div className={styles.avatarBox}>
                  <img
                    src={require("../../assets/login/mo_avatar.png")}
                    alt=""
                  />
                </div>
                <p className={styles.tit}>用户登录</p>
                <div className={styles.formBox}>
                  <Form className="login-form">
                    <Form.Item>
                      {getFieldDecorator("phone", {
                        rules: [{ required: true, message: "请输入手机号!" }],
                      })(
                        <Input
                          type="tel"
                          prefix={
                            <img
                              src={require("../../assets/login/mo_phone.png")}
                            />
                          }
                          placeholder="请输入手机号码"
                        />
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator("pass_word", {
                        rules: [{ required: true, message: "请填写密码!" }],
                      })(
                        <Input
                          prefix={
                            <img
                              src={require("../../assets/login/mo_password.png")}
                              alt=""
                            />
                          }
                          type="password"
                          placeholder="请输入密码"
                          style={{ background: "#fff" }}
                        />
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator("remember", {
                        valuePropName: "checked",
                        initialValue: true,
                      })(<Checkbox>下次自动登录</Checkbox>)}
                      <a className={styles.loginForgot} href="/#/ForgetPwd">
                        忘记密码?
                      </a>
                      <a
                        href={
                          isEntryForm
                            ? "/#/register1?type="+isEntryForm
                            : "/#/register1"
                        }
                        className={styles.registerBtn}
                        style={{ float: "right" }}
                      >
                        立即注册
                      </a>
                      {/* <div style={{ marginTop: "0.5rem" }} > */}
                      <Button
                        type="primary"
                        onClick={this.Login}
                        className={styles.loginBtn}
                        block
                      >
                        {" "}
                        登 录{" "}
                      </Button>
                      {/* </div> */}
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          )}
        </Spin>
      </div>
    );
  }

  Login = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        this.setState({ loading: true });
        API.accountLogin(values)
          .then((res) => {
            console.log('Login=====',res)
            debugger
            if (res.status === 200) {
              let exp_time_day = 1;
              if (values.remember) {
                // 记住我
                exp_time_day = 3;
              }
              store.dispatch(setUserInfo(res.data, exp_time_day));
              // tools.setCookie("super_farm_token", res.data.super_farm_token, 7);
              // tools.setCookie("user_info", res.data, 7);
              message.success("登录成功！");

              //获取url中的type并跳转
              const par = this.props.location.search;
              let name, value,parmas ={};
              let num = par.indexOf("?");
              let str = par.substr(num + 1);
              let arr = str.split("&"); 
              console.log(arr);
              for (let i = 0; i < arr.length; i++) {
                num = arr[i].indexOf("=");
                if (num > 0) {
                  name = arr[i].substring(0, num);
                  value = arr[i].substr(num + 1);
                  parmas[name] = value;
                }
              }
              if (parmas.type) {
                router.history.push(parmas.type);
              } else {
                router.history.push("/");
              }

            } else {
              message.error(res.msg);
            }
            this.setState({ loading: false });
          })
          .catch(() => {
            this.setState({ loading: false });
          });
      }
    });
  };
}

Login = Form.create({})(Login);
