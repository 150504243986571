import React, { Component } from 'react';
import styles from './index.module.less';
import { Button, Divider, Icon, Input, Comment, Tooltip, List, message, Avatar, Col, Modal } from 'antd';
import ArticleApi from '@/src/api/ArticleApi';
import { base } from '@/src/api/base';
import API from '@/src/api/Home'
import { tools } from '@/src/lib/tools';
import store from '@/src/redux/store.js';
import moment from 'moment';
import { setArticleDetails } from '@/src/redux/actions/ArticleDetails';
import PageContent from '@/src/components/PageContent';
import TopicItem from '@/src/components/TopicItem';
import PersonalCenterApi from '@/src/api/PersonalCenterApi';
import { HashRouter } from 'react-router-dom'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas'
// import jsPDF from 'jspdf';
const router = new HashRouter()

export default class ArticleDetails extends Component {

  state = {
    BrowserType: 'PC', // PC Mobile 浏览器类型 手机或者pc端
    data: {},
    like_status: 0, // 1 说明点赞过  2说明没有点赞
    loading: true,
    collection_status: 0,
    offset: 0, // 评论
    limit: 5, // 评论
    CommentData: [],
    moreDataLoading: false,
    RelatedNews: [],
    AdvertisementData: [],
    TextContentHeight: 200,
    IsShowArticle: true,
    HiddenText: '',
    is_vip: '0', // 会员状态 0 未查询 1非会员号 2是会员
    actionFontSize: "16px",  //字号
    vipVisible: false, //vip弹框显示
    publishInpLen: 0,//评论输入框字数
  }

  async componentDidMount() {
    // let { ArticleDetails } = store.getState()
    // if (!ArticleDetails) {
    //   router.history.push('/')
    //   return
    // }
    await this.MineBuyVip()

    this.ArticleClick()

    this.InitData()

    window.addEventListener('resize', this.resizeBind)
    this.resizeBind()

    this.unsubscribe = store.subscribe(this.ListenStore.bind(this))

    //获取轮播图
    let params = { title_bar_no: "wenzhang01", offset: 0, limit: 50 }
    API.QueryAdvertiseDetail(params).then(res => {
      if (res.status === 200) {
        // data: ArticleDetails,
        this.setState({
          loading: false,
          AdvertisementData: res.data.list,
        });
        // store.dispatch(setAdvertisement(AdvertisementData))

      } else {

      }
    })

  }

  async componentWillReceiveProps(props) {

    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
    await this.MineBuyVip()

    this.InitData()

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeBind)
    // 对 store 变化取消监听
    this.unsubscribe(this.ListenStore.bind(this));
  }

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== 'Mobile') {
        this.setState({
          BrowserType: 'Mobile',
        });
      }
    } else {
      if (!this.state.BrowserType !== 'PC') {
        this.setState({
          BrowserType: 'PC',
        });
      }
    }
  }

  ListenStore = () => {

    if (!ArticleDetails || ArticleDetails === {} || ArticleDetails.length === undefined || ArticleDetails.length === 0) {
      return
    }



    this.setState({
      data: ArticleDetails,
      loading: false,
    });
  }

  InitData = () => {
    console.log(this.props)
    ArticleApi.IdQueryArticle(this.props.data.match.params).then(res => {
      debugger
      if (res.status === 200) {
        console.log('详情页数据', res)
        document.title = res.data.title || '民航安保网'
        this.setState({
          data: res.data,
          loading: false,
        })

        const ArticleDetails = res.data
        this.QuerymineArticleLike(ArticleDetails.title_bar_no, ArticleDetails.article_no)
        this.QuerymineArticleCollection(ArticleDetails.title_bar_no, ArticleDetails.article_no)
        this.QueryArticleComment()

        this.QueryRelatedNews(ArticleDetails.keywords)

        this.setState({
          data: ArticleDetails,
          loading: false,
        })
        this.IsRightActive(ArticleDetails.read_permissions)

      } else {
        message.error(res.msg)
        let { ArticleDetails } = store.getState()
        if (!ArticleDetails) {
          router.history.push('/')
          return
        }

        this.QuerymineArticleLike(ArticleDetails.title_bar_no, ArticleDetails.article_no)
        this.QuerymineArticleCollection(ArticleDetails.title_bar_no, ArticleDetails.article_no)
        this.QueryArticleComment()

        this.QueryRelatedNews(ArticleDetails.keywords)
        this.setState({
          data: ArticleDetails,
          loading: false,
        })

        this.IsRightActive(ArticleDetails.read_permissions)
      }
    })


  }

  // 查询是否是会员
  MineBuyVip = () => {
    PersonalCenterApi.MineBuyVip().then(res => {
      // console.log(res)
      if (res.status === 200) {
        if (moment().format('X') < res.data.end_time) {
          this.setState({ is_vip: '2' })
        } else {
          this.setState({ is_vip: '1' })
        }
      } else {
        this.setState({ is_vip: '1' })
      }

    })
  }

  TextContent = null

  IsRightActive = (_read_permissions) => {

    const { clientHeight } = this.TextContent;
    const ShowHeight = clientHeight / 10

    const { userInfo } = store.getState()

    let read_status = false
    if (!_read_permissions) {
      read_status = true
    } else {
      let read_permissions = _read_permissions.split(',')
      for (let index = 0; index < read_permissions.length; index++) {
        const element = read_permissions[index];
        if (element === '1') {
          read_status = true

          return
        } else if (element === '2') {
          // 行业会员 就是需要登录
          if (userInfo) {
            read_status = true

            return
          }
        } else if (element === '3') {
          // vip
          if (userInfo && userInfo.vip_type_no.length > 0) {
            read_status = true

            return
          }
        } else if (element === '4') {
          // 专家
          if (userInfo && userInfo.experts_type_no.length > 0) {
            read_status = true

            return
          }
        }
      }

      if (!read_status) {
        for (let index = 0; index < read_permissions.length; index++) {
          const element = read_permissions[index];
          if (element === '2') {
            // 行业会员 就是需要登录
            if (userInfo) {
              read_status = true
              return
            } else {
              this.setState({ TextContentHeight: ShowHeight, IsShowArticle: false, HiddenText: '登录后查看全文' })
              return
            }
          } else if (element === '3') {
            const { is_vip } = this.state
            // vip
            if (userInfo && userInfo.vip_type_no.length > 0 && is_vip === '2') {
              read_status = true

              return
            } else {
              this.setState({ TextContentHeight: ShowHeight, IsShowArticle: false, HiddenText: '成为VIP查看全文' })
              return
            }
          } else if (element === '4') {
            // 专家
            if (userInfo && userInfo.experts_type_no.length > 0) {
              read_status = true

              return
            } else {
              this.setState({ TextContentHeight: ShowHeight, IsShowArticle: false, HiddenText: '成为专家查看全文' })
              return
            }
          }
        }
      }

    }
  }

  ArticleClick = () => {
    ArticleApi.ArticleClick(this.props.data.match.params).then()
  }

  QueryRelatedNews = async (keywords) => {
    debugger
    const params = {
      offset: 0,
      limit: 5,
      keywords,
    }

    await ArticleApi.KeysQueryArticle(params).then(res => {
      // console.log('bb'+JSON.stringify(res))
      if (res.status === 200) {
        this.setState({
          RelatedNews: {
            id: 111,
            title: '延伸阅读',
            showNum: 5, // 显示几条新闻 默认5条
            ShowHeaderTitle: false, // 是否显示头部标题 默认显示 不显示就显示第一条标题并加粗
            SwiperDataList: [], // 轮播数组 此数组有数据 右侧会显示轮播图
            loading: false,
            data: res.data.list.map(item => {
              item.showRedPoint = false
              item.showPic = true
              item.showDetails = true
              return item
            }),

            // [
            //   {
            //     id: 1, // 必须唯一
            //     title: '9月15日 203家机场将启用“民航临时乘机证明”系统',
            //     pic: 'https://www.xn--ruq67zzlnjfscid.cn/img/civilAviation/8bc1cabd4b9a45f6821ed7ceb1dc5a15.jpeg', // 图片 只有第一张显示图片 
            //     album_video: '', // 是否显示视频图标 默认不显示
            //     showRedPoint: false, // 是否显示红点 第一个标题默认不显示 其他标题默认显示
            //     showPic: true, // 是否显示图片 第一个标题默认显示 其余默认不显示
            //     showDetails: true, // 是否显示点赞等数据详情 默认不显示 显示图片才会有次选项 
            //     like_count: 10001, // 点赞数量
            //     comment_count: 50000, // 评论数 
            //     creation_time: 1577357353, // 创建时间  
            //     BoldTitle: false, // 标题是否加粗 
            //   },
            //   {
            //     id: 2,
            //     title: '新安保 新科技 展现民航安保新格局',
            //     pic: 'https://www.xn--ruq67zzlnjfscid.cn/img/civilAviation/8bc1cabd4b9a45f6821ed7ceb1dc5a15.jpeg',
            //     album_video: '', // 是否显示视频图标 默认不显示
            //     showRedPoint: false, // 是否显示红点 第一个标题默认不显示 其他标题默认显示
            //     showPic: true, // 是否显示图片 第一个标题默认显示 其余默认不显示
            //     showDetails: true, // 是否显示点赞等数据详情 默认不显示 显示图片才会有次选项 
            //     like_count: 10001, // 点赞数量
            //     comment_count: 50000, // 评论数 
            //     creation_time: 1577357353, // 创建时间  
            //     BoldTitle: false, // 标题是否加粗 
            //   },

            // ]
          },
        })
      } else {
        this.setState({
          RelatedNews: {
            id: 111,
            title: '延伸阅读',
            showNum: 5, // 显示几条新闻 默认5条
            ShowHeaderTitle: false, // 是否显示头部标题 默认显示 不显示就显示第一条标题并加粗
            SwiperDataList: [], // 轮播数组 此数组有数据 右侧会显示轮播图
            loading: false,
            data: [],
          }
        })
      }
    })


  }

  // 查询我是否点赞过
  QuerymineArticleLike(title_bar_no, article_no) {

    const { userInfo } = store.getState()

    if (!userInfo) {
      return
    }

    const params = {
      title_bar_no: title_bar_no, //标题栏系统生成唯一id
      article_no: article_no, //文章id(系统生成的唯一id
    }
    // console.log('点赞参数', params)
    ArticleApi.QuerymineArticleLike(params).then(res => {
      // console.log('点赞', res)
      if (res.status === 200) {
        this.setState({
          like_status: res.data.like_status,
        })
      }
    })

  }

  // 点赞
  ArticleLike = async () => {
    const { userInfo } = store.getState()

    if (!userInfo) {
      message.error("请先登录！")
      return
    }
    const { data, like_status, loading } = this.state

    if (loading || like_status === 0) {
      return
    }

    let like_status_change = 1
    if (like_status === 1) {
      like_status_change = 2
    }

    const params = {
      title_bar_no: data.title_bar_no, //标题栏系统生成唯一id
      article_no: data.article_no, //文章id(系统生成的唯一id
      title: data.title,
      status_type: like_status_change,
    }

    await this.setState({ loading: true })
    ArticleApi.ArticleLike(params).then(res => {
      // console.log(res, like_status)
      if (res.status === 200) {
        if (like_status === 2) {
          data.like_count = data.like_count + 1
        } else {
          if (data.like_count > 0) {
            data.like_count = data.like_count - 1
          }
        }
        message.success(res.msg)

        this.setState({
          like_status: like_status_change,
          data,
          loading: false
        })

        store.dispatch(setArticleDetails(data))
      } else {
        message.error(res.msg)
        this.setState({
          loading: false
        })
      }
    })

  }

  // 查询我是否收藏过
  QuerymineArticleCollection = (title_bar_no, article_no) => {

    const { userInfo } = store.getState()

    if (!userInfo) {
      return
    }

    const params = {
      title_bar_no: title_bar_no, //标题栏系统生成唯一id
      article_no: article_no, //文章id(系统生成的唯一id
    }
    // console.log('收藏数据', params)
    ArticleApi.QuerymineArticleCollection(params).then(res => {
      // console.log(res)
      if (res.status === 200) {
        this.setState({
          collection_status: res.data.like_status,
        })
      }
    })

  }

  // 收藏
  ArticleCollection = async () => {
    const { userInfo } = store.getState()

    if (!userInfo) {
      message.error("请先登录！")
      return
    }

    const { data, collection_status, loading } = this.state

    if (loading || collection_status === 0) {
      return
    }

    let collection_status_change = 1
    if (collection_status === 1) {
      collection_status_change = 2
    }

    const params = {
      title_bar_no: data.title_bar_no, //标题栏系统生成唯一id
      article_no: data.article_no, //文章id(系统生成的唯一id
      title: data.title,
      status_type: collection_status_change,
    }
    // console.log('收藏', params)
    await this.setState({ loading: true })
    ArticleApi.ArticleCollection(params).then(res => {
      // console.log('收藏返回', res)
      if (res.status === 200) {
        if (collection_status === 2) {
          data.collection_count = data.collection_count + 1
        } else {
          if (data.collection_count > 0) {
            data.collection_count = data.collection_count - 1
          }
        }
        message.success(res.msg)

        this.setState({
          collection_status: collection_status_change,
          data,
          loading: false
        })
        store.dispatch(setArticleDetails(data))
      } else {
        message.error(res.msg)
        this.setState({
          loading: false
        })

      }
    })

  }

  // 评论
  CommentText = null

  SendComment = async (e) => {
    if (e) e.preventDefault();

    const { userInfo } = store.getState()

    if (!userInfo) {
      message.error("请先登录！")
      return
    }

    if (!this.CommentText.state.value || this.CommentText.state.value === undefined) {
      message.error("您还没填写您的评论内容！")
      return
    }

    const { data, loading } = this.state

    if (loading) {
      return
    }

    const params = {
      title_bar_no: data.title_bar_no, //标题栏系统生成唯一id
      article_no: data.article_no, //文章id(系统生成的唯一id
      title: data.title,
      content: this.CommentText.state.value,
    }

    await this.setState({ loading: true })
    ArticleApi.CommentArticle(params).then(res => {
      if (res.status === 200) {
        data.comment_count = data.comment_count + 1
        this.setState({ offset: 0, CommentData: [], data }, () => { this.QueryArticleComment() })
        message.success(res.msg)
        this.CommentText.state.value = ""
      } else {
        message.error(res.msg)

      }
      this.setState({
        loading: false
      })
    })

  }

  // 查询评论
  QueryArticleComment = async () => {
    const { data, offset, limit, moreDataLoading } = this.state
    //改变文章中图片高度
    if (data.content) {
      data.content = data.content.replace(/[^-]height:\w+;?/g, ";");
      data.content = data.content.replace(/height="\w+"\s{1}/g, "");
      // this.state.data.content = this.state.data.content.replace(/font-size:\w+;?/g, "font-size:" + 16 + "px");
    }
    if (moreDataLoading) {
      return
    }
    const params = {
      offset,
      limit,
      title_bar_no: data.title_bar_no, //标题栏系统生成唯一id
      article_no: data.article_no, //文章id(系统生成的唯一id
    }
    await this.setState({ moreDataLoading: true })
    ArticleApi.QueryArticleComment(params).then(async res => {
      if (res.status === 200) {
        const { CommentData } = this.state
        const SeCommentData = await res.data.list.map(item => {
          if (item.member_nick_name == '') {
            item.member_nick_name = "游客";
          }
          item = {
            ...item,
            // actions: [<span key="comment-list-reply-to-0" onClick={() => this.commentReplyShow(item)} >回复</span>],
            avatar: <Avatar
              icon="user"
              src={base.OssAddress + item.member_icon}
              alt={item.member_nick_name}
            />,
            datetime: (
              <Tooltip
                title={moment(item.creation_time * 1000)
                  .format('YYYY-MM-DD HH:mm:ss')}
              >
                <span>
                  {tools.FormatRemainTimeStr(item.creation_time)}
                </span>
              </Tooltip>
            ),
            isReply: false,
            offset: 0,
            limit: 2,
            loading: true,
            member_nick_name: item.member_nick_name || `${base.NameInitials}${item.account_id}`
          }
          const ReplyComment = this.QueryReplyCommentArticle(item.id, item.offset, item.limit)
          ReplyComment.then(data => {
            if (data.status === 200) {
              let ReplyCommentData = data.data.list.map(item2 => {
                item2 = {
                  ...item2,
                  avatar: <Avatar
                    icon="user"
                    src={base.OssAddress + item2.member_icon}
                    alt={item2.member_nick_name}
                  />,
                  datetime: (
                    <Tooltip
                      title={moment(item2.creation_time * 1000)
                        .format('YYYY-MM-DD HH:mm:ss')}
                    >
                      <span>
                        {tools.FormatRemainTimeStr(item2.creation_time)}
                      </span>
                    </Tooltip>
                  ),
                  member_nick_name: item2.member_nick_name || `${base.NameInitials}${item2.account_id}`
                }

                return item2
              })
              item.ReplyCommentData = ReplyCommentData
              item.offset = data.data.offset
              item.loading = false
            } else {
              item.offset = -1
              item.loading = false
            }
            this.setState({
              CommentData: CommentData.concat(SeCommentData),
              offset: res.data.offset,
              moreDataLoading: false,
            })
          })
          return item
        })
      } else {
        this.setState({
          offset: -1,
          moreDataLoading: false,
        })
      }
    })
  }
  //评论输入框内容
  publishInp = () => {
    if (this.CommentText && this.CommentText.state.value) {
      var len = this.CommentText.state.value.length
      this.setState({
        publishInpLen: len
      })
    }
  }
  // 回复评论
  commentReplyShow = (item) => {

    let { CommentData } = this.state
    CommentData.map(item2 => {
      if (item.id === item2.id) {
        item2.isReply = true
      } else {
        item2.isReply = false
      }
      return item2
    })

    this.setState({
      CommentData: CommentData,
    })
  }

  commentReply = async (e, item) => {
    if (e) e.preventDefault();

    const { userInfo } = store.getState()

    if (!userInfo) {
      message.error("请先登录！")
      return
    }

    if (!item.ReplyText.state.value || item.ReplyText.state.value === undefined) {
      message.error("您还没填写回复内容！")
      return
    }

    const { loading } = this.state

    if (loading) {
      return
    }

    const params = {
      comment_id: item.id,
      content: item.ReplyText.state.value,
    }

    await this.setState({ loading: true })
    ArticleApi.ReplyCommentArticle(params).then(res => {
      if (res.status === 200) {

        message.success(res.msg)

      } else {
        message.error(res.msg)

      }
      this.setState({
        loading: false
      })
    })

  }

  QueryReplyCommentArticle = async (comment_id, offset, limit) => {

    if (offset < 0) {
      return null
    }

    const params = {
      comment_id,
      offset,
      limit,
    }

    let data
    await ArticleApi.QueryReplyCommentArticle(params).then(async res => {
      data = res
    })
    return data

  }

  // 查看更多评论回复
  MoreReplyComment = item => {

    if (item.offset < 0) {
      return
    }

    const { CommentData } = this.state

    CommentData.map(item2 => {
      if (item.id === item2.id) {
        item2.loading = true
      }
      return item2
    })

    this.setState({ CommentData })
    const ReplyComment = this.QueryReplyCommentArticle(item.id, item.offset, item.limit)
    ReplyComment.then(data => {
      if (data.status === 200) {
        let ReplyCommentData = data.data.list.map(item2 => {
          item2 = {
            ...item2,
            avatar: <Avatar
              icon="user"
              src={base.OssAddress + item2.member_icon}
              alt={item2.member_nick_name}
            />,
            datetime: (
              <Tooltip
                title={moment(item2.creation_time * 1000)
                  .format('YYYY-MM-DD HH:mm:ss')}
              >
                <span>
                  {tools.FormatRemainTimeStr(item2.creation_time)}
                </span>
              </Tooltip>
            ),
          }

          return item2
        })
        CommentData.map(item2 => {
          if (item.id === item2.id) {
            item2.ReplyCommentData = item2.ReplyCommentData.concat(ReplyCommentData)
            item2.loading = false
            item2.offset = data.data.offset
          }
          return item2
        })
        this.setState({
          CommentData: CommentData,
        })

      } else {
        CommentData.map(item2 => {
          if (item.id === item2.id) {
            item2.loading = false
            item2.offset = -1
          }
          return item2
        })

        this.setState({ CommentData })
      }

    })

  }
  //改变字体大小
  ChangeFontSize = (type) => {
    this.state.data.content = this.state.data.content.replace(/font-size:\w+;?/g, "font-size:" + type + "px");

    this.setState({
      actionFontSize: type + "px",
      data: this.state.data
    })

  }
  goActiveDetalis = (item) => {
    router.history.push('/ArticleDetails' + item.title_bar_no + '/' + item.article_no)
  }
  //分享文章
  ArticleShare = (type) => {
    debugger
    ArticleApi.ArticleShareLog(this.props.data.match.params).then()
    var _title = "《" + this.state.data.title + "》-民航安保网",
      _width = 520,
      _height = 600,
      _top = 100,
      _left = 200,
      _url = window.location.href;

    switch (type) {
      case "wb":
        // var _shareUrl = 'http://v.t.sina.com.cn/share/share.php?appkey=895033136';     //真实的appkey，必选参数
        var _shareUrl = 'https://service.weibo.com/share/share.php?';     //真实的appkey，必选参数
        _shareUrl += '&url=' + encodeURIComponent(_url || document.location);
        _shareUrl += '&title=' + encodeURIComponent(_title || document.title);
        _shareUrl += '&content=' + 'utf-8';
        window.open(_shareUrl, '_blank', 'width=' + _width + ',height=' + _height + ',top=' + _top + ',left=' + _left);
        break;
      case "wx":
        // var _shareUrl = "http://qr.liantu.com/api.php?";
        // var text = _title + _url
        // _shareUrl += '&text=' + encodeURIComponent(text);
        // window.open(_shareUrl, '_blank', 'width=450,height=350,top=' + _top + ',left=' + _left)
        if (this.state.BrowserType === "Mobile") {
          message.warning('请使用浏览器的分享功能，把这篇文章分享出去。')
        } else {
          var _shareUrl = "https://api.qrserver.com/v1/create-qr-code/?";
          var text = _url
          _shareUrl += 'size=450x350&data=' + encodeURIComponent(text);
          window.open(_shareUrl, '_blank', 'width=450,height=350,top=' + _top + ',left=' + _left)
        }

        break;
      case "qq":
        var _shareUrl = 'http://connect.qq.com/widget/shareqq/index.html?';
        _shareUrl += 'url=' + encodeURIComponent(_url || document.location);
        _shareUrl += '&title=' + encodeURIComponent(_title || document.title);
        _shareUrl += '&summary=' + encodeURIComponent("民航安保网");
        _shareUrl += '&desc=' + encodeURIComponent("");
        _shareUrl += '&site=' + encodeURIComponent("baidu");
        window.open(_shareUrl, '_blank', 'width=750,height=' + _height + ',top=' + _top + ',left=' + _left);

        break;
      case "bd":
        var _shareUrl = 'http://tieba.baidu.com/f/commit/share/openShareApi?';
        _shareUrl += 'title=' + encodeURIComponent(_title || document.title);  //分享的标题
        _shareUrl += '&url=' + encodeURIComponent(_url || document.location);  //分享的链接
        _shareUrl += '&desc=' + encodeURIComponent(_url || document.location);
        window.open(_shareUrl, '_blank', 'width=700,height=' + _height + ',top=' + _top + ',left=' + _left);
        break;
      default:
        break;
    }


  }
  //下载文章按钮
  handleDown = () => {
    const { userInfo } = store.getState()
    if (!userInfo) {
      message.error("请先登录！")
      return
    }
    let download_permissions = this.state.data.download_permissions.split(",")
    for (let index = 0; index < download_permissions.length; index++) {
      const element = download_permissions[index];
      if (element === '1') {
        this.ActiondDown()
        return
      } else if (element === '2') {
        // 行业会员 就是需要登录
        if (userInfo) {
          this.ActiondDown()
          return
        }
      } else if (element === '3') {
        // vip
        debugger
        if (userInfo && userInfo.vip_type_no.length > 0) {
          this.ActiondDown()
          return
        } else {
          this.setState({
            vipVisible: true
          })
        }
      } else if (element === '4') {
        // 专家
        if (userInfo && userInfo.experts_type_no.length > 0) {
          this.ActiondDown()
          return
        }
      }
    }
  }

  //下载文章
  ActiondDown = () => {
    this.setState({ loading: true })
    var _this = this
    const ic = document.getElementById("downDom");
    const width = ic.offsetWidth; //获取dom 宽度
    const height = ic.offsetHeight; //获取dom 高度
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    const rect = ic.getBoundingClientRect() // 获取元素相对于视口的
    var scale = 2
    height > 5000 ? scale = 1.5 : scale = 2
    var opts = {
      scale: scale,
      x: rect.left, // 绘制的dom元素相对于视口的位置
      y: rect.top,
      logging: true, //日志开关，便于查看html2canvas的内部执行流程
      width: width, //dom 原始宽度
      height: height,
      scrollY: -scrollTop,
      useCORS: true // 【重要】开启跨域配置
    };
    html2canvas(ic, opts).then(function (canvas) {
      var ctx = canvas.getContext("2d")
      var img = document.getElementById('waterMarkImg');
      for (var i = 600; i < canvas.height; i += 100) { //水印
        var m = 0
        for (var j = 0; j < 5; j++) {
          ctx.drawImage(img, m, i, 200, 100);
          m += 210
        }
      }
      var pdfW = 600
      var pdfH = 600 / (canvas.width) * (canvas.height)
      var pdf = new jsPDF('p', 'pt', [pdfW, pdfH]); //A4纸，纵向
      pdf.addImage(canvas.toDataURL("image/jpeg", 1.0), "JPEG", 15, 15, pdfW - 30, pdfH - 30);
      pdf.save(_this.state.data.title + ".pdf");
      _this.setState({ loading: false })
    });
  }
  //去个人页面
  handleVipModalOk = () => {
    router.history.push('/PersonalCenter')
  }
  render() {
    const {
      data,
      BrowserType,
      like_status,
      collection_status,
      loading,
      CommentData,
      offset,
      moreDataLoading,
      RelatedNews,
      AdvertisementData,
      TextContentHeight,
      IsShowArticle,
      HiddenText,
      actionFontSize,
      publishInpLen,
    } = this.state;
    console.log('data1', RelatedNews)
    console.log('dataTime', data)
    // 延展阅读去重
    if (RelatedNews.data) {
      const res = new Map()
      RelatedNews.data = RelatedNews.data.filter((a) => !res.has(a.article_no) && res.set(a.article_no, 1))
      // 按时间倒序排序
      if (RelatedNews.data[0]) {
        if (RelatedNews.data[0].release_time) {
          console.log('ss' + RelatedNews.data[0].release_time, RelatedNews.data[0].creation_time)
          RelatedNews.data = RelatedNews.data.sort(function (a, b) {
            return b.release_time - a.release_time
          })
        } else {
          console.log('dd' + RelatedNews.data[0].release_time, RelatedNews.data[0].creation_time)
          RelatedNews.data = RelatedNews.data.sort(function (a, b) {
            return b.creation_time - a.creation_time
          })
        }
      }

    }


    return (
      <div style={{backgroundColor: '#f3f5f9' }}>
        <div className={' Layout ' + (BrowserType === 'PC' ? styles.MoreArticleContentBox : styles.MoreArticleContentBoxMo)}>
          <img style={{ width: "0px" }} id={"waterMarkImg"} src={require('../../../assets/zh/watermark.png')} alt="" />
          <Col xs={24} sm={24} md={AdvertisementData && AdvertisementData.length > 0 ? 16 : 24} style={{ backgroundColor: "#fff" }} >
            <PageContent loading={loading} >
              <div className={styles.ArticleBox}>
                <div className={styles.ModdleBox}>
                  <div className={styles.content} id={"content"}>
                    <div id={"downDom"} style={BrowserType === 'PC' ? { padding: "0 0.8rem" } : {}}>
                      <div className={styles.Title}>
                        {data.title}
                      </div>
                      <div className={styles.simpleInfo} >
                        <div className={styles.simpleInfo1} >
                          <span>{data.release_time ? tools.UnixToStr(data.release_time, 0, 1) : tools.UnixToStr(data.creation_time, 0, 1)}</span>
                          {/* <span>{tools.UnixToStr(data.creation_time, 2, 1)}</span> */}
                          {data.article_source_status !== 0 ? <span>来源：{data.article_source_status === 1 ? "独家原创" : data.article_source || '转载'}</span> : null}
                          {data.author ? <span>作者：{data.author}</span> : null}
                        </div>
                        <div className={styles.simpleInfo2} >
                          <span>字体：</span>
                          <span className={styles.simpleInfo2Span} onClick={this.ChangeFontSize.bind(this, "18")}>大</span>
                          <span className={styles.simpleInfo2Span} onClick={this.ChangeFontSize.bind(this, "16")}>中</span>
                          <span className={styles.simpleInfo2Span} onClick={this.ChangeFontSize.bind(this, "14")}>小</span>
                        </div>
                      </div>

                      {
                        data.video_url ? (<video src={
                          !data.video_url.startsWith("http")
                            ? base.OssAddress + data.video_url
                            : data.video_url
                        } controls style={{ outline: 'none' }} ></video>) : null
                      }

                      {/* {
                    data.video_url?(<video src={
                      !data.video_url.startsWith("http")
                        ? base.OssAddress + data.video_url
                        : data.video_url
                    } controls style={{outline:'none'}} ></video>):null
                  } */}



                      <div>
                        <div className={styles.TextContent} style={!IsShowArticle ? { height: TextContentHeight, fontSize: actionFontSize } : { fontSize: actionFontSize }} ref={ref => { this.TextContent = ref }} dangerouslySetInnerHTML={{ __html: data.content }}></div>
                        {!IsShowArticle ? <a href="/#/register1" style={{ display: "block", color: "#fff" }}><div className={styles.HiddenText}>
                          {HiddenText} <Icon type="down-circle" />
                        </div></a> : null}
                      </div>

                      {
                        data.acc_url ? (
                          <div className={styles.accUrlWrap}>
                            <p>附件：</p>
                            <a href={base.OssAddress + data.acc_url} target="_blank">{data.acc_name ? data.acc_name : '点击查看附件'}</a>
                          </div>
                        ) : null
                      }

                    </div>


                    <Divider className={styles.DividingLine} ></Divider>

                    <div className={(BrowserType === 'PC' ? '' : styles.MobilePadding)} >
                      {/* <div className={styles.comment} >
                    <Icon onClick={this.ArticleLike} className={styles.LikeIco} type="like" theme="filled" />
                  </div> */}
                      {/* 点赞收藏 */}
                      <div className={styles.operatingArticle} >
                        <div className={styles.operatingArticleCon} style={{ float: "right" }}>
                          <div className={styles.operatingArticleSpan}>
                            <div className={styles.operatingArticleIcon}>
                              <img onClick={this.ArticleLike} src={like_status === 1 ? require("../../../assets/zh/mo_zan2.png") : require("../../../assets/zh/mo_zan1.png")} alt="" />
                            </div>
                            <span> {data.like_count}</span>
                          </div>
                          <div className={styles.operatingArticleIcon} >
                            <img onClick={this.ArticleCollection} src={collection_status === 1 ? require("../../../assets/zh/mo_sc2.png") : require("../../../assets/zh/mo_sc1.png")} alt="" />
                          </div>
                          {
                            data.download_permissions && BrowserType === 'PC' ?
                              <div className={styles.operatingArticleIcon} >
                                <img onClick={() => { this.handleDown() }} src={require("../../../assets/zh/mo_down.png")} alt="" />
                              </div> : null
                          }
                          <div className={styles.operatingArticleIcon} >
                            <img onClick={() => { this.ArticleShare("wb") }} src={require("../../../assets/zh/mo_wb.png")} alt="" />
                          </div>
                          <div className={styles.operatingArticleIcon} >
                            <img onClick={() => { this.ArticleShare("wx") }} src={require("../../../assets/zh/mo_wx.png")} alt="" />
                          </div>
                          <div className={styles.operatingArticleIcon} >
                            <img onClick={() => { this.ArticleShare("qq") }} src={require("../../../assets/zh/qq.png")} alt="" />
                          </div>
                          <div className={styles.operatingArticleIcon} >
                            <img onClick={() => { this.ArticleShare("bd") }} src={require("../../../assets/zh/mo_baidu.png")} alt="" />
                          </div>
                        </div>
                      </div>

                      {/* 发布评论 */}
                      {
                        BrowserType == "PC" ? (
                          <div className={styles.PublishComments} >
                            <div className={styles.ta1} >
                              <h3 style={{ marginBottom: '0.38rem' }}>评论</h3>
                              <span>评论 {data.comment_count}</span>
                            </div>
                            <div className={styles.ta2} >
                              <Input.TextArea ref={input => this.CommentText = input} rows={4} allowClear placeholder="写下您想说的话，最多200字 " maxLength={200} />
                              <Button type="primary" icon="edit" onClick={this.SendComment} >发布评论</Button>
                            </div>
                            <div className={styles.ta3}>
                              <span>网友评论仅代表个人观点，与本网站立场无关</span>
                            </div>
                          </div>
                        ) : (
                          <div className={styles.PublishComments} >
                            <div className={styles.ta1} >
                              <span className={styles.tit}>评论<span>({data.comment_count})</span></span>
                              <div className={styles.publishBtn} onClick={this.SendComment} >
                                <img src={require("../../../assets/zh/mo_icon_fb.png")} />
                                <span>发布</span>
                              </div>
                            </div>
                            <div className={styles.ta2} >
                              <Input.TextArea ref={input => this.CommentText = input} rows={4} allowClear placeholder="写下您想说的话，最多200字 " maxLength={201} onChange={this.publishInp} />
                              <span className={styles.publishInpLen}>{publishInpLen}/200</span>
                            </div>
                            <div className={styles.ta3}>
                              <span>（网友评论仅代表个人观点，与本网站立场无关）</span>
                            </div>
                          </div>
                        )
                      }


                      {/* 用户的评论 */}
                      <div className={styles.UserComment} >
                        <List
                          className="comment-list"
                          itemLayout="horizontal"
                          dataSource={CommentData}
                          loading={moreDataLoading}
                          locale={{
                            emptyText: (<div style={{ textAlign: 'center', padding: '0.8rem 0' }}>
                              <span> <Icon style={{ marginRight: '0.3rem' }} type="exclamation-circle" /> 暂无数据</span>
                            </div>)
                          }}
                          renderItem={item => (
                            <li>
                              <Comment
                                actions={item.actions}
                                author={item.member_nick_name}
                                avatar={item.avatar}
                                content={item.content}
                                datetime={item.datetime}
                              >
                                {
                                  item.isReply ?
                                    <div className={styles.ta2} >
                                      <Input.TextArea ref={input => item.ReplyText = input} rows={4} allowClear placeholder="写下您想说的话，最多200字 " maxLength={200} />
                                      <Button type="primary" icon="edit" onClick={(e) => this.commentReply(e, item)} >
                                        发布评论
                                      </Button>


                                    </div> : null
                                }

                                {
                                  item.ReplyCommentData ?
                                    item.ReplyCommentData.map(item2 => {
                                      return (
                                        <Comment
                                          key={item2.id}
                                          author={item2.member_nick_name}
                                          avatar={item2.avatar}
                                          content={item2.content}
                                          datetime={item2.datetime}
                                        />
                                      )
                                    }) : null
                                }
                                {
                                  item.loading ?
                                    <div style={{ textAlign: 'center', padding: '0.8rem 0' }}>
                                      <span><Icon style={{ marginRight: '0.3rem' }} type="sync" spin />加载更多...</span>
                                    </div> : item.ReplyCommentData && item.ReplyCommentData.length > 0 && item.offset >= 0 ?
                                      <div className={styles.ReplyMoreComment} onClick={() => this.MoreReplyComment(item)}>
                                        <div >
                                          查看更多回复<Icon type="down-circle" />
                                        </div>
                                      </div>
                                      : null
                                }


                              </Comment>

                            </li>
                          )}
                        />

                        {
                          moreDataLoading ?
                            <div className={styles.publishTs} style={{ textAlign: 'center', padding: '0.8rem 0' }}>
                              <span><Icon style={{ marginRight: '0.3rem' }} type="sync" spin />加载更多...</span>
                            </div> : CommentData && CommentData.length === 0 ?
                              null :
                              offset === -1 ?
                                <div className={styles.publishTs} style={{ textAlign: 'center', padding: '0.8rem 0' }}>
                                  <span> <Icon style={{ marginRight: '0.3rem' }} type="exclamation-circle" /> 没有更多了 </span>
                                </div>
                                : <div className={styles.MoreComment} onClick={this.QueryArticleComment}>
                                  <div >
                                    查看更多
                                    <Icon type="down-circle" />
                                  </div>
                                </div>
                        }


                      </div>

                      <Divider className={styles.DividingLine1} ></Divider>
                    </div>


                    {
                      RelatedNews.data && RelatedNews.data.length > 0 ?
                        (<div className={styles.relatedNews}>
                          <div>
                            <span className={styles.relatedNewsTit}>{RelatedNews.title}：</span>
                            <span className={styles.keywords} onClick={() => router.history.push("/MoreArticles0/" + data.keywords)}>{data.keywords}</span>
                          </div>
                          <div className={styles.relatedNewsCon}>
                            <ul>
                              {
                                RelatedNews.data.map((item, ind) => {
                                  return (
                                    <li className={styles.item} key={ind}>
                                      <span className={styles.yuanDian} ></span>
                                      <span className={styles.itemTit} onClick={() => this.goActiveDetalis(item)}>{item.title}</span>
                                      {/* <span className={styles.itemTime}>{tools.UnixToStr(item.creation_time, 2, 1)}</span> */}
                                      <span className={styles.itemTime}>{item.release_time ? tools.UnixToStr(item.release_time, 0, 1) : tools.UnixToStr(item.creation_time, 0, 1)}</span>
                                    </li>
                                  )

                                })
                              }
                            </ul>
                          </div>
                        </div>) : null
                    }

                    {/* 相关新闻 */}
                    {/* {
                  RelatedNews.data && RelatedNews.data.length > 0 ?

                    <TopicItem name={this.state.name} title={RelatedNews.title} data={RelatedNews.data} loading={RelatedNews.loading} ShowMoreBtn={false} />
                    : null
                } */}
                  </div>
                </div>

              </div>

            </PageContent>
          </Col>

          <Col className={styles.AdvertisementDataBox} xs={0} sm={0} md={AdvertisementData && AdvertisementData.length > 0 ? 8 : 0} style={{ padding: '0 0 0 4.13rem' }} >
            {
              AdvertisementData && AdvertisementData.length > 0 ? AdvertisementData.map((item, index) => {
                return (
                  <a href={item.url ? item.url : "javascript:void(0)"} key={item.id + index + 'A'} >
                    <div className={styles.AdvertisementData} >
                      <div>
                        <img src={`${base.OssAddress}${item.pic}`} alt={item.name} />
                      </div>
                      <div className={styles.Mask}></div>
                      <div className={styles.Text}>{item.name}</div>
                    </div>
                  </a>

                )
              }) : null
            }

          </Col>

          <Modal
            title="vip"
            okText="前往"
            cancelText="取消"
            width="400px"
            visible={this.state.vipVisible}
            onOk={() => { router.history.push('/PersonalCenter') }}
            onCancel={() => { this.setState({ vipVisible: false, }) }}
          >
            <p>成为vip，立刻下载该文章</p>
          </Modal>
        </div>
      </div>
    )
  }

}

    // const CommentData = [
    //   {
    //     actions: [<span key="comment-list-reply-to-0">Reply to</span>],
    //     author: 'Han Solo',
    //     avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
    //     content: (
    //       <p>
    //         We supply a series of design principles, practical patterns and high quality design
    //         resources (Sketch and Axure), to help people create their product prototypes beautifully and
    //         efficiently.
    //       </p>
    //     ),
    //     datetime: (
    //       <Tooltip
    //         title={moment()
    //           .subtract(1, 'days')
    //           .format('YYYY-MM-DD HH:mm:ss')}
    //       >
    //         <span>
    //           {moment()
    //             .subtract(1, 'days')
    //             .fromNow()}
    //         </span>
    //       </Tooltip>
    //     ),
    //   },
    // ];
