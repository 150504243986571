import * as actionTypes from '../constants/index';
import { local } from '../../lib/tools'

const setAdvertisement = (data) => {
     local.set(actionTypes.SET_ADVERTISEMENT,data,1)
    return {
        type: actionTypes.SET_ADVERTISEMENT,
        data
    }
};
export {setAdvertisement};
