import React, { Component } from 'react';
import { Row, Col, Button } from 'antd';
import styles from './index.module.less';
import Swiper from '@/src/components/Carousel/swiper';
import ImgCard from '@/src/components/ImgCard';
import API from '@/src/api/Home'
import { tools } from '@/src/lib/tools';
import TopicItem from '@/src/components/TopicItem';
import store from '@/src/redux/store.js';

class Content extends Component {

  state = {
    name: '',
    title_bar_no: '',
    TopicItemCss: '',
    SwiperDataList: [],
    MiddlePic: '',
    LatestDevelopments: [],
  };

  constructor(props) {
    super(props)
    this.state.title_bar_no = props.title_bar_no
    this.state.name = props.name
    this.state.path = props.path
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeBind)
    this.resizeBind()
    this.GetSwiper()

    this.unsubscribe = store.subscribe(this.ListenStore.bind(this))
    this.ListenStore()

  }

  GetSwiper = () => {
    let params = { title_bar_no: this.state.title_bar_no, offset: 0, limit: 5 }
    API.QueryAdvertise(params).then(res => {
      if (res.status === 200) {
        let SwiperDataList = []
        let AdvertisementData = []
        let MiddlePic = ''
        res.data.list.forEach(element => {
          switch (element.advertise_location) {
            case 1:
              SwiperDataList = [...SwiperDataList, element]
              break;

            case 3:
              MiddlePic = element.pic
              break;

            case 2:
              AdvertisementData = [...AdvertisementData, element]
              break;

            default:
              break;
          }
        });
        this.setState({
          SwiperDataList,
          MiddlePic,
        })
        // store.dispatch(setAdvertisement(AdvertisementData))

      } else {

      }


    })
  }

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.TopicItemCss !== '') {
        this.setState({
          TopicItemCss: '',
        });
      }
    } else {
      if (!this.state.TopicItemCss !== 'TopicItem') {
        this.setState({
          TopicItemCss: 'TopicItem',
        });
      }
    }
  }

  ListenStore = () => {
    let { TdTitleBar } = store.getState()

    if (!TdTitleBar || TdTitleBar === {} || TdTitleBar.length === undefined || TdTitleBar.length === 0) {
      return
    }
    let oneTitleList = TdTitleBar.filter(item => {
      return item.level === 2 && item.show_status === 2
    })

    let TwoTitleList = oneTitleList.filter(item => {
      return item.parent_id === this.state.title_bar_no
    })

    this.setState({
      TwoTitleList: TwoTitleList,
    })

    // 查询文章
    this.QueryArticle(TwoTitleList)
  }

  QueryArticle = (_TwoTitleList) => {
    // let _TwoTitleList = this.state.TwoTitleList

    if (!_TwoTitleList || _TwoTitleList.length === 0) {
      return
    }
    _TwoTitleList.map((item, index) => {

      // 文章
      let params = { title_bar_no: item.title_bar_no, offset: 0, limit: 5 }
      if (item.title_bar_no === '1041') {
        params.limit = 9
      } else {
        params.limit = 5
      }

      if (item.title_bar_no !== '1041') {
        API.QueryArticle(params).then(res => {
          
          if (res.status === 200) {
            item.data = res.data.list.map((item2, index) => {
              item2.id = item.title_bar_no + item2.id + '-' + tools.random(10000, 99999)
              if (index === 0 && item.title_bar_no === this.state.title_bar_no) {
                item2.BoldTitle = true
                item2.showPic = false
              }
              item2.title_bar_no = item.title_bar_no
              item2.pic = null
              item2.showRedPoint = false
              return item2
            });
  
            if (item.title_bar_no === '1041') {
              item.data = tools.sliceArr(item.data, 3)
            }
            //  else {
            //   item.data = tools.sliceArr(item.data, 1)
            // }
  
  
            item.loading = false;
          } else {
            item.loading = false;
          }
  
          _TwoTitleList[index] = item
          this.setState({
            TwoTitleList: _TwoTitleList,
          })
        })
      } else {
        API.QueryAdvertise(params).then(res => {
          
          if (res.status === 200) {
            item.data = res.data.list.map((item2, index) => {
              item2.id = item.title_bar_no + item2.id + '-' + tools.random(10000, 99999)
              if (index === 0 && item.title_bar_no === this.state.title_bar_no) {
                item2.BoldTitle = true
                item2.showPic = false
              }
              item2.title_bar_no = item.title_bar_no
              item2.title = item2.name
              return item2
            });
  
            if (item.title_bar_no === '1041') {
              item.data = tools.sliceArr(item.data, 3)
            }
            //  else {
            //   item.data = tools.sliceArr(item.data, 1)
            // }
  
  
            item.loading = false;
          } else {
            item.loading = false;
          }
  
          _TwoTitleList[index] = item
          this.setState({
            TwoTitleList: _TwoTitleList,
          })
    
    
        })
      }

     

      return item
    })

    console.log(_TwoTitleList)
  }

  componentWillUnmount() {
    // 对 store 变化取消监听
    this.unsubscribe();
    window.removeEventListener('resize', this.resizeBind)
  }

  render() {

    return (
      <div className={styles.HomeContent} >

        <div className={styles.content1}>

          <div className={styles.FirstDiv} style={{ width: '100%' }} >
            {
              this.state.SwiperDataList.length > 0 ?
                <Col className={this.state.BrowserType === 'PC' ? styles.homeSwiper : ''} xs={24} sm={24} md={24} >
                  <Swiper autoplay isMask={false} SwiperDataList={this.state.SwiperDataList} />
                </Col>
                : null
            }

          </div>

          {/* <Col xs={24} sm={24} md={24} >
            <div className={styles.LatestDevelopments}>
              最新动态
            </div>
            <ImgCard dots={false} loading={false} data={LatestDevelopments} ShowHeaderTitle={false} />
          </Col> */}

          <Row align='top' type='flex' >
                {this.state.TwoTitleList ? this.state.TwoTitleList.map((item) => {
                  return (
                    item.title_bar_no !== "1041" ?
                      <Col key={item.id} className={this.state.TopicItemCss} xs={24} sm={24} md={(item.SwiperDataList && item.SwiperDataList.length > 0) || (item.ExpertList && item.ExpertList.length > 0) ? 24 : 12} >
                        <TopicItem name={this.state.name} title={item.name} data={item.data} SwiperDataList={item.SwiperDataList} ShowHeaderTitle={item.ShowHeaderTitle} showNum={item.showNum} loading={item.loading} ExpertList={item.ExpertList} title_bar_no={item.title_bar_no} ShowMoreBtn={item.title_bar_no === "1043" ? true : false} />
                      </Col>
                      : <Col key={item.id} xs={24} sm={24} md={24}  >
                        <ImgCard title={item.name} dots={false} loading={item.loading} data={item.data} title_bar_no={item.title_bar_no} name={this.state.name} md={8} ShowMoreBtn={false} />
                      </Col>

                  )
                }) : null}
              </Row>

        </div>

      </div>
    )
  }
}

export default Content;