import Server from './server'

class VideosApi extends Server{

  // 查询视频目录
  QueryVideosList(data) {
    
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_videos_list', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  QueryVideoList(data) {
    
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_video_list', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  //根据id查询单个视频目录
  QueryIdVideo(data) {
    
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_id_video', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  //付费接口
  VideoPay(data) {
    
    try {
      let result = this.axios('post', '/RequestAddress/v2/api/video_pay', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  //我是否买过课程
  MineBuyVideos(data) {
    
    try {
      let result = this.axios('post', '/RequestAddress/v2/api/mine_buy_videos', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  //查询视频评论接口
  QueryVideoComment(data) {
    
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_video_comment', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  //评论视频接口
  CommentVideo(data) {
    
    try {
      let result = this.axios('post', '/RequestAddress/v2/api/comment_video', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  //回复评论视频接口
  ReplyCommentVideo(data) {
    
    try {
      let result = this.axios('post', '/RequestAddress/v2/api/reply_comment_video', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  //查询视频回复评论接口
  QueryReplyCommentVideo(data) {
    
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_reply_comment_video', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

   //视频点击接口(后台统计视频点击的总数)
   VideoClick(data) {
    
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/video_click', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

}

export default new VideosApi()