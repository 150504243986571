//home.js
import React, { Component } from 'react';
import styles from './index.module.less';
import { Skeleton, Row, Col, Icon } from 'antd';
import API from '@/src/api/Home';
import ArticleApi from '@/src/api/ArticleApi';
import { base } from '@/src/api/base'
import { tools } from '@/src/lib/tools';
import store from '@/src/redux/store.js';
import { setArticleDetails } from '@/src/redux/actions/ArticleDetails';
import { setBreadcrumbList } from '@/src/redux/actions/BreadcrumbList';

import { HashRouter } from 'react-router-dom'
const router = new HashRouter()

export default class Home extends Component {

  state = {
    dataSource: [],     // 表格数据
    limit: 10,           // 分页每页显示条数
    offset: 0,
    loading: false,
    BrowserType: 'PC', // PC Mobile 浏览器类型 手机或者pc端
  }

  componentDidMount() {

    this.GetData(this.props.keywords)
    window.addEventListener('scroll', tools.debounce(() => {
      const scroll = document.documentElement || document.body
      const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
      if (scrollTop + scroll.clientHeight + 30 >= scroll.scrollHeight) {
        // 滚动到底部需要做的事情
        // console.log("scrollTop",scrollTop);
        if (scrollTop < 50) {
          return
        }
        this.GetData(this.props.keywords)
      }

    }), 1000)
    window.addEventListener('resize', this.resizeBind);
    this.resizeBind();

  }

  componentWillReceiveProps(props) {

    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });

    this.setState({
      offset: 0,
      dataSource: [],
    }, () => this.GetData(props.keywords))

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeBind)
  }
  componentDidUpdate(prevProps,preState) {
    if (this.state.dataSource === preState.dataSource) {
      return
    }
    let keywords = this.props.keywords
    this.state.dataSource.forEach(item => {
      if (item.title.indexOf(keywords) == -1) return;
      let hightStr = `<span style="color: red;">${keywords}</span>`
      let str = new RegExp(keywords,'gi')
      item.title = item.title.replace(str,hightStr)
      item.introduction = item.introduction.replace(str,hightStr)
    })
  }
  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== 'Mobile') {
        this.setState({
          BrowserType: 'Mobile',
        });
      }
    } else {
      if (!this.state.BrowserType !== 'PC') {
        this.setState({
          BrowserType: 'PC',
        });
      }
    }
  }
  GetData = async (keywords) => {
    
    
    if (this.state.offset < 0 || this.state.loading) {
      return
    }
    const { offset, limit } = this.state;
    let _params = {
      limit,
      offset,
    }
    if (this.props.title_bar_no && this.props.title_bar_no !== undefined && this.props.title_bar_no !== 0 && this.props.title_bar_no !== '0') {
      _params.title_bar_no = this.props.title_bar_no
      
      // console.log("调用了",_params);
      await this.setState({ loading: true });
      await API.QueryArticle(_params).then(res => {
        if (res.status === 200) {
          let dataSource1 = res.data.list.map((item2, index) => {
            item2.id = item2.id + '-' + tools.random(10000, 99999)
            if (index === 0 && item2.title_bar_no === '1001') {
              item2.BoldTitle = true
            }
            item2.title_bar_no = _params.title_bar_no
            return item2
          });

          let dataSource = this.state.dataSource

          dataSource = dataSource.concat(dataSource1)

          // dataSource = [...dataSource , this.state.dataSource]

          this.setState({ dataSource, offset: res.data.offset, loading: false });
        } else {
          this.setState({ offset: -1, loading: false });
          // notification.error({
          //   message: '查询失败！',
          //   description: res.msg,
          // });
        }
        this.setState({ loading: false });
      }, () => {
        this.setState({ loading: false });
      })

    } else if (keywords && keywords !== undefined) {
      _params.keywords = keywords
      // console.log("调用了2",_params);

      await this.setState({ loading: true });

      await ArticleApi.KeysQueryArticle(_params).then(res => {
        if (res.status === 200) {
          let dataSource1 = res.data.list.map((item2, index) => {
            item2.id = item2.id + '-' + tools.random(10000, 99999)
            if (index === 0 && item2.title_bar_no === '1001') {
              item2.BoldTitle = true
            }
            item2.title_bar_no = _params.title_bar_no
            return item2
          });
          let dataSource = this.state.dataSource

          dataSource = dataSource.concat(dataSource1)

          // dataSource = [...dataSource , this.state.dataSource]
        
       
          this.setState({ dataSource, offset: res.data.offset, loading: false });
        } else {
          this.setState({ offset: -1, loading: false });
          // notification.error({
          //   message: '查询失败！',
          //   description: res.msg,
          // });
        }
        this.setState({ loading: false });
      }, () => {
        this.setState({ loading: false });
      })

    }


  }

  // 详情
  ToArticleDetails = (item) => {
    console.log(item)
    store.dispatch(setArticleDetails(item))
    let { BreadcrumbList } = store.getState()
    BreadcrumbList.push(
      {
        id: BreadcrumbList.length + 1,
        name: "新闻详情",
        path: '/ArticleDetails' + item.title_bar_no ? item.title_bar_no :'' + '/' + item.article_no
      }
    )
    store.dispatch(setBreadcrumbList(BreadcrumbList))
    // router.history.push('/ArticleDetails' + item.title_bar_no + '/' + item.article_no)
    let num = item.title_bar_no ? item.title_bar_no : 1001
    window.open(
      base.localhost +
      "/#/ArticleDetails" +
      num +
      "/" +
      item.article_no
    );
  }

  render() {
    let {
      dataSource,
      loading,
      offset,
      BrowserType,
    } = this.state;
    console.log('BrowserType', BrowserType)
     
    // 文章去重
    if (dataSource && dataSource.length > 0) {
      const res = new Map()
      dataSource = dataSource.filter((a) => !res.has(a.article_no) && res.set(a.article_no, 1))
      // 按时间倒序排序
      // if (dataSource[0].release_time) {
      //   dataSource = dataSource.sort(function (a, b) {
      //     return b.release_time - a.release_time
      //   })
      // } else {
      //   dataSource = dataSource.sort(function (a, b) {
      //     return b.creation_time - a.creation_time
      //   })
      // }

    }
    return (
      <div className={BrowserType === 'PC' ? styles.MoreArticleContentBox + ' Layout' : styles.MoreArticleContentBoxMo}>

        {
          dataSource.map(item => {
            return (
              <Skeleton active key={item.id} loading={false} >
                <Row type='flex' >
                  <Col xs={24} sm={24} md={24} >
                    <div className={BrowserType === 'PC' ? styles.ArtucleBox : styles.ArtucleBoxMo}>
                      {
                        item.pic && item.pic.length > 0 ?
                          <div className={styles.Pic}>
                            <img src={base.OssAddress + item.pic} alt={item.title} />
                          </div> : null
                      }
                      <div className={styles.Content + ' ' + (item.pic && item.pic.length > 0 && BrowserType === 'PC' ? styles.Content70 : styles.Content100)} style={{ minWidth: 0 }}>
                        <div className={styles.Title} onClick={() => this.ToArticleDetails(item)} dangerouslySetInnerHTML={{__html: item.title}}>

                        </div>
                        <div className={styles.introduction} dangerouslySetInnerHTML={{__html: item.introduction}}>
                        </div>
                        <div className={styles.OtherInfo}>
                          <pre>点赞 ({item.like_count}) 评论 ({item.comment_count}) | {/*tools.FormatRemainTimeStr(item.creation_time)*/}{item.release_time ? tools.UnixToStr(item.release_time, 0, 1) : tools.UnixToStr(item.creation_time, 0, 1)}</pre>
                        </div>
                      </div>

                    </div>
                  </Col>



                </Row>
              </Skeleton>
            )
          })
        }
        {
          loading ?
            <div style={{ textAlign: 'center', padding: '0.8rem 0' }}>
              <span><Icon style={{ marginRight: '0.3rem' }} type="sync" spin />加载更多...</span>
            </div> : null
        }
        {
          dataSource && dataSource.length === 0 && !loading ?
            <div style={{ textAlign: 'center', padding: '0.8rem 0' }}>
              <span> <Icon style={{ marginRight: '0.3rem' }} type="exclamation-circle" /> 暂无数据</span>
            </div> :
            offset === -1 ?
              <div style={{ textAlign: 'center', padding: '0.8rem 0' }}>
                <span> <Icon style={{ marginRight: '0.3rem' }} type="exclamation-circle" /> 没有更多了 </span>
              </div>
              : null

        }

      </div>
    )
  }

}
