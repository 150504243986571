import React, { Component } from 'react';
import { Menu, Input, Button, Divider, Avatar, Popconfirm, Anchor,Icon } from 'antd';
import styles from './index.module.less';
import { tools } from '@/src/lib/tools';
import store from '@/src/redux/store.js';
import { base } from '@/src/api/base'
import API from '../../../../api/exhibition';
import { HashRouter } from 'react-router-dom'
const router = new HashRouter()
const { Link } = Anchor;
const { Search } = Input;

class PageHeader extends Component {
  state = {
    BrowserType: 'PC', // PC Mobile 浏览器类型 手机或者pc端
    active: "",
    isMoMenuShow: false,
    navList: [],
    signData: [],
    registerData: [],
    showSignBox: true,
    showRegisterBox: true,
    topBanner: [],
    topLogo: [],
  };

  componentDidMount() {
    window.addEventListener('resize', this.resizeBind)
    this.resizeBind.bind(this)
    window.addEventListener('scroll', this.handleScroll);
    this.QueryExhbition()
    if (!tools.IsPC()) {
      this.setState({
        BrowserType: "Mobile"
      });
    }
  }
  componentWillUnmount() {
    // 对 store 变化取消监听
    window.removeEventListener('resize', this.resizeBind)
    this.setState({
      showNav: true,
      TitleList: [],
    })
  }
  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== 'Mobile') {
        this.setState({
          BrowserType: 'Mobile',
        });
      }
    } else {
      if (!this.state.BrowserType !== 'PC') {
        this.setState({
          BrowserType: 'PC',
        });
      }
    }
  };
  QueryExhbition = () => {
    let params = {
      limit: 500,
      year: '2021'
    }
    this.setState({ loading: true })
    API.QueryExhbition(params).then(res => {
      if (res.status === 200) {
        var exhbitionData = res.data.list
        var navList = [
          { id: 0, ids: "introduction_mh_box", name: "大会介绍", enName: "CONGRESS INFORMATION" },
          { id: 1, ids: "agenda_mh_box", name: "大会议程", enName: "CONFERENCE AGENDA" },
          { id: 2, ids: "zhici_mh_box", name: "大会嘉宾", enName: "CONFERENCE AGENDA" },
          { id: 3, ids: "sponsor_mh_box", name: "参展企业", enName: "PERVIOUS REVIEWS" },
          { id: 4, ids: "samePeriod_mh_box", name: "图片直播", enName: "PERVIOUS REVIEWS" },
          { id: 5, ids: "video_mh_box", name: "视频新闻", enName: "CONFERENCE AGENDA" },
          { id: 6, ids: "meiti_mh_box", name: "新媒体联盟", enName: "CONFERENCE AGENDA" },
          // { id: 7, ids: "jiangxiang_mh_box", name: "颁奖实况", enName: "CONFERENCE AGENDA" },
          { id:8, ids: "address_mh_box", name: "大会地址", enName: "CONGRESS VENUE" },
        ]
        var topBanner = exhbitionData.filter((item) => {
          // return item.pid == 5 && item.pid !== item.id;
          return item.config_type === '2021顶部大图';
        });
        var topLogo = exhbitionData.filter((item) => {
          // return item.pid == 5 && item.pid !== item.id;
          return item.config_type === '2021顶部导航栏logo';
        });
          var exh1=exhbitionData.filter(item=>{
            return item.id==2 || item.id==3
          })
          if(exh1 && exh1.length>1 && exh1[0].is_hidden==2 && exh1[1].is_hidden==2){
            navList = navList.filter(item => {
              return item.id !== 3
            })
          }
        exhbitionData.forEach(x => {
          if (x.id == 3 && x.is_hidden == 2) {
            navList = navList.filter(item => {
              return item.id !== 4
            })
          }
          if (x.id == 4 && x.is_hidden == 2) {
            navList = navList.filter(item => {
              return item.id !== 4
            })
          }
          if (x.id == 5 && x.is_hidden == 2) {
            navList = navList.filter(item => {
              return item.id !== 5
            })
          }
          if (x.id == 8 && x.is_hidden == 2) {
            navList = navList.filter(item => {
              return item.id !== 8
            })
          }
        })
        var signData = exhbitionData.filter(item => {
          // return item.id == 16
          return item.config_type === '2021展会报名二维码';
        })
        var registerData = exhbitionData.filter(item => {
          return item.config_type === '2021网站注册二维码';
        })
        this.setState({
          navList,
          signData,
          registerData,
          topBanner,
          topLogo,
        })
      } else {
        // message.error(res.msg);
      }
      this.setState({ loading: false });
    }).catch(res => {
      // console.log(res)
      this.setState({ loading: false });
    })
  }
  handleScroll = (event) => {
    //滚动条高度
    let ctx = this;
    let clientHeight = document.documentElement.clientHeight; //可视区域高度
    let scrollTop = document.documentElement.scrollTop;  //滚动条滚动高度
    let scrollHeight = document.documentElement.scrollHeight; //滚动内容高度
  }
  scrollToAnchor = (anchorName) => {
    if (this.state.isMoMenuShow) {
      this.setState({ isMoMenuShow: false })
    }
    if (!this.props.isHome) {
      router.history.push('/exhibitionHome2021')
      return
    }
    if (anchorName) {
      // 找到锚点
      let anchorElement = document.getElementById(anchorName);
      // 如果对应id的锚点存在，就跳转到锚点
      if (anchorElement) { anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' }); }
      this.setState({
        active: anchorName
      })
    }
    
  }
  render() {
    var {
      BrowserType,
      active,
      isMoMenuShow,
      navList,
      signData,
      registerData,
      showSignBox,
      showRegisterBox,
      topBanner,
      topLogo,
     } = this.state
    var { isHome } = this.props
    var isPC = true
    if (BrowserType !== "PC") {
      isPC = false
    }
    return (
      <div className={BrowserType == "PC" ? styles.HeaderBox + " " + styles.headerBgPC : styles.HeaderBox + " " + styles.headerBgMobile}>
        {/* 二维码 */}
        {
          signData.map((item,index)=>{
            return (
              showSignBox ? (
                <div className={styles.ewmBox} style={isPC ? { top: "100px" } : { top: "100px" }}>
                  <img onClick={() => { this.setState({ showSignBox: false }) }} className={styles.closeEwm} src={require("../../assets/image/icon_close.png")} alt="" />
                  <img className={styles.ewmImg} src={item.pic.startsWith('http') ? item.pic : `${base.OssAddress}${item.pic}`} alt="" />
                  <p>展会图片直播</p>
                </div>
              ) : null
            )
          })
        }
        {
          registerData.map((item,index)=>{
            return (
              showRegisterBox ? (
                <div className={styles.ewmBox} style={isPC ? { top: "300px" } : { top: "230px" }}>
                  <img onClick={() => { this.setState({ showRegisterBox: false }) }} className={styles.closeEwm} src={require("../../assets/image/icon_close.png")} alt="" />
                  <img className={styles.ewmImg} src={item.pic.startsWith('http') ? item.pic : `${base.OssAddress}${item.pic}`} alt="" />
                  <p>网站注册扫码</p>
                </div>
              ) : null
            )
          })
        }
        {topBanner.map((item,index)=>{
            return (
              <img className={styles.cover} 
              src={BrowserType == "PC" ? (item.pic.startsWith("http") ? item.pic : `${base.OssAddress}${item.pic}`)
              : (item.pic.startsWith("http") ? item.pic : `${base.OssAddress}${item.pic}`)}  
              onClick={()=>{router.history.push('/exhibitionHome2021')}} />
            )
          })}

        {
          topLogo.map((item,index)=>{
            return (
              <div>
                <div className={styles.TopBox} style={isPC ? {} : { height: "49px" }}>
                <div className={styles.container}>
                  <div className={styles.logoBox} style={isPC ? { width: "354px" } : { width: "238px" }}>
                    <img src={item.pic.startsWith("http") ? item.pic : `${base.OssAddress}${item.pic}`} onClick={()=>{router.history.push('/exhibitionHome2021')}} alt="全国民航安保科技展2021" />
                  </div>
                  {
                    isPC ? (
                      <div className={styles.MenuBox}>
                        {
                          navList && navList.length > 0 ?
                            navList.map((item, ind) => {
                              return (
                                <div key={ind} className={active == item.ids ? styles.MenuItem + " " + styles.active : styles.MenuItem}>
                                  <a onClick={() => this.scrollToAnchor(item.ids)}>{item.name}</a>
                                </div>
                              )
                            }) : null
                        }
                      </div>
                    ) : (
                        <div className={styles.menuIconBox} onClick={() => { this.setState({ isMoMenuShow: !this.state.isMoMenuShow }) }}>
                          <img src={require("../../assets/image/icon_menu.png")} alt="" />
                        </div>
                      )
                  }
                </div>
              </div>
              {
                !isPC ? (
                  <div className={styles.mobileNav} style={isMoMenuShow ?{ top: "0px" } : { top: "-100%" }}>
                    <Icon  type="close-circle-o" className={styles.closeIcon} onClick={() => { this.setState({ isMoMenuShow: !this.state.isMoMenuShow }) }} />
                    {
                      navList && navList.length > 0 ?
                        navList.map((item, ind) => {
                          return (
                            <div key={ind} className={styles.item} onClick={() => this.scrollToAnchor(item.ids)}>
                              <div className={styles.info}>
                                <span className={styles.zh}>{item.name}</span>
                                {/* <span className={styles.en}>{item.enName}</span> */}
                              </div>
                            </div>
                          )
                        }) : null
                    }
                  </div>
                ) : null
              }
              </div>
            )
          })
        }
        
        

      </div>
    )
  }
}

export default PageHeader;