//home.js
import React, { Component } from 'react';
import styles from './index.module.less';
import EntryFormApi from '@/src/api/EntryFormApi';

import { Col, } from 'antd';
import {  Spin } from 'antd';
import LoginHeader from '@/src/components/LoginHeader'
import PageFooter from '@/src/components/PageFooter';
import { HashRouter } from 'react-router-dom'
import moment from 'moment';

const router = new HashRouter()

export default class EntryFormList extends Component {

    state = {
        loading: false,
        axiosData: []
    };

    componentDidMount() {

        this.QueryApplication()

    }
    
    // 查询报名表
    QueryApplication = () => {
        EntryFormApi.QueryApplication({offset: 0 , limit: 5 }).then(res => {
            if (res.status === 200) {
                this.setState({axiosData: res.data.list})
            } else {
                this.setState({ offset: -1 })
            }

        })
    }

    render() {


        const {axiosData } = this.state;

        return (
            <div>
                <Spin spinning={this.state.loading} delay={10} tip="提交中" >

                    <div className="LoginBox box-margin-top">

                        <LoginHeader showUser={true} title='报名表' />

                        <div className={styles.ContentBox}>

                            <Col xs={23} sm={23} md={20} style={{ margin: '0 auto' }} >

                                <div className={styles.RightBox} >
                                        <div style={{ marginBottom: "1.5rem" }}>
                                            <h2>报名表</h2>
                                        </div>
                                    {
                                        axiosData.map(item => {
                                            return(
                                                <a href={`/#/EntryForm${item.application_no}`} key={item.application_no} >
                                                    <div className={styles.MapTa}>
                                                        <div>
                                                            {item.application_title} 
                                                        </div>
                                                        <div>
                                                        {moment(item.start_time * 1000)
                        .format('YYYY-MM-DD HH:mm:ss')} - {moment(item.end_time * 1000)
                            .format('YYYY-MM-DD HH:mm:ss')}
                                                        </div>
                                                    </div>
                                                </a>
                                                
                                            )
                                        })
                                    }
                                </div>

                            </Col>
                        </div>


                        <PageFooter IsAdvertisementShow={false} colorE9f1fc={false} />

                    </div>
                </Spin>
            </div>
        )
    }


}
