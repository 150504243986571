import React, { Component } from 'react';
import styles from './index.module.less';
import PageHeader from '@/src/components/PageHeader';
import PageFooter from '@/src/components/PageFooter';
import Content from './Content.jsx';

export default class MoreOnlineCourse extends Component {

  state = {
    folder_id: '',
  }

  render() {

    const { folder_id } = this.props.match.params

    return (
      <div className={styles.HomeBox + ' Layout'}>

        <PageHeader showNav={false} showBreadcrumbList={true} BreadcrumbListColorE9f1fc={true} />

        <Content folder_id={folder_id} />

        <PageFooter showFooterNavList={true} colorE9f1fc={true} />

      </div>
    )
  }

}
