//Rule.js
import React, { Component } from 'react';
import styles from './index.module.less';

export default class Rule extends Component {
    render() {
        let content=`<div>
        <p style="margin:0pt 0pt 5pt; text-align:center"><span
                style="font-family:微软雅黑; font-size:36pt">用户协议与免责条款</span><span
                style="font-family:微软雅黑; font-size:36pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 5pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 5pt"><span style="font-family:微软雅黑; font-size:12pt">尊敬的用户您好，感谢您访问</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">！本《用户协议与免责条款》是您（下称"用户"）与</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">之间在使用</span><span
                style="font-family:微软雅黑; font-size:12pt">本网站</span><span
                style="font-family:微软雅黑; font-size:12pt">之前，注册用户（又名“帐号”，下统称“帐号”）和使用</span><span
                style="font-family:微软雅黑; font-size:12pt">服务</span><span
                style="font-family:微软雅黑; font-size:12pt">时签署的协议。此用户协议与免责条款由</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">提供。您若注册并使用了本公司的商品或服务，即表示您认同并接受了此协议。请注意，您的使用受制于该条款、服务、要求。请您在使用网站前仔细阅读此条款！</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 5pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 5pt"><span style="font-family:微软雅黑; font-size:12pt">一、重要须知</span></p>
        <p style="margin:0pt 0pt 5pt"><br /><span style="font-family:微软雅黑; font-size:12pt">1.1
                您应认真阅读、充分理解本《用户协议与免责条款》中各条款，特别是免除或者限制</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">责任的免责条款，用户的权利限制条款，约定争议解决方式、司法管辖、法律适用的条款。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">1.2 除非您接受本协议，否则用户无权也无必要继续接受</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">的服务，可以退出本次注册。用户点击接受并继续使用</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">的服务，视为用户已完全的接受本协议。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">1.3 本协议在您开始使用</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">的服务，并注册成为</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">产品的用户时即产生法律效力，请您慎重考虑是否接受本协议，如不接受本协议的任一</span><span
                style="font-family:微软雅黑; font-size:12pt">条款，请自动退出并不再接受</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">的任何服务。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">1.4
                在您签署本协议之后，此文本可能因国家政策、产品以及履行本协议的环境发生变化而进行修改，我们会将修改后的协议发布在本网站上，若您对修改后的协议有异议的，请立即停止登录、使用</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">产品及服务，若您登录或继续使用</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">产品，视为认可修改后的协议。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 5pt"><br /><span style="font-family:微软雅黑; font-size:12pt">二、关于“帐号”及“付费会员”资格</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 5pt"><br /><span style="font-family:微软雅黑; font-size:12pt">2.1 </span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">在旗下业务平台（包括但不限于</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">网站和APP应用等）提供用户注册通道，用户在认可并接受本协议之后，有权选择未被其他用户使用过的手机号码或字母符号组合作为用户的帐号，并自行设置符合安全要求的密码。用户设置的帐号、密码是用户用以登录</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">产品，接受</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">的凭证。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">1) 用户可通过各种已有和未来新增的渠道注册账号及加入付费使用。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">2) 在用户使用具体某种方式加入付费会员时，须阅读并确认相关的用户协议和使用方法。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">3) 用户通过网络填写并提交注册表，表中所填写的内容与个人资料必须真实有效，否则</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">有权拒绝其申请或撤销其账号或付费会员资格，并不予任何赔偿或退还会员费。用户的个人资料发生变化，应及时修改相关资料，否则由此造成的会员权力不能全面有效行使的责任由会员自己承担，</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">有权因此取消其会员资格，并不予任何赔偿或退还会员费。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">4) 成为付费会员后，会员有权利不接受</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">的服务，可申请取消会员服务，但不获得任何服务费用的退还。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">2.2 用户在注册了</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">帐号并不意味获得全部</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">产品的授权，仅仅是取得了接受</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">服务的身份，用户在登录相关网页、加载应用、下载</span><span
                style="font-family:微软雅黑; font-size:12pt">文档</span><span
                style="font-family:微软雅黑; font-size:12pt">时将按需要另行签署单个产品的授权协议。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">2.3 </span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">账户仅限于在</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">网站上注册用户本人使用，禁止赠与、借用、租用、转让或售卖。如果</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">发现或者有理由怀疑使用者并非帐号初始注册人，则有权在未经通知的情况下，暂停或终止向用户提供服务，并有权注销该帐号，而无需向该帐号使用人承担法律责任，由此带来的包括并不限于用户通讯中断、用户资料和信息等清空等损失由用户自行承担。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">2.4
                用户有责任维护个人帐号、密码的安全性与保密性，用户就其帐号及密码项下之一切活动负全部责任，包括用户数据的修改，发表的言论以及其他所有的损失。用户应重视</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">帐号密码保护。用户如发现他人未经许可使用其帐号或发生其他任何安全漏洞问题时立即通知</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">。如果用户在使用</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">服务时违反上述规则而产生任何损失或损害，</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">不承担任何责任。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">2.5 用户帐号在丢失或遗忘密码后，可遵照</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">的申诉途径及时申诉请求找回帐号。用户应提供能增加帐号安全性的个人密码保护资料。用户可以凭初始注册资料及个人密码保护资料填写申诉单向</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">申请找回帐号，</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">的密码找回机制仅负责识别申诉单上所填资料与系统记录资料的正确性，而无法识别申诉人是否系真正帐号有权使用人。对用户因被他人冒名申诉而致的任何损失，</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">不承担任何责任，用户知晓帐号及密码保管责任</span><span
                style="font-family:微软雅黑; font-size:12pt">在于用户，</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">并无义务保证帐号丢失或遗忘密码后用户一定能通过申诉找回帐号。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">2.6 用户应保证注册</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">帐号时填写的身份信息的真实性，任何由于非法、不真实、不准确的用户信息所产生的责任由用户承担。用户应不断根据实际情况更新注册资料，符合及时、详尽、真实、准确的要求。所有原始键入的资料将引用用户的帐号注册资料。如果因用户的注册信息不真实而引起的问题，以及对问题发生所带来的后果，</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">不负任何责任。如果用户提供的信息不准确、不真实、不合法或者</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">有理由怀疑为错误、不实或不完整的资料或在个人资料中发布广告、不严肃内容及无关信息，</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">有权暂停或终止向用户提供服务，注销该帐号，并拒绝用户现在和未来使用</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">服务之全部或任何部分。因此产生的一切损失由用户自行承担。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 5pt"><br /><span style="font-family:微软雅黑; font-size:12pt">三、用户在使用</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">时，应当遵守《中华人民共和国宪法》、《中华人民共和国刑法》、《中华人民共和国民法通则》、《中华人民共和国合同法》、《中华人民共和国著作权法》、《中华人民共和国电信条例》、《互联网信息服务管理办法》、《互联网电子公告服务管理规定》、《计算机信息网络国际互联网安全保护管理办法》等相关规定。用户不得利用</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">服务产品从事违反法律法规、政策以及侵犯他人合法权益的行为，包括但不限于下列行为：</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 5pt"><br /><span style="font-family:微软雅黑; font-size:12pt">3.1 利用</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">服务产品发表、传送、传播、储存反对宪法所确定的基本</span><span
                style="font-family:微软雅黑; font-size:12pt">原则的、危害国家安全、国家统一、社会稳定的、煽动民族仇恨、民族歧视、破坏民族团结的内容，或侮辱诽谤、色情、暴力、引起他人不安及任何违反国家法律法规政策的内容或者设置含有上述内容的网名、角色名。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">3.2 利用</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">服务发表、传送、传播、储存侵害他人知识产权、商业机密、肖像权、隐私权等合法权利或其他道德上令人反感的内容。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">3.3
                进行任何危害计算机网络安全的行为，包括但不限于：使用未经许可的数据或进入未经许可的服务器/帐户;未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息;未经许可，企图探查、扫描、测试本“软件”系统或网络的弱点或其它实施破坏网络安全的行为;企图干涉、破坏本“软件”系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为;伪造TCP/IP数据包名称或部分名称;自行或利用其他软件对</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">提供产品进行反向破解等违法行为。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">3.4 进行任何诸如发布广告、推广信息、销售商品的行为，或者进行任何非法的侵害</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">利益的行为。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">3.5
                进行其他任何违法以及侵犯其他个人、公司、社会团体、组织的合法权益的行为或者法律、行政法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其他行为。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">在任何情况下，如果</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">有理由认为用户的任何行为，包括但不限于用户的任何言论和其它行为违反或可能违反法律法规、国家政策以及本协议的任何规定，</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">可在任何时候不经任何事先通知，即有权终止向用户提供服务。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 5pt"><span style="font-family:微软雅黑; font-size:12pt">四、</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">声明</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 5pt"><br /><span style="font-family:微软雅黑; font-size:12pt">4.1 用户须知，在使用</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">服务可能存在有来自任何他人的包括威胁性的、诽谤性的、令人反感的或非法的内容或行为或对他人权利的侵犯(包括知识产权)的匿名或冒名的信息的风险，用户须承担以上风险，</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">对服务不作担保，不论是明确的或隐含的，包括所有有关信息真实性、适当性、适于某一特定用途、所有权和非侵权性的默示担保和条件，对因此导致任何因用户不正当或非法使用服务产生的直接、间接、偶然、特殊及后续的损害，</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">不承担任何责任。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">4.2 使用</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">服务必须遵守国家有关法律和政策等，维护国家利益，保护国家安全，并遵守本协议，对于用户违法行为或违反本协议的使用(包括但不限于言论发表、传送等)而引起的一切责任，由用户承担全部责任。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">4.3 </span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">提供的所有信息、资讯、内容和服务均来自互联网，并不代表</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">的观点，</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">对其真实性、合法性概不负责，亦不承担任何法律责任。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">4.4 </span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">所提供的产品和服务也属于互联网范畴，也易受到各种安全问题的困扰，包括但不限于：</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">1)个人资料被不法分子利用，造成现实生活中的骚扰;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">2)哄骗、破译密码;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">3)下载安装的其它软件中含有“特洛伊木马”等病毒程序，威胁到个人计算机上信息和数据的安全，继而威胁对本服务的使用。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">4）以及其他类网络安全困扰问题</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">对于发生上述情况的，用户应当自行承担责任。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">4.5 用户须明白，</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">为了整体运营的需要，有权在公告通知后，在不事先通知用户的情况下修改、中断、中止或终止服务，而无须向用户或第三方负责，</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">不承担任何赔偿责任。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">4.6
                用户应理解，互联网技术存在不稳定性，可能导致政府管制、政策限制、病毒入侵、黑客攻击、服务器系统崩溃或者其他现今技术无法解决的风险发生。由以上原因可能导致</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">服务中断或帐号信息损失，对此非人为因素引起的用户损失由用户自行承担责任。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 5pt"><span style="font-family:微软雅黑; font-size:12pt">五、知识产权</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 5pt"><br /><span style="font-family:微软雅黑; font-size:12pt">5.1 </span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">对其旗下运营的网页、应用、软件等产品和服务享有知识产权。受中国法律的保护。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">5.2用户不得对</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">服务涉及的相关网页、应用、软件等产品进行反向工程、反向汇编、反向编译等。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">5.3 用户只能在本《用户协议》以及相应的授权许可协议授权的范围内使用</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">知识产权，未经授权超范围使用的，构成对</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">的侵权。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">5.4 用户在使用</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">产品服务时发表上传的文字、图片、视频、软件以及表演等信息，用户的发表、上传行为是对</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">服务平台的授权，为非独占性、永久性的授权，该授权可转授权。</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">可将前述信息在</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">旗下的所有服务平台上使用，可再次编辑后使用，也可以由</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">授权给合作方使用。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">5.5 用户应保证，在使用</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">产品服务时上传的文字、图片、视频、软</span><span
                style="font-family:微软雅黑; font-size:12pt">件以及表演等的信息不侵犯任何第三方知识产权，包括但不限于商标权、著作权等。若用户在使用</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">产品服务时上传的文字、图片、视频、软件以及表演等的信息中侵犯第三方知识产权，</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">有权移除该侵权产品，并对此不负任何责任。用户应当负责处理前述第三方的权利主张，承担由此产生的全部费用，包括但不限于侵权赔偿、律师费及其他合理费用，并保证</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">不会因此而遭受任何损失。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">5.6 任何单位或个人认为通过</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">提供服务的内容可能涉嫌侵犯其知识产权或信息网络传播权，应该及时向</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">提出书面权利通知投诉，并提供身份证明、权属证明及详细侵权情况证明。</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">在收到上述法律文件后，将会依法尽快断开相关链接内容。</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">提供投诉通道，</span><span
                style="font-family:微软雅黑; font-size:12pt">anbohuizhan@163.com</span><span
                style="font-family:微软雅黑; font-size:12pt">。如投诉中未向</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">提供合法有效的证明材料，</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">有权不采取任何措施。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 5pt"><span style="font-family:微软雅黑; font-size:12pt">六、隐私保护</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 5pt"><br /><span style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">非常重视用</span><span
                style="font-family:微软雅黑; font-size:12pt">户的隐私权，用户在享受</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">提供的服务时可能涉及用户的隐私，因此请用户仔细阅读本隐私保护条款。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">6.1 请用户注意勿在使用</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">服务中透露自己的各类财产帐户、银行卡、信用卡、第三方支付账户及对应密码等重要信息资料，否则由此带来的任何损失由用户自行承担。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">6.2 用户的帐号、密码属于保密信息，</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">会努力采取积极的措施保护用户帐号、密码的安全。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">6.3 互联网的开放性以及技术更新速度快，因非</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">可控制的因素导致用户信息泄漏的，</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">不承担任何责任。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span><br /><span
                style="font-family:微软雅黑; font-size:12pt">6.4 用户在使用</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">服务时不应将自认为隐私的信息发表、上传至</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">，也不应将该等信息通过</span><span
                style="font-family:微软雅黑; font-size:12pt">民航安保网</span><span
                style="font-family:微软雅黑; font-size:12pt">的服务传播给其他人，由于用户的行为引起的隐私泄漏，由用户自行承担责任。</span><span
                style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 5pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 5pt"><span style="font-family:微软雅黑; font-size:12pt">民航安保网</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:等线; font-size:10.5pt">&#xa0;</span></p>
    </div>`
        return (
                    <div className={styles.wrap}>
                        <div className={styles.ContentBox}>
                            <div className={styles.TextContent} dangerouslySetInnerHTML={{ __html: content }}></div>
                        </div>
                    </div>
        )
    }

}