import React, { Component } from 'react';
import { Row, Col } from 'antd';
import styles from './index.module.less';
import Swiper from '@/src/components/Carousel/swiper';
import TopicItem from '@/src/components/TopicItem';
import ImgCard from '@/src/components/ImgCard1';
import store from '@/src/redux/store.js';
import API from '@/src/api/Home'
import { tools } from '@/src/lib/tools';
import { setAdvertisement } from '@/src/redux/actions/Advertisement';
import { base } from '@/src/api/base';
import AdAround from "@/src/components/AdAround";
import { setAdAroundR, setAdAroundL } from "@/src/redux/actions/AdAround";
class Content extends Component {

  state = {
    name: '',
    title_bar_no: '',
    TopicItemCss: '',
    SwiperDataList: [],
    NewsSwiperDataList:[],
    MiddlePic: '',
    AdvertisementData: [],
    adAroundRList: [], //右侧广告
    adAroundLList: [], //左侧广告
    BrowserType:"PC",
    onlyOnce: true
  };

  constructor(props) {
    super(props)
    this.state.title_bar_no = props.title_bar_no
    this.state.name = props.name
    this.state.path = props.path
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(this.ListenStore.bind(this))
    window.addEventListener('resize', this.resizeBind)
    this.ListenStore()
    this.resizeBind()
    this.GetSwiper()
  }

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.TopicItemCss !== "") {
        this.setState({
          TopicItemCss: "",
        });
      }
      if (this.state.BrowserType !== "Mobile") {
        this.setState({
          BrowserType: "Mobile",
        });
      }
    } else {
      if (!this.state.TopicItemCss !== "TopicItem") {
        this.setState({
          TopicItemCss: "TopicItem",
        });
      }
      if (!this.state.BrowserType !== "PC") {
        this.setState({
          BrowserType: "PC",
        });
      }
    }
  };


  ListenStore = () => {
    if (!this.state.onlyOnce) return
    let { TdTitleBar } = store.getState()

    if (!TdTitleBar || TdTitleBar === {} || TdTitleBar.length === undefined || TdTitleBar.length === 0) {
      return
    }
    let oneTitleList = TdTitleBar.filter(item => {
      return item.level === 2 && item.show_status === 2
    })

    let TwoTitleList = oneTitleList.filter(item => {
      return item.parent_id === this.state.title_bar_no
    })

    // 添加一个置顶的 
    TwoTitleList = [{
      id: 0,
      title_bar_no: this.state.title_bar_no,
      parent_id: '',
      name: "",
      level: 2,
      sort: 1,
      nav_status: 2,
      show_status: 2,
      ShowHeaderTitle: false,
      data: [

      ]
    }, ...TwoTitleList]
    TwoTitleList = [TwoTitleList[0],TwoTitleList[2],TwoTitleList[1],TwoTitleList[3]]
    this.setState({
      TwoTitleList: TwoTitleList,
    });

    // 查询文章
    this.QueryArticle(TwoTitleList);
    this.setState({
      onlyOnce: false
    });
  }

  QueryArticle = (_TwoTitleList) => {
    // let _TwoTitleList = this.state.TwoTitleList

    if (!_TwoTitleList || _TwoTitleList.length === 0) {
      return
    }
    const firstLimit = 7;
    _TwoTitleList.map((item, index) => {
      // 文章
      let params = { title_bar_no: item.title_bar_no, offset: 0, limit: 8 }
      if (item.title_bar_no === "1034") {
        params.limit = firstLimit
        item.showNum = firstLimit;
      }
      if (item.title_bar_no === "1005") {
        params.limit = firstLimit
        item.showNum = firstLimit;
      }
      // 文章
      if (item.title_bar_no === '1036') {
        params.limit = 6
      } else if (item.title_bar_no === '1037') {
        params.limit = 8
      }

      if (item.title_bar_no === '1036') {
        params.limit = 9
        API.QuerySubject(params).then(res => {

          if (res.status === 200) {

            item.data = res.data.list.map((item2, index) => {
              item2.id = item.title_bar_no + item2.id + '-' + tools.random(10000, 99999)
              if (index === 0 && item.title_bar_no === this.state.title_bar_no) {
                item2.BoldTitle = true
                item2.showPic = false
              }
              item2.title_bar_no = item.title_bar_no
              item2.title = item2.subject_name

              return item2
            });

            if (item.title_bar_no === '1036') {
              item.data = tools.sliceArr(item.data, 6)
            } else if (item.title_bar_no === '1037') {
              item.data = tools.sliceArr(item.data, 8)
            }


            item.loading = false;
          } else {
            item.loading = false;
          }

          _TwoTitleList[index] = item
          this.setState({
            TwoTitleList: _TwoTitleList,
          })
        })
      } else {
        API.QueryArticle(params).then(res => {

          if (res.status === 200) {
            item.data = res.data.list.map((item2, index) => {
              item2.id = item.title_bar_no + item2.id + '-' + tools.random(10000, 99999)
              if (index === 0 && item.title_bar_no === this.state.title_bar_no || index === 0 &&  item.title_bar_no=="1034") {
                item2.BoldTitle = true
                item2.showPic = false
              }
              item2.title_bar_no = item.title_bar_no

              return item2
            });

            if (item.title_bar_no === '1036') {
              item.data = tools.sliceArr(item.data, 6)
            } else if (item.title_bar_no === '1037') {
              item.data = tools.sliceArr(item.data, 8)
            }


            item.loading = false;
          } else {
            item.loading = false;
          }

          _TwoTitleList[index] = item
          this.setState({
            TwoTitleList: _TwoTitleList,
          })
        })
      }

      return item
    })
  }

  componentWillUnmount() {
    // 对 store 变化取消监听
    this.unsubscribe();
    window.removeEventListener('resize', this.resizeBind)
  }

  GetSwiper = () => {
    let params = { title_bar_no: this.state.title_bar_no, offset: 0, limit: 5 }
    API.QueryAdvertise(params).then(res => {
      if (res.status === 200) {
        let SwiperDataList = []
        let NewsSwiperDataList = []
        let AdvertisementData = []
        let MiddlePic = ''
        res.data.list.forEach(element => {
          switch (element.advertise_location) {
            case 1:
              SwiperDataList = [...SwiperDataList, element]
              break;

            case 3:
              MiddlePic = element.pic
              break;
            case 4:
              NewsSwiperDataList = [...NewsSwiperDataList, element]
              break;
            case 2:
              AdvertisementData = [...AdvertisementData, element]
              break;

            default:
              break;
          }
        });
        this.setState({
          SwiperDataList,
          NewsSwiperDataList,
          MiddlePic,
          AdvertisementData,
        })
        store.dispatch(setAdvertisement(AdvertisementData))

      } else {

      }


    })
    //左右广告
    let params1 = {
      title_bar_no: "lrAd1005",
      offset: 0,
      limit: 8,
    };
    API.QueryAdvertise(params1).then((res) => {
      if (res.status === 200) {
        let adAroundRList = [];
        let adAroundLList = [];
        res.data.list.forEach((element) => {
          switch (element.advertise_location) {
            case 2:
              adAroundRList = [...adAroundRList, element];
              break;

            case 4:
              adAroundLList = [...adAroundLList, element];
              break;

            default:
              break;
          }
        });
        store.dispatch(setAdAroundR(adAroundRList));
        store.dispatch(setAdAroundL(adAroundLList));
        this.setState({
          adAroundRList,
          adAroundLList,
        });
      } else {
      }
    });
  }

  render() {

    const { AdvertisementData,BrowserType } = this.state
    console.log(this.state.TwoTitleList,this.state.NewsSwiperDataList);
    return (
      <div className={styles.HomeContent} >
        <Row justify='space-between' align="bottom" type='flex' >
{/* 
          <div className={styles.FirstDiv} style={BrowserType=="PC"?{}:{padding:"15px 16px",background:"#fff"}}>
            {
              this.state.SwiperDataList.length > 0 ?
                <Col className={this.state.TopicItemCss} xs={24} sm={24} md={24} >
                  <Swiper autoplay SwiperDataList={this.state.SwiperDataList} textPosition={true} isTextShow={true}/>
                </Col>
                : null
            }
          </div> */}
          <div className={BrowserType == "PC" ? styles.ModdleBox : styles.ModdleBoxMo}>
            <Col xs={24} sm={24} md={AdvertisementData && AdvertisementData.length > 0 ? 19 : 24}>
              <Row align='top' type='flex' >
                {this.state.TwoTitleList ? this.state.TwoTitleList.map((item, index) => {
                  return (
                    // 1036往届回顾
                    index > 0 ? item.title_bar_no !== "1036" && item.title_bar_no !== "1037" ? item.title_bar_no == "1034" ? (
                      <Col key={item.id} className={this.state.TopicItemCss} xs={24} sm={24} md={24} >
                        <TopicItem name={this.state.name} title={item.name} data={item.data} SwiperList={this.state.NewsSwiperDataList} ShowHeaderTitle={item.ShowHeaderTitle} showNum={item.showNum} loading={item.loading} ExpertList={item.ExpertList} title_bar_no={item.title_bar_no} isSwiperTopicItem={true} />
                      </Col>
                    ) : (
                        <Col key={item.id} className={this.state.TopicItemCss} xs={24} sm={24} md={(item.SwiperDataList && item.SwiperDataList.length > 0) || (item.ExpertList && item.ExpertList.length > 0) ? 24 : 12} >
                          <TopicItem name={this.state.name} title={item.name} data={item.data} SwiperDataList={item.SwiperDataList} ShowHeaderTitle={item.ShowHeaderTitle} showNum={item.showNum} loading={item.loading} ExpertList={item.ExpertList} title_bar_no={item.title_bar_no} />
                        </Col>
                      ) : <Col key={item.id} xs={24} sm={24} md={24} className={item.title_bar_no === "1036" || item.title_bar_no === "1037" ? this.state.TopicItemCss : ''} >
                        {
                           item.title_bar_no === "1037" ?
                            <ImgCard title={item.name} dots={false} loading={item.loading} data={item.data} title_bar_no={item.title_bar_no} name={this.state.name} md={6} isHover={true} ShowMask={true} />
                            :  <ImgCard title={item.name} dots={false} loading={item.loading} data={item.data} title_bar_no={item.title_bar_no} name={this.state.name} md={8} isHover={false} ShowMask={false} />
                        }
                      </Col> : null

                  )
                }) : null}
              </Row>
            </Col>

            <Col className={styles.AdvertisementDataBox} xs={0} sm={0} md={AdvertisementData && AdvertisementData.length > 0 ? 5 : 0} >
              {
                AdvertisementData.map((item, index) => {
                  return (
                    <a href={item.url ? item.url : "javascript:void(0)"} key={item.id + index + 'A'} >
                      <div className={styles.AdvertisementData}>
                        <div>
                          <img src={`${base.OssAddress}${item.pic}`} alt={item.name} />
                        </div>
                        <div className={styles.Text}>
                          <div>
                            {item.name}
                          </div>
                        </div>
                      </div>
                    </a>

                  )
                })
              }

            </Col>

          </div>

          {/* {
            this.state.SwiperDataList.length > 0 ?
              <Col className={this.state.TopicItemCss} xs={24} sm={24} md={24} >
                <Swiper autoplay SwiperDataList={this.state.SwiperDataList} />
              </Col>
              : null
          }


          {this.state.TwoTitleList ? this.state.TwoTitleList.map((item) => {
            return (
              item.title_bar_no !== "1036" && item.title_bar_no !== "1037" ?
                <Col key={item.id} className={this.state.TopicItemCss} xs={24} sm={24} md={(item.SwiperDataList && item.SwiperDataList.length > 0) || (item.ExpertList && item.ExpertList.length > 0) ? 24 : 12} >
                  <TopicItem name={this.state.name} title={item.name} data={item.data} SwiperDataList={item.SwiperDataList} ShowHeaderTitle={item.ShowHeaderTitle} showNum={item.showNum} loading={item.loading} ExpertList={item.ExpertList} title_bar_no={item.title_bar_no} />
                </Col>
                : <Col key={item.id} xs={24} sm={24} md={24} className={item.title_bar_no === "1036" || item.title_bar_no === "1037" ? '' : this.state.TopicItemCss} >
                  {
                    item.title_bar_no === "1036" || item.title_bar_no === "1037" ?
                      <ImgCard title={item.name} dots={false} loading={item.loading} data={item.data} title_bar_no={item.title_bar_no} name={this.state.name} md={item.title_bar_no === "1037" ? 6 : 8} />
                      : <TopicItem name={this.state.name} title={item.name} data={item.data} SwiperDataList={item.SwiperDataList} ShowHeaderTitle={item.ShowHeaderTitle} showNum={item.showNum} loading={item.loading} ExpertList={item.ExpertList} title_bar_no={item.title_bar_no} />
                  }
                </Col>

            )
          }) : null} */}

        </Row>

      </div>
    )
  }
}

export default Content;