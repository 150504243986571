import * as actionTypes from "../constants/index";
const Setting = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SET_SETTING:
      let newd = action.data;
      // 不为空 
      if (newd) {
        // 在store中已经有值
        // if(state[newd.key]){
        state[newd.key] = newd.val;
        // }else{

        // }
      }
	  return state;
    //   return action.data;
    default:
      return state;
  }
};

export default Setting;
