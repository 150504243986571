import ta_js from 'crypto-js'

export const TAMethodADD = (message, key , iv) => {
  message = JSON.stringify(message)
  var keyHex = ta_js.enc.Utf8.parse(key);
  var encrypted = ta_js.TripleDES.encrypt(message, keyHex, {
    iv: ta_js.enc.Utf8.parse(iv),
    mode: ta_js.mode.CBC,
    padding: ta_js.pad.Pkcs7
  });
  return encrypted.toString();
}

export const TAMethod =  (ciphertext, key , iv )  => {
  var keyHex = ta_js.enc.Utf8.parse(key);
   
  var decrypted =  ta_js.TripleDES.decrypt({
    ciphertext: ta_js.enc.Base64.parse(ciphertext)
  }, keyHex, {
      iv: ta_js.enc.Utf8.parse(iv),
      mode: ta_js.mode.CBC,
      padding: ta_js.pad.Pkcs7
    });
 
  return decrypted.toString(ta_js.enc.Utf8);
}   
 
