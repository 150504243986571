import React, { Component } from "react";
import { Card, Icon, message, Row, Col, Skeleton, Avatar } from "antd";
import styles from "./index.module.less";
import { tools } from "@/src/lib/tools";
import Swiper from "@/src/components/Carousel/swiper";
import Expert from "@/src/components/Expert/";
import { base } from "@/src/api/base";
import API from '@/src/api/api.js'
import store from "@/src/redux/store.js";
import { setArticleDetails } from "@/src/redux/actions/ArticleDetails";
import { setBreadcrumbList } from "@/src/redux/actions/BreadcrumbList";

import { HashRouter } from "react-router-dom";
import moment from "moment";
const router = new HashRouter();

class TopicItem extends Component {
  state = {
    BrowserType: "PC", // PC Mobile 浏览器类型 手机或者pc端
    bigTitleColorConfig: "#3c78ce",
    // smallTitleColorConfig: "#a2373d"
    smallTitleColorConfig: "",
    currentVideoUrl: "",
    currentVideoName: "",
    currentVideoTime: "",
    currentVideoArticleNum: "",
    videoList: [],
    selectIndex: 1,
    videoNo: '',
    expertInfoList: []
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeBind);
    this.resizeBind();
    this.getbigTitleColor();
  }
  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== "Mobile") {
        this.setState({
          BrowserType: "Mobile",
        });
      }
    } else {
      if (!this.state.BrowserType !== "PC") {
        this.setState({
          BrowserType: "PC",
        });
      }
    }
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeBind);
  }
  // 详情
  TopicItemContentClick = (item, title_bar_no, index) => {

    var i = this.props.data.findIndex((value) =>
      value == item
    )
    if (item.title_bar_no == "1052" || item.title_bar_no == '1053') {
      this.setState({
        currentVideoUrl: item.video_url,
        currentVideoName: item.title,
        currentVideoTime: item.update_time,
        currentVideoArticleNum: item.article_no
      })
      if (i == this.props.data.length - 1) {
        this.setState({
          videoList: this.props.data.slice(-4),
        })
      } else {
        this.setState({
          videoList: this.props.data.slice(i, i + 7),
        })
      }
      return;
    }
    if (item.title_bar_no === "1042") {
      return;
    }
    // 1036往届回顾
    if (item.title_bar_no === "1022" || item.title_bar_no === "1036") {
      // console.log(item.subject_no);
      // window.location.href='/ThematicTemplate' + item.subject_no + '/专题'
      // router.history.push('/ThematicTemplate' + item.subject_no + '/专题')
      window.open(
        base.localhost + "/#/ThematicTemplate" + item.subject_no + "/ThematicTemplate"
      );
      return;
    }
    this.ToArticleDetails(item, title_bar_no);


    // const { userInfo } = store.getState()

    // let read_status = false
    // if (!item.read_permissions) {
    //   read_status = true
    //   this.ToArticleDetails(item, title_bar_no)
    // }else{
    //   let read_permissions = item.read_permissions.split(',')
    //   for (let index = 0; index < read_permissions.length; index++) {
    //     const element = read_permissions[index];
    //     if (element === '1') {
    //       read_status = true
    //       this.ToArticleDetails(item, title_bar_no) ;
    //       return
    //     }else if (element === '2') {
    //       // 行业会员 就是需要登录
    //       if (userInfo) {
    //         read_status = true
    //         this.ToArticleDetails(item, title_bar_no)
    //         return
    //       }
    //     }else if (element === '3') {
    //       // vip
    //       if (userInfo && userInfo.vip_type_no.length > 0) {
    //         read_status = true
    //         this.ToArticleDetails(item, title_bar_no)
    //         return
    //       }
    //     }else if (element === '4') {
    //       // 专家
    //       if (userInfo && userInfo.experts_type_no.length > 0) {
    //         read_status = true
    //         this.ToArticleDetails(item, title_bar_no)
    //         return
    //       }
    //     }
    //   }

    //   if (!read_status) {
    //     for (let index = 0; index < read_permissions.length; index++) {
    //       const element = read_permissions[index];
    //       if (element === '2') {
    //         // 行业会员 就是需要登录
    //         if (userInfo) {
    //           read_status = true
    //           this.ToArticleDetails(item, title_bar_no)
    //           return
    //         }else{
    //           message.error("请先登录！")
    //           return
    //         }
    //       }else if (element === '3') {
    //         // vip
    //         if (userInfo && userInfo.vip_type_no.length > 0) {
    //           read_status = true
    //           this.ToArticleDetails(item, title_bar_no)
    //           return
    //         }else{
    //           message.error("该文章需要vip才能阅读")
    //           return
    //         }
    //       }else if (element === '4') {
    //         // 专家
    //         if (userInfo && userInfo.experts_type_no.length > 0) {
    //           read_status = true
    //           this.ToArticleDetails(item, title_bar_no)
    //           return
    //         }else{
    //           message.error("该文章需要注册专家账号才能阅读！")
    //           return
    //         }
    //       }
    //     }
    //   }

    // }
  };
  videoDetails = (articleNo) => {
    store.dispatch(
      setBreadcrumbList([
        {
          id: "1",
          name: "新闻",
          path: "/MHNews1002/1002"
        },
        {
          id: "2",
          name: "新闻详情",
          path: "/ArticleDetails" + 1053 + "/" + articleNo,
        },
      ])
    );
    window.open(`${base.localhost}/#/ArticleDetails1053/${articleNo}`, "_blank");
  }
  ToArticleDetails = (item, title_bar_no) => {
    store.dispatch(setArticleDetails(item));

    // 相关新闻直接跳新闻详情 XGXW
    if (title_bar_no === "XGXW") {
      // router.history.push('/ArticleDetails')
      // return
    }
    if (title_bar_no === undefined) {
      title_bar_no = item.title_bar_no;
    }
    if (title_bar_no === undefined) {
      window.open(
        base.localhost +
        "/#/ArticleDetails" +
        title_bar_no +
        "/" +
        item.article_no
      );
      // router.history.push('/ArticleDetails' + title_bar_no + '/' + item.article_no)
      return;
    }

    store.dispatch(
      setBreadcrumbList([
        {
          id: "1",
          // name: this.props.name,
          name: title_bar_no,
          path: null,
        },
        {
          id: "2",
          name: "新闻详情",
          path: "/ArticleDetails" + title_bar_no + "/" + item.article_no,
        },
      ])
    );
    // http://localhost:3001/#/ArticleDetails1001/20200518112328268715113530265600
    // http://localhost:3001/ArticleDetails1001/20200518112758268716243886804992#/
    // router.history.push('/ArticleDetails' + title_bar_no + '/' + item.article_no)
    window.open(
      base.localhost +
      "/#/ArticleDetails" +
      title_bar_no +
      "/" +
      item.article_no
    );
  };

  // 更多
  MoreArticles = (title_bar_no) => {
    // console.log(title_bar_no);
    // this.props.name

    if (title_bar_no === "1022" || title_bar_no === "1036") {
      store.dispatch(
        setBreadcrumbList([
          {
            id: "1",
            name: title_bar_no,
            path: null,
          },
          {
            id: "2",
            name: "专题",
            path: "/Special" + title_bar_no,
          },
        ])
      );

      router.history.push("/Special" + title_bar_no);
    } else if (title_bar_no === "1051") {
      window.open(base.localhost + "/#/FireFighting1004/1044");
    } else {
      store.dispatch(
        setBreadcrumbList([
          {
            id: "1",
            name: title_bar_no,
            path: null,
          },
          {
            id: "2",
            name: "新闻列表",
            path: "/MoreArticles" + title_bar_no + "/0",
          },
        ])
      );
      if (title_bar_no == "1011") {
        // router.history.push("/MHNews1002/民航新闻");
        window.open(base.localhost + "/#/MHNews1002/1002");
        return;
      }
      if (title_bar_no == "1012") {
        window.open(base.localhost + "/#/SmartSecurity1003/1003");
        // router.history.push("/SmartSecurity1003/智慧安保");
        return;
      }
      if (title_bar_no == "1013") {
        // router.history.push("/Exhibition1005/展会频道");
        window.open(base.localhost + "/#/Exhibition1005/1005");

        return;
      }
      if (title_bar_no === "1014") {
        // router.history.push("/ExpertColumn1004/专家专栏");
        window.open(base.localhost + "/#/ExpertColumn1004/1004");

        return;
      }

      if (title_bar_no === "1030") {
        router.history.push("/ExpertList");
      } else if (title_bar_no === "1015") {
        router.history.push("/Member1006/1006");
      } else {
        window.open(base.localhost + "/#/MoreArticles" + title_bar_no + "/0");
        // router.history.push("/MoreArticles" + title_bar_no + "/0");
      }
    }
  };

  // 大标题颜色
  getbigTitleColor() {
    // var data = {
    //   id: 12
    // }
    if ('1101' != this.props.title_bar_no) {
      return;
    }

    let { Setting } = store.getState();
    // 大标题颜色配置
    let color = '#a2373d'
    // 小标题颜色配置
    let color2 = '#a2373d'
    if (Setting && !!Setting['12']) {
      color = JSON.parse(Setting['12'])[0];
    }
    if (Setting && !!Setting['13']) {
      color2 = JSON.parse(Setting['13'])[0];
    }
    this.setState({
      bigTitleColorConfig: color,
      smallTitleColorConfig: color2,
    });
  };
  getVideoComp(isPC, showRedPoint, showNum) {
    let { currentVideoUrl, currentVideoName, videoList, videoNo, currentVideoTime, currentVideoArticleNum } = this.state;
    if (videoList.length === 0) {
      videoList = this.props.data
    }
    if (this.props.title_bar_no === "1052") {
      videoNo = 1052
    }
    if (this.props.title_bar_no === "1053") {
      videoNo = 1053
    }
    return (
      <div className={styles.vidWrap}>
        <Col xs={24} sm={24} md={13}>
          <div
            className={styles.TopicItemList}
            style={
              this.props.isSwiperTopicItem && isPC
                ? { padding: "0 1rem" }
                : {}
            }
          >
            {/* 1052为首页视频模块 */}
            {this.props.title_bar_no === `${videoNo}` && this.props.data && this.props.data.length >= 0 ? (
              <div>
                <div className={styles.videoMax}>
                  <div style={{ width: '100%', height: '82%', textAlign: 'center' }}>
                    <video
                      src={`${base.OssAddress}${currentVideoUrl !== "" ? currentVideoUrl : this.props.data[0].video_url}`}
                      style={{ width: '0px', height: '100%', objectFit: 'contain' }}
                      controls
                      preload="auto"
                      x-webkit-airplay="true"
                      x5-video-player-fullscreen="false"
                      webkit-playsinline="true"
                      x5-video-player-type="h5"
                      x5-video-orientation="portraint"
                      playsInline={true}
                      controlsList="nodownload"
                      onLoadedData={(e) => {
                        // debugger
                        console.log('onLoadedData')
                        e.target.style.width = "99.9%";
                        if (currentVideoUrl == 'https://hanganyun.oss-cn-beijing.aliyuncs.com/minhang/content/74a400a22e17df76b752c28d5f770648.mp4') {
                          e.target.style.width = "auto";
                        }
                      }}
                      onLoadStart={(e) => {
                        console.log('onLoadStart')
                        e.target.style.width = "0px";
                      }}
                    ></video>
                  </div>
                  <div className={styles.videoBottomTitle}>
                    <div>
                      {currentVideoName !== "" ? currentVideoName.split("-")[0] : (this.props.data[0].title).split("-")[0]}
                      <span>
                        {currentVideoTime !== "" ? tools.UnixToStr(currentVideoTime, 0, 1, 1) : tools.UnixToStr(this.props.data[0]?.creation_time, 0, 1, 1)}
                      </span>
                    </div>
                    <div>
                      {currentVideoName !== "" ? currentVideoName.split("-")[1] : (this.props.data[0].title).split("-")[1]}
                      <Icon type="arrow-right" onClick={() => this.videoDetails(currentVideoArticleNum !== "" ? currentVideoArticleNum : this.props.data[0].article_no)} />
                    </div>
                  </div>

                </div>
              </div>
            ) : (
              <div style={{ textAlign: "right", color: "#333" }}>

              </div>
            )}
          </div>
        </Col>
        <Col xs={24} sm={24} md={11}>
          <div
            className={isPC ? styles.TopicItemListNew : styles.TopicItemListMO}
            style={
              this.props.isSwiperTopicItem && isPC
                ? { padding: "0 1rem" }
                : {}
            }
          >
            {videoList && videoList.length > 1 ? (
              videoList.map((item, index) => {
                if (index + 1 > showNum) {
                  return null;
                }
                let showPic = true;
                let showDetails = false;
                let showAuthor = true;
                if (index === 0) {
                  return false
                  // showRedPoint = false;
                } else {
                  showRedPoint = true;
                  showPic = true;
                  showAuthor = false;
                }
                if (item.showPic !== undefined) {
                  showPic = item.showPic;
                }

                if (
                  this.props.isVip !== undefined &&
                  this.props.isVip
                ) {
                  showPic = false;
                  showRedPoint = true;
                }
                if (item.showRedPoint !== undefined) {
                  showRedPoint = item.showRedPoint;
                }

                if (item.showDetails) {
                  showDetails = item.showDetails;
                }

                return this.props.isVip && index === 0 ? null : (
                  <div
                    className={isPC ? styles.TopicItemContent : styles.TopicItemContentMO}
                    key={item.id + index}
                    onClick={() =>
                      this.TopicItemContentClick(
                        item,
                        this.props.title_bar_no,
                        index
                      )
                    }
                  >
                    {item.pic && showPic ? (
                      <div className={isPC ? styles.TopicItemPic : styles.TopicItemPicMO}>
                        <img
                          src={
                            !item.pic.startsWith("http")
                              ? base.OssAddress + item.pic
                              : item.pic
                          }
                          alt={item.title}
                          className={styles.imgMax}
                        />
                        <img src={require("../../assets/zh/play_icon2.png")} alt="" className={styles.imgMin} />

                        <div className={styles.mask}></div>

                      </div>
                    ) : null}
                    <div className={styles.itemTitle}>
                      <div>
                        <div className={styles.title}>{item.title}</div>
                        <div className={styles.introduction}>{item.introduction}</div>
                      </div>
                      <div className={styles.timeBox}>
                        <p className={styles.videoTime}>发布时间：{moment(item.update_time * 1000).format().slice(0, 10)}</p>
                        <Icon type="arrow-right" onClick={() => this.videoDetails(item.article_no)} />
                      </div>
                    </div>
                    {!isPC && showPic ? null : isPC ? (
                      <div

                      >
                        {showPic && !isPC ? null : (
                          <div className={styles.itemTitle}>
                            {/* <span>{item.title}</span> */}
                          </div>
                        )}

                        {showDetails && showPic && isPC ? (
                          <div className={styles.TopicItemDetails}>
                            <pre>
                              转载自网易新闻 点赞({item.like_count})
                              评论(
                              {item.comment_count}) |
                              {tools.FormatRemainTimeStr(
                                item.creation_time
                              )}
                            </pre>
                          </div>
                        ) : null}
                        {showAuthor && showPic && isPC ? (
                          <div className={styles.TopicItemDetails}>
                            <pre>
                              {/* 来源：{item.article_source} 作者：
                              {item.author}{" "} */}
                              {item.article_source && item.article_source != " " ? "来源：" + item.article_source : null}
                              {item.author && item.author != " " ? "  作者：" + item.author + " " : null}
                            </pre>
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div className={styles.TopicItemOtherMo}>
                        {item.pic ? (
                          <div className={styles.imgBox}>
                            <img
                              src={
                                !item.pic.startsWith("http")
                                  ? base.OssAddress + item.pic
                                  : item.pic
                              }
                              alt={item.title}
                            />
                          </div>
                        ) : null}
                        <div className={styles.tit}>
                          <span>{item.title}</span>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <div style={{ textAlign: "center", color: "#333" }}>

              </div>
            )}
          </div>
        </Col>
      </div>
    )
  }

  // componentDidMount(){
  //   this.props.data

  // }

  render() {
    let { BrowserType } = this.state;
    // this.bigTitleColor();
    var isPC = true;
    if (BrowserType !== "PC") {
      isPC = false;
    }
    // console.log("props", this.props);
    let showRedPoint = true;
    let showNum = 7;
    if (this.props.showNum) {
      showNum = this.props.showNum;
    }
    let ShowHeaderTitle = true;
    if (this.props.ShowHeaderTitle !== undefined) {
      ShowHeaderTitle = this.props.ShowHeaderTitle;
    }

    let loading = true;
    if (this.props.loading !== undefined) {
      loading = this.props.loading;
    }

    let ShowMoreBtn = true;
    if (this.props.ShowMoreBtn !== undefined) {
      ShowMoreBtn = this.props.ShowMoreBtn;
    }

    let ExpertMD = 24;
    let isExpertColumn = false;
    if (
      this.props.ExpertList &&
      this.props.ExpertList !== undefined &&
      this.props.ExpertList.length > 0 &&
      isPC
    ) {
      isExpertColumn = this.props.ExpertList[0].isExpertColumn
        ? this.props.ExpertList[0].isExpertColumn
        : false;

      if (isExpertColumn) {
        ExpertMD = 12;
      } else {
        ExpertMD = 23;
      }
    }

    return (
      <div
        className={`${isPC ? styles.TopicItem : styles.TopicItemMo} ${this.props.SwiperDataList &&
          this.props.SwiperDataList.length > 0 &&
          isPC
          ? styles.SwiperClass
          : ""
          } ${!ShowHeaderTitle && isPC > 0 ? styles.NoHeaderTitle : styles.NoHeaderTitleMo} ${this.props.ExpertList && this.props.ExpertList.length > 0 && isPC
            ? styles.ExpertClass
            : ""
          } `}
      >

        {/*   */}
        <Card
          headStyle={
            this.props.headBgColor && isPC
              ? { background: this.props.headBgColor }
              : {}
          }
          gutter={16}
          style={{ marginBottom: '0.9rem' }}
          title={
            ShowHeaderTitle ? (
                <div>{this.props.title}</div>
            ) : (
              ""
            )
          }
          extra={
            ShowMoreBtn && ShowHeaderTitle && isExpertColumn == false ? (
                <div
                  className={styles.TopicItemMore}
                  onClick={() => {
                    this.MoreArticles(this.props.title_bar_no);
                  }}
                >
                  查看更多内容 >
                </div>
            ) : (
              ""
            )
          }
          bordered={false}
          loading={loading}
          bodyStyle={{padding: "0"}}
        >
          <Row gutter={isPC ? 8 : 0} style={BrowserType == 'PC' ? {display: 'flex'} : ''}>
            {/* 专家介绍 */}
            {this.props.ExpertList && this.props.ExpertList.length > 0 ? (
              <Col
                xs={24}
                sm={24}
                md={
                  this.props.data &&
                    this.props.data !== undefined &&
                    this.props.data.length > 0
                    ? 12
                    : 12
                }
                style={isExpertColumn ? { padding: "0 1rem 0 0" } : ""}
              >
                <div className={styles.ExpertList}>
                  {this.props.ExpertList ? (
                    <Expert
                      column={this.props.column}
                      md={ExpertMD}
                      ExpertList={this.props.ExpertList}
                      isPage={
                        this.props.ExpertList[0].isExpertColumn
                          ? "ExpertColumn"
                          : ""
                      }
                    />
                  ) : null}
                </div>
              </Col>
            ) : null}
            {/* 专家类型 */}
            {this.props.ExpertTypes && this.props.ExpertTypes.length > 0 ? (
              <Col
                xs={24}
                sm={24}
                md={12}
                style={isExpertColumn ? { padding: "0 .5rem 0 1rem" } : ""}
              >
                {this.props.ExpertTypes.map((item, index) => {
                  return (
                    <Skeleton active key={item.id} loading={false}>
                      <div className={styles.ArtucleBox}>
                        <div className={styles.head_url}>
                          <Avatar
                            shape="square"
                            size={90}
                            src={
                              !item.head_url.startsWith("http")
                                ? base.OssAddress + item.head_url
                                : item.head_url
                            }
                          />
                        </div>
                        <div className={styles.ArtucleBoxRight}>
                          <div className={styles.Title}>
                            <span className={styles.ta1}>{item.type_name}</span>
                            <span className={styles.decs}>
                              {item.introduction}
                            </span>
                          </div>

                          <div>专家人数：{item.count}</div>
                        </div>
                      </div>
                    </Skeleton>
                  );
                })}
              </Col>
            ) : null}

            {/* 会员中心 */}
            {
              this.props.isVip &&
                this.props.data &&
                this.props.title_bar_no != "1052" ? (
                <Col
                  xs={24}
                  sm={24}
                  md={
                    this.props.data &&
                      this.props.data !== undefined &&
                      this.props.data.length > 0
                      ? this.props.title_bar_no === "1051" ? 13 : 16
                      : 24
                  }
                >
                  <a
                    href={
                      this.props.title_bar_no === "1051" ? "#" : "/#/register1"
                    }
                  >
                    <div
                      className={styles.vipImgBox}
                      onClick={() =>
                        this.TopicItemContentClick(
                          this.props.data[0],
                          this.props.title_bar_no,
                        )
                      }
                    >
                      <img
                        className={styles.vipImg}
                        src={
                          !this.props.data[0].pic.startsWith("http")
                            ? base.OssAddress + this.props.data[0].pic
                            : this.props.data[0].pic
                        }
                        alt={this.props.data[0].title}
                      />
                      {/* 消防添加图片标题 */}
                      {this.props.data[0] &&
                        this.props.title_bar_no === "1051" ? (
                        <div
                          style={{
                            position: "absolute",
                            bottom: "0",
                            left: "0",
                            width: "100%",
                            marginBottom: '8px',
                            color: "#ffffff",
                            textAlign: "center",
                            fontSize: "1rem",
                          }}
                        >
                          {this.props.data[0].title}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className={styles.mask}></div>
                    </div>
                  </a>
                </Col>
              ) : null}

            {/* 轮播图模块 */}
            {this.props.isSwiperTopicItem && this.props.SwiperList ? (
              <Col
                xs={24}
                sm={24}
                md={
                  this.props.title_bar_no === "1034" ? 11 : 12
                }
                style={
                  isPC ? { padding: "0" } : { paddingBottom: "10px" }
                }
                className={this.props.title_bar_no === "1034" ? styles.swiperToRight : ''}
              >
                <Swiper autoplay SwiperDataList={this.props.SwiperList} />
              </Col>
            ) : null}
            {/* 1052为首页视频模块  1053为专家专栏视频模块  */}
            {
              this.props.title_bar_no === "1052" || this.props.title_bar_no === "1053"
                || this.props.title_bar_no === '20210426143043393061274147098624' ?
                this.getVideoComp(isPC, showRedPoint, showNum) : (
                  <Col
                    xs={24}
                    sm={24}
                    md={
                      this.props.isSwiperTopicItem ||
                        (this.props.SwiperDataList &&
                          this.props.SwiperDataList.length > 0) ||
                        (this.props.ExpertList && this.props.ExpertList.length > 0)
                        ? 12
                        : this.props.isVip
                          ? this.props.title_bar_no === "1051" ? 11 : 8
                          : 24
                    }
                    style={{flex: 1}}
                  >
                    <div
                      className={this.props.title_bar_no === "1051" ? styles.TopicItemListPad : styles.TopicItemList}
                      style={
                        this.props.isSwiperTopicItem && isPC
                          ? { padding: "0 1rem" }
                          : {},
                        !ShowHeaderTitle > 0 && isPC ? {} : { paddingBottom: "0px" }
                      }
                    >

                      {this.props.data ? (
                        this.props.data.map((item, index) => {
                          if (index + 1 > showNum) {
                            return null;
                          }
                          let showPic = true;
                          let showDetails = false;
                          let showAuthor = true;
                          if (index === 0) {
                            showRedPoint = false;
                          } else {
                            showRedPoint = true;
                            showPic = false;
                            showAuthor = false;
                          }
                          if (item.showPic !== undefined) {
                            showPic = item.showPic;
                          }

                          if (this.props.isVip !== undefined && this.props.isVip) {
                            showPic = false;
                            showRedPoint = true;
                          }
                          if (item.showRedPoint !== undefined) {
                            showRedPoint = item.showRedPoint;
                          }

                          if (item.showDetails) {
                            showDetails = item.showDetails;
                          }

                          return this.props.isVip && index === 0 ? null : (
                            <div
                              className={styles.TopicItemContent}
                              key={item.id + index}
                              onClick={() =>
                                this.TopicItemContentClick(
                                  item,
                                  this.props.title_bar_no,
                                  index
                                )
                              }
                            >
                              {item.pic && showPic ? (
                                <div className={styles.TopicItemPic}>
                                  <img
                                    src={
                                      !item.pic.startsWith("http")
                                        ? base.OssAddress + item.pic
                                        : item.pic
                                    }
                                    alt={item.title}
                                  />
                                  <div className={styles.mask}></div>
                                  {!isPC ? (
                                    <div className={styles.TopicItemPicTit}>
                                      <div className={styles.ta1}>
                                        {/* {item.BoldTitle
                                      ? this.bigTitleColor()
                                      : ""} */}
                                        <span
                                          className={
                                            item.BoldTitle
                                              ? styles.TopicItemBoldTitle
                                              : ""
                                          }
                                          style={{
                                            color: item.BoldTitle
                                              ? this.state.bigTitleColorConfig
                                              : this.state.smallTitleColorConfig
                                          }}
                                        >
                                          {item.title}
                                        </span>
                                      </div>
                                      {showAuthor ? (
                                        <div className={styles.TopicItemDetails}>
                                          <pre>
                                            {item.article_source && item.article_source != " " ? "来源：" + item.article_source : null}
                                            {item.author && item.author != " " ? "  作者：" + item.author + " " : null}
                                            {/* 来源：{item.article_source} 作者：
                                        {item.author}{" "} */}
                                          </pre>
                                        </div>
                                      ) : null}
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}
                              {!isPC && showPic ? null : isPC ? (
                                <div
                                  className={
                                    this.props.isVip
                                      ? styles.TopicItemTitleVip +
                                      " " +
                                      styles.TopicItemTitle
                                      : styles.TopicItemTitle
                                  }
                                >
                                  {showPic && !isPC ? null : (
                                    <div className={!showRedPoint ? styles.ta1title : styles.ta1}>
                                      {/* <Badge color={'pink'} /> */}
                                      {showRedPoint ? (<span className={styles.line}></span>) : null}
                                      {item.album_video &&
                                        item.album_video.length > 0 ? (
                                        <Icon
                                          className={styles.TopicItemVideoIco}
                                          type="video-camera"
                                          theme="filled"
                                        />
                                      ) : null}

                                      {/* {this.bigTitleColor()} */}
                                      {/* {
                                    item.BoldTitle
                                      ? this.bigTitleColor()
                                      : ""
                                  } */}

                                      <span
                                        className={
                                          item.BoldTitle
                                            ? styles.TopicItemBoldTitle
                                            : ""
                                        }
                                      >
                                        {item.title}
                                      </span>
                                      {
                                        isPC && showRedPoint ? (
                                          <span>
                                            {tools.UnixToStr(item.update_time, 0, 1, 1)}
                                          </span>
                                        ) : null
                                      }

                                    </div>
                                  )}

                                  {showDetails && showPic && isPC ? (
                                    <div className={styles.TopicItemDetails}>
                                      <pre>
                                        转载自网易新闻 点赞({item.like_count}) 评论(
                                        {item.comment_count}) |
                                        {tools.FormatRemainTimeStr(
                                          item.creation_time
                                        )}
                                      </pre>
                                    </div>
                                  ) : null}
                                  {showAuthor && showPic && isPC ? (
                                    <div className={styles.TopicItemDetails}>

                                      <pre style={{ margin: '0' }}>
                                        {item.article_source && item.article_source != " " ? "来源：" + item.article_source : null}
                                        {item.author && item.author != " " ? "  作者：" + item.author + " " : null}
                                      </pre>
                                      <div className={styles.time}>
                                        {tools.UnixToStr(item.update_time, 0, 1, 1)}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              ) : (
                                <div className={styles.TopicItemOtherMo}>
                                  {item.pic ? (
                                    <div className={styles.imgBox}>
                                      <img
                                        src={
                                          !item.pic.startsWith("http")
                                            ? base.OssAddress + item.pic
                                            : item.pic
                                        }
                                        alt={item.title}
                                      />
                                    </div>
                                  ) : null}
                                  <div className={styles.tit}>
                                    <span>{item.title}</span>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })
                      ) : (
                        <div style={{ textAlign: "center", color: "#333" }}>

                        </div>
                      )}
                    </div>
                  </Col>
                )}

            {this.props.SwiperDataList &&
              this.props.SwiperDataList.length > 0 ? (
              <Col
                xs={24}
                sm={24}
                md={
                  this.props.data &&
                    this.props.data !== undefined &&
                    this.props.data.length > 0
                    ? 12
                    : 24
                }
              >
                <div className={styles.SwiperDataList}>
                  {this.props.SwiperDataList ? (
                    <Swiper
                      SwiperDataList={this.props.SwiperDataList}
                      autoplay
                    />
                  ) : null}
                </div>
              </Col>
            ) : null}
          </Row>
        </Card>
      </div>
    );
  }
}
export default TopicItem;
