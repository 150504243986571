import React, { Component } from "react";
import { Row, Col, Anchor, Carousel, Modal, Collapse, Divider } from "antd";
import styles from "./home.module.less";
import PageContent from "@/src/components/PageContent";
import store from "@/src/redux/store.js";
import API from "../../../api/exhibition";
import { base } from "@/src/api/base";
// import { setAdvertisement } from "@/src/redux/actions/Advertisement";
// import { tools } from "@/src/lib/tools";
import swiperPatch from "@/src/components/swiperPatch/swiperPatch";
import { HashRouter } from "react-router-dom";
import moment from "moment";
import PersonalCenterApi from "@/src/api/PersonalCenterApi";
// import { setArticleDetails } from "@/src/redux/actions/ArticleDetails";
// import { setBreadcrumbList } from "@/src/redux/actions/BreadcrumbList";
// import SwiperCore, { EffectCoverflow, Pagination } from "swiper";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper.less";
// import SwiperFun from "swiper";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper/core';
import 'swiper/swiper.less';
import MySwiper from "../components/swiper2021/swiper";
// import 'swiper/components/pagination/pagination.less';

// SwiperCore.use([EffectCoverflow, Pagination]);
SwiperCore.use([Autoplay, Pagination, Navigation]);
const router = new HashRouter();
// const { Link } = Anchor;
const { Panel } = Collapse;
class Content extends Component {
  state = {
    name: "首页",
    speakers: {
      name: '',
      work: '',
      describe: '',
    },
    awardName: "最受欢迎奖",
    titleArr: [
      "特别贡献奖",
      "最受欢迎奖",
      "最具潜力奖",
      "最具影响力奖",
      "最佳创新奖",
      "新媒体宣传奖",
    ],
    exhbitionData: [], //总数据
    videoList: [], //视频列表
    leftVideo: [], //左侧视频
    newsList: [], //文字新闻
    introductionData: "",
    internationalGuestsList: [], //国际嘉宾
    domesticGuestsList: [], //国内嘉宾
    guestList: [], //嘉宾
    guestActive: "domestic", //选中嘉宾
    sponsorList: [], //赞助商
    picShowList: [], //图片直播
    // agendaList: [], //大会议程列表
    agendaHeaders: [], //大会议程列表
    // agendaData: [], //大会议程详情
    centerPic: [], // 中间图片
    showSignBox: true,
    nMLeftCarousel: [], // 新媒体左侧轮播   
    associatedMedia: [], //新媒体联盟合作媒体
    purpose: [], // 新媒体联盟宗旨
    principle: [], // 新媒体联盟原则
    declaration: [], // 新媒体联盟宣言
    titleList: [], // 荣誉奖项
    awardingGuests: [], // 特别贡献奖颁奖嘉宾
    awardCompany: [], // 特别贡献奖获奖企业
    awardingGuests1: [], // 最受欢迎奖颁奖嘉宾
    awardCompany1: [], // 最受欢迎奖获奖企业
    awardingGuests2: [], // 最具潜力奖颁奖嘉宾
    awardCompany2: [], // 最具潜力奖获奖企业
    awardingGuests3: [], // 最具影响力奖颁奖嘉宾
    awardCompany3: [], // 最具影响力奖获奖企业
    awardingGuests4: [], // 最佳创新奖颁奖嘉宾
    awardCompany4: [], // 最佳创新奖获奖企业
    awardingGuests5: [], // 新媒体宣传奖颁奖嘉宾
    awardCompany5: [], // 新媒体宣传奖获奖企业
    addressPic: [], // 大会地址
    addressList: [],
    guestSpeakerList: [], // 开幕致辞嘉宾
    hostGuestList: [], //主持嘉宾
    latestNewsData: "", //最新资讯
    samePeriodList: [], //同期展览
    cooperationUnitList: [], //合作单位9
    cooperationMediaLsit: [], //合作媒体10
    designatedMediaList: [], //指定媒体11
    BrowserType: "PC", // PC Mobile 浏览器类型 手机或者pc端
    // isLoad: false,
    showModal: false, //展商目录弹框
    loading: false,
    currentVideoSrc: '', //当前播放视频
    currentVideoName: '', //当前播放视频标题
    is_vip: "0",
    showIndex: 0, //大会议程展示索引
  };

  componentDidMount() {
    window.addEventListener("resize", this.resizeBind);
    this.resizeBind();
    this.QueryExhbition();
    this.MineBuyVip();
    this.swiperInit();
  }
  swiperInit = () => {
    // new SwiperFun(".swiper-container", {
    //   effect: "coverflow",
    //   grabCursor: true,
    //   centeredSlides: true,
    //   slidesPerView: "auto",
    //   coverflowEffect: {
    //     rotate: 50,
    //     stretch: 0,
    //     depth: 100,
    //     modifier: 1,
    //     slideShadows: true,
    //   },
    //   pagination: {
    //     el: ".swiper-pagination",
    //   },
    // });
    // new SwiperFun(".swiper-container",{
    // 	pagination: ".swiper-dots",
    // 	navigator: {
    // 		prev: ".swiper-btn-prev",
    // 		next: ".swiper-btn-next"
    // 	},
    // 	delay: 3000, // 切换的时间 默认2000
    // 	autoPlay: true // 是否自动切换 默认true
    // });
  };

  // 查询是否是会员
  MineBuyVip = () => {
    PersonalCenterApi.MineBuyVip().then((res) => {
      console.log("isVip", res);
      if (res.status === 200) {
        if (moment().format("X") < res.data.end_time) {
          this.setState({ is_vip: "2" });
        } else {
          this.setState({ is_vip: "1" });
        }
      } else {
        // this.setState({ is_vip: "1" });
      }
      // this.setState({ menuKey: "1" });
    });
  };

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== "Mobile") {
        this.setState({
          BrowserType: "Mobile",
        });
        // 主持嘉宾滚动条位置初始化
      }
    } else {
      if (!this.state.BrowserType !== "PC") {
        this.setState({
          BrowserType: "PC",
        });
      }
    }
  };

  componentWillUnmount() {
    // 对 store 变化取消监听
    if (this.state.guest_clear_timeout) {
      this.state.guest_clear_timeout();
    }
    if (this.state.sponsor_clear_timeout) {
      this.state.sponsor_clear_timeout();
    }
    window.removeEventListener("resize", this.resizeBind);
  }
  QueryExhbition = () => {
    let params = {
      limit: 1000,
      year: '2021'
    };
    this.setState({ loading: true });
    console.log("params", params);
    API.QueryExhbition(params)
      .then((res) => {

        console.log("data", res);
        if (res.status === 200) {
          var exhbitionData = res.data.list;
          var introductionData = exhbitionData.filter((item) => {
            // config_type: "introduction
            // return item.id == 1;
            return item.config_type === '2021大会介绍';
          })[0];
          introductionData = introductionData.content;
          if (this.state.BrowserType !== "PC") {
            introductionData = introductionData.replace(
              /font-size:\w+;?/g,
              "font-size:15px"
            );
            introductionData = introductionData.replace(
              /line-height:\w+;?/g,
              "line-height:1"
            );
          }
          // var re1 = /\<(\p.*)\>/g;
          //  var re1=/<\/(p.*)>/gm;
          // var r = introductionData.replace(re1, '<a href="/#/exhibitionDetails">[查看更多]</a>');
          // console.log(r)
          let videoList = exhbitionData.filter((item) => {
            // return item.pid === 47 && item.is_hidden !== 2;
            return item.config_type === '2021视频模块配置';
          });
          let leftVideo = exhbitionData.filter((item) => {
            // return item.pid === 47 && item.is_hidden !== 2;
            return item.config_type === '2021左侧视频模块配置';
          });

          console.log(videoList, "视频列表1");
          setTimeout(() => {
            this.setState({
              currentVideoSrc: base.OssAddress + leftVideo[0].category,
              currentVideoName: leftVideo[0].content_short
            });
          }, 500);
          if (videoList.length > 0) {
            let arr = videoList.sort(function (a, b) {
              return a.sort - b.sort;
            });
            this.setState({ videoList, arr });
          }
          var internationalGuestsList = exhbitionData.filter((item) => {
            // return item.pid == 2;
            return item.config_type === '2021国际嘉宾';
          });
          var domesticGuestsList = exhbitionData.filter((item) => {
            // return item.pid == 3;
            return item.config_type === '2021国内嘉宾';
          });
          if (
            internationalGuestsList[0] && internationalGuestsList[0].is_hidden == 1 &&
            domesticGuestsList[0] && domesticGuestsList[0].is_hidden == 2
          ) {
            this.setState({
              guestActive: "international",
              guestList: internationalGuestsList,
            });
          } else if (
            internationalGuestsList[0] && internationalGuestsList[0].is_hidden == 2 &&
            domesticGuestsList[0] && domesticGuestsList[0].is_hidden == 2
          ) {
            this.setState({ guestActive: "", guestList: [] });
          } else {
            this.setState({
              guestActive: "domestic",
              guestList: domesticGuestsList,
            });
          }

          // var agendaData = exhbitionData.filter((item) => {
          //   // return item.pid == 4 && item.pid == item.id;
          //   return item.config_type === '2021大会议程';
          // });
          var agendaHeaders = exhbitionData.filter((item) => {
            // return item.pid == 4 && item.pid == item.id;
            return item.config_type === '2021大会议程' && item.content === '头';
          });

          // 这是个二维数组 第几天 每天下面的议程
          var agendaList = [];
          exhbitionData.forEach((item, index) => {
            // 把所有符合条件的子节点加入header下面
            for (let i = 0; i < agendaHeaders.length; i++) {
              const ah = agendaHeaders[i];
              if (item.pid === ah.id) {
                if (!ah.children) {
                  ah.children = []
                }
                ah.children.push(item)
              }
            }

          })
          // if (agendaData[0] && agendaData[0].is_hidden == 1) {
          //   var agendaShow = exhbitionData.filter((item) => {
          //     return (
          //       [19, 20, 21, 22, 23, 100, 176].indexOf(item.pid) != -1 &&
          //       item.pid == item.id &&
          //       item.is_hidden == 1
          //     );
          //   });
          //   if (agendaShow.length > 0) {
          //     agendaShow = this.sortFn(agendaShow);
          //     agendaShow.forEach((x) => {
          //       let arr = exhbitionData.filter((item) => {
          //         return item.pid == x.id && item.id !== item.pid;
          //       });
          //       arr = this.sortFn(arr);
          //       arr = [x, ...arr];
          //       agendaList.push(arr);
          //     });
          //   }
          // }

          var addressPic = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid !== item.id;
            return item.config_type === '2021大会地址';
          });
          var addressList = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid !== item.id;
            return item.config_type === '2021地址到达方式';
          });
          console.log('addressList', addressList)
          var awardCompany5 = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid !== item.id;
            return item.config_type === '2021荣誉奖项新媒体宣传奖获奖企业';
          });
          var awardCompany4 = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid !== item.id;
            return item.config_type === '2021荣誉奖项最佳创新奖获奖企业';
          });
          var awardCompany3 = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid !== item.id;
            return item.config_type === '2021荣誉奖项最具影响力奖获奖企业';
          });
          var awardingGuests5 = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid !== item.id;
            return item.config_type === '2021荣誉奖项新媒体宣传奖颁奖嘉宾';
          });
          var awardingGuests4 = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid !== item.id;
            return item.config_type === '2021荣誉奖项最佳创新奖颁奖嘉宾';
          });
          var awardingGuests3 = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid !== item.id;
            return item.config_type === '2021荣誉奖项最具影响力奖颁奖嘉宾';
          });
          var awardCompany2 = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid !== item.id;
            return item.config_type === '2021荣誉奖项最具潜力奖获奖企业';
          });
          var awardingGuests2 = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid !== item.id;
            return item.config_type === '2021荣誉奖项最具潜力奖颁奖嘉宾';
          });
          var awardCompany1 = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid !== item.id;
            return item.config_type === '2021荣誉奖项最受欢迎奖获奖企业';
          });
          var awardingGuests1 = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid !== item.id;
            return item.config_type === '2021荣誉奖项最受欢迎奖颁奖嘉宾';
          });
          var awardCompany = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid !== item.id;
            return item.config_type === '2021荣誉奖项特别贡献奖获奖企业';
          });
          var awardingGuests = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid !== item.id;
            return item.config_type === '2021荣誉奖项特别贡献奖颁奖嘉宾';
          });
          var titleList = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid !== item.id;
            return item.config_type === '2021新媒体联盟荣誉奖项';
          });
          var declaration = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid !== item.id;
            return item.config_type === '2021新媒体联盟宣言';
          });
          var principle = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid !== item.id;
            return item.config_type === '2021新媒体联盟原则';
          });
          var purpose = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid !== item.id;
            return item.config_type === '2021新媒体联盟宗旨';
          });
          var associatedMedia = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid !== item.id;
            return item.config_type === '2021新媒体联盟合作媒体';
          });
          var nMLeftCarousel = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid !== item.id;
            return item.config_type === '2021新媒体联盟左侧轮播';
          });
          var guestSpeakerList = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid !== item.id;
            return item.config_type === '2021开幕致辞嘉宾';
          });
          var first = guestSpeakerList[0].address.split("，")
          this.changeSpeakers(first[0], first[1], first[2])
          var hostGuestList = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid !== item.id;
            return item.config_type === '2021主持嘉宾';
          });
          var centerPic = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid !== item.id;
            return item.config_type === '2021中间图片';
          });
          var sponsorList = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid !== item.id;
            return item.config_type === '2021大会赞助商';
          });
          var picShowList = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid !== item.id;
            return item.config_type === '2021图片直播';
          });
          var newsList = exhbitionData.filter((item) => {
            return item.config_type === '2021文字新闻配置';
          });
          var sponsorData = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid == item.id;
            return item.config_type === '2021大会赞助商';
          });
          var sponsorExhibitors = exhbitionData.filter((item) => {
            // return item.pid == 5 && item.pid == item.id;
            return item.config_type === '2021大会展商';
          });
          var latestNewsData = exhbitionData.filter((item) => {
            // return item.id == 7;
            return item.config_type === '2021最新资讯';
          });
          var samePeriodList = exhbitionData.filter((item) => {
            // return item.pid == 8;
            return item.config_type === '2021同期展览';
          });
          var cooperationUnitList = exhbitionData.filter((item) => {
            // return item.pid == 9;
            return item.config_type === '2021合作单位';
          });
          var cooperationMediaLsit = exhbitionData.filter((item) => {
            // return item.pid == 10;
            return item.config_type === '2021新媒体联盟合作媒体';
          });
          var designatedMediaList = exhbitionData.filter((item) => {
            // return item.pid == 11;
            return item.config_type === '2021指定媒体';
          });
          internationalGuestsList = this.sortFn(internationalGuestsList);
          domesticGuestsList = this.sortFn(domesticGuestsList);
          sponsorList = this.sortFn(sponsorList);
          picShowList = this.sortFn(picShowList);
          samePeriodList = this.sortFn(samePeriodList);
          cooperationUnitList = this.sortFn(cooperationUnitList);
          cooperationMediaLsit = this.sortFn(cooperationMediaLsit);
          designatedMediaList = this.sortFn(designatedMediaList);
          agendaHeaders = this.sortFn(agendaHeaders);
          centerPic = this.sortFn(centerPic);
          leftVideo = this.sortFn(leftVideo);
          newsList = this.sortFn(newsList);
          hostGuestList = this.sortFn(hostGuestList);
          guestSpeakerList = this.sortFn(guestSpeakerList);
          nMLeftCarousel = this.sortFn(nMLeftCarousel);
          associatedMedia = this.sortFn(associatedMedia);
          purpose = this.sortFn(purpose);
          principle = this.sortFn(principle);
          declaration = this.sortFn(declaration);
          titleList = this.sortFn(titleList);
          awardingGuests = this.sortFn(awardingGuests);
          awardCompany = this.sortFn(awardCompany);
          awardingGuests1 = this.sortFn(awardingGuests1);
          awardCompany1 = this.sortFn(awardCompany1);
          awardingGuests2 = this.sortFn(awardingGuests2);
          awardCompany2 = this.sortFn(awardCompany2);
          awardingGuests3 = this.sortFn(awardingGuests3);
          awardCompany3 = this.sortFn(awardCompany3);
          awardingGuests4 = this.sortFn(awardingGuests4);
          awardCompany4 = this.sortFn(awardCompany4);
          awardingGuests5 = this.sortFn(awardingGuests5);
          awardCompany5 = this.sortFn(awardCompany5);
          addressPic = this.sortFn(addressPic);
          addressList = this.sortFn(addressList);

          this.setState(
            {
              exhbitionData,
              introductionData,
              internationalGuestsList,
              domesticGuestsList,
              sponsorList,
              picShowList,
              sponsorData,
              // agendaList,
              // agendaData,
              agendaHeaders,
              latestNewsData,
              samePeriodList,
              cooperationUnitList,
              cooperationMediaLsit,
              designatedMediaList,
              centerPic,
              leftVideo,
              newsList,
              hostGuestList,
              guestSpeakerList,
              nMLeftCarousel,
              associatedMedia,
              purpose,
              principle,
              declaration,
              titleList,
              awardingGuests,
              awardCompany,
              awardingGuests1,
              awardCompany1,
              awardingGuests2,
              awardCompany2,
              awardingGuests3,
              awardCompany3,
              awardingGuests4,
              awardCompany4,
              awardingGuests5,
              awardCompany5,
              addressPic,
              addressList
            },
            () => {
              this.sponsorSwiper();
              this.guestSwiper();
            }
          );
        } else {
          // message.error(res.msg);
        }
        this.setState({ loading: false });
      })
      .finally((res) => {
        this.setState({ loading: false });
      });
  };
  // 根据pid查询
  QueryExhbitionByPid = () => {
    var { isYear, exhbitorParams, offset, limit } = this.state;
    let params = {
      limit,
      offset,
      pid: 3,
    };
    this.setState({ loading: true });
    API.QueryExhbitionByPid(params)
      .then((res) => {
        return;
        if (res.status === 200) {
          var exhbitorData = res.data.list;
          this.setState({
            exhbitorData,
            total: res.data.total,
          });
        } else {
          // message.error(res.msg);
        }
        this.setState({ loading: false });
      })
      .finally((res) => {
        this.setState({ loading: false });
      });
  };
  // 排序
  sortFn = (arr) => {
    if (arr && arr.length > 1) {
      arr.sort(function (a, b) {
        if (a.sort < b.sort) {
          return -1;
        } else if (a.sort == b.sort) {
          return 0;
        } else {
          return 1;
        }
      });
    }
    return arr;
  };
  //嘉宾分类切换
  handleChangeGuest = (type) => {
    if (type == "international") {
      this.setState({
        guestList: this.state.internationalGuestsList,
        guestActive: "international",
      });
    } else {
      this.setState({
        guestList: this.state.domesticGuestsList,
        guestActive: "domestic",
      });
    }
  };
  guestSwiper = () => {
    const carousel_autoplay_time = 3000;
    const {
      carousel_autoplay,
      carousel_prev,
      carousel_next,
      clear_timeout,
    } = swiperPatch(this.slider, carousel_autoplay_time);
    this.setState({
      carousel_prev,
      carousel_next,
      guest_clear_timeout: clear_timeout,
    });
    // 启动轮播
    carousel_autoplay();
    // 离开清理定时器
    // return clear_timeout
  };
  sponsorSwiper = () => {
    const carousel_autoplay_time = 2500;
    const { carousel_autoplay, clear_timeout } = swiperPatch(
      this.sponsorCarousel,
      carousel_autoplay_time
    );
    this.setState({
      sponsor_clear_timeout: clear_timeout,
    });
    // 启动轮播
    carousel_autoplay();
    // 离开清理定时器
    // return clear_timeout
  };
  toExhibitor = () => {
    const { userInfo } = store.getState();
    if (!userInfo || this.state.is_vip <= 1) {
      this.setState({ showModal: true });
    } else {
      document.documentElement.scrollTop = document.body.scrollTop =0;
      router.history.push("/exhibitor");
    }
  };

  /**
   * @大会议程点击事件
   */
  agendaClick = (index) => {
    this.setState({ showIndex: index });
  };

  // 视频详情
  ImgCardContentClick = (item) => {
    console.log(item)
    this.setState({
      currentVideoSrc: base.OssAddress + item.category,
      currentVideoName: item.content_short
    })
    // router.history.push("/videoDetals" + item.pid + "/" + item.id,'_blank');
    // window.open(`/#/video2021Detals${item.pid}/${item.id}`);
  };
  ItemNewsClick = (item) => {
    window.open(`/#/video2021Detals${item.pid}/${item.id}`);
  };

  changeSpeakers = (name, work, describe) => {
    this.setState({
      speakers: {
        name,
        work,
        describe,
      },
    });
  };

  awardNameChange(e, that) {
    let name = that.state.titleArr[e.activeIndex % 5];
    that.setState({
      awardName: name,
    });
  }

  render() {
    const {
      BrowserType,
      loading,
      currentVideoSrc, //当前播放视频
      currentVideoName,
      exhbitionData,
      introductionData,
      guestList,
      internationalGuestsList,
      domesticGuestsList,
      guestActive,
      sponsorList,
      picShowList,
      sponsorData,
      // agendaList,
      agendaHeaders,
      latestNewsData,
      samePeriodList,
      carousel_prev,
      carousel_next,
      cooperationUnitList, //合作单位9
      cooperationMediaLsit, //合作媒体10
      designatedMediaList, //指定媒体
      // isLoad,
      showModal,
      showIndex,
      prizeData,
      videoList,
      speakers,
      awardName,
      centerPic,
      leftVideo,
      newsList,
      showSignBox,
      hostGuestList,
      guestSpeakerList,
      nMLeftCarousel,
      associatedMedia,
      purpose,
      principle,
      declaration,
      titleList,
      awardingGuests,
      awardCompany,
      awardingGuests1,
      awardCompany1,
      awardingGuests2,
      awardCompany2,
      awardingGuests3,
      awardCompany3,
      awardingGuests4,
      awardCompany4,
      awardingGuests5,
      awardCompany5,
      addressPic,
      addressList
    } = this.state;
    var md = 12;
    var isPC = true;
    var adMd = 8;
    if (BrowserType !== "PC") {
      md = 24;
      isPC = false;
    }
    if (document.querySelector(".home_host__2ZE4e")) {
      let left = document.querySelector(".home_host__2ZE4e").scrollLeft;
      document.querySelector(".home_host__2ZE4e").scrollLeft = 160;
    }
    //嘉宾
    let guestPageSize = isPC ? 10 : 6;
    var guestPageNum = Math.ceil(guestList.length / guestPageSize);
    let guestHtml = [];
    for (var i = 0; i < guestPageNum; i++) {
      guestHtml.push(
        <div key={i}>
          <div className={styles.swpiperItem}>
            <Row>
              {guestList.map((item, ind) => {
                return ind != 0 &&
                  ind <= (i + 1) * guestPageSize &&
                  ind > i * guestPageSize ? (
                  <Col
                    span={isPC ? 4 : 7}
                    key={ind}
                    offset={ind % (guestPageSize / 2) == 0 ? 0 : 1}
                  >
                    <div className={styles.guestBox}>
                      <div className={styles.imgBox}>
                        <img
                          src={
                            item.pic.startsWith("http")
                              ? item.pic
                              : `${base.OssAddress}${item.pic}`
                          }
                          alt=""
                        />
                      </div>
                      <p className={styles.name}>{item.content_short}</p>
                      <p>{item.content}</p>
                    </div>
                  </Col>
                ) : null;
              })}
            </Row>
          </div>
        </div>
      );
    }

    // 赞助商
    let sponsorPageSize = isPC ? 4 : 4;
    var sponsorPageNum = Math.ceil(sponsorList.length / sponsorPageSize);
    let sponsorHtml = [];
    for (var i = 0; i < sponsorPageNum; i++) {
      sponsorHtml.push(
        <div key={i}>
          <div className={styles.swpiperItem}>
            <Row>
              {sponsorList.map((item, ind) => {
                return ind < (i + 1) * sponsorPageSize &&
                  ind >= i * sponsorPageSize ? (
                  <Col
                    key={ind}
                    span={isPC ? 6 : 12}
                  >
                    <div className={styles.sponsorItem}>
                      <div className={styles.imgBox}>
                        <img
                          src={
                            item.pic.startsWith("http")
                              ? item.pic
                              : `${base.OssAddress}${item.pic}`
                          }
                          alt=""
                        />
                      </div>
                      <p className={styles.name}>{item.content_short}</p>
                      {/* <p className={styles.col999}>{item.address}</p> */}
                    </div>
                  </Col>
                ) : null;
              })}
            </Row>
          </div>
        </div>
      );
    }

    // 大会议程
    let agendaHtml = [];
    let agendaHtmlHeader = [];
    if (agendaHeaders && agendaHeaders.length > 0) {

      let classArr = [];
      if (isPC) {
        classArr = [
          "nextRight",
          "nextRight",
          "nextBottom",
          "nextBottom",
          "nextLeft",
          "nextLeft",
          "nextRight",
          "nextRight",
        ];
      } else {
        classArr = [
          "nextRight",
          "nextBottom",
          "nextBottom",
          "nextLeft",
          "nextRight",
          "nextBottom",
          "nextBottom",
          "nextRight",
        ];
      }
      agendaHeaders.forEach((item, ind) => {

        let j = ind;
        // 互换位置 因为显示顺序调整
        // if (isPC) {
        //   switch (ind) {
        //     case 3:
        //       j = 5;
        //       break;
        //     case 5:
        //       j = 3;
        //       break;
        //   }
        // } else {
        //   switch (ind) {
        //     case 2:
        //       j = 3;
        //       break;
        //     case 3:
        //       j = 2;
        //       break;
        //     case 7:
        //       j = 8;
        //       break;
        //     case 8:
        //       j = 7;
        //       break;
        //   }
        // }
        agendaHtmlHeader[j] = (
          <div
            className={j === agendaHeaders.length - 1 ? "" : styles[classArr[j]]}
          >
            <div
              className={
                this.state.showIndex == ind
                  ? styles.itemListActive
                  : styles.itemList
              }
              key={item.id}
              onClick={(e) => {
                this.agendaClick(ind);
              }}
            >
              <p>{item.category}</p>
              {/* <p>{item.content_short}</p> */}
            </div>
          </div>
        );
      });

      if (isPC) {
        agendaHeaders.forEach((item, ind) => {
          if (ind === showIndex) {
            let html = (
              <div key={ind} className={styles.agendaCon}>
                <div className={styles.header}>
                  {/* <span>{item.category}</span> */}
                  {/* <span className={styles.divider}>|</span> */}
                  <span>{item.content_short}</span>
                </div>
                <table className={styles.agendaList}>
                  {item.children && item.children.map((x, y) => {
                    return (
                      <tr key={x.id} className={styles.item}>
                        <td className={styles.left}>
                          <p className={styles.time}>
                            <img
                              src={require("../assets/image/icon_time.png")}
                              alt=""
                            />
                            <span style={{ whiteSpace: 'nowrap', fontSize: '21px' }}>{x.address}</span>
                            <p className={styles.tit}>{x.content_short}</p>
                          </p>
                        </td>
                        <td className={styles.right} dangerouslySetInnerHTML={{ __html: x.category }}></td>
                      </tr>
                    );

                  })}
                </table>
              </div>
            );
            agendaHtml.push(html);
          }
        });
        console.log(agendaHtmlHeader, "dom");
      } else {
        agendaHeaders.forEach((item, ind) => {
          if (ind !== showIndex) {
            return false;
          }
          let html = (
            <div key={ind} className={styles.agendaCon}>
              <div className={styles.header}>
                {/* <span>{item.category}</span> */}
                {/* <span className={styles.divider}>|</span> */}
                <span>{item.content_short}</span>
              </div>
              <Collapse
                className={styles.agendaList}
                expandIcon={({ isActive }) => (
                  <img
                    className={styles.jiantouIcon + " icon"}
                    src={
                      isActive
                        ? require("../assets/image/icon_up.png")
                        : require("../assets/image/icon_down.png")
                    }
                  />
                )}
                expandIconPosition="right"
              >
                {item.children && item.children.map((x, y) => {

                  return (
                    <Panel
                      header={
                        <div className={styles.left}>
                          <img className={styles.time}
                            src={require("../assets/image/icon_time.png")}
                            alt=""
                          />
                          <span style={{ whiteSpace: 'nowrap' }}>{x.address}</span>
                          <p className={styles.tit}>{x.content_short}</p>
                        </div>
                      }
                      className={styles.item}
                      key={x.id}
                      disabled={x.address ? false : true}
                    >
                      <p className={styles.right} dangerouslySetInnerHTML={{ __html: x.category }}></p>
                    </Panel>
                  );

                })}
              </Collapse>
            </div>
          );
          agendaHtml.push(html);
        });
      }
    }
    const lunboSetting = {
      dots: true,
      lazyLoad: true,
    };

    //荣誉奖项
    if (prizeData && prizeData.length > 0) {
    }

    // console.log(introductionData)
    return (
      <div className={isPC ? styles.Content : styles.ContentMo}>
        <PageContent loading={loading}>
          {/* 大会介绍 */}
          <div id="introduction_mh_box" className={styles.introductionBox}>
            <div className={styles.container}>
              <div className={styles.aboutTxt}>
                <p className={styles.titleStyle + " " + styles.col000}>
                  大会介绍
                </p>
                {/* <p className={styles.titleEnStyle + " " + styles.col999}>
                  CONGRESS INFORMATION
                </p> */}
                <div
                  className={styles.TextContent}
                  ref={(ref) => {
                    this.TextContent = ref;
                  }}
                  dangerouslySetInnerHTML={{ __html: introductionData }}
                ></div>
              </div>
              {/* <div className={styles.aboutInfo}>
                <div className={styles.item}>
                  <span className={styles.num}>
                    <div className={styles.con}>
                      <div className={isLoad ? styles.numN + " " + styles.num3 : styles.numN}></div>
                      <div className={styles.numN + " " + styles.num0}></div>
                      <div className={styles.numN + " " + styles.num0}></div>
                      <div className={styles.plus}></div>
                    </div>
                  </span>
                  <div className={styles.des}>国内外演讲专家</div>
                </div>
                <div className={styles.item}>
                  <span className={styles.num}>
                    <div className={styles.con}>
                      <div className={isLoad ? styles.numN + " " + styles.num4 : styles.numN}></div>
                      <div className={styles.numN + " " + styles.num0}></div>
                      <div className={styles.numN + " " + styles.num0}></div>
                      <div className={styles.plus}></div>
                    </div>
                  </span>
                  <div className={styles.des}>单位企业</div>
                </div>
                <div className={styles.item}>
                  <span className={styles.num}>
                    <div className={styles.con}>
                      <div className={isLoad ? styles.numN + " " + styles.num1 : styles.numN}></div>
                      <div className={styles.numN + " " + styles.num0}></div>
                      <div className={styles.numN + " " + styles.num0}></div>
                      <div className={styles.plus}></div>
                    </div>
                  </span>
                  <div className={styles.des}>国家及地区</div>
                </div>
                <div className={styles.item}>
                  <span className={styles.num}>
                    <div className={styles.con}>
                      <div className={styles.numN + " " + styles.num1}></div>
                      <div className={isLoad ? styles.numN + " " + styles.numW : styles.numN}></div>
                      <div className={styles.plus}></div>
                    </div>
                  </span>
                  <div className={styles.des}>参会人次</div>
                </div>
              </div> */}
            </div>
          </div>
          {/* 大会议程 */}
          {agendaHeaders.length > 0 ? (
            <div id="agenda_mh_box" className={styles.agendaBox1}>
              <div className={styles.container}>
                <p className={styles.titleStyle + " " + styles.col000}>
                  大会议程
                </p>
                {/* <p className={styles.titleEnStyle + " " + styles.col999}>
                  CONFERENCE AGENDA
                </p> */}
                <div className={styles.agendaHeader}>{agendaHtmlHeader}</div>
                {agendaHtml}
                {/* 开幕致辞嘉宾 */}


                <div id="zhici_mh_box" className={styles.wrapBox}>
                  <div className={styles.container}>
                    <p className={styles.titleStyle + " " + styles.col000}>
                      参会嘉宾
                    </p>
                    {/* <p className={styles.titleEnStyle + " " + styles.col999}>
                  OPENING REMARKS
                </p> */}
                    <div className={styles.kaimu}>
                      <div className={styles.leftTit}>
                        {speakers.name}
                      </div>
                      <div className={styles.centerLine}></div>
                      <div className={styles.rightSection}>{speakers.describe}</div>
                      <span className={styles.leftWork}>{speakers.work}</span>
                    </div>
                    {isPC ? (
                      <div className={styles.imagelist}>
                        {
                          guestSpeakerList.map((item) => {
                            return (
                              <img key={item.id}
                                src={item.pic.startsWith("http") ? item.pic : `${base.OssAddress}${item.pic}`}
                                onClick={() => {
                                  var content = item.address.split("，")
                                  this.changeSpeakers(
                                    content[0],
                                    content[1],
                                    content[2]
                                  );
                                }}
                                alt=""
                              />
                            )
                          })
                        }
                      </div>
                    ) : (
                      <div style={{ whiteSpace: 'nowrap', overflowX: 'scroll' }}>
                        {
                          guestSpeakerList.map((item) => {
                            return (
                              <img key={item.id}
                                style={{ width: '140px', marginRight: '5px' }}
                                src={item.pic.startsWith("http") ? item.pic : `${base.OssAddress}${item.pic}`}
                                onClick={() => {
                                  var content = item.address.split("，")
                                  this.changeSpeakers(
                                    content[0],
                                    content[1],
                                    content[2]
                                  );
                                }}
                                alt=""
                              />
                            )
                          })
                        }
                      </div>
                    )}

                  </div>
                </div>
              </div>
            </div>
          ) : null}



          {/* 赞助商 */}
          {sponsorData &&
            sponsorData.length > 0 &&
            sponsorData[0].is_hidden == 1 ? (
            <div id="sponsor_mh_box" className={styles.sponsorBox}>
              <div className={styles.container}>
                <p className={styles.titleStyle + " " + styles.col000}>
                  参展企业
                </p>
                {/* <p className={styles.titleEnStyle + " " + styles.col999}>
                  LIST OF EXHIBITORS
                </p> */}

                <div className={styles.swiperBox}>

                  <Carousel
                    autoplay
                    {...lunboSetting}
                    ref={(el) => (this.sponsorCarousel = el)}
                  >

                    {sponsorHtml}
                  </Carousel>
                </div>
                <div
                  className={styles.btnBox}
                  onClick={() => {
                    this.toExhibitor();
                  }}
                >
                  展商名录&gt;&gt;
                </div>
                <Modal
                  visible={showModal}
                  onOk={() => {
                    router.history.push("/register1");
                  }}
                  onCancel={() => {
                    this.setState({ showModal: false });
                  }}
                  okText="确认"
                  cancelText="取消"
                >
                  <p style={{ padding: "20px 0" }}>只有vip用户才可查看</p>
                </Modal>
              </div>
            </div>
          ) : null}

          {/* 图片直播 */}
          {
            <div className={styles.picShowContainer} id="samePeriod_mh_box">
              <p className={styles.titleStyle + " " + styles.col000}>
                图片直播
              </p>
              {isPC ? (
                <div className={styles.swiperBox}>
                  <Swiper
                    spaceBetween={50}
                    slidesPerView={4}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false,
                    }}
                    loop
                  >

                    {
                      picShowList.map((item) => {
                        return (
                          <SwiperSlide>
                            <div key={item.id} className={styles.picShowItem}>
                              <img
                                className={styles.picShowImg}
                                src={item.pic.startsWith("http") ? item.pic : `${base.OssAddress}${item.pic}`}
                                alt=""
                              />
                            </div>
                          </SwiperSlide>
                        )
                      })

                    }

                  </Swiper>

                </div>
              ) : (
                <div>
                  <Swiper
                    style={{ width: '100%', height: '200rpx' }}
                    spaceBetween={10}
                    slidesPerView={2}
                    onSlideChange={() => console.log('slide asaaa')}
                    onSwiper={(swiper) => console.log(swiper)}
                    autoplay={{
                      delay: 5000,
                      disableOnInteraction: false,
                    }}
                    loop
                  >

                    {
                      picShowList.map((item) => {
                        return (
                          <SwiperSlide>
                            <div key={item.id}>
                              <img
                                style={{ width: '100%', height: '20rpx' }}
                                src={item.pic.startsWith("http") ? item.pic : `${base.OssAddress}${item.pic}`}
                                alt=""
                              />
                            </div>
                          </SwiperSlide>
                        )
                      })

                    }

                  </Swiper>

                </div>
              )}

            </div>
          }


          {/* 视频展示 */}
          {videoList && videoList.length > 0 ? (
            <div id="video_mh_box" className={styles.container}>
              <p className={styles.titleStyle + " " + styles.col000}>
                大会报道
              </p>
              <div className={styles.newsBox}>
                {isPC ? (
                  <ul className={styles.titleBox}>
                    {
                      newsList.map(item => {
                        return (
                          <li className={styles.titleItem}
                            onClick={() => {
                              this.ItemNewsClick(item);
                            }}><a className={styles.itemTxt}>{item.content_short}</a></li>
                        )
                      })
                    }
                  </ul>
                ) :
                  (
                    <ul className={styles.titleBox}>
                      {
                        newsList.map(item => {
                          return (
                            <li
                              style={{
                                width: '97%', textOverflow: 'ellipsis', color: '#0E86EC',
                                marginTop: '10px', whiteSpace: 'nowrap', overflow: 'hidden', fontSize: '15px'
                              }}
                              onClick={() => {
                                this.ItemNewsClick(item);
                              }}>.&nbsp;&nbsp;{item.content_short}</li>
                          )
                        })
                      }
                    </ul>
                  )
                }
              </div>
              {isPC ? (
                <div
                  className={styles.moreNews}
                  onClick={() => {
                    window.open(`/#/video2021List:${videoList[0].pid}`);
                  }}
                >
                  更多...
                </div>
              ) :
                (<div
                  style={{ width: '50px', height: '50px', marginLeft: 'auto' }}
                  onClick={() => {
                    window.open(`/#/video2021List:${videoList[0].pid}`);
                  }}
                >
                  更多...
                </div>)
              }

              <Row style={{ width: '100%', height: '28vw' }}>
                {isPC ? (
                  <Col span={isPC ? 12 : 24}>
                    <div className={styles.videoMax}>
                      <div style={{ width: '100%', height: '82%', textAlign: 'center' }}>
                        <video src={currentVideoSrc}
                          style={{ width: '0px', height: '100%', objectFit: 'contain'}}
                          controls
                          preload="auto"
                          x-webkit-airplay="true"
                          x5-video-player-fullscreen="false"
                          webkit-playsinline="true"
                          x5-video-player-type="h5"
                          x5-video-orientation="portraint"
                          playsinline="true"
                          x5-playsinline=""
                          controlslist="nodownload"
                          onLoadedData = {(e) => {
                            debugger
                            console.log('onLoadedData')
                            e.target.style.width = "100%";
                            if (currentVideoSrc == 'https://hanganyun.oss-cn-beijing.aliyuncs.com/minhang/content/74a400a22e17df76b752c28d5f770648.mp4') {
                              e.target.style.width = "auto";
                            }
                          }}
                          onLoadStart={(e) => {
                            console.log('onLoadStart')
                            e.target.style.width = "0px";
                          }}
                        ></video>
                      </div>
                      <div className={styles.videoBottomTitle}>{currentVideoName}</div>    
                    </div>
                  </Col>
                )
                  : (
                    <div style={{ width: '100%', height: '250px', textAlign: 'center', background: '#000000', paddingTop: '30px' }}>
                      <video src={currentVideoSrc}
                        style={{ width: '100%', height: '200px', objectFit: 'contain' }}
                        controls
                        preload="auto"
                        x-webkit-airplay="true"
                        x5-video-player-fullscreen="false"
                        object-fit="cover"
                        webkit-playsinline="true"
                        x5-video-player-type="h5"
                        x5-video-orientation="portraint"
                        playsinline={true}
                        controlslist="nodownload"
                      ></video>
                      <div style={{
                        marginTop: '13px', background: '#000000', color: '#fff',
                        paddingBottom: '10px', display: 'flex', alignItems: 'center', textAlign: 'left',
                        paddingLeft: '10px', lineHeight: '22px', fontSize: '15px'
                      }}>{currentVideoName}</div>
                    </div>

                  )
                }


                {isPC ? (
                  <>
                    <Col span={isPC ? 12 : 24}>
                      <div className={styles.vidRightWrap}>
                        {videoList.length >= 1 ? (
                          videoList.map((item, index) => {
                            // if (index == 0 || index > 3) {
                            //   return false;
                            // }
                            return (
                              <div className={styles.itemWrap}>
                                <Row key={item.id}>
                                  <Col span={10}>
                                    <div
                                      className={styles.imgWrapR}
                                      onClick={() => {
                                        this.ImgCardContentClick(item);
                                      }}
                                    >
                                      {item.category ? (
                                        <img
                                          src={require("../assets/image/play2x.png")}
                                          className={styles.playIconR}
                                          alt=""
                                        />
                                      ) : null}

                                      <img
                                        className={styles.videoImageR}
                                        src={
                                          item.pic.startsWith("http")
                                            ? item.pic
                                            : `${base.OssAddress}${item.pic}`
                                        }
                                        alt=""
                                      />
                                    </div>
                                  </Col>
                                  <Col span={14}>
                                    <span>
                                      {item.content_short}
                                    </span>
                                    <p className={styles.videoTime}>发布时间: {moment(item.update_time * 1000).format().slice(0, 10)} 时长: {item.address}</p>
                                    {/* <p
                                      dangerouslySetInnerHTML={{
                                        __html: item.content.replace(
                                          /<.*?>/gi,
                                          ""
                                        ),
                                      }}
                                    ></p> */}
                                  </Col>
                                </Row>
                              </div>
                            );
                          })
                        ) : (
                          <div
                            style={{ textAlign: "center", marginTop: "40px" }}
                          >
                            {/* 加载中 */}
                          </div>
                        )}
                      </div>
                    </Col>

                  </>
                ) : (
                  <Col>
                    <div className={styles.videoMin} style={{
                      display: 'flex', overflow: 'hidden', overflowX: 'scroll',
                      background: '#f4f2f2', paddingTop: '15px', paddingLeft: '15px', paddingRight: '15px',
                      marginTop: '65px'
                    }}>

                      {videoList.map((item, index) => {
                        return (
                          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginRight: '15px' }}>
                            <div
                              style={{ width: '60%', height: '80px', position: 'relative', }}
                              onClick={() => {
                                this.ImgCardContentClick(item);
                              }}
                            >
                              {item.category ? (
                                <img
                                  src={require("../assets/image/play2x.png")}
                                  style={{
                                    position: 'absolute',
                                    top: '40%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '30px'
                                  }}
                                  alt=""
                                />
                              ) : null}

                              <img
                                className={styles.videoImageMin}
                                src={
                                  item.pic.startsWith("http")
                                    ? item.pic
                                    : `${base.OssAddress}${item.pic}`
                                }
                                alt=""
                              />
                            </div>
                            <p style={{ width: '210px', height: '49px', wordWrap: 'wrap', lineHeight: '22px', fontSize: '15px', marginTop: '20px' }}>
                              {item.content_short}
                            </p>
                            <p style={{ fontSize: '13px', marginBottom: '15px', width: '100%' }}>发布时间: {moment(item.update_time * 1000).format().slice(0, 10)} 时长: {item.address}</p>
                          </div>
                        );

                      })}
                    </div>
                  </Col>
                )}
              </Row>
            </div>
          ) : null}


          {/* 新联体联盟 */}
          {isPC ? (
            <div id="meiti_mh_box" className={styles.agendaBox2} style={{ marginBottom: '40px' }}>
              <div className={styles.container}>
                <p className={styles.titleStyle + " " + styles.col000}>
                  新媒体联盟
                </p>
                <ul className={styles.iconList}>
                  {
                    associatedMedia.map((item) => {
                      return (
                        <li key={item.id} className={styles.picBox}>
                          <img
                            src={item.pic.startsWith("http") ? item.pic : `${base.OssAddress}${item.pic}`}
                            alt="" />
                          <a href={item.link} target="_blank" >
                            <div className={styles.mask}>
                            </div>
                          </a>
                        </li>
                      )
                    })
                  }
                </ul>



                <div style={{ display: 'flex',width: '95%',margin: '0 auto' }}>
                  <div style={{width: '40%',marginLeft: '-5%'}}>
                    
                    <div className={styles.noWrap}>
                      <span className={styles.fontWeight}>联盟原则</span>
                      <div>
                        {
                          principle.map((item) => {
                            return (
                              <p key={item.id}>{item.address}</p>
                            )
                          })
                        }
                      </div>
                    </div>
                    <div className={styles.noWrap}>
                      <span className={styles.fontWeight}>联盟宗旨</span>
                      <div>
                        {purpose.map((item) => {
                          return (
                            <div className={styles.text} key={item.id} dangerouslySetInnerHTML={{ __html: item.content }}></div>
                          )
                        })}
                      </div>
                    </div>
                  </div>

                  <div className={styles.noWrap}>
                    <span className={styles.fontWeight} style={{marginLeft:'-73%'}}>联盟宣言</span>
                    <div style={{width: '91%',marginLeft: '8%'}}>
                      {
                        declaration.map((item) => {
                          return (
                            <div className={styles.listMar} key={item.id}>
                              <p>
                                <span className={styles.texttitle}>{item.category}</span> : {item.address}
                              </p>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>

                </div>

              </div>
            </div>
          ) : (
            <div id="meiti_mh_box" style={{
              background: '#FFFFFF',
              boxShadow: '0rem 0rem 2rem 0rem rgba(0, 0, 0, 0.14)',
              borderRadius: '6px',
              maxWidth: '20rem',
              margin: '470px auto 20px',
              padding: '20px 0'
            }}>
              <div className={styles.container}>
                <p className={styles.titleStyle + " " + styles.col000}>
                  新媒体联盟
                </p>
                <ul className={styles.iconList}>
                  {
                    associatedMedia.map((item) => {
                      return (
                        <li key={item.id} className={styles.picBox}>
                          <img
                            src={item.pic.startsWith("http") ? item.pic : `${base.OssAddress}${item.pic}`}
                            alt="" />
                          <a href={item.link} target="_blank" >
                            <div style={{
                              display: 'block',
                              position: 'absolute',
                              top: '0',
                              left: '0',
                              width: '62px',
                              height: '45px',
                              background: 'rgba(101, 101, 101, 0.3)',
                              opacity: '0',
                            }}>
                            </div>
                          </a>
                        </li>
                      )
                    })
                  }
                </ul>
                <div className={styles.section}>
                <div className={styles.noWrap}>
                    <span className={styles.fontWeight}>联盟原则</span>
                    <div>
                      {
                        principle.map((item) => {
                          return (
                            <p key={item.id}>{item.address}</p>
                          )
                        })
                      }
                    </div>
                  </div>
                  <div className={styles.noWrap}>
                    <span className={styles.fontWeight}>联盟宗旨</span>
                    <div>
                      {purpose.map((item) => {
                        return (
                          <div className={styles.text} key={item.id} dangerouslySetInnerHTML={{ __html: item.content }}></div>
                        )
                      })}
                    </div>
                  </div>

                  

                  <div className={styles.noWrap}>
                    <span className={styles.fontWeight}>联盟宣言</span>
                    <div>
                      {
                        declaration.map((item) => {
                          return (
                            <div className={styles.listMar} key={item.id}>
                              <p>
                                <span className={styles.texttitle}>{item.category}</span> : {item.address}
                              </p>
                            </div>
                          )
                        })
                      }

                    </div>
                  </div>
                </div>


              </div>
            </div>
          )}

          {/* 荣誉奖项 */}
          <div id="jiangxiang_mh_box" className={styles.agendaBox2} style={{ display: 'none' }}>
            <div className={styles.container}>
              <p className={styles.titleStyle + " " + styles.col000}>
                荣誉奖项
              </p>
              {/* <p className={styles.titleEnStyle + " " + styles.col999}>
                HONORABLE MENTION
              </p> */}

              <div className={styles.swiper}>
                <MySwiper
                  titleList={this.state.titleList}
                // data={{
                //   titleList: [
                //     "特别贡献奖",
                //     "最受欢迎奖",
                //     "最具潜力奖",
                //     "最具影响力奖",
                //     "最佳创新奖",
                //     "新媒体宣传奖",
                //   ],
                // }}
                />
                {/* <p className={styles.swiperTitle}>{awardName}</p> */}
              </div>

              {isPC ? (
                <div className={styles.prize}>
                  <Row>
                    <Col span={12}>
                      <div className={styles.prizeWrap}>
                        <div className={styles.prizeTitle}>
                          <img
                            src={require("../assets/image/jbMax.png")}
                            alt=""
                          />
                          <span>特别贡献奖</span>
                        </div>

                        <div className={styles.prizeName}>
                          <span>颁奖嘉宾：</span>
                          {
                            awardingGuests.map((item) => {
                              return (
                                <p key={item.id}>{item.address}</p>
                              )
                            })
                          }

                        </div>

                        <div className={styles.prizeEnterprise}>
                          <span className={styles.addMargin}>获奖企业：</span>
                          {awardCompany.map((item) => {
                            return (
                              <p key={item.id}>{item.address}</p>
                            )
                          })}

                          {/* <p>深圳中集天达空港设备公司</p>
                          <p>北京航星机器制造有限公司</p> */}
                        </div>
                      </div>
                    </Col>

                    <Col span={12}>
                      <div className={styles.prizeWrap}>
                        <div className={styles.prizeTitle}>
                          <img
                            src={require("../assets/image/jbMax.png")}
                            alt=""
                          />
                          <span>最受欢迎奖</span>
                        </div>

                        <div className={styles.prizeName}>
                          <span>颁奖嘉宾：</span>
                          {awardingGuests1.map((item) => {
                            return (
                              <p key={item.id}>{item.address}</p>
                            )
                          })}

                        </div>

                        <div className={styles.prizeEnterprise}>
                          <span className={styles.addMargin}>获奖企业：</span>
                          {awardCompany1.map((item) => {
                            return (
                              <p key={item.id}>{item.address}</p>
                            )
                          })}
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className={styles.prizeWrap}>
                        <div className={styles.prizeTitle}>
                          <img
                            src={require("../assets/image/jbMax.png")}
                            alt=""
                          />
                          <span>最具潜力奖</span>
                        </div>

                        <div className={styles.prizeName}>
                          <span>颁奖嘉宾：</span>
                          {awardingGuests2.map((item) => {
                            return (
                              <p key={item.id}>{item.address}</p>
                            )
                          })}
                        </div>

                        <div className={styles.prizeEnterprise}>
                          <span className={styles.addMargin}>获奖企业：</span>
                          {awardCompany2.map((item) => {
                            return (
                              <p key={item.id}>{item.address}</p>
                            )
                          })}
                        </div>
                      </div>
                    </Col>

                    <Col span={12}>
                      <div className={styles.prizeWrap}>
                        <div className={styles.prizeTitle}>
                          <img
                            src={require("../assets/image/jbMax.png")}
                            alt=""
                          />
                          <span>最具影响力奖</span>
                        </div>

                        <div className={styles.prizeName}>
                          <span>颁奖嘉宾：</span>
                          {awardCompany3.map((item) => {
                            return (
                              <p key={item.id}>{item.address}</p>
                            )
                          })}
                        </div>

                        <div className={styles.prizeEnterprise}>
                          <span className={styles.addMargin}>获奖企业：</span>
                          {awardCompany3.map((item) => {
                            return (
                              <p key={item.id}>{item.address}</p>
                            )
                          })}
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className={styles.prizeWrap}>
                        <div className={styles.prizeTitle}>
                          <img
                            src={require("../assets/image/jbMax.png")}
                            alt=""
                          />
                          <span>最佳创新奖</span>
                        </div>

                        <div className={styles.prizeName}>
                          <span>颁奖嘉宾：</span>
                          {awardCompany4.map((item) => {
                            return (
                              <p key={item.id}>{item.address}</p>
                            )
                          })}
                        </div>

                        <div className={styles.prizeEnterprise}>
                          <span className={styles.addMargin}>获奖企业：</span>
                          {awardCompany4.map((item) => {
                            return (
                              <p key={item.id}>{item.address}</p>
                            )
                          })}
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className={styles.prizeWrap}>
                        <div className={styles.prizeTitle}>
                          <img
                            src={require("../assets/image/jbMax.png")}
                            alt=""
                          />
                          <span>新媒体宣传奖</span>
                        </div>

                        <div className={styles.prizeName}>
                          <span>颁奖嘉宾：</span>
                          {awardCompany5.map((item) => {
                            return (
                              <p key={item.id}>{item.address}</p>
                            )
                          })}
                        </div>

                        <div className={styles.prizeEnterprise}>
                          <span className={styles.addMargin}>获奖单位：</span>
                          {awardCompany5.map((item) => {
                            return (
                              <p key={item.id}>{item.address}</p>
                            )
                          })}
                        </div>
                      </div>
                    </Col>
                    <Col span={24} style={{ width: "100%" }}>
                      <p className={styles.prizeFooter}>以上排名不分先后</p>
                    </Col>
                  </Row>
                </div>
              ) : (
                <div className={styles.prizeMo}>
                  <Carousel autoplay>
                    <div>
                      <Row>
                        <Col span={24}>
                          <div className={styles.prizeWrap}>
                            <div className={styles.prizeTitle}>
                              <img
                                src={require("../assets/image/jbMax.png")}
                                alt=""
                              />
                              <span>特别贡献奖</span>
                            </div>

                            <div className={styles.prizeName}>
                              <span>颁奖嘉宾：</span>
                              {
                                awardingGuests.map((item) => {
                                  return (
                                    <p key={item.id}>{item.address}</p>
                                  )
                                })
                              }
                            </div>

                            <div className={styles.prizeEnterprise}>
                              <span className={styles.addMargin}>
                                获奖企业：
                              </span>
                              {awardCompany.map((item) => {
                                return (
                                  <p key={item.id}>{item.address}</p>
                                )
                              })}
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <div className={styles.prizeWrap}>
                            <div className={styles.prizeTitle}>
                              <img
                                src={require("../assets/image/jbMax.png")}
                                alt=""
                              />
                              <span>最受欢迎奖</span>
                            </div>

                            <div className={styles.prizeName}>
                              <span>颁奖嘉宾：</span>
                              {awardingGuests1.map((item) => {
                                return (
                                  <p key={item.id}>{item.address}</p>
                                )
                              })}
                            </div>

                            <div className={styles.prizeEnterprise}>
                              <span className={styles.addMargin}>
                                获奖企业：
                              </span>
                              {awardCompany1.map((item) => {
                                return (
                                  <p key={item.id}>{item.address}</p>
                                )
                              })}
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <p className={styles.ranking}>以上排名不分先后</p>
                        </Col>
                      </Row>
                    </div>

                    <div>
                      <Row>
                        <Col span={24}>
                          <div className={styles.prizeWrap}>
                            <div className={styles.prizeTitle}>
                              <img
                                src={require("../assets/image/jbMax.png")}
                                alt=""
                              />
                              <span>最具潜力奖</span>
                            </div>

                            <div className={styles.prizeName}>
                              <span>颁奖嘉宾：</span>
                              {awardingGuests2.map((item) => {
                                return (
                                  <p key={item.id}>{item.address}</p>
                                )
                              })}
                            </div>

                            <div className={styles.prizeEnterprise}>
                              <span className={styles.addMargin}>
                                获奖企业：
                              </span>
                              {awardCompany2.map((item) => {
                                return (
                                  <p key={item.id}>{item.address}</p>
                                )
                              })}
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <div className={styles.prizeWrap}>
                            <div className={styles.prizeTitle}>
                              <img
                                src={require("../assets/image/jbMax.png")}
                                alt=""
                              />
                              <span>最具影响力奖</span>
                            </div>

                            <div className={styles.prizeName}>
                              <span>颁奖嘉宾：</span>
                              {awardCompany3.map((item) => {
                                return (
                                  <p key={item.id}>{item.address}</p>
                                )
                              })}
                            </div>

                            <div className={styles.prizeEnterprise}>
                              <span className={styles.addMargin}>
                                获奖企业：
                              </span>
                              <p>
                              </p>
                              {awardCompany3.map((item) => {
                                return (
                                  <p key={item.id}>{item.address}</p>
                                )
                              })}
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <p className={styles.ranking}>以上排名不分先后</p>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Row>
                        <Col span={24}>
                          <div className={styles.prizeWrap}>
                            <div className={styles.prizeTitle}>
                              <img
                                src={require("../assets/image/jbMax.png")}
                                alt=""
                              />
                              <span>最佳创新奖</span>
                            </div>

                            <div className={styles.prizeName}>
                              <span>颁奖嘉宾：</span>
                              {awardCompany4.map((item) => {
                                return (
                                  <p key={item.id}>{item.address}</p>
                                )
                              })}
                            </div>

                            <div className={styles.prizeEnterprise}>
                              <span className={styles.addMargin}>
                                获奖企业：
                              </span>
                              {awardCompany4.map((item) => {
                                return (
                                  <p key={item.id}>{item.address}</p>
                                )
                              })}
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <div className={styles.prizeWrap}>
                            <div className={styles.prizeTitle}>
                              <img
                                src={require("../assets/image/jbMax.png")}
                                alt=""
                              />
                              <span>新媒体宣传奖</span>
                            </div>

                            <div className={styles.prizeName}>
                              <span>颁奖嘉宾：</span>
                              {awardCompany5.map((item) => {
                                return (
                                  <p key={item.id}>{item.address}</p>
                                )
                              })}
                            </div>

                            <div className={styles.prizeEnterprise}>
                              <span className={styles.addMargin}>
                                获奖单位：
                              </span>
                              {awardCompany5.map((item) => {
                                return (
                                  <p key={item.id}>{item.address}</p>
                                )
                              })}
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <p className={styles.ranking}>以上排名不分先后</p>
                        </Col>
                      </Row>
                    </div>
                  </Carousel>
                </div>
              )}
            </div>
          </div>

          {/* 最新资讯 */}
          {latestNewsData &&
            latestNewsData.length > 0 &&
            latestNewsData[0].is_hidden == 1 ? (
            <div id="latestNews_mh_box" className={styles.latestNewsBox}>
              {/* <a href={latestNewsData[0].link} target="_blank"> */}
              <a>
                <img
                  src={
                    isPC
                      ? require("../assets/image/news_bg.jpg")
                      : require("../assets/image/news_bgMo.jpg")
                  }
                  alt=""
                />
              </a>
            </div>
          ) : null}

          {/* 同期展览 */}
          {/* {
            samePeriodList && samePeriodList.length > 0 && samePeriodList[0].is_hidden == 1 ? (
              <div id="samePeriod_mh_box" className={styles.samePeriodBox}>
                <div className={styles.container}>
                  <p className={styles.titleStyle + " " + styles.col000}>同期展览</p>
                  <p className={styles.titleEnStyle + " " + styles.col999}>CONCURRENT EXHIBITION</p>
                  <div className={styles.con}>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      {
                        samePeriodList && samePeriodList.length > 0 ?
                          samePeriodList.map((item, ind) => {
                            return ind > 0 && ind < 3 ? (
                              <Col key={ind} className="gutter-row" span={md}>
                                <a className={styles.itemBox} href={item.link} target="_blank">
                                  <div className={styles.imgBox}>
                                    <img src={item.pic.startsWith('http') ? item.pic : `${base.OssAddress}${item.pic}`} alt="" />
                                  </div>
                                  <p>{item.content_short}</p>
                                </a>
                              </Col>
                            ) : null
                          }) : null
                      }
                    </Row>
                  </div>
                </div>
              </div>
            ) : null
          } */}

          {/* 大会地址 */}
          {isPC ? (
            <div id="address_mh_box" className={styles.addrContainer}>
              <p className={styles.titleStyle + " " + styles.col000}>
                大会地址
              </p>
              <div className={styles.addressBox}>

                {/* <p className={styles.titleEnStyle + " " + styles.col999}>
                CONGRESS VENUE
              </p> */}
                <div className={styles.addressContainer}>
                  {addressPic.map((item, index) => {
                    return (
                      <div className={styles.addressImgBox}>
                        {isPC ? (
                          <img style={{ height: '100%', width: '100%' }} src={item.pic.startsWith("http") ? item.pic : `${base.OssAddress}${item.pic}`} alt="" />
                        ) : (
                          <img src={item.pic.startsWith("http") ? item.pic : `${base.OssAddress}${item.pic}`} alt="" />
                        )}
                      </div>
                    )
                  })}
                  <div className={styles.addresstextBox}>
                    {
                      addressList.map((item, index) => {
                        return (
                          <div className={styles.addresstop}>
                            <p className={styles.addresstitle}>{item.content_short}</p>
                            <p>出租车：直达</p>
                            <p>{item.address}</p>
                            <p>{item.category}</p>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div id="address_mh_box" className={styles.addrContainer}>
              <p className={styles.titleStyle + " " + styles.col000}>
                大会地址
              </p>
              <div style={{
                maxWidth: '20rem',
                margin: '0 auto',
                padding: '15px 15px',
                boxShadow: '0rem 0rem 2rem 0rem rgba(0, 0, 0, 0.14)',
                borderRadius: '1rem'
              }}>

                {addressPic.map((item, index) => {
                  return (
                    <div>
                      <img style={{ height: '100%', width: '98%', marginBottom: '20px' }} src={item.pic.startsWith("http") ? item.pic : `${base.OssAddress}${item.pic}`} alt="" />
                    </div>
                  )
                })}
                <div className={styles.addresstextBox}>
                  {
                    addressList.map((item, index) => {
                      return (
                        <div style={{ marginBottom: '20px' }}>
                          <p className={styles.addresstitle}>{item.content_short}</p>
                          <p>出租车：直达</p>
                          <p>{item.address}</p>
                          <p>{item.category}</p>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          )}

          {/* 合作单位 */}
          {/* {
            cooperationUnitList && cooperationUnitList.length > 1 && cooperationUnitList[0].id == cooperationUnitList[0].pid && cooperationUnitList[0].is_hidden == 1 ? (
              <div className={styles.cooperationUnitBox}>
                <div className={styles.container}>
                  <div className={styles.cooperationTit}>合作单位</div>
                  <div className={styles.imgBox}>
                    {
                      cooperationUnitList.map(item => {
                        return item.id != item.pid ? <img key={item.id} src={item.pic.startsWith('http') ? item.pic : `${base.OssAddress}${item.pic}`} alt="" /> : ""
                      })
                    }
                  </div>
                </div>
              </div>
            ) : null
          } */}
          {/* 指定媒体 */}
          {/* {
            designatedMediaList && designatedMediaList.length > 1 && designatedMediaList[0].id == designatedMediaList[0].pid && designatedMediaList[0].is_hidden == 1 ? (
              <div className={styles.cooperationUnitBox}>
                <div className={styles.container}>
                  <div className={styles.cooperationTit}>指定媒体</div>
                  <div className={styles.imgBox}>
                    {
                      designatedMediaList.map(item => {
                        return item.id != item.pid ? <img key={item.id} src={item.pic.startsWith('http') ? item.pic : `${base.OssAddress}${item.pic}`} alt="" /> : ""
                      })
                    }
                  </div>
                </div>
              </div>
            ) : null
          } */}
        </PageContent>
      </div>
    );
  }
}

export default Content;
