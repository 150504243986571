import React, { Component } from 'react';
import { Menu, Input, Button, Divider, Breadcrumb, Avatar, Popconfirm } from 'antd';
import styles from './index.module.less';
import { tools } from '@/src/lib/tools';
import store from '@/src/redux/store.js';
import { setBreadcrumbList } from '@/src/redux/actions/BreadcrumbList';
import { setUserInfo } from '@/src/redux/actions/userInfo';
import { base } from '@/src/api/base';

import { HashRouter } from 'react-router-dom'
const router = new HashRouter()

const { Search } = Input;

class PageHeader extends Component {

  state = {
    current: 'home',
    showSearch: true,
    showNav: true,
    TitleList: [],
    BreadcrumbList: [],
    showBreadcrumbList: false,
  };

  componentDidMount() {
    this.unsubscribe = store.subscribe(this.ListenStore.bind(this))
    window.addEventListener('resize', this.resizeBind)
    this.resizeBind.bind(this)
    this.ListenStore()

    if (!tools.IsPC()) {
      this.setState({
        showSearch: false,
      });
    }
  }

  componentWillUnmount() {
    // 对 store 变化取消监听
    this.unsubscribe(this.ListenStore.bind(this));
    window.removeEventListener('resize', this.resizeBind)
    this.setState({
      current: 'home',
      showSearch: true,
      showNav: true,
      TitleList: [],
      BreadcrumbList: [],
      showBreadcrumbList: false,
    })
  }

  ListenStore = () => {

    let { TdTitleBar } = store.getState()
    if (!TdTitleBar || TdTitleBar === {} || TdTitleBar.length === undefined || TdTitleBar.length === 0) {
      return
    }
    let oneTitleList = TdTitleBar.filter(item => {
      return item.level === 1 && item.nav_status === 2 && item.show_status === 2
    })
    this.setState({
      TitleList: oneTitleList,
    });
  }

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.showSearch) {
        this.setState({
          showSearch: false,
        });
      }
    } else {
      if (!this.state.showSearch) {
        this.setState({
          showSearch: true,
        });
      }
    }
  }

  handleClick = e => {
    const { title_bar_no, name } = e.item.props
    this.setState({
      current: e.key,
    });
    router.history.push(`${e.key}${title_bar_no}/${title_bar_no}`)
  };

  LoginOut = e => {
    store.dispatch(setUserInfo(null, -1))
  }

  openPersonalCenter = () => {
    router.history.push('/PersonalCenter');
    //打开新标签的方式
    // const newLabel = window.open('about:blank');
    // newLabel.location.href="/#/PersonalCenter"
  };

  render() {

    let showNav = this.state.showSearch
    if (this.props.showNav !== undefined) {
      showNav = this.props.showNav
    }

    let showSearch = this.state.showSearch
    if (this.props.showSearch !== undefined) {
      showSearch = this.props.showSearch
    }

    // let BreadcrumbList = this.state.BreadcrumbList
    // if (this.props.BreadcrumbList !== undefined) {
    //   BreadcrumbList = this.props.BreadcrumbList
    // }

    const { BreadcrumbList, userInfo } = store.getState()

    let showBreadcrumbList = this.state.showBreadcrumbList
    if (this.props.showBreadcrumbList !== undefined) {
      showBreadcrumbList = this.props.showBreadcrumbList
    }

    let IsDivider = false
    if (this.props.IsDivider !== undefined) {
      IsDivider = this.props.IsDivider
    }

    return (
      <div className={styles.HeaderBox} >

        {/* 搜索框 */}
        {showSearch ?
          <div className={styles.HeaderSearch} >

            <div className={styles.ta} >
              <div>
                <a href="#"><img className={styles.logo} src={require('../../../../assets/logo.png')} alt="民航安保网" /></a>
              </div>

              <div className={styles.timeDiv} >
              {/* <a href="#">{tools.getCurrentDate()}</a> */}
                {tools.getCurrentDate()}
              </div>
            </div>

            <div className={styles.ta}>
              <div>
                <Search
                  className={styles.Search}
                  allowClear={true}
                  placeholder="请输入关键字"
                  enterButton
                  onSearch={value => {
                    if (value === '') {
                      return
                    }
                    store.dispatch(setBreadcrumbList(
                      [

                      ]
                    ))

                    router.history.push('/MoreArticles0/' + value)
                  }}
                />
              </div>

              <div className={styles.RightBtn} >
                {/* <Icon className={styles.mailIco} type="mail" theme="filled" /> */}
                {
                  !userInfo ?
                    // <Button  type="primary" onClick={() => router.history.push(`/login`)} > 注册 / 登录 </Button>
                    <div className={styles.login} onClick={() => router.history.push(`/login`)} > <span>注册</span> <span>登录</span> </div>
                    :
                    <div>

                      <Avatar onClick={this.openPersonalCenter} icon="user" src={userInfo.head_url} />
                      <span onClick={this.openPersonalCenter} className={styles.NickName} >{userInfo.user_name || `${base.NameInitials}${userInfo.id}`}</span>
                      <Popconfirm
                        title="确认退出当前账号吗?"
                        onConfirm={this.LoginOut}
                        okText="退出"
                        cancelText="取消"
                      >
                        <Button type="primary" className={styles.LoginOutBtn} > 退出 </Button>
                      </Popconfirm>
                    </div>
                }

              </div>
            </div>

          </div> : null

        }


        {/* 导航栏   */}
        {showNav ?

          <div className={styles.MenuBox}>
            <Menu onClick={this.handleClick} selectedKeys={[this.props.current]} mode="horizontal"  >

              {
                this.state.TitleList.length > 0 ?
                  this.state.TitleList.map(item => {
                    return (
                      <Menu.Item key={item.skip_url} title_bar_no={item.title_bar_no} name={item.name} >
                        {item.name}
                      </Menu.Item>

                    )
                  })
                  : null
              }

            </Menu>
          </div> : IsDivider ? <Divider className={styles.DividingLine} ></Divider> : null

        }

        {/* 面包屑 */}
        {/* Breadcrumb */}
        {
          showBreadcrumbList && BreadcrumbList && BreadcrumbList.length > 0 ?
            <div style={{ padding: '1rem 2rem' }}>
              <Breadcrumb>
                {
                  BreadcrumbList.map(
                    (item, index) => {

                      let path = null
                      if (index === 0 || item.path === null || item.path === undefined) {
                        let { TdTitleBar } = store.getState()

                        TdTitleBar.forEach(item3 => {
                          if (item3.title_bar_no === item.name && item3.parent_id !== '1') {
                            item.name = item3.parent_id
                          }
                        })

                        TdTitleBar.forEach(item3 => {
                          if (item3.title_bar_no === item.name) {
                            item.name = item3.name
                          }
                        })

                        path = TdTitleBar.filter(item2 => {
                          return item2.name === item.name
                        })
                        if (path && path.length > 0) {
                          path = path[0].skip_url + path[0].title_bar_no + '/' + path[0].name
                        }
                      }

                      return (
                        <Breadcrumb.Item key={item.id} href={`/#${item.path || path}`} onClick={() => {

                          if (index !== BreadcrumbList.length - 1) {
                            let BreadcrumbList2 = BreadcrumbList.slice(0, index + 1)
                            store.dispatch(setBreadcrumbList(BreadcrumbList2))
                          }

                        }} >
                          <span>{item.name}</span>
                        </Breadcrumb.Item>

                      )
                    }
                  )
                }

              </Breadcrumb>
            </div>

            : null
        }

      </div>
    )
  }
}

export default PageHeader;