//home.js
import React, { Component } from "react";
import styles from "./index.module.less";
import API from '@/src/api/api.js'

import {
  Form,
  Input,
  Button,
  Col,
  Select,
  Tooltip,
  Icon,
  Checkbox,
  Divider,
  Tag,
} from "antd";
import { message, Spin } from "antd";
import { base } from "@/src/api/base";
import LoginHeader from "@/src/components/LoginHeader";
import PageFooter from "@/src/components/PageFooter";
import AliOssUpload from "@/src/components/AliOssUpload";
import { tools } from "@/src/lib/tools";
import { HashRouter } from "react-router-dom";

const router = new HashRouter();
const { Option } = Select;
const { TextArea } = Input;
const { CheckableTag } = Tag;

export default class Login extends Component {
  state = {
    loading: false,
    codeStr: "发送验证码",
    codeState: false, // 验证码按钮触发状态
    count_down_time: 60,
    AccountTypeList: [],
    confirmDirty: false,
    IsUploadOriginalArticle: false,
    UploadOriginalArticleUrl: "",
    zj_account_type_no: "",
    register_type: 1,
    company: [], //单位标签
    companyChecked: [], //已选单位标签
    work: [], //岗位
    workChecked: [],
    interest: [], //兴趣
    interestChecked: [],
    selectedTags: [], //已选择企业标签
    tagsData: [], //企业标签列表
    isTypeFirm: true, //注册类型是否为企业
    isContacts: false, //是否展示企业联系人信息
    sex: "1",
  };
  componentDidMount() {
    this.setState(
      { register_type: this.props.match.params.type },
      this.QueryMemberType()
    );
    this.QueryFirmTab();
    //返回页面顶部
    window.scrollTo(0, 0);
  }
  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("pass_word")) {
      callback("两次密码不一致!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["pass_word2"], { force: true });
    }
    callback();
  };
  compareToFirsChkRule = (rule, value, callback) => {
    if (!value) {
      callback("请勾选同意！");
    } else {
      callback();
    }
  };
  QueryFirmTab = () => {
    // let params = {
    //     id:1,
    // }
    // API.QueryFirmTab(params).then(res => {
    //     console.log(res,'111111')
    //     if (res.status === 200) {
    //         this.setState({
    //             tagsData:JSON.parse(res.data.config_value)
    //         })
    //     } else {
    //         message.error(res.msg);
    //     }
    //     this.setState({ loading: false });
    // }).catch(res => {
    //     // console.log(res)
    //     this.setState({ loading: false });
    // })

    let apiObj = [
      {
        id: 1,
        dataArr: "tagsData",
      },
      {
        id: 9,
        dataArr: "company",
      },
      {
        id: 10,
        dataArr: "work",
      },
      {
        id: 11,
        dataArr: "interest",
      },
    ];
    apiObj.forEach((item) => {
      let params = {
        id: item.id,
      };
      API.QueryFirmTab(params)
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              [item.dataArr]: JSON.parse(res.data.config_value),
            });
          } else {
            message.error(res.msg);
          }
          this.setState({ loading: false });
        })
        .catch((res) => {
          // console.log(res)
          this.setState({ loading: false });
        });
    });
  };
  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !value });
  };
  typeChange = (e) => {
    if (e == 1) {
      this.setState({ isTypeFirm: true });
    } else {
      this.setState({ isTypeFirm: false });
    }
    console.log(e);
  };
  idCardChange = (e) => {
    if (e.target.value.length >= 17) {
      var num = e.target.value.slice(16, 17);
      if (parseInt(num) % 2 != 0) {
        this.setState({ sex: "1" });
      } else {
        this.setState({ sex: "2" });
      }
    }
  };
  handleTab(tag, checked) {
    const { selectedTags } = this.state;
    const nextSelectedTags = checked
      ? selectedTags.length >= 5
        ? selectedTags
        : [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    this.setState({ selectedTags: nextSelectedTags });
    // if(nextSelectedTags.length<=4){
    // }else{
    //     message.warning('最多选择五个标签');
    // }
    // console.log('You are interested in: ', nextSelectedTags);
  }
  handleTabCompany(tag, checked) {
    this.setState({ companyChecked: tag });

    // const { companyChecked } = this.state;
    // const nextcompanyChecked = checked?(companyChecked.length>=1?companyChecked :[...companyChecked, tag]) : companyChecked.filter(t => t !== tag);
    // this.setState({ companyChecked: nextcompanyChecked });
  }
  handleTabWork(tag, checked) {
    this.setState({ workChecked: tag });

    // const { workChecked } = this.state;
    // const nextworkChecked = checked?(workChecked.length>=1?workChecked :[...workChecked, tag]) : workChecked.filter(t => t !== tag);
    // this.setState({ workChecked: nextworkChecked });
  }

  handleTabInterest(tag, checked) {
    const { interestChecked } = this.state;
    const nextinterestChecked = checked
      ? interestChecked.length >= 5
        ? interestChecked
        : [...interestChecked, tag]
      : interestChecked.filter((t) => t !== tag);
    this.setState({ interestChecked: nextinterestChecked });
  }

  contactsChange = (e) => {
    this.setState({ isContacts: e.target.checked });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };
    const tailLayout = {
      wrapperCol: { offset: 4, span: 20 },
    };

    const {
      AccountTypeList,
      IsUploadOriginalArticle,
      zj_account_type_no,
      selectedTags,
      tagsData,
      workChecked,
      work,
      interestChecked,
      interest,
      isTypeFirm,
      sex,
      company,
      companyChecked,
    } = this.state;
    var selectedTags1 = JSON.stringify(selectedTags);
    return (
      <div>
        <Spin spinning={this.state.loading} delay={10} tip="注册中">
          <div className="LoginBox box-margin-top">
            <LoginHeader title="注册" />

            <div className={styles.ContentBox}>
              <Col xs={23} sm={23} md={20} style={{ margin: "0 auto" }}>
                <div className={styles.RightBox}>
                  <img
                    className={styles.bg}
                    src={require("../../assets/zh/register_bg.png")}
                    alt=""
                  />
                  <Form className="login-form">
                    <div className={styles.titleText}>注册</div>
                    <Form.Item
                      label="身份选择"
                      {...formItemLayout}
                      hasFeedback
                      style={{ padding: "0 1.8rem" }}
                    >
                      {getFieldDecorator("type", {
                        rules: [{ required: true, message: "请选择身份!" }],
                        initialValue: "1",
                      })(
                        <Select
                          placeholder="请选择您要注册的身份！"
                          onChange={this.typeChange}
                        >
                          <Option value="1">企业</Option>
                          <Option value="2">个人</Option>
                        </Select>
                      )}
                    </Form.Item>

                    {/* <div className={styles.titleTextS}><Divider>注册信息</Divider></div>
                                        <div className={styles.boxBorder}>
                                            <Form.Item {...formItemLayout} label="手机号" hasFeedback >
                                                {getFieldDecorator('phone', {
                                                    rules: [{ required: true, message: '请输入手机号!' }],
                                                })(
                                                    <Input type='tel' placeholder="手机号" />,
                                                )}
                                            </Form.Item>
                                            <Form.Item {...formItemLayout} label="验证码" className={styles.codeForm} >
                                                {getFieldDecorator('code', {
                                                    rules: [{ required: true, message: '请输入验证码!' }],
                                                })( 
                                                <Input placeholder="请输入验证码!" />,
                                                )}
                                                <Button type="primary" disabled={this.state.codeState} onClick={this.SendCode} className="login-form-button" > {this.state.codeStr} </Button>
                                            </Form.Item>
                                            <Form.Item label="登录密码" {...formItemLayout} hasFeedback>
                                                {getFieldDecorator('pass_word', {
                                                    rules: [
                                                        { required: true, message: '请填写6~16位数字组成的登录密码!', },
                                                        { validator: this.validateToNextPassword, }, ],
                                                })(<Input.Password placeholder="请填写6~16位数字组成的登录密码!" />)}
                                            </Form.Item>
                                            <Form.Item label="确认密码" {...formItemLayout} hasFeedback>
                                                {getFieldDecorator('pass_word2', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '请确认登录密码!',
                                                        },
                                                        {
                                                            validator: this.compareToFirstPassword,
                                                        },
                                                    ],
                                                })(<Input.Password onBlur={this.handleConfirmBlur} placeholder="请确认登录密码！" />)}
                                            </Form.Item>
                                        </div> */}

                    {!isTypeFirm ? (
                      <>
                        <div className={styles.titleTextS}>
                          <Divider>
                            {isTypeFirm ? "注册人信息" : "个人信息"}
                          </Divider>
                        </div>
                        <div className={styles.boxBorder}>
                          <Form.Item
                            {...formItemLayout}
                            label="手机号"
                            hasFeedback
                          >
                            {getFieldDecorator("phone", {
                              rules: [
                                { required: true, message: "请输入手机号!" },
                              ],
                            })(<Input type="tel" placeholder="手机号" />)}
                          </Form.Item>
                          <Form.Item
                            {...formItemLayout}
                            label="验证码"
                            className={styles.codeForm}
                          >
                            {getFieldDecorator("code", {
                              rules: [
                                { required: true, message: "请输入验证码!" },
                              ],
                            })(<Input placeholder="请输入验证码!" />)}
                            <Button
                              type="primary"
                              disabled={this.state.codeState}
                              onClick={this.SendCode}
                              className="login-form-button"
                            >
                              {" "}
                              {this.state.codeStr}{" "}
                            </Button>
                          </Form.Item>
                          <Form.Item
                            label="登录密码"
                            {...formItemLayout}
                            hasFeedback
                          >
                            {getFieldDecorator("pass_word", {
                              rules: [
                                {
                                  required: true,
                                  message: "请填写6~16位数字组成的登录密码!",
                                },
                                { validator: this.validateToNextPassword },
                              ],
                            })(
                              <Input.Password placeholder="请填写6~16位数字组成的登录密码!" />
                            )}
                          </Form.Item>
                          <Form.Item
                            label="确认密码"
                            {...formItemLayout}
                            hasFeedback
                          >
                            {getFieldDecorator("pass_word2", {
                              rules: [
                                {
                                  required: true,
                                  message: "请确认登录密码!",
                                },
                                {
                                  validator: this.compareToFirstPassword,
                                },
                              ],
                            })(
                              <Input.Password
                                onBlur={this.handleConfirmBlur}
                                placeholder="请确认登录密码！"
                              />
                            )}
                          </Form.Item>
                          <Form.Item
                            {...formItemLayout}
                            label="邮箱："
                            hasFeedback
                          >
                            {getFieldDecorator("email", {
                              rules: [
                                { required: true, message: "请填写邮箱地址!" },
                                { type: "email", message: "邮箱格式不正确!" },
                              ],
                            })(<Input placeholder="请填写邮箱地址！" />)}
                          </Form.Item>
                          <Form.Item
                            {...formItemLayout}
                            label="真实姓名："
                            hasFeedback
                          >
                            {getFieldDecorator("real_name", {
                              rules: [
                                {
                                  required: false,
                                  message: "请输入真实姓名：!",
                                },
                              ],
                            })(<Input placeholder="请填写真实姓名！" />)}
                          </Form.Item>
                          <Form.Item
                            {...formItemLayout}
                            label="个人单位"
                            hasFeedback
                            className="firmLabel"
                          >
                            <p className={styles.lableTs}>请选择您的所在单位</p>
                            <div>
                              {company.map((tag) => (
                                <CheckableTag
                                  key={tag}
                                  checked={companyChecked.indexOf(tag) > -1}
                                  onChange={(checked) =>
                                    this.handleTabCompany(tag, checked)
                                  }
                                >
                                  {tag}
                                </CheckableTag>
                              ))}
                            </div>
                          </Form.Item>
                          <Form.Item
                            {...formItemLayout}
                            label="岗位"
                            hasFeedback
                            className="firmLabel"
                          >
                            <p className={styles.lableTs}>请选择您的岗位</p>
                            <div>
                              {work.map((tag) => (
                                <CheckableTag
                                  key={tag}
                                  checked={workChecked.indexOf(tag) > -1}
                                  onChange={(checked) =>
                                    this.handleTabWork(tag, checked)
                                  }
                                >
                                  {tag}
                                </CheckableTag>
                              ))}
                            </div>
                          </Form.Item>
                          <Form.Item
                            {...formItemLayout}
                            label="兴趣"
                            hasFeedback
                            required={false}
                          >
                            <p className={styles.lableTs}>
                              请选择您的兴趣，最多5个
                            </p>
                            <div>
                              {interest.map((tag) => (
                                <CheckableTag
                                  key={tag}
                                  checked={interestChecked.indexOf(tag) > -1}
                                  onChange={(checked) =>
                                    this.handleTabInterest(tag, checked)
                                  }
                                >
                                  {tag}
                                </CheckableTag>
                              ))}
                            </div>
                          </Form.Item>

                          {/* <Form.Item {...formItemLayout} label="职务：" hasFeedback >
                                                {getFieldDecorator('job', {
                                                    rules: [{ required: true, message: '请填写职务!' }],
                                                })(
                                                    <Input
                                                        placeholder="请填写您的职务！"
                                                    />,
                                                )}
                                            </Form.Item>
                                            <Form.Item {...formItemLayout} label="身份证号码" hasFeedback >
                                                {getFieldDecorator('idcard', {
                                                    rules: [{ required: true, message: '请输入身份证号码！'},
                                                            {len:18,message:'身份证号码长度为18个字符'}],
                                                })(
                                                    <Input onChange={this.idCardChange.bind(this)} placeholder="请输入您的身份证号码" />,
                                                )}
                                            </Form.Item>
                                            <Form.Item {...formItemLayout} label="性别" hasFeedback >
                                                {getFieldDecorator('gender', {
                                                    rules: [{ required: true, message: '请选择您的性别！' }],
                                                    initialValue:sex
                                                })(
                                                    <Select placeholder="请选择您的性别">
                                                        <Option value="1" >男</Option>
                                                        <Option value="2" >女</Option>
                                                    </Select>,
                                                )}
                                            </Form.Item> */}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={styles.boxBorder}>
                          <div className={styles.titleTextS}>
                            <Divider>企业信息</Divider>
                          </div>
                          <Form.Item
                            {...formItemLayout}
                            label="企业名称"
                            hasFeedback
                          >
                            {getFieldDecorator("work_units", {
                              rules: [
                                {
                                  required: true,
                                  message: "请输入您所在的企业全称！",
                                },
                              ],
                            })(<Input placeholder="请输入您所在的企业全称" />)}
                          </Form.Item>
                          <Form.Item
                            {...formItemLayout}
                            label="企业类别"
                            hasFeedback
                            className="firmLabel"
                          >
                            <p className={styles.lableTs}>
                              请选择您的企业类别，最多5个
                            </p>
                            <div>
                              {tagsData.map((tag) => (
                                <CheckableTag
                                  key={tag}
                                  checked={selectedTags.indexOf(tag) > -1}
                                  onChange={(checked) =>
                                    this.handleTab(tag, checked)
                                  }
                                >
                                  {tag}
                                </CheckableTag>
                              ))}
                            </div>
                          </Form.Item>
                        </div>
                        <div className={styles.boxBorder}>
                          <div className={styles.titleTextS}>
                            <Divider>注册人信息</Divider>
                          </div>
                          <Form.Item
                            {...formItemLayout}
                            label="真实姓名："
                            hasFeedback
                          >
                            {getFieldDecorator("real_name", {
                              rules: [
                                {
                                  required: true,
                                  message: "请输入真实姓名：!",
                                },
                              ],
                            })(<Input placeholder="请填写真实姓名！" />)}
                          </Form.Item>
                          <Form.Item
                            {...formItemLayout}
                            label="职务："
                            hasFeedback
                          >
                            {getFieldDecorator("job", {
                              rules: [
                                { required: true, message: "请填写职务!" },
                              ],
                            })(<Input placeholder="请填写您的职务！" />)}
                          </Form.Item>
                          <Form.Item
                            {...formItemLayout}
                            label="手机号"
                            hasFeedback
                          >
                            {getFieldDecorator("phone", {
                              rules: [
                                { required: true, message: "请输入手机号!" },
                              ],
                            })(<Input type="tel" placeholder="手机号" />)}
                          </Form.Item>
                          <Form.Item
                            {...formItemLayout}
                            label="验证码"
                            className={styles.codeForm}
                          >
                            {getFieldDecorator("code", {
                              rules: [
                                { required: true, message: "请输入验证码!" },
                              ],
                            })(<Input placeholder="请输入验证码!" />)}
                            <Button
                              type="primary"
                              disabled={this.state.codeState}
                              onClick={this.SendCode}
                              className="login-form-button"
                            >
                              {" "}
                              {this.state.codeStr}{" "}
                            </Button>
                          </Form.Item>
                          <Form.Item
                            label="登录密码"
                            {...formItemLayout}
                            hasFeedback
                          >
                            {getFieldDecorator("pass_word", {
                              rules: [
                                {
                                  required: true,
                                  message: "请填写6~16位数字组成的登录密码!",
                                },
                                { validator: this.validateToNextPassword },
                              ],
                            })(
                              <Input.Password placeholder="请填写6~16位数字组成的登录密码!" />
                            )}
                          </Form.Item>
                          <Form.Item
                            label="确认密码"
                            {...formItemLayout}
                            hasFeedback
                          >
                            {getFieldDecorator("pass_word2", {
                              rules: [
                                {
                                  required: true,
                                  message: "请确认登录密码!",
                                },
                                {
                                  validator: this.compareToFirstPassword,
                                },
                              ],
                            })(
                              <Input.Password
                                onBlur={this.handleConfirmBlur}
                                placeholder="请确认登录密码！"
                              />
                            )}
                          </Form.Item>
                          <Form.Item
                            {...formItemLayout}
                            label="邮箱："
                            hasFeedback
                          >
                            {getFieldDecorator("email", {
                              rules: [
                                { required: true, message: "请填写邮箱地址!" },
                                { type: "email", message: "邮箱格式不正确!" },
                              ],
                            })(<Input placeholder="请填写邮箱地址！" />)}
                          </Form.Item>

                          <Form.Item
                            {...formItemLayout}
                            label="身份证号码"
                            hasFeedback
                          >
                            {getFieldDecorator("idcard", {
                              rules: [
                                { required: false, message: "" },
                                {
                                  len: 18,
                                  message: "身份证号码长度为18个字符",
                                },
                              ],
                            })(
                              <Input
                                onChange={this.idCardChange.bind(this)}
                                placeholder="请输入您的身份证号码"
                              />
                            )}
                          </Form.Item>

                          <Form.Item
                            {...formItemLayout}
                            label="企业简介"
                            hasFeedback
                          >
                            {getFieldDecorator("node", {
                              rules: [{ required: false, message: "" }],
                            })(
                              <TextArea
                                rows={4}
                                placeholder="请输入公司简介(非必填)"
                              />
                            )}
                          </Form.Item>
                          <Form.Item {...tailLayout}>
                            <Checkbox
                              defaultChecked={false}
                              style={{ textAlign: "right" }}
                              onChange={this.contactsChange}
                            >
                              注册人是否为业务联系人
                            </Checkbox>
                          </Form.Item>
                          {/* <Form.Item {...formItemLayout} label="性别" hasFeedback >
                                                        {getFieldDecorator('gender', {
                                                            rules: [{ required: true, message: '请选择您的性别！' }],
                                                            initialValue:sex
                                                        })(
                                                            <Select placeholder="请选择您的性别">
                                                                <Option value="1" >男</Option>
                                                                <Option value="2" >女</Option>
                                                            </Select>,
                                                        )}
                                                    </Form.Item> */}
                        </div>
                        {this.state.isContacts ? (
                          ""
                        ) : (
                          <div className={styles.boxBorder}>
                            <div className={styles.titleTextS}>
                              <Divider>业务联系人信息</Divider>
                            </div>
                            <Form.Item
                              {...formItemLayout}
                              label="真实姓名："
                              hasFeedback
                            >
                              {getFieldDecorator("co_contact", {
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入真实姓名：!",
                                  },
                                ],
                              })(<Input placeholder="请填写真实姓名！" />)}
                            </Form.Item>
                            <Form.Item
                              {...formItemLayout}
                              label="职务："
                              hasFeedback
                            >
                              {getFieldDecorator("co_contact_job", {
                                rules: [
                                  { required: true, message: "请填写职务!" },
                                ],
                              })(<Input placeholder="请填写您的职务！" />)}
                            </Form.Item>
                            <Form.Item
                              {...formItemLayout}
                              label="手机号"
                              hasFeedback
                            >
                              {getFieldDecorator("co_contact_phone", {
                                rules: [
                                  { required: true, message: "请输入手机号!" },
                                ],
                              })(<Input type="tel" placeholder="手机号" />)}
                            </Form.Item>
                            {/* <Form.Item {...formItemLayout} label="验证码" className={styles.codeForm} >
                                                                {getFieldDecorator('code', {
                                                                    rules: [{ required: true, message: '请输入验证码!' }],
                                                                })( 
                                                                <Input placeholder="请输入验证码!" />,
                                                                )}
                                                                <Button type="primary" disabled={this.state.codeState} onClick={this.SendCode} className="login-form-button" > {this.state.codeStr} </Button>
                                                            </Form.Item> */}
                            <Form.Item
                              {...formItemLayout}
                              label="邮箱："
                              hasFeedback
                            >
                              {getFieldDecorator("co_contact_email", {
                                rules: [
                                  {
                                    required: true,
                                    message: "请填写邮箱地址!",
                                  },
                                  { type: "email", message: "邮箱格式不正确!" },
                                ],
                              })(<Input placeholder="请填写邮箱地址！" />)}
                            </Form.Item>
                          </div>
                        )}
                      </>
                    )}

                    {/* <div className={styles.titleTextS}><Divider>企业信息</Divider></div>
                                        {isTypeFirm?(
                                        <div className={styles.boxBorder}>
                                            <Form.Item {...formItemLayout} label="企业名称" hasFeedback >
                                                {getFieldDecorator('work_units', {
                                                    rules: [{ required: true, message: '请输入您所在的企业全称！' }],
                                                })(
                                                    <Input placeholder="请输入您所在的企业全称" />,
                                                )}
                                            </Form.Item>
                                            <Form.Item {...formItemLayout} label="企业类别" hasFeedback  className="firmLabel">
                                            <p className={styles.lableTs}>请选择您的企业类别，最多5个</p>
                                                    <div>
                                                        {tagsData.map(tag => (
                                                                <CheckableTag
                                                                  key={tag}
                                                                  checked={selectedTags.indexOf(tag) > -1}
                                                                  onChange={checked => this.handleTab(tag, checked)}>
                                                                  {tag}
                                                                </CheckableTag>
                                                            ))}
                                                    </div>
                                            </Form.Item>
                                            <Form.Item {...formItemLayout} label="企业电话" hasFeedback >
                                                {getFieldDecorator('co_phone', {
                                                    rules: [{required: true, message: '请输入企业座机电话！' },]
                                                            
                                                })(
                                                    <Input placeholder="请输入企业座机电话" />,
                                                )}
                                            </Form.Item>
                                            <Form.Item {...formItemLayout} label="企业联系人" hasFeedback >
                                                {getFieldDecorator('co_contact', {
                                                    rules: [{ required: true, message: '请输入企业联系人！' }],
                                                })(
                                                    <Input placeholder="请输入联系人真实姓名" />,
                                                )}
                                            </Form.Item>
                                            <Form.Item {...formItemLayout} label="联系人职务" hasFeedback >
                                                {getFieldDecorator('co_contact_job', {
                                                    rules: [{required: true, message: '请输入联系人的职务！'}],
                                                })(
                                                    <Input placeholder="请输入联系人的职务" />,
                                                )}
                                            </Form.Item>
                                            <Form.Item {...formItemLayout} label="联系人电话" hasFeedback >
                                                {getFieldDecorator('co_contact_phone', {
                                                    rules: [{required: true, message: '请输入联系人的电话！' },
                                                            {min:11,message:'电话不能少于11个字符'}],
                                                })(
                                                    <Input placeholder="请输入联系人的电话" />,
                                                )}
                                            </Form.Item>
                                            <Form.Item {...formItemLayout} label="联系人邮箱" hasFeedback >
                                                {getFieldDecorator('co_contact_email', {
                                                    rules: [{ required: true, message: '请输入联系人的邮箱！' },
                                                            {type: 'email', message: '邮箱格式不正确!',}],
                                                })(
                                                    <Input placeholder="请输入联系人的邮箱信息" />,
                                                )}
                                            </Form.Item>
                                        </div>
                                        ):(
                                        <div className={styles.boxBorder}>
                                            <Form.Item {...formItemLayout} label="企业名称" hasFeedback >
                                                {getFieldDecorator('work_units', {
                                                    rules: [{ required: true, message: '请输入您所在的企业全称！' }],
                                                })(
                                                    <Input placeholder="请输入您所在的企业全称" />,
                                                )}
                                            </Form.Item>
                                            <Form.Item {...formItemLayout} label="企业类别" hasFeedback className="firmLabel">
                                            <p className={styles.lableTs}>请选择您的企业类别，最多5个</p>
                                                <div>
                                                    {tagsData.map(tag => (
                                                            <CheckableTag
                                                              key={tag}
                                                              checked={selectedTags.indexOf(tag) > -1}
                                                              onChange={checked => this.handleTab(tag, checked)}>
                                                              {tag}
                                                            </CheckableTag>
                                                        ))}
                                                </div>
                                            </Form.Item>
                                            <Form.Item {...formItemLayout} label={<span>企业电话<span>(选填)</span></span>} hasFeedback >
                                                {getFieldDecorator('co_phone', {
                                                   
                                                })(
                                                    <Input placeholder="请输入企业座机电话" />,
                                                )}
                                            </Form.Item>
                                            <Form.Item {...formItemLayout} label={<span>企业联系人<span>(选填)</span></span>}   hasFeedback >
                                                {getFieldDecorator('co_contact', {
                                                  
                                                })(
                                                    <Input placeholder="请输入联系人真实姓名" />,
                                                )}
                                            </Form.Item>
                                            <Form.Item {...formItemLayout} label={<span>联系人职务<span>(选填)</span></span>} hasFeedback >
                                                {getFieldDecorator('co_contact_job', {
                                                })(
                                                    <Input placeholder="请输入联系人的职务" />,
                                                )}
                                            </Form.Item>
                                            <Form.Item {...formItemLayout} label={<span>联系人电话<span>(选填)</span></span>} hasFeedback >
                                                {getFieldDecorator('co_contact_phone', {
                                                     rules: [{min:11,message:'电话不能少于11个字符'}],
                                                })(
                                                    <Input placeholder="请输入联系人的电话" />,
                                                )}
                                            </Form.Item>
                                            <Form.Item {...formItemLayout} label={<span>联系人邮箱<span>(选填)</span></span>}  hasFeedback >
                                                {getFieldDecorator('co_contact_email', {
                                                    rules: [{type: 'email', message: '邮箱格式不正确!'}],
                                                })(
                                                    <Input placeholder="请输入联系人的邮箱信息" />,
                                                )}
                                            </Form.Item>
                                        </div>)}                                        
                                         */}
                    <Form.Item
                      hidden="hidden"
                      label="身份选择"
                      {...formItemLayout}
                      hasFeedback
                    >
                      {getFieldDecorator("account_type_no", {
                        rules: [{ required: true, message: "请选择身份!" }],
                      })(
                        <Select placeholder="请选择您要注册的身份！">
                          {AccountTypeList.map((item) => (
                            <Option key={item.id} value={item.account_type_no}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>

                    <Form.Item>
                      <p className={styles.ts}>
                        注册普通会员成功后即可付费升级VIP，优享更多特权
                      </p>
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                      {getFieldDecorator("chkrule", {
                        rules: [
                          { required: true, message: "请点击勾选同意!" },
                          { validator: this.compareToFirsChkRule },
                        ],
                      })(
                        <Checkbox style={{ textAlign: "center" }}>
                          点击勾选同意
                        </Checkbox>
                      )}
                      <a href="/#/rule" target="_blank">
                        <span className={styles.rule}>
                          《用户条款与免责声明》
                        </span>
                      </a>
                    </Form.Item>
                    <Form.Item>
                      <div className={styles.registeredBtn}>
                        <Button
                          type="primary"
                          onClick={this.Register}
                          className="login-form-button"
                          block
                        >
                          {" "}
                          我要注册{" "}
                        </Button>
                        <div className={styles.goLogin}>
                          <a href="/#/login">返回登录</a>
                        </div>
                      </div>
                    </Form.Item>
                  </Form>
                </div>
              </Col>
            </div>
            <PageFooter IsAdvertisementShow={false} colorE9f1fc={false} />
          </div>
        </Spin>
      </div>
    );
  }

  QueryMemberType = () => {
    let params = {
      offset: 0,
      limit: 50,
    };
    API.QueryMemberType(params)
      .then((res) => {
        if (res.status === 200) {
          const { register_type } = this.state;

          let zj_account_type_no = res.data.list.filter((item1) => {
            return item1.level === 4;
          });

          const AccountTypeList = res.data.list.filter((item) => {
            return item.level + "" === register_type + "";
          });

          if (zj_account_type_no.length > 0) {
            zj_account_type_no = zj_account_type_no[0].account_type_no;
          }
          this.setState({ AccountTypeList, zj_account_type_no });
          if (AccountTypeList.length === 1) {
            this.props.form.setFieldsValue({
              account_type_no: AccountTypeList[0].account_type_no,
            });
          }
        } else {
          message.error(res.msg);
        }
        this.setState({ loading: false });
      })
      .catch((res) => {
        // console.log(res)
        this.setState({ loading: false });
      });
  };

  // 获取上传oss文件子组件
  onUploadRef = (ref) => {
    this.children = ref;
  };

  onFileSuccess = (res) => {
    if (res.status === 200) {
    } else {
      message.success("文章上传失败，请重新上传！");
    }
  };

  GetFile = (file, fileList) => {
    if (fileList.length === 0) {
      this.props.form.setFieldsValue({
        upload_original_url: "",
      });
    } else {
      this.props.form.setFieldsValue({
        upload_original_url: file.url,
      });
    }
  };

  SendCode = () => {
    let phone = this.props.form.getFieldValue("phone");

    // 检查手机号是否正确
    if (!tools.checkPhone(phone)) {
      message.error("请输入正确的手机号！");
      return;
    }

    // 先禁用按钮 避免请求延时 用户点击多次

    var params = {
      account: phone,
      action: "register",
      country_code: "86",
    };

    // 验证码
    // this.setState({ loading: true, codeState: true });
    this.setState({ codeState: true });

    API.GetVerificationCode(params)
      .then((result) => {
        this.setState({ loading: false });
        if (result.status === 200) {
          // 禁用发送验证码 开始倒计时
          this.setState({ codeState: true });
          this.VerificationCodeCountdown();
          // 记录现在获取验证码的手机号
          // this.code_phone = this.phone
        } else {
          this.setState({ codeState: false });
          message.error(result.msg);
        }
      })
      .catch((res) => {
        // console.log(res)
        // this.setState({ loading: false });
      });
  };

  VerificationCodeCountdown() {
    const { count_down_time } = this.state;
    let time = count_down_time;
    let date = Date.parse(new Date()) + time * 1000;
    var timer = setInterval(() => {
      let num = date - Date.parse(new Date());
      // console.log(num)
      if (num >= 0) {
        this.setState({ codeStr: num / 1000 + "s" });
      } else {
        this.setState({ codeState: false, codeStr: "获取验证码" });
        clearInterval(timer);
      }

      // time--

      // if (time === 0) {

      //     this.setState({ codeState: false, codeStr: '获取验证码' });

      //     clearInterval(timer)

      // } else {

      //     this.setState({ codeStr: time + 's' });

      // }
    }, 1000);
  }

  Register = async (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log('Registervalues*****',values);
      if (!err) {
        if (this.state.isTypeFirm) {
          if (this.state.selectedTags.length > 0) {
            values.co_label = JSON.stringify(this.state.selectedTags);
          } else {
            message.warning("请选择企业标签");
            return;
          }
        } else {
          let arr = [
            {
              name: "所在单位",
              arrChecked: "companyChecked",
              field: "work_units",
            },
            {
              name: "岗位",
              arrChecked: "workChecked",
              field: "job",
            },
            {
              name: "兴趣",
              arrChecked: "interestChecked",
              field: "hobby",
            },
          ];
          for (let i in arr) {
            let item = arr[i];
            if (this.state[item["arrChecked"]].length > 0) {
              values[item["field"]] = JSON.stringify(
                this.state[item["arrChecked"]]
              );
            } else {
              message.warning(`请选择${item.name}`);
              return;
            }
          }
        }

        // if(this.state.selectedTags.length>0){
        //     values.co_label=JSON.stringify(this.state.selectedTags)
        // }else{
        //     message.warning("请选择企业标签");
        //     return
        // }
        console.log('Received values of form: 66666', values);
        values.type = parseInt(values.type);
        // values.gender=parseInt(values.gender)

        const { IsUploadOriginalArticle, register_type } = this.state;
        if (IsUploadOriginalArticle && values.upload_original_url.length > 0) {
          values.original_url = base.OssAddress + values.upload_original_url;
        } else {
          this.props.form.setFields({
            upload_original_url: {
              value: values.upload_original_url,
              errors: [new Error("请上传原创文章或者填写原创文章地址！")],
            },
          });
          // return
        }

        // if (register_type == 4) {
        //     values.experts_type_no = values.account_type_no
        //     delete values.account_type_no
        // }
        console.log('Registervalues',values);

        this.setState({ loading: true });
        API.Register(values)
          .then((res) => {
            console.log("res", res);
            this.setState({ loading: false });
            if (res.status === 200) {
              if (
                IsUploadOriginalArticle &&
                values.upload_original_url.length > 0
              ) {
                this.children.handleUpload();
              }

              message.success("注册成功！");

            //   const par = this.props.location.search;
            //   if (par.indexOf("type=EntryForm") >= 0) {
            //     router.history.push("/entry_form_list")
            //   } else {
            //     router.history.push("/#/login")
            //   }

              this.targetPath()


              
            } else {
              message.error(res.msg);
            }
          })
          .catch((res) => {
            // console.log(res)
            this.setState({ loading: false });
          });
      }
    });
  };


  targetPath = ()=>{
      //获取url中的type并跳转
      const par = this.props.location.search;
      let name, value,parmas ={};
      let num = par.indexOf("?");
      let str = par.substr(num + 1);
      let arr = str.split("&"); 
      console.log(arr);
      for (let i = 0; i < arr.length; i++) {
        num = arr[i].indexOf("=");
        if (num > 0) {
          name = arr[i].substring(0, num);
          value = arr[i].substr(num + 1);
          parmas[name] = value;
        }
      }
      if (parmas.type) {
        router.history.push(parmas.type);
      } else {
        router.history.push("/#/login");
      }
  }
}

Login = Form.create({})(Login);
