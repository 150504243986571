import Server from './server'

class API extends Server{
  QueryTitleBarChildren(data) {
    
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_title_bar_children', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }
  QueryTitleBar(data) {
    
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_title_bar', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }
  QuerySetting(data) {
    
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_system_config', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

}

export default new API()