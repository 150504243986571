/**
 *
 * @param carousel carousel ref
 * @param carousel_autoplay_time 自定义时间
 */
const init_carousel_autoplay = (carousel, carousel_autoplay_time) => {
    // 轮播定时器
    var carousel_autoplay_timer = null
    // 自动播放
    const carousel_autoplay = () => {
        carousel_autoplay_timer = setTimeout(() => {
            if(carousel){
                carousel.slick.slickNext()
            }
            
            carousel_autoplay()
        }, carousel_autoplay_time);
    }
    // 清理定时器
    const clear_timeout = () => {
        if(carousel_autoplay_timer){
            clearTimeout(carousel_autoplay_timer)
            return
        }
        // cb && cb()
    }
    // 重置自动播放
    const reset_aotoplay_timer = () => {
        // 清理
        
        clear_timeout(() => {
            // 继续轮播
                carousel_autoplay()
        })
    }
    // 轮播上一个
    const carousel_prev = () => {
        reset_aotoplay_timer()
        carousel.slick.slickPrev()
    }
    // 轮播下一个
    const carousel_next = () => {
        reset_aotoplay_timer()
        carousel.slick.slickNext()
    }
    return {
        carousel_autoplay,
        carousel_prev,
        carousel_next,
        clear_timeout,
    }
}
 
export default init_carousel_autoplay