import * as actionTypes from '../constants/index';
const setTdTitleBar = (data) => {
    return {
        type: actionTypes.SET_TITLE_BAR,
        data
    }
};
const setAllTdTitleBar = (data) => {
    return {
        type: actionTypes.SET_ALL_TITLE_BAR,
        data
    }
};
const setSetting = (data) => {
    return {
        type: actionTypes.SET_SETTING,
        data
    }
};
export {setTdTitleBar, setAllTdTitleBar, setSetting};
