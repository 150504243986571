import React, { Component } from 'react';
import { Row, Col, Button } from 'antd';
import styles from './index.module.less';
import Swiper from '@/src/components/Carousel/swiper';
import store from '@/src/redux/store.js';
import API from '@/src/api/Home'
import { tools } from '@/src/lib/tools';

class Content extends Component {

  state = {
    name: '',
    title_bar_no: '',
    TopicItemCss: '',
    SwiperDataList: [],
    MiddlePic: '',
  };

  constructor(props) {
    super(props)
    this.state.title_bar_no = props.title_bar_no
    this.state.name = props.name
    this.state.path = props.path
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(this.ListenStore.bind(this))
    window.addEventListener('resize', this.resizeBind)
    this.ListenStore()
    this.resizeBind()
    this.GetSwiper()
  }

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.TopicItemCss !== '') {
        this.setState({
          TopicItemCss: '',
        });
      }
    } else {
      if (!this.state.TopicItemCss !== 'TopicItem') {
        this.setState({
          TopicItemCss: 'TopicItem',
        });
      }
    }
  }

  ListenStore = () => {
    let { TdTitleBar } = store.getState()

    if (!TdTitleBar || TdTitleBar === {} || TdTitleBar.length === undefined || TdTitleBar.length === 0) {
      return
    }
    let oneTitleList = TdTitleBar.filter(item => {
      return item.level === 2 && item.show_status === 2
    })

    let TwoTitleList = oneTitleList.filter(item => {
      return item.parent_id === this.state.title_bar_no
    })

    // 添加一个置顶的 
    // TwoTitleList = [{
    //   id: 0,
    //   title_bar_no: this.state.title_bar_no,
    //   parent_id: '',
    //   name: "",
    //   level: 2,
    //   sort: 1,
    //   nav_status: 2,
    //   show_status: 2,
    //   ShowHeaderTitle: false,
    //   data: [

    //   ]
    // }, ...TwoTitleList]

    this.setState({
      TwoTitleList: TwoTitleList,
    })

    // 查询文章
    this.QueryArticle(TwoTitleList)
  }

  QueryArticle = (_TwoTitleList) => {
    // let _TwoTitleList = this.state.TwoTitleList

    if (!_TwoTitleList || _TwoTitleList.length === 0) {
      return
    }
    _TwoTitleList.map((item, index) => {

      // 文章
      let params = { title_bar_no: item.title_bar_no, offset: 0, limit: 5 }
      if (item.title_bar_no === '1040') {
        params.limit = 3
      } else {
        params.limit = 1
      }


      API.QueryArticle(params).then(res => {

        if (res.status === 200) {
          item.data = res.data.list.map((item2, index) => {
            item2.id = item.title_bar_no + item2.id + '-' + tools.random(10000, 99999)
            if (index === 0 && item.title_bar_no === this.state.title_bar_no) {
              item2.BoldTitle = true
              item2.showPic = false
            }
            item2.title_bar_no = item.title_bar_no

            return item2
          });

          if (item.title_bar_no === '1040') {
            item.data = tools.sliceArr(item.data, 3)
          } else {
            item.data = tools.sliceArr(item.data, 1)
          }


          item.loading = false;
        } else {
          item.loading = false;
        }

        _TwoTitleList[index] = item
        this.setState({
          TwoTitleList: _TwoTitleList,
        })
      })

      return item
    })

    console.log(_TwoTitleList)
  }

  GetSwiper = () => {
    let params = { title_bar_no: this.state.title_bar_no, offset: 0, limit: 5 }
    API.QueryAdvertise(params).then(res => {
      console.log(res)
      if (res.status === 200) {
        let SwiperDataList = []
        let AdvertisementData = []
        let MiddlePic = ''
        res.data.list.forEach(element => {
          element.name = null
          switch (element.advertise_location) {
            case 1:
              SwiperDataList = [...SwiperDataList, element]
              break;

            case 3:
              MiddlePic = element.pic
              break;

            case 2:
              AdvertisementData = [...AdvertisementData, element]
              break;

            default:
              break;
          }
        });
        this.setState({
          SwiperDataList,
          MiddlePic,
        })
        // store.dispatch(setAdvertisement(AdvertisementData))

      } else {

      }


    })
  }

  componentWillUnmount() {
    // 对 store 变化取消监听
    this.unsubscribe();
    window.removeEventListener('resize', this.resizeBind)
  }

  render() {

    return (
      <div className={styles.HomeContent} >

        <Row justify='space-between' type='flex' >

        <div className={styles.FirstDiv} style={{width: '100%'}} >
            {
              this.state.SwiperDataList.length > 0 ?
                <Col className={this.state.BrowserType === 'PC' ? styles.homeSwiper : ''} xs={24} sm={24} md={24} >
                  <Swiper autoplay isMask={false} SwiperDataList={this.state.SwiperDataList} />
                </Col>
                : null
            }
            
          </div>

          {/* {this.state.TwoTitleList ? this.state.TwoTitleList.map((item) => {
            return (
              <Col key={item.id} className={this.state.TopicItemCss} xs={24} sm={24} md={24} >
                <ImgCard title={item.name} dots={false} loading={item.loading} data={item.data} title_bar_no={item.title_bar_no} name={this.state.name} md={item.title_bar_no === "1040" ? 8 : 24} />
              </Col>
            )
          }) : null
          } */}
        </Row>

      </div>
    )
  }
}

export default Content;