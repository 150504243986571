import React, {Component} from 'react';
import {Button, List, message, Pagination, Popconfirm} from "antd";
import styles from './comment.module.less'
import API from "../../api/PersonalCenterApi";
import store from '@/src/redux/store.js';
import {setArticleDetails} from '@/src/redux/actions/ArticleDetails';
import {HashRouter} from "react-router-dom";

/**
 * 我的评论
 */
const router = new HashRouter();
export default class Comment extends Component {

    state = {
        loading: false,
        cur_page: 1,
        current_page: 1,
        page_zise: 5,
        dataTotal: 0,
        listData: []
    };

    componentDidMount() {
        this.getMineComment();
    }

    getMineComment() {
        this.setState({loading: true});
        const params = {
            limit: this.state.page_zise,
            offset: this.state.cur_page - 1,
        };
        API.queryMineComment(params).then(res => {
            if (res.status === 200) {
                this.setState({
                    listData: res.data.list
                });
                this.setState({
                    dataTotal: res.data.total
                });
            } else {
                message.error(res.msg);
            }
            this.setState({loading: false});
        }).catch(() => {
            this.setState({loading: false});
        })
    }

    deleteComment = (id) => {
        const params = {
            //注意：这两个二选一
            // "comment_id"评论id
            // "comment_replay_id" 回复评论id
            comment_id: id,
        };
        API.deleteMineComment(params).then(res => {
            if (res.status === 200) {
                message.success(res.msg);
                this.getMineComment();
            } else {
                message.error(res.msg);
            }
            this.setState({loading: false});
        }).catch(() => {
            this.setState({loading: false});
        })
    };

    onChangePage = (value) => {
        let _this = this;
        this.setState({
            cur_page: (value - 1) * this.state.page_zise + 1,
        });
        this.setState({
            current_page: value,
        }, function () {
            _this.getMineComment();
        });
    };

    // 详情
    ImgCardContentClick = (item) => {
        if (item.title_bar_no === '') {
            message.error('找不到该文章');
            return
        }
        store.dispatch(setArticleDetails(item));
        router.history.push('/ArticleDetails' + item.title_bar_no + '/' + item.article_no)
    };

    render() {

        const {listData, loading} = this.state;

        return (
            <div>
                <List className={styles.comment}
                      loading={loading}
                      itemLayout="vertical"
                      size="default"
                      dataSource={listData}
                      renderItem={item => (
                          <List.Item
                              key={item.title}>
                              <List.Item.Meta/>
                              <a className={styles.title}
                                 onClick={this.ImgCardContentClick.bind(this, item)}>
                                  {item.title}</a>
                              <div className={styles.content}>{item.content}</div>

                              <Popconfirm
                                  title="确认删除当前评论吗?"
                                  onConfirm={() => this.deleteComment(item.id)}
                                  okText="确认"
                                  cancelText="取消">
                                  <Button className={styles.button} type='default'
                                          size='small'>删除评论</Button>
                              </Popconfirm>

                          </List.Item>
                      )}
                />
                <Pagination className={styles.pagination}
                            size="small"
                            pageSize={this.state.page_zise}
                            current={this.state.current_page}
                            total={this.state.dataTotal}
                            onChange={this.onChangePage}
                            showQuickJumper/>
            </div>

        );
    }
}