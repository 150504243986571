import * as actionTypes from '../constants/index';
const TdTitleBar = (state = [], action) => {
	switch (action.type) {
		case actionTypes.SET_TITLE_BAR:
			return action.data;
		default:
			return state;
	}
};
const AllTdTitleBar = (state = [], action) => {
	switch (action.type) {
		case actionTypes.SET_ALL_TITLE_BAR:
			return action.data;
		default:
			return state;
	}
};

export {TdTitleBar, AllTdTitleBar};
