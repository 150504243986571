import * as actionTypes from '../constants/index';
import { local } from '../../lib/tools'

const ArticleDetails = (state = null, action) => {
	let data = local.get(actionTypes.SET_ARTICLE_DRTAILS)
	switch (action.type) {
		case actionTypes.SET_ARTICLE_DRTAILS:
			return action.data || data ;
		default:
			return state || data ;
	}
};

export default ArticleDetails;
