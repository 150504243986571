import React, { Component } from 'react';
import styles from './index.module.less';
import PageHeader from '@/src/components/PageHeader';
import PageFooter from '@/src/components/PageFooter';
import Content from './Content.jsx';

export default class ExpertList extends Component {

  state = {
    title_bar_no: '',
  }

  render() {

    const { title_bar_no , keywords } = this.props.match.params

    return (
      <div className={styles.HomeBox + ' Layout'}>

        <PageHeader showNav={false} showBreadcrumbList={true}  breadcrumbListStyle={true} />

        <Content title_bar_no={title_bar_no} keywords={keywords} />

        <PageFooter showFooterNavList={true} />

      </div>
    )
  }

}
