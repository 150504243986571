import * as actionTypes from '../constants/index';
import { local } from '../../lib/tools'

const userInfo = (state = null, action) => {
	let data = local.get(actionTypes.SET_USERINFO)

	switch (action.type) {
		case actionTypes.SET_USERINFO:
			return action.data || data;
		default:
			return state || data;
	}
};

export default userInfo;
