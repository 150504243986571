import React, { Component } from 'react';
import styles from './index.module.less';
import { Col,Divider } from 'antd';
import Api from '@/src/api/api';
import PageContent from '@/src/components/PageContent';
import { base } from '@/src/api/base';
import { Document, Page } from 'react-pdf';

export default class ArticleDetails extends Component {

  state = {
    BrowserType: 'PC', // PC Mobile 浏览器类型 手机或者pc端
    pdfData: '',
    loading: true,
    collection_status: 0,
  }

  async componentDidMount() {
    this.InitData()
    window.addEventListener('resize', this.resizeBind)
    this.resizeBind()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeBind)
  }
  onDocumentLoadSuccess = () => {

  }
  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== 'Mobile') {
        this.setState({
          BrowserType: 'Mobile',
        });
      }
    } else {
      if (!this.state.BrowserType !== 'PC') {
        this.setState({
          BrowserType: 'PC',
        });
      }
    }
  }

  InitData = () => {
    let params = {
      id: 19
    }
    Api.QueryFirmTab(params).then(res => {
      if (res.status === 200) {
        this.setState({
          pdfData: res.data.config_value,
          loading: false,
        })
      }
    })


  }




  onDocumentLoadSuccess = ({ numPages })  => {
    // setNumPages(numPages);
    console.log('11111')
  }

  render() {
    const {
      pdfData,
      BrowserType,
      loading,
      collection_status
    } = this.state;
    return (
      <div>
        <Col xs={24} sm={24} style={{height: '100vh',padding: '20px' }} >
          <PageContent loading={loading} >
            <div className={styles.TextContent}>
              <Document
              onLoadSuccess={this.onDocumentLoadSuccess}
                file='cheshipdf.pdf'
              >
                <Page pageNumber={1} />
              </Document>
              <div className={styles.downBox}>
                <Divider className={styles.DividingLine} ></Divider>
                <div className={styles.operatingArticleIcon} >
                  <img onClick={this.ArticleCollection} src={collection_status === 1 ? require("../../assets/zh/mo_sc2.png") : require("../../assets/zh/mo_sc1.png")} alt="" />
                </div>
                <div className={styles.accUrlWrap}>
                  <a href={base.OssAddress+pdfData} target="_blank">点击查看pdf附件</a>
                </div>
              </div>
            </div>
          </PageContent>
        </Col>
      </div>
    )
  }

}
