import React, { Component } from "react";
import { Row, Col, Carousel, Tabs, Button, Icon, Skeleton } from "antd";
import { HashRouter } from 'react-router-dom'
import styles from "./home.module.less";
import SwiperBox from "@/src/components/Carousel/swiper";
import TopicItem from "@/src/components/TopicItem";
import FriendshipLink from "@/src/components/FriendshipLink";
import store from "@/src/redux/store.js";
import API from "@/src/api/Home";
import { setAdvertisement } from "@/src/redux/actions/Advertisement";
import { base } from "@/src/api/base";
import { tools } from "@/src/lib/tools";
import AdAround from "@/src/components/AdAround";
import { setAdAroundR, setAdAroundL } from "@/src/redux/actions/AdAround";
import 'swiper/swiper-bundle.js'
import 'swiper/swiper.less'
import Swiper from "swiper";
import { startTransition } from "react";
import { compose } from "redux";
import { setBreadcrumbList } from "@/src/redux/actions/BreadcrumbList";
const router = new HashRouter()
const { TabPane } = Tabs;
class PageHeader extends Component {
  state = {
    name: "首页",
    title_bar_no: "1001",
    current: "PageHeader",
    TopicItemCss: "",
    SwiperDataList: [],
    AdvertisementData: [],  // 中间广告
    MiddlePic: "",
    BrowserType: "PC", // PC Mobile 浏览器类型 手机或者pc端
    adAroundRList: [], // 右侧广告
    adAroundLList: [], // 左侧广告
    currentIndex: 0,
    newsCurrentIndex: 0,
    isPlay: false,
    isYiZhuHover: false,
    isNewsNextBtnHover: false,
    num: -1,         // 新闻中心数据第几条
    newsDataList: [], // 新闻中心数据
    newsDataLoad: true, // 新闻中心数据加载
    yiZhuDataList: [], // 专家专栏译注
    yizhuNum: 0, // 专家专栏译注第几条
    expertSayList: [], // 专家专栏专家说
    newsSwiperList: [], // 新闻中心轮播
    ZhSwiperList: [], // 展会轮播
    smartDataList: [], // 智慧安保数据
    fireDataList: [],  // 消防应急数据
    videDataList: [], // 视频中心
    moreNewsUrl: '/MoreArticles1011/0', // 新闻中心跳转更多地址
    moreNewsName: '行业新闻', // 新闻中心跳转更多面包屑名字
    newsCenterTabs: [
      { name: '行业新闻', key: "1" },
      { name: '政策跟踪', key: "2" },
      { name: '国外新闻', key: "3" },
    ],
    onlyOnce: true

  };

  componentDidMount() {
    this.unsubscribe = store.subscribe(this.ListenStore.bind(this));
    window.addEventListener("resize", this.resizeBind);
    this.resizeBind();
    this.GetSwiper();
  }

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.TopicItemCss !== "") {
        this.setState({
          TopicItemCss: "",
        });
      }
      if (this.state.BrowserType !== "Mobile") {
        this.setState({
          BrowserType: "Mobile",
        });
      }
    } else {
      if (!this.state.TopicItemCss !== "TopicItem") {
        this.setState({
          TopicItemCss: "TopicItem",
        });
      }
      if (!this.state.BrowserType !== "PC") {
        this.setState({
          BrowserType: "PC",
        });
      }
    }
  };

  ListenStore = () => {
    if (!this.state.onlyOnce) return
    let { TdTitleBar } = store.getState();

    if (
      !TdTitleBar ||
      TdTitleBar === {} ||
      TdTitleBar.length === undefined ||
      TdTitleBar.length === 0
    ) {
      return;
    }
    let oneTitleList = TdTitleBar.filter((item) => {
      return item.level === 2 && item.show_status === 2;
    });
    let TwoTitleList = oneTitleList.filter((item) => {
      return item.parent_id === this.state.title_bar_no || item.parent_id === "1005" || item.parent_id === "1002";
    });
    // 添加一个置顶的
    TwoTitleList = [
      {
        id: 0,
        title_bar_no: this.state.title_bar_no,
        parent_id: "",
        name: "",
        level: 2,
        sort: 1,
        nav_status: 2,
        show_status: 2,
        ShowHeaderTitle: false,
        data: [],
      },
      {
        id: 0,
        title_bar_no: '1004',
        parent_id: "",
        name: "",
        level: 2,
        sort: 1,
        nav_status: 2,
        show_status: 2,
        ShowHeaderTitle: false,
        data: [],
      },
      ...TwoTitleList,
    ];
    TwoTitleList.splice(5, 0, {
      id: 9999999999,
      title_bar_no: "-1014-1",
      parent_id: "",
      name: "",
      level: 2,
      sort: 1,
      nav_status: 2,
      show_status: 2,
      ShowHeaderTitle: false,
    });
    TwoTitleList.map((item) => {
      // item.data = []
      if (item.title_bar_no === "1010") {
        item.newsList = []
      }
      if (item.title_bar_no === "1014") {
        item.ExpertList = [];
      }
      if (item.title_bar_no === "1015") {
        item.SwiperDataList = [];
      }

      if (item.title_bar_no === "1016") {
        item.FriendshipLinkList = [];
      }
      if (item.title_bar_no === "1004") {
        item.yizhuList = [];
        item.expertSayList = [];
      }

      return item;
    });
    this.setState({
      TwoTitleList: TwoTitleList,
    });
    this.QueryArticle(TwoTitleList);
    this.setState({
      onlyOnce: false
    })
  };

  QueryArticle = (_TwoTitleList) => {
    // let _TwoTitleList = this.state.TwoTitleList
    if (!_TwoTitleList || _TwoTitleList.length === 0) {
      return;
    }
    let { Setting } = store.getState();
    // 宣传栏文章数量
    let firstLimit = 7;

    _TwoTitleList.map((item, index) => {
      const title_bar_no = item.title_bar_no;
      // 友情链接
      if (item.title_bar_no === "1016") {
        let params = { title_bar_no: item.title_bar_no, offset: 0, limit: 20 };
        API.QueryFriendlyLink(params).then((res) => {
          if (res.status === 200) {
            item.FriendshipLinkList = res.data.list.filter((item2) => {
              return item2.status === 2;
            });
            item.FriendshipLinkList.sort((a, b) => {
              return a.sort - b.sort;
            });
            item.loading = false;
          } else {
            item.loading = false;
          }
          _TwoTitleList[index] = item;
          this.setState({
            TwoTitleList: _TwoTitleList,
          });

          new Swiper('.friendShipSwiper', {
            loop: true,
            effect: 'coverflow',
            slidesPerView: 7,
            spaceBetween: 17,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            observer: true,
            // autoplay: {
            //   delay: 3500,
            //   disableOnInteraction: false,
            // },
            breakpoints: {
              320: {  //当屏幕宽度大于等于320
                slidesPerView: 3,
                spaceBetween: 10
              },
              768: {  //当屏幕宽度大于等于768 
                slidesPerView: 5,
                spaceBetween: 17
              },
              1280: {  //当屏幕宽度大于等于1280
                slidesPerView: 7,
                spaceBetween: 17
              }
            }
          });

        });
      } else {


        // 文章
        let params = { title_bar_no: item.title_bar_no, offset: 0, limit: 11, need_content: 2 };

        if (item.title_bar_no === "1001") {
          params.limit = firstLimit;
          item.showNum = firstLimit;
        }

        //专家专栏
        if (item.title_bar_no === "1014") {
          params.limit = 7;
          item.showNum = 7;
        }
        //消防应急救援
        if (item.title_bar_no === "1051") {
          params.limit = 11;
          item.showNum = 11;
        }
        //视频新闻
        if (item.title_bar_no === "1052") {
          params.limit = 100;
        }
        // 展会
        if (item.title_bar_no === '1036') {
          params.limit = 9
          API.QuerySubject(params).then(res => {

            if (res.status === 200) {

              item.data = res.data.list.map((item2, index) => {
                item2.id = item.title_bar_no + item2.id + '-' + tools.random(10000, 99999)
                if (index === 0 && item.title_bar_no === this.state.title_bar_no) {
                  item2.BoldTitle = true
                  item2.showPic = false
                }
                item2.title_bar_no = item.title_bar_no
                item2.title = item2.subject_name

                return item2
              });

              if (item.title_bar_no === '1036') {
                this.setState({
                  ZhSwiperList: item.data
                })
              } else if (item.title_bar_no === '1037') {
                item.data = tools.sliceArr(item.data, 8)
              }


              item.loading = false;
            } else {
              item.loading = false;
            }

            _TwoTitleList[index] = item
            this.setState({
              TwoTitleList: _TwoTitleList,
            });
          })
        }


        // 专家专栏
        if (item.title_bar_no === '1004') {
          params.limit = 8;
          item.showNum = 8;
        }
        API.QueryArticle(params).then((res) => {
          if (res.status === 200) {
            item.data = res.data.list.map((item2, index) => {
              item2.id =
                item.title_bar_no + item2.id + "-" + tools.random(10000, 99999);
              if (index === 0 && item.title_bar_no === "1001") {
                item2.BoldTitle = true;
                item2.showPic = false;
              }
              item2.title_bar_no = title_bar_no;
              return item2;
            });

            item.loading = false;
          } else {
            item.loading = false;
          }

          // 新闻中心获取行业政策数据
          if (item.title_bar_no === "1011") {
            item.newsList = item.data
            this.setState({
              newsDataList: item.newsList.slice(0, 4),
              newsSwiperList: item.newsList.slice(0, 3),
              newsDataLoad: false
            });
          }
          // 专家专栏
          if (item.title_bar_no === "1004") {
            item.yizhuList = item.data.slice(4, 6);
            item.expertSayList = item.data.slice(0, 3);
            item.expertSayList.map(item => {
              item.url = `#/ArticleDetails${item.title_bar_no}/${item.article_no}`
            })
            this.setState({
              yiZhuDataList: item.yizhuList,
              expertSayList: item.expertSayList
            })
          }
          // 消防应急
          if (item.title_bar_no === "1051") {
            this.setState({
              fireDataList: item.data.slice(0, 9)
            })
            new Swiper('.fireSwiper', {
              slidesPerView: 3,
              spaceBetween: 65,
              slidesPerGroup: 3,
              loop: true,
              loopFillGroupWithBlank: true,
              autoplay: {
                delay: 3500,
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
              },
              pagination: {
                el: '.swiper-pagination',
                clickable: true,
              },
              breakpoints: {
                320: {  //当屏幕宽度大于等于320
                  slidesPerView: 3,
                  spaceBetween: 15
                },
                768: {  //当屏幕宽度大于等于768 
                  slidesPerView: 3,
                  spaceBetween: 25
                },
                1280: {  //当屏幕宽度大于等于1280
                  slidesPerView: 3,
                  spaceBetween: 65
                }
              }
            });
          }
          // 智慧安保
          if (item.title_bar_no === "1012") {
            this.setState({
              smartDataList: item.data.slice(0, 4)
            })
          }
          // 视频中心
          if (item.title_bar_no === "1052") {
            this.setState({
              videDataList: item.data.slice(0, 4)
            })
          }
          if (item.title_bar_no === "1014") {
            // 专家

            let params = {
              title_bar_no: item.title_bar_no,
              offset: 0,
              limit: 20,
            };
            API.QueryExperts(params).then((res) => {
              if (res.status === 200) {
                item.ExpertList = res.data.list.map((item2) => {
                  item2.title_bar_no = title_bar_no;
                  return item2;
                });
              }

              _TwoTitleList[index] = item;

              this.setState({
                TwoTitleList: _TwoTitleList,
              });
            });
          } else if (item.title_bar_no === "1015") {
            // 会员图片

            let params = {
              title_bar_no: item.title_bar_no,
              offset: 0,
              limit: 2,
            };
            API.QueryAdvertise(params).then((res) => {
              if (res.status === 200) {
                let SwiperDataList = [];
                let AdvertisementData = [];
                res.data.list.forEach((element) => {
                  switch (element.advertise_location) {
                    case 1:
                      SwiperDataList = [...SwiperDataList, element];
                      break;

                    case 2:
                      AdvertisementData = [...AdvertisementData, element];
                      break;

                    default:
                      break;
                  }
                });

                item.SwiperDataList = SwiperDataList;
              }

              _TwoTitleList[index] = item;

              this.setState({
                TwoTitleList: _TwoTitleList,
              });
            });
          } else {
            _TwoTitleList[index] = item;

            this.setState({
              TwoTitleList: _TwoTitleList,
            });
          }
        });
      }
      return item;
    });
  };
  componentWillUnmount() {
    // 对 store 变化取消监听
    this.unsubscribe();
    window.removeEventListener("resize", this.resizeBind);
  }

  GetSwiper = () => {
    let params = {
      title_bar_no: this.state.title_bar_no,
      offset: 0,
      limit: 50,
    };
    API.QueryAdvertise(params).then((res) => {
      if (res.status === 200) {
        let SwiperDataList = [];
        let AdvertisementData = [];
        let MiddlePic = "";
        res.data.list.forEach((element) => {
          switch (element.advertise_location) {
            case 1:
              SwiperDataList = [...SwiperDataList, element];
              break;

            case 3:
              MiddlePic = element.pic;
              break;

            case 2:
              AdvertisementData = [...AdvertisementData, element];
              break;

            default:
              break;
          }
        });

        this.setState({
          SwiperDataList,
          MiddlePic,
          AdvertisementData,
        });
        store.dispatch(setAdvertisement(AdvertisementData));
      } else {
      }
    });
    //左右广告
    let params1 = {
      title_bar_no: "lrAd1001",
      offset: 0,
      limit: 8,
    };
    API.QueryAdvertise(params1).then((res) => {
      if (res.status === 200) {
        let adAroundRList = [];
        let adAroundLList = [];
        res.data.list.forEach((element) => {
          switch (element.advertise_location) {
            case 2:
              adAroundRList = [...adAroundRList, element];
              break;

            case 4:
              adAroundLList = [...adAroundLList, element];
              break;

            default:
              break;
          }
        });
        store.dispatch(setAdAroundR(adAroundRList));
        store.dispatch(setAdAroundL(adAroundLList));
        this.setState({
          adAroundRList,
          adAroundLList,
        });
      } else {
      }
    });
  };
  tabChange = (key) => {
    switch (key) {
      case '1':
        this.setState({
          moreNewsUrl: '/MoreArticles1011/0',
          moreNewsName: '行业新闻'

        })
        break;
      case '2':
        this.setState({
          moreNewsUrl: '/MoreArticles1010/0',
          moreNewsName: '政策跟踪'
        })
        break;
      case '3':
        this.setState({
          moreNewsUrl: '/MoreArticles1021/0',
          moreNewsName: '国外新闻'
        })
        break;
    }
    this.state.TwoTitleList.map(item => {
      if ((key === '1' && item.title_bar_no === "1011")
        || (key === '2' && item.title_bar_no === "1010")
        || (key === '3' && item.title_bar_no === "1021")) {

        this.setState({
          newsDataList: item.data.slice(0, 4),
          newsSwiperList: item.data.slice(0, 3)
        })
      }
    })
  }
  // 更多智慧安保、消防应急
  moreFireOrSmart = (key) => {
    if (key == '1') {
      window.open(`${base.localhost}/#/FireFighting1044/1044`, "_blank");
    } else {
      window.open(`${base.localhost}/#/SmartSecurity1003/1003`, "_blank");
    }

  }
  MoreZh = () => {
    store.dispatch(
      setBreadcrumbList([
        {
          id: "1",
          name: "展会频道",
          path: "/Exhibition1005/1005",
        },
        {
          id: "2",
          name: "更多",
          path: "/Special1036",
        },
      ])
    );
    router.history.push('/Special1036')

  }
  moreNews = () => {
    store.dispatch(
      setBreadcrumbList([
        {
          id: "1",
          name: this.state.moreNewsName,
          path: this.state.moreNewsUrl,
        },
      ])
    );
    window.open(`${base.localhost}/#${this.state.moreNewsUrl}`, "_blank");
  }
  moreExpert = () => {
    window.open(`${base.localhost}/#/ExpertColumn1004/1004`, "_blank");
  }
  moreVideo = () => {
    store.dispatch(
      setBreadcrumbList([
        {
          id: "1",
          name: "视频中心",
          path: "/MoreArticles1052/0",
        }
      ])
    );
    window.open(`${base.localhost}/#/MoreArticles1052/0`, "_blank");
  }
  toDetail = (item) => {
    store.dispatch(
      setBreadcrumbList([
        {
          id: "2",
          name: "详情",
          path: `/ArticleDetails${item.title_bar_no}/${item.article_no}`,
        },
      ])
    );
    window.open(`${base.localhost}/#/ArticleDetails${item.title_bar_no}/${item.article_no}`, "_blank");
  }
  handleClick = (e) => {
    this.setState({
      current: e.key,
    });
  };
  newsNextBtnHover = (flag) => {
    this.setState({
      isNewsNextBtnHover: flag,
    });
  }
  handleYiZhuHover = (flag, index) => {
    this.setState({
      isYiZhuHover: flag,
    });
    if (flag) {
      this.setState({
        yizhuNum: index
      });
    } else {
      this.setState({
        yizhuNum: 0
      });
    }
  }
  newsItemHover = (flag, index) => {
    if (flag) {
      this.setState({
        num: index
      })
    } else {
      this.setState({
        num: -1
      })
    }
  }

  render() {

    const { AdvertisementData, BrowserType, isYiZhuHover, yiZhuDataList, isNewsNextBtnHover, smartDataList, fireDataList, videDataList, newsDataLoad, ZhSwiperList } = this.state;
    var adMd = 8
    var isPC = true
    if (BrowserType !== "PC") {
      adMd = 24;
      isPC = false
    }
    return (



      <div className={styles.content}>
        {/* 顶部轮播图 */}
        {
          isPC ? (
            <div className={styles.FirstDiv}>
              <div className={styles.bigBanner}>
              </div>
            </div>
          ) : (
            <div className={styles.FirstDivMo}>
              <img src={require("../../assets/home/top-banner.jpg")}></img>
            </div>
          )
        }

        {/* 新闻中心 */}
        <Row justify="center" type="flex" className={styles.HomeContent}>
          <div className={isPC ? styles.newsBox : styles.newsBoxMo}>
            <div className={isPC ? styles.homeTitle : styles.homeTitleMo}>
              <div className={styles.titleImg}>
                <img src={require("../../assets/home/title1.png")}></img>
              </div>
              <div className={styles.more} onClick={() => { this.moreNews() }}>查看更多内容 ></div>
            </div>
            <div className={styles.newCenterBox}>
              {
                this.state.newsSwiperList.length > 0 ?
                  <Col xs={24} sm={24} md={12} style={BrowserType == "PC" ? { maxWidth: '40rem', minWidth: '35rem' } : {}}>
                    <SwiperBox autoplay SwiperDataList={this.state.newsSwiperList} textPosition={true} isTextShow={true} isShowBtn={false} description={true} />
                  </Col>
                  : null
              }
              <Col s={24} sm={24} md={12}>
                <div className={styles.newCenterContentBox}>
                  <Tabs tabPosition="top" animated={false} onTabClick={this.tabChange}>
                    {this.state.newsCenterTabs ? (
                      this.state.newsCenterTabs.map((item, index) => {
                        return (
                          <TabPane tab={item.name} key={item.key}>
                            <Skeleton active loading={newsDataLoad}>
                              <div className={styles.newsContainer}>
                                {
                                  this.state.newsDataList.length > 0 ? this.state.newsDataList.map((item, index) => {
                                    return (

                                      <div className={styles.newsItemBox} onClick={() => { this.toDetail(item) }}>
                                        <div className={styles.newsItemleft}>
                                          <div>{tools.transformTime(item.creation_time, 0, 1)[1]}</div>
                                          <div>{tools.transformTime(item.creation_time, 0, 1)[0]}</div>
                                        </div>
                                        <div className={styles.newsItemRight} onMouseOver={() => this.newsItemHover(true, index)} onMouseOut={() => this.newsItemHover(false)}>
                                          <div className={this.state.num == index ? "selected" : ''}>{item.title}</div>
                                          <div>{item.introduction}</div>
                                        </div>
                                      </div>

                                    )
                                  }) : null
                                }
                              </div>
                            </Skeleton>
                          </TabPane>
                        )
                      })
                    ) : null}
                  </Tabs>
                </div>
              </Col>
            </div>
          </div>
        </Row>
        {/* 展会中心 */}
        <div className={isPC ? styles.exhibitionBox : styles.exhibitionBoxMo}>
          <div className={isPC ? styles.homeTitle : styles.homeTitleMo}>
            <div className={styles.titleImg}>
              <img src={require("../../assets/home/title6.png")}></img>
            </div>
          </div>
          <div className={styles.exhibitionContent}>
            <div className={styles.leftBox}>
              <a href={ZhSwiperList[0]?.skip_url ? ZhSwiperList[0]?.skip_url : "javascript:;"} alt={ZhSwiperList[0]?.name} title={ZhSwiperList[0]?.name} target="_blank">
                <img src={`${base.OssAddress}${ZhSwiperList[0]?.pic}`} alt={ZhSwiperList[0]?.subject_name}></img>
              </a>
            </div>
            <div className={styles.rightBox}>
              <div className={styles.rightTopBox}>
                <a href={ZhSwiperList[1]?.skip_url ? ZhSwiperList[1]?.skip_url : "javascript:;"} alt={ZhSwiperList[1]?.name} title={ZhSwiperList[1]?.name} target="_blank">
                  <img src={`${base.OssAddress}${ZhSwiperList[1]?.pic}`} alt={ZhSwiperList[1]?.subject_name}></img>
                </a>
              </div>
              <div className={styles.rightBottomBox}>
                <a href={ZhSwiperList[2]?.skip_url ? ZhSwiperList[2]?.skip_url : "javascript:;"} alt={ZhSwiperList[2]?.name} title={ZhSwiperList[2]?.name} target="_blank" className={styles.leftPic}>
                  <img src={`${base.OssAddress}${ZhSwiperList[2]?.pic}`} alt={ZhSwiperList[2]?.subject_name}></img>
                </a>
                <img src={require("../../assets/home/zh-more.png")} className={styles.rightPic} onClick={() => { this.MoreZh() }}></img>
              </div>
            </div>

          </div>
        </div>
        {/* 专家专栏 */}
        {
          <div className={isPC ? styles.expertBox : styles.expertBoxMo}>
            <div className={isPC ? styles.homeTitle : styles.homeTitleMo}>
              <div className={styles.titleImg}>
                <img src={require("../../assets/home/title2.png")}></img>
              </div>
              <div className={styles.more} onClick={() => { this.moreExpert() }}>查看更多内容 ></div>
            </div>
            <div className={styles.expertContainer}>
              <div className={styles.leftBox}>
                <SwiperBox autoplay SwiperDataList={this.state.expertSayList} titFontsize={"1rem"} isShowBtn={false} textPosition={true} imgSize={isPC ? true : false} isTextShow={true} description={true} />
              </div>
              <div className={styles.rightBox}>
                {yiZhuDataList.length > 0 ? (
                  <div className={styles.yiZhuBox}>
                    {
                      yiZhuDataList.map((item, index) => {
                        return (
                          <div className={this.state.yizhuNum == index ? styles.hoverYiZhuItem : styles.yiZhuItem} onMouseOver={() => this.handleYiZhuHover(true, index)} onMouseOut={() => this.handleYiZhuHover(false, index)} onClick={() => { this.toDetail(item) }}>
                            <div className={styles.yiZhuTitle}>
                              <div>{item.title}</div>
                              <div>{item.introduction}</div>
                            </div>
                            <div className={styles.yiZhuTime}>
                              <div className={styles.time}>
                                <div>{tools.transformTime(item.creation_time, 0, 1)[1]}</div>
                                <div>{tools.transformTime(item.creation_time, 0, 1)[0]}</div>
                              </div>
                              <div className={styles.yiZhuIcon}></div>
                            </div>
                          </div>
                        )
                      })
                    }


                  </div>
                ) : null
                }


                {
                  this.state.TwoTitleList ? this.state.TwoTitleList.map(item => {
                    return (
                      item.title_bar_no == "1004" ? (
                        <div className={styles.expertSayBox}>
                          {
                            item.expertSayList.length > 0 ? item.expertSayList.map((i, index) => {
                              return (
                                <div className={styles.expertSayItem} onMouseOver={() => this.handleYiZhuHover(true, -1)} onMouseOut={() => this.handleYiZhuHover(false, 0)} onClick={() => { this.toDetail(i) }}>
                                  <div className={styles.newsItemBox}>
                                    <div className={styles.newsItemleft}>
                                      <div>23</div>
                                      <div>2021.03</div>
                                    </div>
                                    <div className={styles.newsItemRight}>
                                      <div>{i.title}</div>
                                      <Icon type="arrow-right" className={styles.arrowRight} />
                                    </div>
                                  </div>
                                </div>
                              )
                            }) : null
                          }
                        </div>
                      ) : null
                    )
                  }) : null
                }

              </div>
            </div>
          </div>
        }
        {/* 智慧安保 */}
        <div className={isPC ? styles.smartBox : styles.smartBoxMo}>
          <div className={isPC ? styles.homeTitle : styles.homeTitleMo}>
            <div className={styles.titleImg}>
              <img src={require("../../assets/home/title3.png")}></img>
            </div>
            <div className={styles.more} onClick={() => { this.moreFireOrSmart() }}>查看更多内容 ></div>
          </div>
          <div className={styles.smartContainer}>
            <div className={styles.leftBox}>
              <div className={styles.topImg} onClick={() => { this.toDetail(smartDataList[0]) }}>
                <img src={`${base.OssAddress}${smartDataList[0]?.pic}`} alt={smartDataList[0]?.name}></img>
              </div>
              <div onClick={() => { this.toDetail(smartDataList[0]) }}>
                <div className={styles.itemBox}>
                  <div className={styles.newsTitle}>{smartDataList[0]?.title}</div>
                  <div className={styles.newsContent}>{smartDataList[0]?.introduction}</div>
                </div>
                <div className={styles.moreNewsBtn}>
                  <Icon type="arrow-right" className={styles.arrowRight} />
                </div>
              </div>
            </div>
            <div className={styles.rightBox}>
              {
                smartDataList.map((item, index) => {
                  return (
                    index > 0 ? (
                      <div className={styles.item}>
                        <div className={styles.newsItemleft} onClick={() => { this.toDetail(item) }}>
                          <img src={`${base.OssAddress}${item.pic}`} alt={item.name}></img>
                        </div>
                        <div className={styles.newsItemRight} onClick={() => { this.toDetail(item) }}>
                          <div className={styles.itemBox}>
                            <div className={styles.newsTitle}>{item.title}</div>
                            <div className={styles.newsContent}>{item.introduction}</div>
                          </div>
                          <div className={styles.moreNewsBtn} onClick={() => { this.toDetail(item) }}>
                            <Icon type="arrow-right" className={styles.arrowRight} />
                          </div>
                        </div>
                      </div>
                    ) : null
                  )
                })
              }

            </div>
          </div>
        </div>
        {/* 消防应急 */}
        {
          <div className={isPC ? styles.fireBox : styles.fireBoxMo}>
            <div className={isPC ? styles.homeTitle : styles.homeTitleMo}>
              <div className={styles.titleImg}>
                <img src={require("../../assets/home/title4.png")}></img>
              </div>
              <div className={styles.more} onClick={() => { this.moreFireOrSmart(1) }}>查看更多内容 ></div>
            </div>
            <div className={styles.fireContainer}>
              <div className="swiper-container fireSwiper">
                <div className="swiper-wrapper">
                  {
                    fireDataList.map(item => {
                      return (
                        <div className="swiper-slide">
                          <div className={styles.topImg} onClick={() => { this.toDetail(item) }}>
                            <img src={`${base.OssAddress}${item?.pic}`} alt={item?.name} style={{ width: '100%' }}></img>
                            <div className={styles.time}>
                              <div className={styles.topTime}>{tools.transformTime(item.creation_time, 0, 1)[1]}</div>
                              <div className={styles.bottomTime}>{tools.transformTime(item.creation_time, 0, 1)[0]}</div>
                            </div>
                          </div>
                          <div className={styles.itemContent} onClick={() => { this.toDetail(item) }}>
                            <div className={styles.itemBox}>
                              <div className={styles.newsTitle}>{item?.title}</div>
                              <div className={styles.newsContent}>{item?.introduction}</div>
                            </div>
                            <div className={styles.moreNewsBtn}>
                              <Icon type="arrow-right" className={styles.arrowRight} />
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
                <div class="swiper-pagination"></div>
              </div>
            </div>
          </div>
        }
        {/* 视频中心 */}
        {
          <div className={isPC ? styles.videoCenter : styles.videoCenterMo}>
            <div className={isPC ? styles.homeTitle : styles.homeTitleMo}>
              <div className={styles.titleImg}>
                <img src={require("../../assets/home/title5.png")}></img>
              </div>
              <div className={styles.more} onClick={() => { this.moreVideo() }}>查看更多内容 ></div>
            </div>
            <div className={styles.videoContainer}>
              <div className={styles.videoBox}>
                <div className={styles.videoMax}>
                  <video
                    src={`${base.OssAddress}${videDataList[0]?.video_url}`}
                    style={{ width: '0px', height: '80%', objectFit: 'contain' }}
                    controls
                    initial-time="0.1"
                    x-webkit-airplay="true"
                    x5-video-player-fullscreen="false"
                    webkit-playsinline="true"
                    x5-video-player-type="h5"
                    x5-video-orientation="portraint"
                    playsInline="true"
                    controlsList="nodownload"
                    onLoadedData={(e) => {
                      e.target.style.width = "100%";
                    }}
                    onLoadStart={(e) => {
                      e.target.style.width = "0px";
                    }}
                  ></video>
                  <div className={styles.videoBottomTitle}>
                    <div className={styles.videoTitleTop}>
                      <div>{videDataList[0]?.title}</div>
                      <div>{videDataList[0] && tools.UnixToStr(videDataList[0]?.creation_time, 0, 1, 1)}</div>
                    </div>
                    <div className={styles.videoTitleBottom} onClick={() => { this.toDetail(videDataList[0]) }}>
                      <div>{videDataList[0]?.introduction}</div>
                      <div>
                        <img src={require("../../assets/video/arrow.png")} className={styles.moreNewsArrow}></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.videoPicBox}>
                {
                  videDataList.map((item, index) => {
                    return (
                      index > 0 ? (
                        <div className={styles.videoPicItem} onClick={() => { this.toDetail(item) }}>
                          <div className={styles.videoImgBox} >
                            <img src={`${base.OssAddress}${item.pic}`} alt={item.subject_name} className={styles.videoImg} />
                          </div>

                          <img src={require("../../assets/video/play.png")} className={styles.playIcon}></img>
                          <div className={styles.videoPicText}>
                            <div>{item.title}</div>
                            <div>{item.introduction}</div>
                            <div className={styles.videoPicBottom}>
                              <div>{tools.UnixToStr(item.creation_time, 0, 1, 1)}</div>
                              <div>
                                <img src={require("../../assets/video/arrow.png")} className={styles.moreNewsArrow}></img>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null
                    )
                  })
                }

              </div>
            </div>
          </div>
        }

        {/* 友情链接 */}


        <div className={isPC ? styles.friendshipLiskBox : styles.friendshipLiskBoxMo}>
          <div className="swiper-button-prev">
            <img src={require("../../assets/friend-ship-arrow.png")} className={styles.nextIcon}></img>
          </div>
          <div className={styles.friendshipContainer}>
            {this.state.TwoTitleList
              ? this.state.TwoTitleList.map((item, index) => {
                return index > 5 ? (
                  item.title_bar_no === "1016" ? (
                    <div className="swiper-container friendShipSwiper">
                      <div className="swiper-wrapper">
                        {
                          item.FriendshipLinkList.length > 0 ? item.FriendshipLinkList.map(item => {
                            return (
                              <div className="swiper-slide" key={item.id}>
                                <a href={item.url ? item.url : "javascript:;"} alt={item.name} title={item.name} target="_blank">
                                  <img src={`${base.OssAddress}${item.pic}`} alt={item.name}></img>
                                </a>
                              </div>
                            )
                          }) : null
                        }
                      </div>
                    </div>

                  ) : null
                ) : null;
              })
              : null}
          </div>
          <div className="swiper-button-next">
            <img src={require("../../assets/friend-ship-arrow.png")} className={styles.nextIcon}></img>
          </div>
        </div>


      </div>
    );
  }
}

export default PageHeader;
