import React, { Component } from 'react';
import styles from './index.module.less';
import PageHeader from '@/src/components/PageHeader';
import PageFooter from '@/src/components/PageFooter';
import Content from './Content.jsx';

export default class MoreArticles extends Component {

  state = {
    title_bar_no: '',
  }

  componentDidMount() {

  }

  render() {

    let { title_bar_no } = this.props.match.params

    console.log(title_bar_no)
    console.log('this.props',this.props)

    return (
      <div className={styles.HomeBox + ' Layout'}>

        <PageHeader showNav={false} showNavMo={true} showBreadcrumbList={true} breadcrumbListStyle={true} current='/ArticleDetails'/>

        <Content data={this.props} />

        <PageFooter />

      </div>
    )
  }
}
