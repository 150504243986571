import React, { Component } from 'react';
import { Row, Col, Skeleton, Carousel, Icon } from 'antd';
import styles from './index.module.less';
import SwiperBox from '@/src/components/Carousel/swiper';
import TopicItem from '@/src/components/TopicItem';
import ImgCard from '@/src/components/ImgCard1';
import store from '@/src/redux/store.js';
import API from '@/src/api/Home'
import { tools } from '@/src/lib/tools';
import { setAdvertisement } from '@/src/redux/actions/Advertisement';
import { base } from '@/src/api/base';
import VideosApi from '@/src/api/VideosApi';
import AdAround from "@/src/components/AdAround";
import { setAdAroundR, setAdAroundL } from "@/src/redux/actions/AdAround";
import { HashRouter } from 'react-router-dom'
import 'swiper/swiper-bundle.js'
import 'swiper/swiper.less'
import Swiper from "swiper"
import { setBreadcrumbList } from "@/src/redux/actions/BreadcrumbList";;
const router = new HashRouter()
class Content extends Component {

  state = {
    name: '',
    title_bar_no: '',
    TopicItemCss: '',
    SwiperDataList: [],
    MiddlePic: '',
    AdvertisementData: [],
    TwoTitleList: [],
    BrowserType: "PC",
    adAroundRList: [], //右侧广告
    adAroundLList: [], //左侧广告
    expertTypes: [],//专家类型
    expertList: [],
    onlyOnce: true,
  };

  constructor(props) {
    super(props)
    this.state.title_bar_no = props.title_bar_no
    this.state.name = props.name
    this.state.path = props.path
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(this.ListenStore.bind(this))
    window.addEventListener('resize', this.resizeBind)
    this.resizeBind()
    this.GetSwiper()
    if (this.state.TwoTitleList.length === 0) {
      this.ListenStore()
    }
  }

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.TopicItemCss !== "") {
        this.setState({
          TopicItemCss: "",
        });
      }
      if (this.state.BrowserType !== "Mobile") {
        this.setState({
          BrowserType: "Mobile",
        });
      }
    } else {
      if (!this.state.TopicItemCss !== "TopicItem") {
        this.setState({
          TopicItemCss: "TopicItem",
        });
      }
      if (!this.state.BrowserType !== "PC") {
        this.setState({
          BrowserType: "PC",
        });
      }
    }
  };


  ListenStore = () => {
    if (!this.state.onlyOnce) return
    let { TdTitleBar } = store.getState()

    if (!TdTitleBar || TdTitleBar === {} || TdTitleBar.length === undefined || TdTitleBar.length === 0) {
      return
    }
    let oneTitleList = TdTitleBar.filter(item => {
      return item.level === 2 && item.show_status === 2
    })

    let TwoTitleList = oneTitleList.filter(item => {
      return item.parent_id === this.state.title_bar_no
    })
    // 添加一个置顶的 
    TwoTitleList = [{
      id: 0,
      title_bar_no: this.state.title_bar_no,
      parent_id: '',
      name: "",
      level: 2,
      sort: 1,
      nav_status: 2,
      show_status: 2,
      ShowHeaderTitle: false,
      data: [

      ]
    }, ...TwoTitleList]

    this.setState({
      TwoTitleList: TwoTitleList,
    })
    //查询专家类型
    this.QueryExpertTypes();
    // 查询文章
    this.QueryArticle(TwoTitleList);
    this.setState({
      onlyOnce: false
    });
  }

  QueryArticle = (_TwoTitleList) => {
    if (!_TwoTitleList || _TwoTitleList.length === 0) {
      return
    }
    _TwoTitleList.map((item, index) => {

      // 文章
      let params = { title_bar_no: item.title_bar_no, offset: 0, limit: 8 }
      //专家栏视频新闻
      if (item.title_bar_no === "1053") {
        params.limit = 100;
      }
      if (item.title_bar_no === '1004') {
        params.limit = 7;
        item.showNum = 7;
      }
      if (item.title_bar_no === '1031' || item.title_bar_no === '1032') {
        params.limit = 5
        item.showNum = 5;
      }

      if (item.title_bar_no === '1030') {
        params.limit = 20
        API.QueryExperts(params).then(res => {
          if (res.status === 200) {
            item.data = []
            item.ExpertList = res.data.list
            item.ExpertList[0].isExpertColumn = true
          }
          item.loading = false;
          _TwoTitleList[index] = item
          this.setState({
            TwoTitleList: _TwoTitleList,
            expertList: item.ExpertList
          })
          new Swiper('.expertSwiper', {
            slidesPerView: 4,
            spaceBetween: 30,
            slidesPerGroup: 4,
            loop: false,
            loopFillGroupWithBlank: true,
            autoplay: {
              delay: 2000,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            },
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
            breakpoints: {
              320: {  //当屏幕宽度大于等于320
                slidesPerView: 3,
                spaceBetween: 15
              },
              768: {  //当屏幕宽度大于等于768 
                slidesPerView: 4,
                spaceBetween: 25
              },
              1280: {  //当屏幕宽度大于等于1280
                slidesPerView: 4,
                spaceBetween: 30
              }
            }
          });
        });
      } else if (item.title_bar_no === '1032') {
        API.QueryArticleOrderByClick(params).then(res => {
          if (res.status === 200) {
            item.data = res.data.list.map((item2, index) => {
              item2.id = item.title_bar_no + item2.id + '-' + tools.random(10000, 99999)
              if (index === 0 && item.title_bar_no === this.state.title_bar_no) {
                item2.BoldTitle = true
                item2.showPic = false
              }
              item2.title_bar_no = item.title_bar_no

              return item2
            });
            item.loading = false;
          } else {
            item.loading = false;
          }

          _TwoTitleList[index] = item
          this.setState({
            TwoTitleList: _TwoTitleList,
          })
        })
      } else if (item.title_bar_no === '1033') {
        params.limit = 6
        VideosApi.QueryVideosList(params).then(res => {

          if (res.status === 200) {
            item.data = res.data.list.map((item2, index) => {
              item2.id = item.title_bar_no + item2.id + '-' + tools.random(10000, 99999)
              if (index === 0 && item.title_bar_no === this.state.title_bar_no) {
                item2.BoldTitle = true
                item2.showPic = false
              }
              item2.title_bar_no = item.title_bar_no
              item2.pic = item2.cover_pictures
              item2.title = item2.folder_name

              return item2
            });
            item.data = tools.sliceArr(item.data, 6)
            item.loading = false;
          } else {
            item.loading = false;
          }

          _TwoTitleList[index] = item
          this.setState({
            TwoTitleList: _TwoTitleList,
          })
        })
      } else {
        API.QueryArticle(params).then(res => {
          if (res.status === 200) {
            item.data = res.data.list.map((item2, index) => {
              item2.id = item.title_bar_no + item2.id + '-' + tools.random(10000, 99999)
              if (index === 0 && item.title_bar_no === this.state.title_bar_no) {
                item2.BoldTitle = true
                item2.showPic = false
              }
              item2.title_bar_no = item.title_bar_no

              return item2
            });


            item.loading = false;
          } else {
            item.loading = false;
          }

          _TwoTitleList[index] = item
          this.setState({
            TwoTitleList: _TwoTitleList,
          })
        })
      }
      return item
    })
  }

  QueryExpertTypes = () => {
    let _params = {
      limit: 10,
      offset: 0,
    }
    API.QueryExpertTypes(_params).then(res => {
      if (res.status === 200) {
        let dataSource1 = res.data.list;
        if (!dataSource1) {
          return;
        }
        this.setState({
          expertTypes: res.data.list
        })
      }

    })
  }
  componentWillUnmount() {
    // 对 store 变化取消监听
    this.unsubscribe();
    window.removeEventListener('resize', this.resizeBind)
  }
  MoreArticles = () => {
    store.dispatch(
      setBreadcrumbList([
        {
          id: "1",
          name: '专家专栏',
          path: "/ExpertColumn1004/1004",
        },
        {
          id: "2",
          name: '更多',
          path: "/MoreArticles1004/0",
        },
      ])
    );
    window.open(`${base.localhost}/#/MoreArticles1004/0`, "_blank");
  }
  GetSwiper = () => {
    let params = { title_bar_no: this.state.title_bar_no, offset: 0, limit: 50 }
    API.QueryAdvertise(params).then(res => {
      if (res.status === 200) {
        let SwiperDataList = []
        let AdvertisementData = []
        let MiddlePic = ''
        res.data.list.forEach(element => {
          switch (element.advertise_location) {
            case 1:
              SwiperDataList = [...SwiperDataList, element]
              break;

            case 3:
              MiddlePic = element.pic
              break;

            case 2:
              AdvertisementData = [...AdvertisementData, element]
              break;

            default:
              break;
          }
        });
        this.setState({
          SwiperDataList,
          MiddlePic,
          AdvertisementData,
        })
        store.dispatch(setAdvertisement(AdvertisementData))

      } else {

      }


    })
    let params1 = {
      title_bar_no: "lrAd1004",
      offset: 0,
      limit: 8,
    };
    API.QueryAdvertise(params1).then((res) => {
      if (res.status === 200) {
        let adAroundRList = [];
        let adAroundLList = [];
        res.data.list.forEach((element) => {
          switch (element.advertise_location) {
            case 2:
              adAroundRList = [...adAroundRList, element];
              break;

            case 4:
              adAroundLList = [...adAroundLList, element];
              break;

            default:
              break;
          }
        });
        store.dispatch(setAdAroundR(adAroundRList));
        store.dispatch(setAdAroundL(adAroundLList));
        this.setState({
          adAroundRList,
          adAroundLList,
        });
      } else {
      }
    });
  }

  tmp = (item) => {
    if (item.title_bar_no != "1030") {
      return <ImgCard title={item.name} dots={false} loading={item.loading} data={item.data} title_bar_no={item.title_bar_no} name={this.state.name} />
    }
  }
  render() {
    const { AdvertisementData, BrowserType } = this.state
    var adMd = 8
    if (BrowserType !== "PC") adMd = 24


    return (
      <div>
        <Row justify='space-between' type='flex' >

          <div className={BrowserType !== "PC" ? styles.FirstDivMo : styles.FirstDiv}>
            <div className={styles.FirstBox}>
              {
                this.state.SwiperDataList.length > 0 ?
                  <Col xs={24} sm={24} md={11} style={BrowserType == "PC" ? {} : { padding: "0 16px" }} className={styles.leftSwiper}>
                    <SwiperBox autoplay SwiperDataList={this.state.SwiperDataList} textPosition={true} isTextShow={true} />
                  </Col>
                  : null
              }
              {
                this.state.TwoTitleList && this.state.TwoTitleList.length > 0 ?
                  <Col key={this.state.TwoTitleList[0].id + 'aaa'} className={styles.CardOne} xs={24} sm={24} md={(this.state.TwoTitleList[0].SwiperDataList && this.state.TwoTitleList[0].SwiperDataList.length > 0) || (this.state.TwoTitleList[0].ExpertList && this.state.TwoTitleList[0].ExpertList.length > 0) ? 24 : 13} >

                    <TopicItem name={this.state.name} title={this.state.TwoTitleList[0].name} data={this.state.TwoTitleList[0].data} SwiperDataList={this.state.TwoTitleList[0].SwiperDataList} ShowHeaderTitle={this.state.TwoTitleList[0].ShowHeaderTitle} showNum={this.state.TwoTitleList[0].showNum} loading={this.state.TwoTitleList[0].loading} ExpertList={this.state.TwoTitleList[0].ExpertList} title_bar_no={this.state.TwoTitleList[0].title_bar_no} />
                  </Col>
                  : null
              }
            </div>
            {
              <div className={styles.moreBox}>
                <div
                  className={styles.headerTitleMoreBtn}
                  onClick={() => { this.MoreArticles(this.props.title_bar_no); }}>
                  <span>MORE </span>
                  <Icon type="arrow-right" />
                </div>
              </div>
            }
            {/* 专家介绍 */}
            <div className={this.state.BrowserType !== "PC" ? styles.expertBoxMo : styles.expertBox}>
              <TopicItem name={"专家介绍"} title={"专家介绍"} loading={false} ExpertList={this.state.expertList} ShowMoreBtn={false}/>
              <div className="swiper-container expertSwiper">
                <div className="swiper-wrapper">
                  {
                    this.state.expertList.length > 0 ? this.state.expertList.map((item, index) => {
                      return (
                        <div className="swiper-slide" key={item.id}>
                          <div className={styles.expertItem}>
                            <div className={styles.expertInfo}>
                              <img src={`${base.OssAddress}${item.head_url}`} alt={item.real_name} className={styles.expertImg} />

                              {/* <img src={require("../../assets/expert/head.jpg")} className={styles.expertImg}></img> */}
                              <div className={styles.expertTitle}>专家介绍</div>
                              <p className={styles.expertName}>{item.real_name}</p>
                              <div className={styles.textContainer}>
                                <div className={styles.textBox}>
                                  {item.introduction?.split('；').map((item, index) => {
                                    return (<p className={styles.expertResume} key={index}>{item}</p>)
                                  })}
                                </div>
                              </div>
                            </div>
                            <div className={styles.expertInfoback} >
                              <p className={styles.expertName}>{item.real_name}</p>
                              <p className={styles.expertResume}>{item.resume}</p>
                            </div>
                          </div>
                        </div>
                      )
                    }) : null
                  }
                </div>
                <div class="swiper-pagination"></div>
              </div>


            </div>
          </div>

          <div className={BrowserType == "PC" ? styles.ModdleBox : styles.ModdleBoxMo}>
            <Col xs={24} sm={24} md={AdvertisementData && AdvertisementData.length > 0 ? 24 : 24} style={BrowserType == "PC" ? { padding: "2rem 0 5rem" } : { padding: "2rem 0" }}>
              <Row align='top' type='flex' gutter={16}>
                {this.state.TwoTitleList ? this.state.TwoTitleList.map((item, index) => {
                  return (
                    index > 0 ? item.title_bar_no != "1030" && item.title_bar_no !== "1033" ?
                      <Col key={item.id} className={this.state.TopicItemCss} xs={24} sm={24} md={(item.SwiperDataList && item.SwiperDataList.length > 0) || (item.ExpertList && item.ExpertList.length > 0) || (item.title_bar_no == "1053") ? 24 : 11} >
                        <TopicItem name={this.state.name} title={item.name} data={item.data} SwiperDataList={item.SwiperDataList} ShowHeaderTitle={item.ShowHeaderTitle} showNum={item.showNum} loading={item.loading} ExpertList={item.ExpertList} title_bar_no={item.title_bar_no} />
                      </Col>
                      : <Col key={item.id} xs={24} sm={24} md={24} className={this.state.TopicItemCss} >
                        {this.tmp(item)}
                      </Col> : null

                  )
                }) : null}
              </Row>
            </Col>
          </div>
        </Row>
      </div>
    )
  }
}

export default Content;