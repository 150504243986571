// 正式服   
var base = {
  OssAddress: "https://hanganyun.oss-cn-beijing.aliyuncs.com/" ,
  OssDownloadAddress: "https://hanganyun.oss-cn-beijing.aliyuncs.com/" ,
  // localhost: "http://" + window.location.host ,
  // localhost: "http://152.136.17.68" ,
  localhost: "http://www.cacps.cn",
  baseUrl: "/RequestAddress" ,
  NameInitials: 'MHAB' , 
  url: "http://www.cacps.cn" ,
  // url: "http://152.136.17.68" ,
}

// // 测试服
// var base = {
//   OssAddress: "https://hanganyun.oss-cn-beijing.aliyuncs.com/" ,
//   OssDownloadAddress: "https://hanganyun.oss-cn-beijing.aliyuncs.com/" ,
//   localhost: "http://" + window.location.host ,
//   // localhost: "http://www.cacps.cn/" ,
//   baseUrl: "/RequestAddress" ,
//   NameInitials: 'MHAB' , 
//   // url: "http://www.cacps.cn/" ,
//   url: "http://" + window.location.host ,
// }

export {
  base
}