import React, { Component } from "react";
import { Row, Col, Button } from "antd";
import styles from "./index.module.less";
import Swiper from "@/src/components/Carousel/swiper";
import ImgCard from "@/src/components/ImgCard1";
import store from "@/src/redux/store.js";
import API from "@/src/api/Home";
import { tools } from "@/src/lib/tools";
import AdAround from "@/src/components/AdAround";
import { setAdAroundR, setAdAroundL } from "@/src/redux/actions/AdAround";
import TopicItem from "@/src/components/TopicItem";


class Content extends Component {
  state = {
    name: "",
    title_bar_no: "",
    TopicItemCss: "",
    SwiperDataList: [],
    MiddlePic: "",
    MembershipPrivileges: [],
    adAroundRList: [], //右侧广告
    adAroundLList: [], //左侧广告
    BrowserType: "PC",
    NewsSwiperDataList1:[]
  };

  constructor(props) {
    super(props);
    this.state.title_bar_no = props.title_bar_no;
    this.state.name = props.name;
    this.state.path = props.path;
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(this.ListenStore.bind(this));
    window.addEventListener("resize", this.resizeBind);
    this.ListenStore();
    this.resizeBind();
    this.GetSwiper();
  }

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.TopicItemCss !== "") {
        this.setState({
          TopicItemCss: "",
        });
      }
      if (this.state.BrowserType !== "Mobile") {
        this.setState({
          BrowserType: "Mobile",
        });
      }
    } else {
      if (!this.state.TopicItemCss !== "TopicItem") {
        this.setState({
          TopicItemCss: styles.topicPadd,
        });
      }
      if (!this.state.BrowserType !== "PC") {
        this.setState({
          BrowserType: "PC",
        });
      }
    }
  };
  ListenStore = () => {
    let { TdTitleBar } = store.getState();

    if (
      !TdTitleBar ||
      TdTitleBar === {} ||
      TdTitleBar.length === undefined ||
      TdTitleBar.length === 0
    ) {
      return;
    }
    let oneTitleList = TdTitleBar.filter((item) => {
      return item.level === 2 && item.show_status === 2;
    });

    let TwoTitleList = oneTitleList.filter((item) => {
      return item.parent_id === this.state.title_bar_no;
    });

    // 添加一个置顶的
    // TwoTitleList = [{
    //   id: 0,
    //   title_bar_no: this.state.title_bar_no,
    //   parent_id: '',
    //   name: "",
    //   level: 2,
    //   sort: 1,
    //   nav_status: 2,
    //   show_status: 2,
    //   ShowHeaderTitle: false,
    //   data: [

    //   ]
    // }, ...TwoTitleList]

    this.setState({
      TwoTitleList: TwoTitleList,
    });

    // 查询文章
    this.QueryArticle(TwoTitleList);
  };

  QueryArticle = (_TwoTitleList) => {
    // let _TwoTitleList = this.state.TwoTitleList

    if (!_TwoTitleList || _TwoTitleList.length === 0) {
      return;
    }
    _TwoTitleList.map((item, index) => {
      // 文章
      let params = { title_bar_no: item.title_bar_no, offset: 0, limit: 5 };
      if (item.title_bar_no === "1040") {
        params.limit = 3;
        API.QueryArticle(params).then((res) => {
          if (res.status === 200) {
            item.data = res.data.list.map((item2, index) => {
              item2.id =
                item.title_bar_no + item2.id + "-" + tools.random(10000, 99999);
              if (
                index === 0 &&
                item.title_bar_no === this.state.title_bar_no
              ) {
                item2.BoldTitle = true;
                item2.showPic = false;
              }
              item2.title_bar_no = item.title_bar_no;

              return item2;
            });

            if (item.title_bar_no === "1040") {
              // 1040a数据
              item.data = tools.sliceArr(item.data, 3);
              console.log(item.data);
            } else {
              item.data = tools.sliceArr(item.data, 1);
            }

            item.loading = false;
          } else {
            item.loading = false;
          }

          _TwoTitleList[index] = item;
          this.setState({
            TwoTitleList: _TwoTitleList,
          });
        });
      } else if (item.title_bar_no === "1038") {
        API.QueryMemberype({
          offset: 0,
          limit: 5,
          level: 2,
        }).then((res) => {
          if (res.status === 200) {
            item.data = res.data.list.map((item2, index) => {
              item2.id =
                item.title_bar_no + item2.id + "-" + tools.random(10000, 99999);
              if (
                index === 0 &&
                item.title_bar_no === this.state.title_bar_no
              ) {
                item2.BoldTitle = true;
                item2.showPic = false;
              }
              item2.title_bar_no = item.title_bar_no;

              return item2;
            });

            if (item.title_bar_no === "1040") {
              item.data = tools.sliceArr(item.data, 3);
            } else {
              item.data = tools.sliceArr(item.data, 1);
            }

            item.loading = false;
          } else {
            item.loading = false;
          }

          _TwoTitleList[index] = item;
          this.setState({
            TwoTitleList: _TwoTitleList,
          });
        });
      } else if (item.title_bar_no === "1039") {
        API.QueryArticle(params).then((res) => {
          if (res.status === 200) {
            item.data = res.data.list.map((item2, index) => {
              item2.id =
                item.title_bar_no + item2.id + "-" + tools.random(10000, 99999);
              if (
                (index === 0 &&
                  item.title_bar_no === this.state.title_bar_no) ||
                (index === 0 && item.title_bar_no == "1039")
              ) {
                item2.BoldTitle = true;
                item2.showPic = false;
              }
              item2.title_bar_no = item.title_bar_no;

              return item2;
            });


            item.loading = false;
          } else {
            item.loading = false;
          }

          _TwoTitleList[index] = item;
          this.setState({
            TwoTitleList: _TwoTitleList,
          });
        });


        // API.QueryVipList({
        //   offset: 0,
        //   limit: 5,
        //   level: 2,
        // }).then((res) => {
        //   if (res.status === 200) {
        //     item.data = res.data.map((item2, index) => {
        //       item2.id =
        //         item.title_bar_no + item2.id + "-" + tools.random(10000, 99999);
        //       if (
        //         index === 0 &&
        //         item.title_bar_no === this.state.title_bar_no
        //       ) {
        //         item2.BoldTitle = true;
        //         item2.showPic = false;
        //       }
        //       item2.title_bar_no = item.title_bar_no;

        //       return item2;
        //     });

        //     if (item.title_bar_no === "1040") {
        //       item.data = tools.sliceArr(item.data, 3);
        //     } else {
        //       item.data = tools.sliceArr(item.data, 1);
        //     }

        //     item.loading = false;
        //   } else {
        //     item.loading = false;
        //   }

        //   _TwoTitleList[index] = item;
        //   this.setState({
        //     TwoTitleList: _TwoTitleList,
        //   });
        // });
      }

      return item;
    });
  };

  componentWillUnmount() {
    // 对 store 变化取消监听
    this.unsubscribe();
    window.removeEventListener("resize", this.resizeBind);
  }

  GetSwiper = () => {
    let params = { title_bar_no: this.state.title_bar_no, offset: 0, limit: 5 };
    API.QueryAdvertise(params).then((res) => {
      if (res.status === 200) {
        let SwiperDataList = [];
        let AdvertisementData = [];
        let MiddlePic = "";
        let NewsSwiperDataList1 = []
        res.data.list.forEach((element) => {
          switch (element.advertise_location) {
            case 1:
              SwiperDataList = [...SwiperDataList, element];
              break;

            case 3:
              MiddlePic = element.pic;
              break;

            case 2:
              AdvertisementData = [...AdvertisementData, element];
              break;

              case 4:
                NewsSwiperDataList1 = [...NewsSwiperDataList1, element];
                break;
            default:
              break;
          }
        });
        this.setState({
          SwiperDataList,
          MiddlePic,
          NewsSwiperDataList1,
        });
        // store.dispatch(setAdvertisement(AdvertisementData))
      } else {
      }
    });
    //左右广告
    let params1 = {
      title_bar_no: "lrAd1006",
      offset: 0,
      limit: 8,
    };
    API.QueryAdvertise(params1).then((res) => {
      if (res.status === 200) {
        let adAroundRList = [];
        let adAroundLList = [];
        res.data.list.forEach((element) => {
          switch (element.advertise_location) {
            case 2:
              adAroundRList = [...adAroundRList, element];
              break;

            case 4:
              adAroundLList = [...adAroundLList, element];
              break;

            default:
              break;
          }
        });
        store.dispatch(setAdAroundR(adAroundRList));
        store.dispatch(setAdAroundL(adAroundLList));
        this.setState({
          adAroundRList,
          adAroundLList,
        });
      } else {
      }
    });
  };

  render() {
    const { userInfo, BrowserType } = store.getState();
    console.log(this.state.TwoTitleList);
    return (
      <div className={styles.HomeContent}>
        <Row justify="space-between" type="flex">
          <div
            className={styles.FirstDiv}
            style={
              BrowserType == "PC"
                ? {}
                : { padding: "15px 16px", background: "#fff" }
            }
          >
            {this.state.SwiperDataList.length > 0 ? (
              <Col className={this.state.TopicItemCss} xs={24} sm={24} md={24}>
                <Swiper autoplay SwiperDataList={this.state.SwiperDataList} />
              </Col>
            ) : null}
          </div>

          <div className={styles.ModdleBox}>
            <Col
              xs={24}
              sm={24}
              md={24}
              style={BrowserType == "PC" ? { padding: "2rem 0 0" } : {}}
            >
              <Row align="top" type="flex">
                {this.state.TwoTitleList
                  ? this.state.TwoTitleList.map((item) => {
                      console.log("TwoTitleList", item);
                      return item.title_bar_no == 1039 ? (
                        <Col
                              key={item.id}
                              className={this.state.TopicItemCss}
                              xs={24}
                              sm={24}
                              md={24}
                            >
                              <TopicItem
                                name={this.state.name}
                                title={item.name}
                                data={item.data}
                                SwiperList={this.state.NewsSwiperDataList1}
                                ShowHeaderTitle={item.ShowHeaderTitle}
                                showNum={item.showNum}
                                loading={item.loading}
                                ExpertList={item.ExpertList}
                                title_bar_no={item.title_bar_no}
                                isSwiperTopicItem={true}
                              />
                            </Col>
                      ) : (
                        <Col
                          key={item.id}
                          className={this.state.TopicItemCss}
                          xs={24}
                          sm={24}
                          md={24}
                        >
                          <ImgCard
                            title={item.name}
                            dots={false}
                            loading={item.loading}
                            data={item.data}
                            title_bar_no={item.title_bar_no}
                            name={this.state.name}
                            md={item.title_bar_no === "1040" ? 8 : 24}
                            ShowMask={false}
                            ShowMore={
                              item.title_bar_no === "1040" ? true : false
                            }
                            isHover={false}
                          />
                        </Col>
                      );
                    })
                  : null}
              </Row>
            </Col>
          </div>
        </Row>

        {/* {!userInfo ?
          <div className={styles.MbmberBtn}>
            <Button shape="round" href="/#/register1" >
              成为会员
            </Button>
          </div>
          : null} */}
      </div>
    );
  }
}

export default Content;
