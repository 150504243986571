import React, { Component } from 'react';
import { Card, Icon, Carousel , Avatar } from 'antd';
import styles from './index.module.less';
import store from '@/src/redux/store.js';
import { setArticleDetails } from '@/src/redux/actions/ArticleDetails';
import { setBreadcrumbList } from '@/src/redux/actions/BreadcrumbList';

import { HashRouter } from 'react-router-dom'
import { base } from '../../api/base';
const router = new HashRouter()

class ImgCard extends Component {

  state = {
    BrowserType: 'PC', // PC Mobile 浏览器类型 手机或者pc端
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeBind)
    this.resizeBind()
  }

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== 'Mobile') {
        this.setState({
          BrowserType: 'Mobile',
        });
      }
    } else {
      if (!this.state.BrowserType !== 'PC') {
        this.setState({
          BrowserType: 'PC',
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeBind)
  }

  // 详情
  ImgCardContentClick = (item) => {
    return
    store.dispatch(setArticleDetails(item))
    store.dispatch(setBreadcrumbList(
      [
        {
          id: '1',
          name: this.props.name,
          path: null,
        },
        {
          id: '2',
          name: "新闻详情",
          path: '/ArticleDetails'+ item.title_bar_no + '/' + item.article_no
        }
      ]
    ))
    // router.history.push('/ArticleDetails'+ item.title_bar_no + '/' + item.article_no)
    window.open(
      base.localhost +
        "/#/ArticleDetails" +
        item.title_bar_no +
        "/" +
        item.article_no
    );
  }

  // 更多
  MoreArticles = (title_bar_no,title) => {
    return
    if (title_bar_no === '1029') {
      store.dispatch(setBreadcrumbList(
        [
          {
            id: '1',
            name: title_bar_no,
            path: null ,
          },
          {
            id: '2',
            name: title,
            path: '/MoreArticles'+title_bar_no + '/0'
          }
        ]
      ))
  
      router.history.push('/MoreArticles' + title_bar_no + '/0')

    } else {
      store.dispatch(setBreadcrumbList(
        [
          {
            id: '1',
            name: title_bar_no,
            path: null,
          },
          {
            id: '2',
            name: "专题",
            path: '/Special' + title_bar_no
          }
        ]
      ))

      router.history.push('/Special' + title_bar_no)
    }

  }

  render() {

    let ShowHeaderTitle = true
    if (this.props.ShowHeaderTitle !== undefined) {
      ShowHeaderTitle = this.props.ShowHeaderTitle
    }

    let loading = true
    if (this.props.loading !== undefined) {
      loading = this.props.loading
    }

    const { BrowserType } = this.state

    return (
      <div className={`${styles.ImgCard} ${!ShowHeaderTitle > 0 ? styles.NoHeaderTitle : ''} ${BrowserType === 'PC' ? styles.PCImgCard : styles.MobileCard}`}>

        <Card title={ShowHeaderTitle ? <div >{this.props.title}</div> : ''} extra={ShowHeaderTitle ? <div className={styles.ImgCardMore} onClick={() => { this.MoreArticles(this.props.title_bar_no,this.props.title) }} >更多  <Icon type="right-circle" theme="filled" /> </div> : ''} bordered={false} loading={loading} bodyStyle={{ paddingLeft: '0.2rem', paddingRight: '0.2rem' }} >
          <div className={styles.IcoTa1 + ' ' + styles.IcoTa} onClick={() => this.Carousel.prev()} >
            <Icon type="left" />
          </div>
          <div className={styles.IcoTa2 + ' ' + styles.IcoTa} onClick={() => this.Carousel.next()} >
            <Icon type="right" />
          </div>

          <Carousel className={styles.ImgCardList} {...this.props} ref={dom => { this.Carousel = dom; }} >
            {
              this.props.data && this.props.data.length > 0 ?
              
                this.props.data.map((item, index) => {
                  return (
                    <div className={styles.ImgCardContent} key={index}  >
                      {
                        item && item.length > 0 ?
                          item.map((item2, index) => {
                            return (
                              <div className={`${styles.ImgCardTa1}`} key={item2.id} onClick={this.ImgCardContentClick.bind(this, item2)} >
                                <div className={styles.ImgCardPic}>
                                  <Avatar src={`${base.OssAddress}${item2.pic}`} size={64} icon="user" />
                                </div>
                                <div className={styles.ImgCardTitle} >
                                  <div className={styles.name} >{item2.name}</div>
                                  <div className={styles.desc}>介绍：{item2.desc}</div>
                                </div>
                              </div>
                            )
                          }) : null
                      }

                    </div>
                  )
                }) : null
            }

          </Carousel>


        </Card>

      </div>
    )
  }
}
export default ImgCard;