import Server from './server'

class ExhbitionApi extends Server{

  // 查询展会数据
  QueryExhbition(data) {
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_page_config', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }
  QueryExhbitionByPid(data) {
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_page_config_byPid', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }
  /**
   *  用途：查询展商信息
   *  @url http://cangdu.org:8001/v2/login
   *  @method post
   *  @return {promise}
   */
  QueryExhbitor(data) {
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_exhibitor', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  /**
   *  用途：查询推荐展商信息
   *  @url http://cangdu.org:8001/v2/login
   *  @method post
   *  @return {promise}
   */
  QueryVipExhibitor(data) {
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_vipExhibitor', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }
  /**
   *  用途：根据id查询展商信息
   *  @url http://cangdu.org:8001/v2/login
   *  @method post
   *  @return {promise}
   */
  QueryExhibitorById(data) {
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_exhibitorById', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }
}

export default new ExhbitionApi()