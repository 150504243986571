import * as actionTypes from '../constants/index';
import { local } from '../../lib/tools'

const setArticleDetails = (data) => {

    local.set(actionTypes.SET_ARTICLE_DRTAILS,data,1)

    return {
        type: actionTypes.SET_ARTICLE_DRTAILS,
        data
    }
};
export {setArticleDetails};
