import React, { Component } from "react";
import {
  Row,
  Col,
  Anchor,
  Carousel,
  Modal,
  Collapse,
  Divider,
  Icon,
} from "antd";
import styles from "./home.module.less";
import PageContent from "@/src/components/PageContent";
import store from "@/src/redux/store.js";
import API from "../../../api/exhibition";
import { base } from "@/src/api/base";
import { setAdvertisement } from "@/src/redux/actions/Advertisement";
import { tools } from "@/src/lib/tools";
import swiperPatch from "@/src/components/swiperPatch/swiperPatch";
import { HashRouter, withRouter } from "react-router-dom";
import PersonalCenterApi from "@/src/api/PersonalCenterApi";
import moment from "moment";

const router = new HashRouter();
const { Link } = Anchor;
const { Panel } = Collapse;
class Content extends Component {
  state = {
    name: "首页",
    exhbitionData: [], //总数据
    speechByLeaders: [], //19领导致辞
    expertSpeech: [], //19专家演讲
    wonderfulActivities: [], //19专家聘任
    achievementShow: [], //19行业成果展示
    guestVisit: [], //19图文直播
    elegantDemeanor: [],//展会风采
    introductionData: "",
    internationalGuestsList: [], //国际嘉宾
    domesticGuestsList: [], //国内嘉宾
    guestList: [], //嘉宾
    guestActive: "domestic", //选中嘉宾
    sponsorList: [], //赞助商
    agendaList: [], //大会议程列表
    agendaData: [], //大会议程详情
    latestNewsData: "", //最新资讯
    samePeriodList: [], //同期展览
    cooperationUnitList: [], //合作单位9
    cooperationMediaLsit: [], //合作媒体10
    designatedMediaList: [], //指定媒体11
    BrowserType: "PC", // PC Mobile 浏览器类型 手机或者pc端
    isLoad: false,
    showModal: false, //展商目录弹框
    loading: false,
    agendaListSplice: [],
    expertSpeechSplice: [],
    guestVisitSplice: [],
    is_vip: '0',
    moreBtnShow: {//展开更多按钮的显示和隐藏
      agenda: true,//大会议程
      expert: true,//专家演讲
      guest: true,//图文直播
    },
  };

  componentDidMount() {
    window.addEventListener("resize", this.resizeBind);
    this.resizeBind();
    this.QueryExhbition();
    this.MineBuyVip();
    setTimeout(() => {
      this.setState({
        isLoad: true,
      });
    }, 500);
    //
  }

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== "Mobile") {
        this.setState({
          BrowserType: "Mobile",
        });
      }
    } else {
      if (!this.state.BrowserType !== "PC") {
        this.setState({
          BrowserType: "PC",
        });
      }
    }
  };
  componentWillUnmount() {
    // 对 store 变化取消监听
    if (this.state.guest_clear_timeout) {
      this.state.guest_clear_timeout();
    }
    if (this.state.sponsor_clear_timeout) {
      this.state.sponsor_clear_timeout();
    }
    window.removeEventListener("resize", this.resizeBind);
  }
  QueryExhbition = () => {
    let params = {
      limit: 1000,
      // year:'2019',
    };
    this.setState({ loading: true });
    API.QueryExhbition(params)
      .then((res) => {
        if (res.status === 200) {
          console.log("data", res);
          var exhbitionData = res.data.list;
          var introductionData = exhbitionData.filter((item) => {
            return item.id == 1;
          })[0];
          introductionData = introductionData.content;
          if (this.state.BrowserType !== "PC") {
            introductionData = introductionData.replace(
              /font-size:\w+;?/g,
              "font-size:15px"
            );
            introductionData = introductionData.replace(
              /line-height:\w+;?/g,
              "line-height:1"
            );
          }
          // var re1 = /\<(\p.*)\>/g;
          //  var re1=/<\/(p.*)>/gm;
          // var r = introductionData.replace(re1, '<a href="/#/exhibitionDetails">[查看更多]</a>');
          // console.log(r)
          var internationalGuestsList = exhbitionData.filter((item) => {
            return item.pid == 2;
          });

          var domesticGuestsList = exhbitionData.filter((item) => {
            return item.pid == 3;
          });
          if (
            internationalGuestsList[0].is_hidden == 1 &&
            domesticGuestsList[0].is_hidden == 2
          ) {
            this.setState({
              guestActive: "international",
              guestList: internationalGuestsList,
            });
          } else if (
            internationalGuestsList[0].is_hidden == 2 &&
            domesticGuestsList[0].is_hidden == 2
          ) {
            this.setState({ guestActive: "", guestList: [] });
          } else {
            this.setState({
              guestActive: "domestic",
              guestList: domesticGuestsList,
            });
          }
          var agendaData = exhbitionData.filter((item) => {
            return item.pid == 4 && item.pid == item.id;
          });
          var agendaList = [];
          if (agendaData[0].is_hidden == 1) {
            var agendaShow = exhbitionData.filter((item) => {
              return (
                [33, 34, 35, 36, 37, 38].indexOf(item.pid) != -1 &&
                item.is_hidden == 1 && item.config_type == "t"
              );
              // return [19, 20, 21, 22, 23].indexOf(item.pid) != -1 && item.pid == item.id && item.is_hidden == 1
            });
            console.log("agendaShow", agendaShow);
            if (agendaShow.length > 0) {
              agendaShow = this.sortFn(agendaShow, "id");
              agendaShow.forEach((x) => {
                let arr = exhbitionData.filter((item) => {
                  // return item.pid == x.id && item.id !== item.pid
                  return item.pid == x.pid && item.id !== item.pid;
                });
                arr = this.sortFn(arr);
                // arr = [x, ...arr]
                agendaList.push(arr);
              });
            }
            this.state.agendaListSplice = [...agendaList]
            agendaList.splice(1)
            console.log(this.state.agendaListSplice)
          }

          var sponsorList = exhbitionData.filter((item) => {
            return item.pid == 5 && item.pid !== item.id;
          });
          var sponsorData = exhbitionData.filter((item) => {
            return item.pid == 5 && item.pid == item.id;
          });
          var latestNewsData = exhbitionData.filter((item) => {
            return item.id == 7;
          });
          var samePeriodList = exhbitionData.filter((item) => {
            return item.pid == 8;
          });
          var cooperationUnitList = exhbitionData.filter((item) => {
            return item.pid == 9;
          });
          var cooperationMediaLsit = exhbitionData.filter((item) => {
            return item.pid == 10;
          });
          var designatedMediaList = exhbitionData.filter((item) => {
            return item.pid == 11;
          });
          var speechByLeaders = exhbitionData.filter((item) => {
            return item.pid == 32;
          });
          var speechByLeaders1 = []
          speechByLeaders.forEach((item) => {
            item.moContent = item.content.replace(/<\/?.+?>/g, "").replace(/ /g, "")
            speechByLeaders1.push(item)
          })
          speechByLeaders = speechByLeaders1
          var expertSpeech = exhbitionData.filter((item) => {
            return item.pid == 39;
          });
          var expertSpeech1 = []
          expertSpeech.forEach((item) => {
            item.moContent = item.content.replace(/<\/?.+?>/g, "").replace(/ /g, "")
            expertSpeech1.push(item)
          })
          expertSpeech = expertSpeech1
          var wonderfulActivities = exhbitionData.filter((item) => {
            return item.pid == 40;
          });
          var achievementShow = exhbitionData.filter((item) => {
            return item.pid == 41;
          });
          var guestVisit = exhbitionData.filter((item) => {
            return item.pid == 42;
          });
          var guestVisit1 = []
          guestVisit.forEach((item) => {
            //去掉文字的html标签
            item.moContent = item.content.replace(/<\/?.+?>/g, "").replace(/ /g, "")
            guestVisit1.push(item)
          })
          guestVisit = guestVisit1

          var elegantDemeanor = exhbitionData.filter((item) => {
            return item.pid == 43;
          });
          internationalGuestsList = this.sortFn(internationalGuestsList);
          domesticGuestsList = this.sortFn(domesticGuestsList);
          sponsorList = this.sortFn(sponsorList);
          samePeriodList = this.sortFn(samePeriodList);
          cooperationUnitList = this.sortFn(cooperationUnitList);
          cooperationMediaLsit = this.sortFn(cooperationMediaLsit);
          designatedMediaList = this.sortFn(designatedMediaList);
          speechByLeaders = this.sortFn(speechByLeaders);
          expertSpeech = this.sortFn(expertSpeech);
          this.state.expertSpeechSplice = expertSpeech.splice(4)
          wonderfulActivities = this.sortFn(wonderfulActivities);
          achievementShow = this.sortFn(achievementShow);
          guestVisit = this.sortFn(guestVisit);
          this.state.guestVisitSplice = guestVisit.splice(4)
          elegantDemeanor = this.sortFn(elegantDemeanor)
          console.log("图文直播--------", guestVisit);

          this.setState(
            {
              exhbitionData,
              introductionData,
              internationalGuestsList,
              domesticGuestsList,
              sponsorList,
              sponsorData,
              agendaList,
              agendaData,
              latestNewsData,
              samePeriodList,
              cooperationUnitList,
              cooperationMediaLsit,
              designatedMediaList,
              speechByLeaders,
              expertSpeech,
              wonderfulActivities,
              achievementShow,
              guestVisit,
              elegantDemeanor,
            },
            () => {
              // this.sponsorSwiper();
              // this.guestSwiper();
            }
          );
        } else {
          // message.error(res.msg);
        }
        this.setState({ loading: false });
      })
      .catch((res) => {
        this.setState({ loading: false });
      });
  };
  // 根据pid查询
  QueryExhbitionByPid = () => {
    var { isYear, exhbitorParams, offset, limit } = this.state;
    let params = {
      limit,
      offset,
      pid: 3,
    };
    this.setState({ loading: true });
    API.QueryExhbitionByPid(params)
      .then((res) => {
        return;
        if (res.status === 200) {
          var exhbitorData = res.data.list;
          this.setState({
            exhbitorData,
            total: res.data.total,
          });
        } else {
          // message.error(res.msg);
        }
        this.setState({ loading: false });
      })
      .catch((res) => {
        this.setState({ loading: false });
      });
  };
  // 排序
  sortFn = (arr, name) => {
    let name1 = name ? name : "sort"
    if (arr && arr.length > 1) {
      arr.sort(function (a, b) {
        if (a[name1] < b[name1]) {
          return -1;
        } else if (a[name1] == b[name1]) {
          return 0;
        } else {
          return 1;
        }
      });
    }
    return arr;
  };
  //嘉宾分类切换
  handleChangeGuest = (type) => {
    if (type == "international") {
      this.setState({
        guestList: this.state.internationalGuestsList,
        guestActive: "international",
      });
    } else {
      this.setState({
        guestList: this.state.domesticGuestsList,
        guestActive: "domestic",
      });
    }
  };
  guestSwiper = () => {
    const carousel_autoplay_time = 3000;
    const {
      carousel_autoplay,
      carousel_prev,
      carousel_next,
      clear_timeout,
    } = swiperPatch(this.slider, carousel_autoplay_time);
    this.setState({
      carousel_prev,
      carousel_next,
      guest_clear_timeout: clear_timeout,
    });
    // 启动轮播
    carousel_autoplay();
    // 离开清理定时器
    // return clear_timeout
  };
  sponsorSwiper = () => {
    const carousel_autoplay_time = 2500;
    const { carousel_autoplay, clear_timeout } = swiperPatch(
      this.sponsorCarousel,
      carousel_autoplay_time
    );
    this.setState({
      sponsor_clear_timeout: clear_timeout,
    });
    // 启动轮播
    carousel_autoplay();
    // 离开清理定时器
    // return clear_timeout
  };
  toExhibitor = () => {
    const { userInfo } = store.getState();
    console.log(userInfo);
    if (!userInfo || (userInfo && userInfo.vip_type_no.length <= 0)) {
      this.setState({ showModal: true });
    } else {
      document.documentElement.scrollTop = document.body.scrollTop =0;
      router.history.push("/exhibitor");
    }
  };
  // 查询是否是会员
  MineBuyVip = () => {
    PersonalCenterApi.MineBuyVip().then((res) => {
      console.log(res);
      if (res.status === 200) {
        if (moment().format("X") < res.data.end_time) {
          this.setState({ vipData: res.data, is_vip: "2" });
        } else {
          this.setState({ vipData: res.data, is_vip: "1" });
        }
      } else {
        this.setState({ is_vip: "1" });
      }
      this.setState({ menuKey: "1" });
    });
  };
  //查看详情
  seeDetails = (arrName, id) => {
    if (this.state.is_vip == "2") {
      // var list=[] 
      //   list=this.state[arrName].map((item)=>{
      //     if(item.id==id){
      //       item.more=1
      //     }
      //     return item
      //   })
    } else {
      this.setState({ showModal: true })
    }


  }

  render() {
    const {
      BrowserType,
      loading,
      exhbitionData,
      introductionData,
      guestList,
      internationalGuestsList,
      domesticGuestsList,
      guestActive,
      sponsorList,
      sponsorData,
      agendaList,
      latestNewsData,
      samePeriodList,
      carousel_prev,
      carousel_next,
      cooperationUnitList, //合作单位9
      cooperationMediaLsit, //合作媒体10
      designatedMediaList, //指定媒体
      isLoad,
      showModal,
      speechByLeaders,
      expertSpeech,
      wonderfulActivities,
      achievementShow,
      guestVisit,
      elegantDemeanor,
      is_vip,
      moreBtnShow,
    } = this.state;
    var md = 12;
    var isPC = true;
    var adMd = 8;
    if (BrowserType !== "PC") {
      md = 24;
      isPC = false;
    }
    //嘉宾
    let guestPageSize = isPC ? 10 : 6;
    var guestPageNum = Math.ceil(guestList.length / guestPageSize);
    let guestHtml = [];
    for (var i = 0; i < guestPageNum; i++) {
      guestHtml.push(
        <div key={i}>
          <div className={styles.swpiperItem}>
            <Row>
              {guestList.map((item, ind) => {
                return ind != 0 &&
                  ind <= (i + 1) * guestPageSize &&
                  ind > i * guestPageSize ? (
                    <Col
                      key={item.id}
                      span={isPC ? 4 : 7}
                      key={ind}
                      offset={ind % (guestPageSize / 2) == 0 ? 0 : 1}
                    >
                      <div className={styles.guestBox}>
                        <div className={styles.imgBox}>
                          <img
                            src={
                              item.pic.startsWith("http")
                                ? item.pic
                                : `${base.OssAddress}${item.pic}`
                            }
                            alt=""
                          />
                        </div>
                        <p className={styles.name}>{item.content_short}</p>
                        <p>{item.content}</p>
                      </div>
                    </Col>
                  ) : null;
              })}
            </Row>
          </div>
        </div>
      );
    }

    // 赞助商
    let sponsorPageSize = isPC ? 5 : 3;
    var sponsorPageNum = Math.ceil(sponsorList.length / sponsorPageSize);
    let sponsorHtml = [];
    for (var i = 0; i < sponsorPageNum; i++) {
      sponsorHtml.push(
        <div key={i}>
          <div className={styles.swpiperItem}>
            <Row>
              {sponsorList.map((item, ind) => {
                return ind < (i + 1) * sponsorPageSize &&
                  ind >= i * sponsorPageSize ? (
                    <Col
                      key={item.id}
                      span={isPC ? 4 : 7}
                      offset={ind % sponsorPageSize == 0 ? 0 : 1}
                    >
                      <div className={styles.sponsorItem}>
                        <div className={styles.imgBox}>
                          <img
                            src={
                              item.pic.startsWith("http")
                                ? item.pic
                                : `${base.OssAddress}${item.pic}`
                            }
                            alt=""
                          />
                        </div>
                        <p className={styles.name}>{item.content_short}</p>
                        {/* <p className={styles.col999}>{item.address}</p> */}
                      </div>
                    </Col>
                  ) : null;
              })}
            </Row>
          </div>
        </div>
      );
    }

    // 大会议程
    let agendaHtml = [];
    if (agendaList && agendaList.length > 0) {
      if (isPC) {
        agendaList.forEach((item, ind) => {
          let html = (
            <div key={ind} className={styles.agendaCon}>
              <div className={styles.header}>
                {item.map((x, y) => {

                  return (
                    x.config_type == "t" ? (<span>
                      <span>{x.category}</span>
                      <span className={styles.divider}>|</span>
                      <span>{x.content_short}</span>
                    </span>
                    ) : ""
                  )

                })}

              </div>

              <table className={styles.agendaList}>
                {item.map((x, y) => {
                  if (x.config_type !== "t" && x.is_hidden !== 2) {
                    return (
                      <tr key={x.id} className={styles.item}>
                        <td className={styles.left}>
                          <p className={styles.time}>
                            <img
                              src={require("../assets/image/icon_time.png")}
                              alt=""
                            />
                            <span>{x.category}</span>
                          </p>
                          <p className={styles.tit}>{x.content_short}</p>
                        </td>
                        <td className={styles.right}>{x.address}</td>
                      </tr>
                    );
                  }
                })}
              </table>
            </div>
          );
          agendaHtml.push(html);
        });
      } else {
        agendaList.forEach((item, ind) => {
          let html = (
            <div key={ind} className={styles.agendaCon}>
              <div className={styles.header}>
                {item.map((x, y) => {

                  return (
                    x.config_type == "t" ? (<span>
                      <span>{x.category}</span>
                      <span className={styles.divider}>|</span>
                      <span>{x.content_short}</span>
                    </span>
                    ) : ""
                  )

                })}
              </div>
              <Collapse
                className={styles.agendaList}
                expandIcon={({ isActive }) => (
                  <img
                    className={styles.jiantouIcon + " icon"}
                    src={
                      isActive
                        ? require("../assets/image/icon_up.png")
                        : require("../assets/image/icon_down.png")
                    }
                  />
                )}
                expandIconPosition="right"
              >
                {item.map((x, y) => {
                  if (x.config_type !== "t" && x.is_hidden !== 2) {
                    return (
                      <Panel
                        header={
                          <div className={styles.left}>
                            <p className={styles.time}>
                              <img
                                src={require("../assets/image/icon_time.png")}
                                alt=""
                              />
                              <span>{x.category}</span>
                            </p>
                            <p className={styles.tit}>{x.content_short}</p>
                          </div>
                        }
                        className={styles.item}
                        key={x.id}
                        disabled={x.address ? false : true}
                      >
                        <div className={styles.right}>{x.address}</div>
                      </Panel>
                    );
                  }
                })}
              </Collapse>
            </div>
          );
          agendaHtml.push(html);
        });
      }
    }
    const lunboSetting = {
      dots: true,
      lazyLoad: true,
    };

    //嘉宾
    let achievementPageSize = isPC ? 4 : 2;
    var achievementPageNum = Math.ceil(
      achievementShow.length / achievementPageSize
    );
    let achievementHtml = [];
    for (var i = 0; i < achievementPageNum; i++) {
      achievementHtml.push(
        <div key={i}>
          <div className={styles.swpiperItem}>
            <Row className={styles.rowBox}>
              {achievementShow.map((item, ind) => {
                return ind != 0 &&
                  ind <= (i + 1) * achievementPageSize &&
                  ind > i * achievementPageSize ? (
                    <Col span={isPC ? 6 : 12} key={ind}>
                      <div className={styles.achbox}>
                        <div className={styles.imgBox}>
                          <img
                            src={
                              item.pic.startsWith("http")
                                ? item.pic
                                : `${base.OssAddress}${item.pic}`
                            }
                            alt=""
                          />
                        </div>
                        <p className={styles.name}>{item.content_short}</p>
                      </div>
                    </Col>
                  ) : null;
              })}
            </Row>
          </div>
        </div>
      );
    }


    //嘉宾
    let achievementPageSize1 = isPC ? 4 : 2;
    var achievementPageNum1 = Math.ceil(
      elegantDemeanor.length / achievementPageSize1
    );
    let achievementHtml1 = [];
    for (var i = 0; i < achievementPageNum1; i++) {
      achievementHtml1.push(
        <div key={i}>
          <div className={styles.swpiperItem}>
            <Row className={styles.rowBox}>
              {elegantDemeanor.map((item, ind) => {
                return ind != 0 &&
                  ind <= (i + 1) * achievementPageSize1 &&
                  ind > i * achievementPageSize1 ? (
                    <Col span={isPC ? 6 : 12} key={ind}>
                      <div className={styles.achbox}>
                        <div className={styles.imgBox}>
                          <img
                            src={
                              item.pic.startsWith("http")
                                ? item.pic
                                : `${base.OssAddress}${item.pic}`
                            }
                            alt=""
                          />
                        </div>
                        <p className={styles.name}>{item.content_short}</p>
                      </div>
                    </Col>
                  ) : null;
              })}
            </Row>
          </div>
        </div>
      );
    }




    return (
      <div className={isPC ? styles.Content : styles.ContentMo}>
        <PageContent loading={loading}>
          {/* 大会介绍 */}
          <div id="introduction_mh_box" className={styles.introductionBox}>
            <div className={styles.container}>
              <div className={styles.aboutTxt}>
                <p className={styles.titleStyle + " " + styles.col000}>
                  大会介绍
                </p>
                {/* <p className={styles.titleEnStyle + " " + styles.col999}>  CONGRESS INFORMATION</p> */}
                <div
                  className={styles.TextContent}
                  ref={(ref) => {
                    this.TextContent = ref;
                  }}
                >
                  为贯彻落实民航局“科技兴安”战略要求，促进民航领域与安防专业领域的深度结合，在民航局业务主管部门大力支持下，
                  由中国民用机场协会、深圳市安全防范行业协会主办，深圳机场集团联合主办的2019（首届）全国民航安保科技展，于8月29-30日在深圳举行。
                  本届展会将全面展示近年来民航安保领域科技创新成果，搭建民航业与科技业沟通交流合作平台，为民航安全发展提供科技支撑，助力高质量发展。
                </div>
              </div>

              {/* <div className={styles.aboutInfo}>
                <div className={styles.item}>
                  <span className={styles.num}>
                    <div className={styles.con}>
                      <div
                        className={
                          isLoad ? styles.numN + " " + styles.num3 : styles.numN
                        }
                      ></div>
                      <div className={styles.numN + " " + styles.num0}></div>
                      <div className={styles.numN + " " + styles.num0}></div>
                      <div className={styles.plus}></div>
                    </div>
                  </span>
                  <div className={styles.des}>国内外演讲专家</div>
                </div>
                <div className={styles.item}>
                  <span className={styles.num}>
                    <div className={styles.con}>
                      <div
                        className={
                          isLoad ? styles.numN + " " + styles.num4 : styles.numN
                        }
                      ></div>
                      <div className={styles.numN + " " + styles.num0}></div>
                      <div className={styles.numN + " " + styles.num0}></div>
                      <div className={styles.plus}></div>
                    </div>
                  </span>
                  <div className={styles.des}>单位企业</div>
                </div>
                <div className={styles.item}>
                  <span className={styles.num}>
                    <div className={styles.con}>
                      <div
                        className={
                          isLoad ? styles.numN + " " + styles.num1 : styles.numN
                        }
                      ></div>
                      <div className={styles.numN + " " + styles.num0}></div>
                      <div className={styles.numN + " " + styles.num0}></div>
                      <div className={styles.plus}></div>
                    </div>
                  </span>
                  <div className={styles.des}>国家及地区</div>
                </div>
                <div className={styles.item}>
                  <span className={styles.num}>
                    <div className={styles.con}>
                      <div className={styles.numN + " " + styles.num1}></div>
                      <div
                        className={
                          isLoad ? styles.numN + " " + styles.numW : styles.numN
                        }
                      ></div>
                      <div className={styles.numN + " " + styles.numRight}></div>
                      <div className={styles.plus}></div>
                    </div>
                  </span>
                  <div className={styles.des}>参会人次</div>
                </div>
              </div> */}
            </div>
          </div>

          {/* 大会议程 */}
          {agendaList.length > 0 ? (
            <div id="agenda_mh_box" className={styles.agendaBox}>
              <div className={styles.container}>
                <p className={styles.titleStyle + " " + styles.col000}>
                  大会议程
                </p>
                {/* <p className={styles.titleEnStyle + " " + styles.col999}> CONFERENCE AGENDA</p> */}
                <div className={styles.hide} ref="hide">
                  {agendaHtml}
                  {moreBtnShow.agenda ? (
                    <div
                      className={styles.agendaShow}
                      onClick={() => {
                        this.state.moreBtnShow.agenda = false
                        this.setState({
                          agendaList: [...this.state.agendaListSplice],
                          moreBtnShow: this.state.moreBtnShow,
                        })
                      }}
                    >
                      <span>点击展开</span>
                      <Icon type="caret-down" />
                    </div>
                  ) : null}

                </div>
              </div>
            </div>
          ) : null}

          {/* 领导致辞 */}
          {speechByLeaders.length > 0 ? (
            <div id="guests_mh_box" className={isPC ? styles.speakWrap : styles.speakWrapMO}>
              <p className={styles.titleStyle + " " + styles.col000}>
                领导致辞
              </p>
              {/* <p className={styles.titleEnStyle + " " + styles.col000}>Speech by leaders</p> */}
              <div className={styles.speak}>
                {speechByLeaders.map((item, i) => {
                  return (
                    <Row key={item.id} gutter={10} style={isPC ? { marginBottom: "40px" } : { marginBottom: "20px" }}>
                      <Col span={isPC ? 4 : 7}>
                        <img
                          src={
                            item.pic.startsWith("http")
                              ? item.pic
                              : `${base.OssAddress + item.pic}`
                          }
                          alt=""
                        />
                      </Col>
                      <Col span={isPC ? 20 : 17}>
                        <div className={styles.marginBot}>
                          <div className={styles.spTitle}>
                            <span className={styles.spSpan1}>{item.address}</span>
                            <span className={styles.spSpan2}>
                              &nbsp;&nbsp;{item.content_short}
                            </span>
                            {isPC ? (<div className={styles.spSpan3} onClick={() => {
                              if (is_vip == 2) {
                                sessionStorage.setItem("data", JSON.stringify({
                                  isPC: isPC,
                                  item: item,
                                }));
                                console.log("item", item);
                                window.open('/#/exhibitionHomedetails', '_blank')
                              } else {
                                this.setState({ showModal: true })
                              }
                            }}>查看详情</div>) : ''}
                            <Modal
                              visible={showModal}
                              onOk={() => { router.history.push('/register1') }}
                              onCancel={() => { this.setState({ showModal: false }) }}
                              okText="确认"
                              cancelText="取消"
                            >
                              <p style={{ padding: "20px 0" }}>只有vip用户才可查看</p>
                            </Modal>
                            {/* {isPC ? (<Link to="/exhibitionHomedetails">查看详情link</Link>) :'' } */}
                          </div>

                          <div
                            onClick={(e) => {
                              if (!isPC && e.target.nodeName != 'SPAN') {
                                return false
                              }
                              if (is_vip == 2) {
                                sessionStorage.setItem("data", JSON.stringify({
                                  isPC: isPC,
                                  item: item,
                                }));
                                window.open('/#/exhibitionHomedetails', '_blank')
                              } else {
                                this.setState({ showModal: true })
                              }
                            }}
                            className={styles.content}
                            dangerouslySetInnerHTML={{ __html: isPC ? item.content : item.moContent.slice(0, 25) + `...<span class=${styles.spSpan3}>查看详情</span></p>` }}
                          ></div>
                        </div>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            </div>
          ) : null}

          {/* 专家演讲 */}
          {expertSpeech.length > 0 ? (
            <div id="sponsor_mh_box" className={styles.expertSpeech}>
              <div className={isPC ? styles.speakWrap : styles.speakWrapMO} >
                <p className={styles.titleStyle + " " + styles.col000}>
                  专家演讲
                </p>
                {/* <p className={styles.titleEnStyle + " " + styles.col999}>Expert speech </p> */}
                <div className={styles.speak} ref="expertSpeech">
                  {expertSpeech.map((item, i) => {
                    return (
                      <Row key={item.id} gutter={10} style={isPC ? { marginBottom: "40px" } : { marginBottom: "20px" }}>
                        <Col span={isPC ? 4 : 7}>
                          <img
                            src={
                              item.pic.startsWith("http")
                                ? item.pic
                                : `${base.OssAddress + item.pic}`
                            }
                            alt=""
                          />
                        </Col>
                        <Col span={isPC ? 20 : 17}>
                          <div className={styles.marginBot}>
                            <div className={styles.spTitle}>
                              {isPC ?
                                (<span className={styles.spSpan}>
                                  {item.category}
                                </span>)
                                : ''}


                              <span className={styles.spSpan1}>
                                {item.address}
                              </span>

                              {!isPC ?
                                (<span className={styles.spSpan}>
                                  {item.category}
                                </span>)
                                : ''}

                              <span className={styles.spSpan2}>
                                &nbsp;&nbsp;{item.content_short}
                              </span>
                              {isPC ? <div className={styles.spSpan3} onClick={() => {
                                if (is_vip == 2) {
                                  sessionStorage.setItem("data", JSON.stringify({
                                    isPC: isPC,
                                    item: item,
                                  }));
                                  window.open('/#/exhibitionHomedetails', '_blank')
                                } else {
                                  this.setState({ showModal: true })
                                }
                              }}
                              >查看详情</div> : ''}

                            </div>
                            <div
                              onClick={(e) => {
                                if (!isPC && e.target.nodeName != 'SPAN') {
                                  return false
                                }
                                if (is_vip == 2) {
                                  sessionStorage.setItem("data", JSON.stringify({
                                    isPC: isPC,
                                    item: item,
                                  }));
                                  window.open('/#/exhibitionHomedetails', '_blank')
                                } else {
                                  this.setState({ showModal: true })
                                }
                              }}
                              className={styles.content}
                              dangerouslySetInnerHTML={{ __html: isPC ? item.content : item.moContent.slice(0, 25) + `...<span class=${styles.spSpan3} >查看详情</span></p>` }}
                            ></div>
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                  {
                    moreBtnShow.expert ? (
                      <div
                        className={styles.open}
                        onClick={() => {
                          if (expertSpeech.length != 4) {
                            return
                          }
                          this.state.moreBtnShow.expert = false
                          this.setState({
                            expertSpeech: [...expertSpeech, ...this.state.expertSpeechSplice],
                            moreBtnShow: this.state.moreBtnShow,
                          })
                        }}
                      >
                        <span >点击展开</span>
                        <Icon type="caret-down" />
                      </div>
                    ) : null
                  }

                </div>
              </div>
            </div>
          ) : null}

          {/* 图文直播 */}
          {guestVisit.length > 0 ? (
            <div id="samePeriod_mh_box" className={styles.guesBox}>
              <div className={styles.speakWrap}>
                <p className={styles.titleStyle + " " + styles.col000}>
                  图文直播
                </p>
                {/* <p className={styles.titleEnStyle + " " + styles.col000}>Exclusive interview with guests</p> */}
                <div className={styles.speak} ref="hide1">
                  {guestVisit.map((item, i) => {
                    return (
                      <Row key={item.id} gutter={10} style={isPC ? { marginBottom: "40px" } : { marginBottom: "20px" }}>
                        <Col span={isPC ? 4 : 7}>
                          <img
                            src={
                              item.pic.startsWith("http")
                                ? item.pic
                                : `${base.OssAddress + item.pic}`
                            }
                            alt=""
                          />
                        </Col>
                        <Col span={isPC ? 20 : 17}>
                          <div className={styles.marginBot}>
                            <div className={styles.spTitle}>
                              <span className={styles.spSpan1}>
                                {item.address}
                              </span>
                              <span className={styles.spSpan2}>
                                &nbsp;&nbsp;{item.content_short}
                              </span>
                              {isPC ? (<div className={styles.spSpan3} onClick={() => {
                                if (is_vip == 2) {
                                  sessionStorage.setItem("data", JSON.stringify({
                                    isPC: isPC,
                                    item: item,
                                  }));
                                  window.open('/#/exhibitionHomedetails', '_blank')
                                } else {
                                  this.setState({ showModal: true })
                                }
                              }}>查看详情</div>) : ''}

                            </div>
                            <div
                              onClick={(e) => {
                                if (!isPC && e.target.nodeName != 'SPAN') {
                                  return false
                                }
                                if (is_vip == 2) {
                                  sessionStorage.setItem("data", JSON.stringify({
                                    isPC: isPC,
                                    item: item,
                                  }));
                                  window.open('/#/exhibitionHomedetails', '_blank')
                                } else {
                                  this.setState({ showModal: true })
                                }
                              }}
                              className={styles.content}
                              dangerouslySetInnerHTML={{ __html: isPC ? item.content : item.moContent.slice(0, 25) + `...<span class=${styles.spSpan3}>查看详情</span></p>` }}
                            ></div>
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                  {
                    moreBtnShow.guest ? (
                      <div className={styles.agendaShow}
                        onClick={() => {
                          if (guestVisit.length != 4) {
                            return
                          }
                          this.state.moreBtnShow.guest = false
                          this.setState({
                            guestVisit: [...guestVisit, ...this.state.guestVisitSplice],
                            moreBtnShow: this.state.moreBtnShow,
                          })
                        }}
                      >
                        <span >加载更多</span>
                        <Icon type="caret-down" />
                      </div>) : null

                  }

                </div>
              </div>
            </div>
          ) : null}

          {/* 专家聘任 */}
          {/* {wonderfulActivities.length > 0 ? (
            <div id="latestNews_mh_box" className={styles.wonderfulActivities}>
              <p className={styles.title}>专家聘任</p>
              <p className={styles.titleEnStyle + " " + styles.col999}>Wonderful activities</p>
              <div className={styles.wACenter}>
                {wonderfulActivities.map((item) => {
                  return (
                    <div className={styles.wASertion} key={item.id}>
                      {item.category && item.category !== "0" ? (
                        <div className={styles.titleStyle + " " + styles.col000}>{item.category}</div>
                      ) : null}
                      <div
                        ref='imgHeightChange'
                        id={item.category == "新媒体联盟"
                          ? "newMedia_mh_box"
                          : ""}
                        className={
                          item.category == "新媒体联盟"
                            ? styles.addPadding
                            : styles.image
                        }
                        style={item.address && item.address !== "0" ? { paddingBottom: "0px" } : {}}
                      >
                        <img
                          src={
                            item.pic.startsWith("http")
                              ? item.pic
                              : `${base.OssAddress}${item.pic}`
                          }
                          alt=""
                        />
                        {
                          item.category == "新媒体联盟" ? (
                            <div className={styles.more}>
                              <span onClick={() => {
                                this.refs.imgHeightChange.style.height = 'auto'
                              }}>查看更多</span>
                            </div>
                          ) : ''
                        }

                      </div>

                      {item.address && item.address !== "0" ? (
                        <div className={styles.footerText}>{item.address}</div>
                      ) : null}

                    </div>
                  );
                })}

              </div>
            </div>
          ) : null} */}

          {/* 专家聘任 */}
          {wonderfulActivities.length > 0 ? (
            wonderfulActivities.map((item) => {
              return (
                <div id={item.category == "新媒体联盟"
                  ? "newMedia_mh_box"
                  : "latestNews_mh_box"} className={styles.wonderfulActivities}>
                  <p className={styles.titleStyle + " " + styles.col000}>{item.category}</p>
                  <div className={styles.wACenter}>
                    <div className={styles.wASertion} key={item.id}>
                      <div
                        ref='imgHeightChange'
                        className={item.category == "新媒体联盟" ? styles.addPadding : styles.image}
                        style={item.address && item.address !== "0" ? { paddingBottom: "0px" } : {}}
                      >
                        <img
                          src={item.pic.startsWith("http") ? item.pic : `${base.OssAddress}${item.pic}`}
                          alt=""
                        />
                        {
                          item.category == "新媒体联盟" ? (
                            <div className={styles.more}>
                              <span onClick={() => {
                                this.refs.imgHeightChange.style.height = 'auto'
                              }}>查看更多</span>
                            </div>
                          ) : ''
                        }

                      </div>
                      {item.address && item.address !== "0" ? (
                        <div className={styles.footerText}>{item.address}</div>
                      ) : null}

                    </div>
                  </div>
                </div>
              )
            })) : null}

          {/* 展会风采 */}
          {elegantDemeanor.length > 0 ? (
            <div id="address_mh_box" className={styles.chievementShowFC}>
              <p className={styles.titleStyle + " " + styles.col000}>展会风采</p>
              {/* <p className={styles.titleEnStyle + " " + styles.col000}>Exclusive interview with guests</p> */}
              <div className={styles.swiper}>
                <Icon
                  type="left"
                  className={styles.left}
                  onClick={() => {
                    this.refs.swip1.prev();
                  }}
                />
                <Icon
                  type="right"
                  className={styles.right}
                  onClick={() => {
                    this.refs.swip1.next();
                  }}
                />
                <Carousel
                  ref="swip1"
                  autoplay
                  className={styles.carSwiper}
                  beforeChange={(a, s) => {
                    this.refs.currentpage1.innerText = s + 1 + '/' + Math.ceil(elegantDemeanor.length / (isPC ? 4 : 2))
                    // console.log(a, s);
                  }}
                >
                  {achievementHtml1}
                </Carousel>
                <div className={styles.swipPage} ref="currentpage1">1/{Math.ceil(elegantDemeanor.length / (isPC ? 4 : 2))}</div>
              </div>
            </div>
          ) : null}
          {/* 行业成果展示 */}
          {achievementShow.length > 0 ? (
            <div id="chievementShow_mh_box" className={styles.chievementShow}>
              <div className={styles.titleStyle + " " + styles.col000}>行业成果展示</div>
              {/* <div className={styles.title}>行业成果展示</div> */}
              <div className={styles.swiper}>
                <Icon
                  type="left"
                  className={styles.left}
                  onClick={() => {
                    this.refs.swip.prev();
                  }}
                />
                <Icon
                  type="right"
                  className={styles.right}
                  onClick={() => {
                    this.refs.swip.next();
                  }}
                />
                <Carousel
                  ref="swip"
                  autoplay
                  beforeChange={(a, s) => {
                    this.refs.currentpage.innerText = s + 1 + '/' + Math.ceil(achievementShow.length / (isPC ? 4 : 2))
                  }}
                >
                  {achievementHtml}
                </Carousel>
                <div className={styles.swipPage} ref='currentpage'>1/{Math.ceil(achievementShow.length / 4)}</div>
              </div>
            </div>
          ) : null}
        </PageContent>
      </div>
    );
  }
}

export default withRouter(Content);
