//home.js
import React, { Component } from 'react';
import styles from './home.module.less';
// import API from '../../api/api';
import PageHeader from '../components/pageHeaderOld';
import HomeContent from './content';
import Content from './newscontent';
import PageFooter from '../components/pageFooterOld';
import { Router, Route } from 'react-router';
export default class Home extends Component {
  render() {
    return (
      <div className={styles.HomeBox}>
        <PageHeader isHome={true}/>
        <HomeContent />
        {/* <Router>
          <Route path="/exhibitionHomedetails" component={Content}/>
        </Router> */}
        <PageFooter colorE9f1fc={false} />
      </div>
    )
  }

}
