import React, { Component } from "react";
import { Row, Col, Skeleton, Carousel, Icon } from "antd";
import styles from "./index.module.less";
import Swiper from "@/src/components/Carousel/swiper";
import TopicItem from "@/src/components/TopicItem";
import ImgCard from "@/src/components/ImgCard1";
import store from "@/src/redux/store.js";
import API from "@/src/api/Home";
import { tools } from "@/src/lib/tools";
import { setAdvertisement } from "@/src/redux/actions/Advertisement";
import { base } from "@/src/api/base";
import VideosApi from "@/src/api/VideosApi";
import AdAround from "@/src/components/AdAround";
import { setAdAroundR, setAdAroundL } from "@/src/redux/actions/AdAround";
import { HashRouter } from 'react-router-dom'
import { setBreadcrumbList } from "@/src/redux/actions/BreadcrumbList";
const router = new HashRouter()
class Content extends Component {
  state = {
    name: "",
    title_bar_no: "",
    TopicItemCss: "",
    SwiperDataList: [],
    MiddlePic: "",
    AdvertisementData: [],
    TwoTitleList: [],
    BrowserType: "PC",
    adAroundRList: [], //右侧广告
    adAroundLList: [], //左侧广告
    expertTypes: [], //专家类型
    onlyOnce: true
  };

  constructor(props) {
    super(props);
    this.state.title_bar_no = props.title_bar_no;
    this.state.name = props.name;
    this.state.path = props.path;
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(this.ListenStore.bind(this))
    window.addEventListener("resize", this.resizeBind);
    this.ListenStore()
    this.resizeBind();
    this.GetSwiper();
    this.setState({
      TwoTitleList: this.state.TwoTitleList,
    });

    if (this.state.TwoTitleList.length === 0) {
      this.ListenStore()
    }
  }

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.TopicItemCss !== "") {
        this.setState({
          TopicItemCss: "",
        });
      }
      if (this.state.BrowserType !== "Mobile") {
        this.setState({
          BrowserType: "Mobile",
        });
      }
    } else {
      if (!this.state.TopicItemCss !== "TopicItem") {
        this.setState({
          TopicItemCss: "TopicItem",
        });
      }
      if (!this.state.BrowserType !== "PC") {
        this.setState({
          BrowserType: "PC",
        });
      }
    }
  };

  ListenStore = () => {
    if (!this.state.onlyOnce) return
    let { TdTitleBar } = store.getState()

    if (!TdTitleBar || TdTitleBar === {} || TdTitleBar.length === undefined || TdTitleBar.length === 0) {
      return
    }
    let oneTitleList = TdTitleBar.filter(item => {
      return item.level === 2 && item.show_status === 2
    })

    let TwoTitleList = oneTitleList.filter(item => {
      return item.parent_id === this.state.title_bar_no
    })

    // 添加一个置顶的
    TwoTitleList = [{
      id: 0,
      title_bar_no: this.state.title_bar_no,
      parent_id: '',
      name: "",
      level: 2,
      sort: 1,
      nav_status: 2,
      show_status: 2,
      ShowHeaderTitle: false,
      data: [

      ]
    }, ...TwoTitleList]

    this.setState({
      TwoTitleList: TwoTitleList,
    })
    //查询专家类型
    this.QueryExpertTypes();
    // 查询文章
    this.QueryArticle(TwoTitleList);
    this.setState({
      onlyOnce: false
    });
  }

  QueryArticle = (_TwoTitleList) => {
    // let _TwoTitleList = this.state.TwoTitleList
    if (!_TwoTitleList || _TwoTitleList.length === 0) {
      return;
    }
    _TwoTitleList.map((item, index) => {
      // 文章
      let params = { title_bar_no: item.title_bar_no, offset: 0, limit: 8 };
      if (item.parent_id === "1044") {
        params.limit = 5;
      }
        API.QueryArticle(params).then((res) => {
          if (res.status === 200) {
            item.data = res.data.list.map((item2, index) => {
              item2.id =
                item.title_bar_no + item2.id + "-" + tools.random(10000, 99999);
              if (
                index === 0 &&
                item.title_bar_no === this.state.title_bar_no
              ) {
                item2.BoldTitle = true;
                item2.showPic = false;
              }
              item2.title_bar_no = item.title_bar_no;

              return item2;
            });
            item.loading = false;
          } else {
            item.loading = false;
          }


          _TwoTitleList[index] = item;
          this.setState({
            TwoTitleList: _TwoTitleList,
          });
          //        console.log( "aaaaaaaaaaa",this.state.TwoTitleList);
          // if(this.state.TwoTitleList && this.state.TwoTitleList.length>0){
          //   this.state.TwoTitleList[1].name="最新资讯"
          //   this.state.TwoTitleList[2].name="消防安全"
          //   this.state.TwoTitleList[3].name="消防培训"
          // }
        });
      
      return item;
    });
  };

  QueryExpertTypes = () => {
    let _params = {
      limit: 10,
      offset: 0,
    };

    VideosApi.QueryVideosList(_params).then((res) => {
      console.log(res, '---------------------')
      // 专家介绍，右侧
      if (res.status === 200) {
        let dataSource1 = res.data.list;
        if (!dataSource1) {
          return;
        }
        this.setState({
          expertTypes: res.data.list,
        });
        // let data = this.state.right.data || [];
        // data = data.concat(dataSource1)
        // this.setState({right: { data, offset: res.data.offset,limit:10, total: res.data.total }, loading: false });
      }
    });
  };
  componentWillUnmount() {
    // 对 store 变化取消监听
    // this.unsubscribe();
    window.removeEventListener("resize", this.resizeBind);
  }
  MoreArticles = () => {
    store.dispatch(
      setBreadcrumbList([
        {
          id: "1",
          name: '消防应急救援',
          path: "/FireFighting1044/1044",
        },
        {
          id: "2",
          name: '更多',
          path: "/MoreArticles1044/0",
        },
      ])
    );
    window.open(`${base.localhost}/#/MoreArticles1044/0`, "_blank");
  }
  GetSwiper = () => {
    let params = {
      title_bar_no: this.state.title_bar_no,
      offset: 0,
      limit: 50,
    };
    API.QueryAdvertise(params).then((res) => {
      console.log(res)
      //广告
      if (res.status === 200) {
        let SwiperDataList = [];
        let AdvertisementData = [];
        let MiddlePic = "";
        res.data.list.forEach((element) => {
          switch (element.advertise_location) {
            case 1:
              SwiperDataList = [...SwiperDataList, element];
              break;

            case 3:
              MiddlePic = element.pic;
              break;

            case 2:
              AdvertisementData = [...AdvertisementData, element];
              break;

            default:
              break;
          }
        });
        this.setState({
          SwiperDataList,
          MiddlePic,
          AdvertisementData,
        });
        store.dispatch(setAdvertisement(AdvertisementData));
      } else {
      }
    });
    let params1 = {
      title_bar_no: "lrAd1044",
      offset: 0,
      limit: 8,
    };
    API.QueryAdvertise(params1).then((res) => {
      console.log(res)
      // 轮播图
      if (res.status === 200) {
        let adAroundRList = [];
        let adAroundLList = [];
        res.data.list.forEach((element) => {
          switch (element.advertise_location) {
            case 2:
              adAroundRList = [...adAroundRList, element];
              break;

            case 4:
              adAroundLList = [...adAroundLList, element];
              break;

            default:
              break;
          }
        });
        store.dispatch(setAdAroundR(adAroundRList));
        store.dispatch(setAdAroundL(adAroundLList));
        this.setState({
          adAroundRList,
          adAroundLList,
        });
      } else {
      }
    });
  };

  render() {
    const { AdvertisementData, BrowserType, SwiperDataList } = this.state;
    console.log(this.state.TwoTitleList);
    let videoUrl
    if (SwiperDataList && SwiperDataList.length > 0) {
      videoUrl = base.OssAddress + SwiperDataList[0].pic
      var dom = `<div class="media-wrap video-wrap"><video 
              style="media-wrap video-wrap"
              title={${videoUrl}}
              poster="http://admin.cacps.cn/AddArticle59" 
              src="${videoUrl}"
              controls="" 
              preload="auto" 
              x-webkit-airplay="true" 
              x5-video-player-fullscreen="false" 
              object-fit="cover" 
              webkit-playsinline="true" 
              x5-video-player-type="h5"
              x5-video-orientation="portraint" 
              playsinline="true" 
              x5-playsinline="" 
              controlslist="nodownload" 
              ></video></div>`;
    } else if (SwiperDataList && SwiperDataList.length > 1) {
      // var dom = 
    }

    // title="https://hanganyun.oss-cn-beijing.aliyuncs.com/minhang/content/ArticleLib/94664caadc8dcff09a5f1133dd27f9ea.mp4" 
    var dom = `<div class="media-wrap video-wrap"><video 
              style="media-wrap video-wrap"
              title={${videoUrl}}
              poster="http://admin.cacps.cn/AddArticle59" 
              src="${videoUrl}"
              controls="" 
              preload="auto" 
              x-webkit-airplay="true" 
              x5-video-player-fullscreen="false" 
              object-fit="cover" 
              webkit-playsinline="true" 
              x5-video-player-type="h5"
              x5-video-orientation="portraint" 
              playsinline="true" 
              x5-playsinline="" 
              controlslist="nodownload" 
              ></video></div>`;

    // `<video
    // controls
    // webkit-playsinline="true"
    // x5-video-player-type="h5"
    // x5-video-orientation="portraint"
    // x5-video-player-fullscreen="true"
    // style="position:absolute;z-index:1"
    // title="https://hanganyun.oss-cn-beijing.aliyuncs.com/minhang/content/ArticleLib/94664caadc8dcff09a5f1133dd27f9ea.mp4"
    // poster="http://admin.cacps.cn/AddArticle59"
    // src="https://hanganyun.oss-cn-beijing.aliyuncs.com/minhang/content/ArticleLib/94664caadc8dcff09a5f1133dd27f9ea.mp4"></video>`
    var adMd = 8;
    if (BrowserType !== "PC") adMd = 24;
    return (
      <div>
        <Row justify="space-between" type="flex">
          <div
            className={
              BrowserType !== "PC" ? styles.FirstDivMo : styles.FirstDiv
            }
          >
            <div className={styles.FirstBox}>
              {
                <Col
                  xs={24}
                  sm={24}
                  md={11}
                  style={BrowserType == "PC" ? {} : { padding: "0 16px" }}
                  className={styles.leftSwiper}
                >

                  {SwiperDataList.length > 1 ? (
                    <Swiper autoplay SwiperDataList={this.state.SwiperDataList} textPosition={true} isTextShow={true} />
                  ) : (<div
                    className={styles.TextContent}
                    dangerouslySetInnerHTML={{ __html: dom }}
                  ></div>)}
                </Col>
              }
              {this.state.TwoTitleList && this.state.TwoTitleList[0] && this.state.TwoTitleList[0].data.length > 0 ? (
                <Col
                  key={this.state.TwoTitleList[0].id + "aaa"}
                  className={styles.CardOne}
                  xs={24}
                  sm={24}
                  md={
                    (this.state.TwoTitleList[0].SwiperDataList &&
                      this.state.TwoTitleList[0].SwiperDataList.length > 0) ||
                      (this.state.TwoTitleList[0].ExpertList &&
                        this.state.TwoTitleList[0].ExpertList.length > 0)
                      ? 12
                      : 12
                  }
                >
                  <TopicItem
                    name={this.state.name}
                    title={this.state.TwoTitleList[0].name}
                    data={this.state.TwoTitleList[0].data}
                    SwiperDataList={this.state.TwoTitleList[0].SwiperDataList}
                    ShowHeaderTitle={this.state.TwoTitleList[0].ShowHeaderTitle}
                    showNum="7"
                    loading={this.state.TwoTitleList[0].loading}
                    ExpertList={this.state.TwoTitleList[0].ExpertList}
                    title_bar_no={this.state.TwoTitleList[0].title_bar_no}
                  />
                </Col>
              ) : (<Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '400px', fontSize: '25px', color: '#333' }}>
                {/* 加载中 */}
              </Col>)}
            </div>
            {
              <div className={styles.moreBox}>
                <div
                  className={styles.headerTitleMoreBtn}
                  onClick={() => { this.MoreArticles(this.props.title_bar_no); }}>
                  <span>MORE </span>
                  <Icon type="arrow-right" />
                </div>
              </div>
            }
          </div>
          <div className={BrowserType == "PC" ? styles.ModdleBox : styles.ModdleBoxMo}>
            <Col
              xs={24}
              sm={24}
              md={AdvertisementData && AdvertisementData.length > 0 ? 24 : 24}
              style={ BrowserType == "PC" ? {padding: "2rem 0 5rem"} : {padding: "2rem 0"} }
            >
              <Row align="top" type="flex" gutter={16}>
                {this.state.TwoTitleList
                  ? this.state.TwoTitleList.map((item, index) => {
                    return index > 0 ? (
                      <Col
                        key={item.id}
                        className={this.state.TopicItemCss}
                        xs={24}
                        sm={24}
                        md={
                          (item.SwiperDataList &&
                            item.SwiperDataList.length > 0) ||
                            (item.ExpertList && item.ExpertList.length > 0)
                            ? 11
                            : 11
                        }
                      >
                        <TopicItem
                          name={this.state.name}
                          title={item.name}
                          data={item.data}
                          SwiperDataList={item.SwiperDataList}
                          ShowHeaderTitle={item.ShowHeaderTitle}
                          showNum={item.showNum}
                          loading={item.loading}
                          ExpertList={item.ExpertList}
                          title_bar_no={item.title_bar_no}
                        />
                      </Col>
                    ) : null;
                  })
                  : null}
              </Row>
            </Col>

            {/* <Col className={styles.AdvertisementDataBox} xs={0} sm={0} md={AdvertisementData && AdvertisementData.length > 0 ? 5 : 0} >
              {
                AdvertisementData.map((item, index) => {
                  return (
                    <a href={item.url} key={item.id + index + 'A'} >
                      <div className={styles.AdvertisementData}>
                        <div>
                          <img src={`${base.OssAddress}${item.pic}`} alt={item.name} />
                        </div>
                        <div className={styles.Text}>
                          <div>
                            {item.name}
                          </div>
                        </div>
                      </div>
                    </a>

                  )
                })
              }

            </Col> */}
          </div>

          {/* {
            this.state.SwiperDataList.length > 0 ?
              <Col className={this.state.TopicItemCss} xs={24} sm={24} md={12} >
                <Swiper autoplay SwiperDataList={this.state.SwiperDataList} />
              </Col>
              : null
          }


          {this.state.TwoTitleList ? this.state.TwoTitleList.map((item) => {
            return (
              item.title_bar_no !== "1030" && item.title_bar_no !== "1033" ?
                <Col key={item.id} className={this.state.TopicItemCss} xs={24} sm={24} md={(item.SwiperDataList && item.SwiperDataList.length > 0) || (item.ExpertList && item.ExpertList.length > 0) ? 24 : 12} >
                  <TopicItem name={this.state.name} title={item.name} data={item.data} SwiperDataList={item.SwiperDataList} ShowHeaderTitle={item.ShowHeaderTitle} showNum={item.showNum} loading={item.loading} ExpertList={item.ExpertList} title_bar_no={item.title_bar_no} />
                </Col>
                : <Col key={item.id} xs={24} sm={24} md={24} className={item.title_bar_no === "1030" ? this.state.TopicItemCss : ''} >
                  {
                    item.title_bar_no !== "1030" ?
                      <ImgCard title={item.name} dots={false} loading={item.loading} data={item.data} title_bar_no={item.title_bar_no} name={this.state.name} />
                      : <TopicItem name={this.state.name} title={item.name} data={item.data} SwiperDataList={item.SwiperDataList} ShowHeaderTitle={item.ShowHeaderTitle} showNum={item.showNum} loading={item.loading} ExpertList={item.ExpertList} title_bar_no={item.title_bar_no} />
                  }
                </Col>

            )
          }) : null} */}
        </Row>
      </div>
    );
  }
}

export default Content;
