import Server from './server'

class UploadAxios extends Server{

  // oss token
  GetOsstoken(data) {
    
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/get_osstoken', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

}

export default new UploadAxios()