import React, { Component } from 'react';
import { Upload, Button, Icon, notification, } from 'antd';
import PropTypes from 'prop-types';
// import { imgPreview } from '@/src/lib/utils/image-utils.js';
import MD5 from 'crypto-js/md5';
import API from '@/src/api/api';
import { base } from '@/src/api/base';
import Axios from 'axios';

/**
 * 上传图片到阿里云oss
 */

export default class AliOssUpload extends Component {
    constructor(props) {
        super(props);
        if (this.props.onUploadRef) {
            this.props.onUploadRef(this)
        }
    }
    static propTypes = {
        UploadCallBack: PropTypes.func,
        folder: PropTypes.string,
    };

    static defaultProps = {
        folder: '',
    }

    state = {
        OSSData: { host: '' },
        ImgFileState: false,
        update_file: null,
        file_name: '',
        file_list: [],
        ParamsFormData: {}
    };

    componentDidMount() {

        this.init()
    }

    componentWillReceiveProps() {

        let { fileList } = this.props

        if (fileList && fileList.length > 0) {
            this.setState({ file_list: fileList })
        }
    }

    init = async () => {
        this.GetOSSData(OSSData => {
            if (OSSData === null) {
                return
            }

            this.setState({
                OSSData,
            });
        });
    };

    GetOSSData = (callback) => {
        API.GetOssToken({}).then((res) => {
            if (res.status === 200) {
                // return {
                //     dir: 'user-dir/',
                //     expire: '1577811661',
                //     host: '//www.mocky.io/v2/5cc8019d300000980a055e76',
                //     accessId: 'c2hhb2RhaG9uZw==',
                //     policy: 'eGl4aWhhaGFrdWt1ZGFkYQ==',
                //     signature: 'ZGFob25nc2hhbw==',
                // };
                callback(res.data);
            } else {
                notification.error({
                    message: '失败！',
                    description: res.msg,
                });
            };
            callback(null)
        }).catch(() => {
            notification.error({
                message: '失败！',
                description: "网络超时！",
            });

        });

    };

    // 检查图片压缩等工作是否准备完毕 生成图片名称
    CheckBeforeUploadingPictures() {
        if (this.state.ImgFileState) {
            notification.warning({
                message: '提示！',
                description: "图片正在压缩中，请稍后提交！",
            });
            return false;
        }

        if (this.state.file_list.length === 0) {
            notification.warning({
                message: '提示！',
                description: "请选择上传图片！",
            });
            return false;
        }

        let file = this.state.file
        let filename = MD5(file.name + file.uid);
        let fileType = file.name.split(".");
        fileType = `.${fileType[fileType.length - 1]}`;
        filename += fileType;
        const ImgkeyName = `${this.props.folder}/${filename}`;
        this.setState({ file_name: ImgkeyName })

        return ImgkeyName
    }

    // 用户选取图片 将图片进行压缩
    onChange(e) {

        if (this.props.GetImgList !== undefined) {
            this.props.GetImgList(e)
        }

        this.setState({ file_list: e.fileList }, () => {
            this.transformFile(e.file)
            this.getExtraData(e.file)
        })

        // if (e.fileList.length !== this.state.file_list.length) {
        //     this.setState({ file_list: e.fileList })
        //     return
        // }

        // let file = e.file
        // this.setState({ file: file, ImgFileState: true, file_list: e.fileList })
        // imgPreview(file, (file) => {
        //     this.setState({ update_file: file, ImgFileState: false })
        // })

    }

    beforeUpload = () => {

        // const { OSSData } = this.state;
        // const expire = OSSData.expire * 1000;

        // if (expire < Date.now()) {
        //     await this.init();
        // }

        if (this.props.autoUpload !== undefined) {
            if (!this.props.autoUpload) {
                return false
            }
        }

        return true;
    };

    transformFile = async file => {

        // this.setState({ ImgFileState: false })
        // await imgPreview(file, (file) => {

        //     return file;
        // })

        const { OSSData, file_list } = this.state;

        const suffix = file.name.slice(file.name.lastIndexOf('.'));
        const filename = MD5(Date.now() + file.name + file.uid) + suffix;
        file.url = OSSData.dir + filename;
        if (this.props.folder !== undefined) {
            file.url = OSSData.dir + this.props.folder + filename;
        }

        if (this.props.GetFile !== undefined) {
            this.props.GetFile(file, file_list)
        }

        this.setState({ file: file, ImgFileState: true })

        return file;

    };

    fileList = [];
    getExtraData = (file) => {
        const { OSSData } = this.state;

        const data = {
            key: file.url,
            OSSAccessKeyId: OSSData.accessid,
            policy: OSSData.policy,
            Signature: OSSData.signature,
        }

        this.setState({ ParamsFormData: data })

        return data;
    };

    // 下载添加oss前缀
    onDownload = (file) => {

        if (file.url.startsWith('http')) {
            window.open(file.url)
            return file.url
        }

        let url = base.OssDownloadAddress + file.url
        window.open(url)
        return url
    }

    // 预览添加oss前缀
    onPreview = (file) => {
        if (file.url.startsWith('http')) {
            window.open(file.url)
            return file.url
        }
        let url = base.OssAddress + file.url
        window.open(url)
        return url
    }

    onSuccess = e => {
        console.log("上传成功！", e)
    }

    handleUpload = async () => {

        const { file_list, OSSData } = this.state;

        const formData = new FormData();

        const { ParamsFormData } = this.state

        for (let item in ParamsFormData) {
            formData.append(item, ParamsFormData[item]);
        }

        formData.append('file', file_list[0].originFileObj);

        // this.setState({
        //     uploading: true,
        // });

        // You can use any AJAX library you like
        Axios({
            url: OSSData.host,
            method: 'post',
            processData: false,
            data: formData,
        }).then((res) => {
            if (this.props.onFileSuccess !== undefined) {
                this.props.onFileSuccess({ status: 200, fileName: ParamsFormData.key })
            }
            return true

        }).catch(function (error) {
            // console.log(error);
            if (this.props.onFileSuccess !== undefined) {
                this.props.onFileSuccess({ status: 201, fileName: ParamsFormData.key })
            }
            return false
        });;
    };


    render() {
        const { limit, text } = this.props;
        const { OSSData } = this.state;

        return (
            <div>

                <Upload {...this.props} action={OSSData.host} onChange={this.onChange.bind(this)} beforeUpload={this.beforeUpload.bind(this)} transformFile={this.transformFile.bind(this)} data={this.getExtraData.bind(this)} fileList={this.state.file_list} onDownload={this.onDownload.bind(this)} onPreview={this.onPreview} ref={dom => this.Upload = dom} onSuccess={this.onSuccess} >
                    {
                        limit > this.state.file_list ?
                            <Button  >
                                <Icon type="upload" /> {text}
                            </Button> : null
                    }

                </Upload>


                {/* <Button onClick={this.PutCosImg.bind(this)} >
                    <Icon type="upload" /> 上传
            </Button> */}
            </div>
        );
    }
}
