
let AAA = require('js-base64').Base64;
var tools = {


    /**
     * 获取url参数
     */
    getUrlQuery() {
        //首先获取到当前页面的地址栏信息
        var url = window.location.href;
        //console.log(url);

        var obj = {};
        var reg = /\?/;
        if (url.match(reg)) {
            //判断传入参数，以问号截取，问号后是参数
            var chars = url.split('?')[1];

            //再截&号
            var arr = chars.split('&');

            //获得截取后的数组为键值对字符串
            for (var i = 0; i < arr.length; i++) {

                //保守一点确定看是否为 name=value形式
                var num = arr[i].indexOf("=");

                if (num > 0) {
                    //拼接字符串
                    var name = arr[i].substring(0, num);
                    var value = arr[i].substr(num + 1);

                    //拼接对象，并转码
                    obj[decodeURIComponent(name)] = decodeURIComponent(value);
                }
            }
        }
        obj.has = function (key) {
            return this[key] !== undefined;
        };
        //console.log(obj);
        return obj;
    },

    /*
     * 功能：设置cookie
     * 参数：
     * _name cookie名称
     * _value    该cookie名称所对应的数据
     * _date 该cookie的过期时间，单位天
     * 返回值：无
     * 示例：
     * setCookie("a", "10", 1);  创建或修改一个cookie，名称叫做a，值为"10"，过期时间为1天后
     *   setCookie("a", "10"); 创建或修改一个cookie，过期时间为会话（会话指页面打开到关闭的时间有效）
     *   setCookie("a", "10", -1);   删除名称叫做a的这个cookie
     */
    setCookie(_name, _value, _date) {
        // 创建一个对象
        var obj = {
            "tmp": _value
        }
        // console.log("obj : ", obj);
        // 将对象转为字符串
        var str = JSON.stringify(obj); // str='{"tmp":_value}'
        // console.log("str : ", str);
        str = encodeURIComponent(str); // 中文编码
        // console.log("str : ", str);
        // 存储cookie
        if (_date) { // 如果有输入这个参数，则表示设置一个具体的时间
            var d = new Date();
            d.setDate(d.getDate() + _date);
            document.cookie = _name + "=" + str + "; path=/; expires=" + d.toGMTString();
        } else { // 没有设置_date这个参数时，创建和修改的cookie的过期时间为会话
            document.cookie = _name + "=" + str + "; path=/;";
        }
    },
    /*
     * 功能：获取cookie
     * 参数：
     * _name 指cookie名称
     * 返回值：存储的内容
     * 示例：
     * getCookie("a")
     */
    getCookie(_name) {
        // 找到所有的cookie
        var str = document.cookie; // str="a=1; b=2; c=3"
        // 字符串转数组
        var arr = str.split("; "); // 注意分号后面有一个空格    arr = ["a=1", "b=2", "c=3"]
        // 循环
        for (var i = 0, l = arr.length; i < l; i++) {
            var col = arr[i].split("="); // arr[i]="a=1" col = ["a", "1"]
            if (col[0] === _name) {
                //return decodeURIComponent(col[1]);    // 下标0指cookie名称，下标1指cookie值
                // _name所对应的cookie
                // console.log("col[1] : ", col[1]);
                let str = decodeURIComponent(col[1]);
                // console.log("str : ", str);
                // console.log("最后str"+str["tmp"]);
                // 字符串转为json对象
                var obj = JSON.parse(str);
                // console.log("obj : ", obj);
                // 在json对象中，获取tmp属性
                return obj.tmp;
            }
        }
        // 如果循环结束后，也没有找到_name，直接返回空
        return ""; // 如果此处不写，则返回undefined
    },

    // unix时间戳转字符串时间
    // 参数 1 时间戳 参数2 不传默认格式 时间格式 2017-01-03 传1  则获取时间格式 2017-01-03 10:13:48  参数4 传1，则获取时间格式 2017.01.03
    UnixToStr(value, type, cy1000, dot) {
        if (cy1000 === 1) { value = value * 1000 }
        var date = new Date(value),
            Y = date.getFullYear(),
            m = date.getMonth() + 1,
            d = date.getDate(),
            H = date.getHours(),
            i = date.getMinutes(),
            s = date.getSeconds();
        if (m < 10) {
            m = '0' + m;
        }
        if (d < 10) {
            d = '0' + d;
        }
        if (H < 10) {
            H = '0' + H;
        }
        if (i < 10) {
            i = '0' + i;
        }
        if (s < 10) {
            s = '0' + s;
        }
        // <!-- 获取时间格式 2017-01-03 10:13:48 -->
        // var t = Y+'-'+m+'-'+d+' '+H+':'+i+':'+s;
        // <!-- 获取时间格式 2017-01-03 -->
        var t = Y + '-' + m + '-' + d;
        if (dot === 1) {
            var t = Y + '.' + m + '.' + d;
        }
        if (type === 1) {
            t = Y + '-' + m + '-' + d + ' ' + H + ':' + i + ':' + s;
        }
        return t;
    },
    // 时间格式 2017-01-03 转换为[3, 2017.01]
    transformTime(value, type, cy1000) {
        if (cy1000 === 1) { value = value * 1000 }
        var date = new Date(value),
            Y = date.getFullYear(),
            m = date.getMonth() + 1,
            d = date.getDate(),
            H = date.getHours(),
            i = date.getMinutes(),
            s = date.getSeconds();
        if (m < 10) {
            m = '0' + m;
        }
        if (d < 10) {
            d = '0' + d;
        }
        if (H < 10) {
            H = '0' + H;
        }
        if (i < 10) {
            i = '0' + i;
        }
        if (s < 10) {
            s = '0' + s;
        }
        // <!-- 获取时间格式 2017-01-03 10:13:48 -->
        // var t = Y+'-'+m+'-'+d+' '+H+':'+i+':'+s;
        // <!-- 获取时间格式 2017-01-03 -->
        var t = Y + '.' + m;
        if (type === 1) {
            t = Y + '.' + m;
        }
        var time = []
        time[0] = t
        time[1] = d
        return time;
    },
    formatMsgTime(timestamp) {
        // 补全为13位
        var arrTimestamp = (timestamp + '').split('');
        for (var start = 0; start < 13; start++) {
            if (!arrTimestamp[start]) {
                arrTimestamp[start] = '0';
            }
        }
        timestamp = arrTimestamp.join('') * 1;

        var minute = 1000 * 60;
        var hour = minute * 60;
        var day = hour * 24;
        // var halfamonth = day * 15;
        var month = day * 30;
        var now = new Date().getTime();
        var diffValue = now - timestamp;

        // 如果本地时间反而小于变量时间
        if (diffValue < 0) {
            return '不久前';
        }

        // 计算差异时间的量级
        var monthC = diffValue / month;
        var weekC = diffValue / (7 * day);
        var dayC = diffValue / day;
        var hourC = diffValue / hour;
        var minC = diffValue / minute;

        // 数值补0方法
        var zero = function (value) {
            if (value < 10) {
                return '0' + value;
            }
            return value;
        };

        // 使用
        if (monthC > 12) {
            // 超过1年，直接显示年月日
            return (function () {
                var date = new Date(timestamp);
                return date.getFullYear() + '年' + zero(date.getMonth() + 1) + '月' + zero(date.getDate()) + '日';
            })();
        } else if (monthC >= 1) {
            return parseInt(monthC) + "月前";
        } else if (weekC >= 1) {
            return parseInt(weekC) + "周前";
        } else if (dayC >= 1) {
            return parseInt(dayC) + "天前";
        } else if (hourC >= 1) {
            return parseInt(hourC) + "小时前";
        } else if (minC >= 1) {
            return parseInt(minC) + "分钟前";
        }
        return '刚刚';

    },
    // 数组去重
    _unique(arr) {

        for (var i = 0; i < arr.length; i++) {
            for (var j = i + 1; j < arr.length; j++) {
                if (arr[i] === arr[j]) {
                    arr.splice(j, 1);
                    j--;
                }
            }
        }
        return arr;
    },
    // json数组去重
    _jsonUnique(array, key) {
        var result = [array[0]];
        for (var i = 1; i < array.length; i++) {
            var item = array[i];
            var repeat = false;
            for (var j = 0; j < result.length; j++) {
                if (item[key] === result[j][key]) {
                    repeat = true;
                    break;
                }
            }
            if (!repeat) {
                result.push(item);
            }
        }
        return result;
    },
    // 查询json数组是否有重复 两个key 两个属性重复
    _jsonQueryRepeat(array, key, key2) {

        if (array.length === 1) {
            return false
        }
        var result = [array[0]];
        for (var i = 1; i < array.length; i++) {
            var item = array[i];
            var repeat = false;
            for (var j = 0; j < result.length; j++) {
                if (item[key] === result[j][key] && item[key] !== '') {
                    repeat = true;
                    if (key2) {
                        if (item[key2] === result[j][key2] && item[key2] !== '') {
                            repeat = true;
                        } else {
                            repeat = false;
                        }
                    }
                    break;
                }
            }
            if (!repeat) {
                result.push(item);
            }
        }
        return repeat;
    },
    // json数组根据key分组
    _jsonGrouping(array, key) {

    },
    // 查询json数组中是否有某个值  json数组jsonArray中的key属性中是否有value值
    _jsonQueryParams(jsonArray, key, value) {
        if (jsonArray.length === 1) {
            return false
        }
        var result = [jsonArray[0]];
        var repeat = false;
        for (var i = 1; i < jsonArray.length; i++) {
            var item = jsonArray[i];

            for (var j = 0; j < result.length; j++) {
                if (item[key] === value) {

                    repeat = true;

                    break;
                }

            }
            if (!repeat) {
                result.push(item);
            }

        }

        return repeat;
    },
    _jsonQueryParamsData(jsonArray, key, value) {
        if (jsonArray.length < 1) {
            return false
        }
        var result = [jsonArray[0]];
        // var repeat = false;
        for (var i = 0; i < jsonArray.length; i++) {
            var item = jsonArray[i];
            // console.log(item)
            for (var j = 0; j < result.length; j++) {
                // console.log(item[key])
                // console.log(value)
                if (item[key] === value) {

                    // repeat = true;
                    result = item
                    // console.log(result)
                    return result
                }

            }
            // if (!repeat) {
            //     result.push(item);
            // }

        }

        return result;
    },

    // redirect:type=inside&value=/news 解析这种参数
    ParsingUrlLikeBannerParameters(param) {

        var obj = {};
        //判断传入参数，以问号截取，问号后是参数
        // var chars = param.split(':')[1];
        var chars = param.replace("redirect:", "");

        //再截&号
        var arr = chars.split('&&');

        //获得截取后的数组为键值对字符串
        for (var i = 0; i < arr.length; i++) {

            //保守一点确定看是否为 name=value形式
            var num = arr[i].indexOf("=");

            if (num > 0) {
                //拼接字符串
                var name = arr[i].substring(0, num);
                var value = arr[i].substr(num + 1);

                //拼接对象，并转码
                obj[decodeURIComponent(name)] = decodeURIComponent(value);
            }
        }

        obj.has = function (key) {
            return this[key] !== undefined;
        };
        //console.log(obj);
        return obj;
    },
    checkPhone(phone) {
        if(!(/^1(3\d|4\d|5\d|6\d|7\d|8\d|9\d)\d{8}$/g.test(phone))){
            return false;
        }
        return true
    },
    checkNickname(str) {
        //多于3个数字、连续字母（手机号、座机号、qq、邮箱、微信（变量名））
        // if (str.length < 3 || str.length > 8) {
        //     return false
        // }
        // let reg = /[\d]{3,}|[a-zA-Z]{4,}/g
        // if((reg.test(str))){
        //     return false;
        // }
        return true
    },
    // 倒计时  parm 时间差 倒计时几秒还是几毫秒
    FormatRemainTime(time_difference) {

        // var startDate = new Date(); //开始时间
        // var endDate = new Date(endTime); //结束时间
        // var t = endDate.getTime() - startDate.getTime(); //时间差
        var t = time_difference
        var d = 0,
            h = 0,
            m = 0,
            s = 0;
        if (t >= 0) {
            d = Math.floor(t / 3600 / 24);
            h = Math.floor(t / 60 / 60 % 24);
            m = Math.floor(t / 60 % 60);
            s = Math.floor(t % 60);
        }
        // return d + "天 " + h + "小时 " + m + "分钟 " + s + "秒";

        // 补零
        if (h < 10) {
            h = "0" + h
        }
        if (m < 10) {
            m = "0" + m
        }
        if (s < 10) {
            s = "0" + s
        }
        // if ( d > 0 ){
        // 	return d + "天" + h + ": " + m + ": " + s  ;
        // }
        h = d * 24 + parseInt(h)
        return h + ": " + m + ": " + s;
    },

    FormatRemainTimeStr(StartUnixTime) {
        var newUnixTime = (new Date()).getTime() / 1000;

        var t = newUnixTime - StartUnixTime

        var d = 0,
            h = 0,
            m = 0,
            s = 0;
        if (t >= 0) {
            d = Math.floor(t / 3600 / 24);
            h = Math.floor(t / 60 / 60 % 24);
            m = Math.floor(t / 60 % 60);
            s = Math.floor(t % 60);
        }

        var str;
        if (d > 0) {
            str = d + "天前"
        } else if (h > 0) {
            str = h + "小时前";
        } else if (m > 0) {
            str = m + "分钟前"
        } else if (s >= 0) {
            str = s + "秒前";
        }

        return str;
    },

    // 保留n位小数
    // v表示要转换的值
    // e表示要保留的位数
    Round(v, e) {

        var t = 1;

        for (; e > 0; t *= 10, e--);

        for (; e < 0; t /= 10, e++);

        return Math.round(v * t) / t;

    },
    // 保留n位小数，不四舍五入
    // 参数一：数
    // 参数二：保留几位小数
    formatDecimal(num, decimal) {
        num = num.toString()
        let index = num.indexOf('.')
        if (index !== -1) {
            num = num.substring(0, decimal + index + 1)
        } else {
            num = num.substring(0)
        }
        return parseFloat(num).toFixed(decimal)
    },

    // 查询json数组某个字段的下标
    QueryJsonArrIndex(jsonArray, key, value) {
        if (jsonArray.length < 1) {
            return false
        }
        var result = [jsonArray[0]];
        var repeat = false;
        for (var i = 1; i < jsonArray.length; i++) {
            var item = jsonArray[i];

            for (var j = 0; j < result.length; j++) {

                if (item[key] === value) {
                    repeat = i;
                    return repeat;
                }

            }

        }

        return repeat;
    },
    /**
     * 产生随机整数，包含下限值，包括上限值
     * @param {Number} lower 下限
     * @param {Number} upper 上限
     * @return {Number} 返回在下限到上限之间的一个随机整数
     */
    random(lower, upper) {
        return Math.floor(Math.random() * (upper - lower + 1)) + lower;
    },

    DMT() {

        return {
            a: AAA.decode("a2VqaW91a3UxMjM0NTY3OHNoaWRhaWdz"),
            b: AAA.decode("d3VtYW5zZ3k=")
        }
    },

    // 获取本地URL
    getBaseUrl() {
        var ishttps = 'https:' === document.location.protocol ? true : false;
        var url = window.location.host;
        if (ishttps) {
            url = 'https://' + url;
        } else {
            url = 'http://' + url;
        }
        return url;
    },

    IsPC() {
        var userAgentInfo = navigator.userAgent;
        var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
        var flag = true;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) { flag = false; break; }
        }
        return flag;
    },

    getCurrentDate() {

        var myDate = new Date();

        var year = myDate.getFullYear(); //年

        var month = myDate.getMonth() + 1; //月

        var day = myDate.getDate(); //日

        var days = myDate.getDay();

        switch (days) {

            case 1:

                days = '星期一';

                break;

            case 2:

                days = '星期二';

                break;

            case 3:

                days = '星期三';

                break;

            case 4:

                days = '星期四';

                break;

            case 5:

                days = '星期五';

                break;

            case 6:

                days = '星期六';

                break;

            case 0:

                days = '星期日';

                break;

            default:

                break;

        }

        var str = year + "年" + month + "月" + day + "日  " + days;

        return str;

    },

    // 防抖 
    // 使用： 
    // 处理函数
    // function handle() {    
    //     console.log(Math.random()); 
    // }
    // // 滚动事件
    // window.addEventListener('scroll', debounce(handle, 1000));
    debounce(fn, wait) {
        var timeout = null;
        return function () {
            if (timeout !== null) clearTimeout(timeout);
            timeout = setTimeout(fn, wait);
        }
    },

    // 将一个大数组分割成几个小数组
    sliceArr(array, size) {
        let result = [];
        for (let x = 0; x < Math.ceil(array.length / size); x++) {
            let start = x * size;
            let end = start + size;
            result.push(array.slice(start, end));
        }
        return result;
    }

}

/**
 * sessionStorage
 */
const session = {
    get: function (key) {
        var data = sessionStorage[key];
        if (!data || data === "null" || data === "" || data === undefined || data === "undefined") {
            return null;
        }
        return JSON.parse(data).value;
    },
    set: function (key, value) {
        var data = {
            value: value
        }
        sessionStorage[key] = JSON.stringify(data);
    },
    // 删除
    remove(key) {
        sessionStorage.removeItem(key);
    },
    // 清除全部
    clear() {
        sessionStorage.clear();
    }
}

const local = {
    get: function (name) {
        // 兼容IE
        if (this.hasLocalSotrage()) {
            try {
                var obj = localStorage.getItem(name) || ''
                var cut = obj.indexOf(';expiretime=')
                var expiretime = obj.substring(cut)
                var val = obj.substring(0, cut < 0 ? cut.length : cut)
                if (expiretime) {
                    // console.log(expiretime)
                    expiretime = expiretime.substring(12)
                    if (expiretime < this.time()) {
                        //已经过期
                        // alert('已过期，删除所有数据')
                        local.clear(name);
                        return null
                    } else {
                        //未过期
                        // alert('未过期')
                        return this.back(val)
                    }
                } else {
                    return null
                }
            } catch(e){
                this.getCookie(name)
            }
        } else {
            this.getCookie(name)
        }

    },

    back: function (val) {
        if ((val.startsWith('{') && val.endsWith('}')) || (val.startsWith('[') && val.endsWith(']'))) {
            return JSON.parse(val)
        } else if (val === '' || val === undefined) {
            return null
        } else {
            return val
        }
    },

    set: function (name, val, expireTime) {
        //如何存储的是对象，对象和字符出字符串拼接
        //[object Object];expiretime= 1520689159000
        //{"name":"weihong"};expiretime= 1520689584000
        // 兼容IE
        if (this.hasLocalSotrage()) {
            try {
                val = typeof (val) === 'object' ? JSON.stringify(val) : val
                if (expireTime) {
                    val += ';expiretime= ' + (this.time() + expireTime * 24 * 60 * 60 * 1000)
                }
                localStorage.setItem(name, val)
            }
            catch (error) {
                console.error('localStorage.setItem报错， ', error.message)
                this.setCookie(name, val, expireTime);
            }
        } else {
            this.setCookie(name, val, expireTime);
        }

    },

    clear: function (name) {
        console.log('删除所有数据')
        localStorage.removeItem(name)

    },
    time: function () {
        return Date.parse(new Date())//1520687826000
    },
    // 判断浏览器是否支持 hasLocalSotrage
    hasLocalSotrage: function () {
        return window.Storage && window.localStorage && window.localStorage instanceof Storage
    },
    //设置cookie
    setCookie: function (key, value, day) {
        var t = day || 30;
        var d = new Date();
        d.setTime(d.getTime() + (t * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = key + "=" + value + "; " + expires;
    },
    //获取cookie
    getCookie: function (name) {
        var arr, reg = new RegExp("(^|)" + name + "=([^]*)(|$)");
        if (arr === document.cookie.match(reg)) {
            return arr[2];
        }
        else {
            return null;
        }
    }
}
// local.set('name',{name:'weihong'},1)
// local.set('name', 'weihong',1)
// console.log(local.get('name'))

export {
    tools,
    session,
    local
}
