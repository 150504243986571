import axios from 'axios'
import { base } from './base'
import { message } from 'antd';
import { TAMethodADD, TAMethod } from '../lib/des'
import { tools } from '../lib/tools';
import store from '@/src/redux/store.js';
import { setUserInfo } from '@/src/redux/actions/userInfo';
// import { HashRouter } from 'react-router-dom'
// const router = new HashRouter()

const ta_ = tools.DMT()
const ta_a = ta_.a
const ta_b = ta_.b

/**
 * @params method {string} 方法名
 * @params url {string} 请求地址  例如：/login 配合baseURL组成完整请求地址
 * @params baseURL {string} 请求地址统一前缀 ***需要提前指定***  例如：http://cangdu.org
 * @params timeout {number} 请求超时时间 默认 30000
 * @params params {object}  get方式传参key值
 * @params headers {string} 指定请求头信息
 * @params withCredentials {boolean} 请求是否携带本地cookies信息默认开启
 * @params validateStatus {func} 默认判断请求成功的范围 200 - 300
 * @return {Promise}
 * 其他更多拓展参看axios文档后 自行拓展
 * 注意：params中的数据会覆盖method url 参数，所以如果指定了这2个参数则不需要在params中带入
*/
export default class Server {
  axios(method, url, data, option) {
    return new Promise((resolve, reject) => {
      // url = base.baseUrl+url
      let _option = {
        method,
        url:base.url + url,
        baseUrl: base.baseUrl,
        timeout: 30000,
        params: null,
        data: data,
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        withCredentials: false,  //是否携带cookie发起请求
        validateStatus: (status) => {
          return status >= 200 && status < 500
        },
        ...option
      }

      // console.log('请求:', JSON.stringify(_option));
      if (_option.url.includes("/RequestAddress")) {
        _option.data = TAMethodADD(_option.data, ta_a, ta_b)
        const { userInfo } = store.getState()
        if (userInfo) {
          _option.headers = {..._option.headers,'AuthToken': userInfo.auth_token,}
        }
      }
      
      // console.log('prpos')

      axios.request(_option).then(res => {
        // resolve(typeof res.data === 'object'? res.data:JSON.parse(res.data))
        if(1 == res.data.encrypt && res.data.data ){
          try {
            res.data.data = JSON.parse(res.data.data)
          } catch {
            res.data.data = res.data.data
          }
          
        }else if (res.data.status === 200 && res.data.data && _option.url.includes("/RequestAddress")) {
          res.data.data = JSON.parse(TAMethod(res.data.data, ta_a, ta_b))
        }
        // console.log('响应:', JSON.stringify(res));
        resolve(res.data)

        if (res.data.status === 401 || res.data.status === 403) {
          // router.history.push('/')
          store.dispatch(setUserInfo(null, -1))
          // message.error("请先登录！") 
          // message.error("权限已失效，请重新登录！");
          // router.history.push('./login')
        }


      }, error => {
        message.error("网络超时!",url);
        if (error.response) {
          reject(error.response.data)
        } else {
          reject(error)
        }
      })
    })
  }
}