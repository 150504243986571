import React, { Component } from "react";
import {
  Menu,
  Input,
  Button,
  Icon,
  Divider,
  Breadcrumb,
  Avatar,
  Popconfirm,
} from "antd";
import styles from "./index.module.less";
import { tools } from "@/src/lib/tools";
import store from "@/src/redux/store.js";
import { setBreadcrumbList } from "@/src/redux/actions/BreadcrumbList";
import { setUserInfo } from "@/src/redux/actions/userInfo";
import { base } from "@/src/api/base";

import { HashRouter } from "react-router-dom";
import { bind } from "file-loader";
import API from '@/src/api/TdTitleBar'
const router = new HashRouter();

const { Search } = Input;

class PageHeader extends Component {
  state = {
    current: "home",
    showSearch: true,
    showSearchBox: false,
    showNav: true,
    showNavMo: true,
    TitleList: [],
    BreadcrumbList: [],
    showBreadcrumbList: false,
    BrowserType: 'PC', // PC Mobile 浏览器类型 手机或者pc端
    showMenu: false,
    oldIndex: null,
    isHover: false,
    headTitle: '',
    EnglishTitle: '',
    backgroundImg: ''
  };

  componentDidMount() {
    this.unsubscribe = store.subscribe(this.ListenStore.bind(this));
    window.addEventListener("resize", this.resizeBind);
    this.resizeBind.bind(this);
    this.ListenStore();

    if (!tools.IsPC()) {
      this.setState({
        showSearch: true,
        BrowserType: "Mobile"
      });
    }
  }
  componentWillUnmount() {
    // 对 store 变化取消监听
    this.unsubscribe(this.ListenStore.bind(this));
    window.removeEventListener("resize", this.resizeBind);
    this.setState({
      current: "home",
      showSearch: true,
      showNav: true,
      TitleList: [],
      BreadcrumbList: [],
      showBreadcrumbList: false,
      breadcrumbListStyle: false,
    });
  }
  ListenStore = () => {
    let { AllTdTitleBar } = store.getState();
    if (
      !AllTdTitleBar ||
      AllTdTitleBar === {} ||
      AllTdTitleBar.length === undefined ||
      AllTdTitleBar.length === 0
    ) {
      return;
    }
    let oneTitleList = AllTdTitleBar.filter((item) => {
      return (
        item.level === 1 && item.nav_status === 2 && item.show_status === 2
      );
    });
    this.setState({
      TitleList: oneTitleList,
    });
    console.log(this.state.TitleList)
    // 顶部大图文字
    // eslint-disable-next-line default-case
    switch (this.props.current) {
      case '/MHNews':
        this.setState({
          backgroundImg: 'banner01.jpg'
        });
        break
      case '/FireFighting':
        this.setState({
          backgroundImg: 'banner04.jpg'
        });
        break
      case '/SmartSecurity':
        this.setState({
          backgroundImg: 'banner03.jpg'
        });
        break
      case '/ExpertColumn':
        this.setState({
          backgroundImg: 'banner02.jpg'
        });
        break
      case '/Exhibition':
        this.setState({
          backgroundImg: 'banner01.jpg'
        });
        break
      case '/Member':
        this.setState({
          backgroundImg: 'banner01.jpg'
        });
        break
    }
  };

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.showSearch) {
        this.setState({
          showSearch: true,
        });
      }
      if (this.state.BrowserType !== 'Mobile') {
        this.setState({
          BrowserType: 'Mobile',
        });
      }
    } else {
      if (!this.state.showSearch) {
        this.setState({
          showSearch: true,
        });
      }
      if (!this.state.BrowserType !== 'PC') {
        this.setState({
          BrowserType: 'PC',
        });

      }
    }
  };
  setHover = (flag) => {
    this.setState({
      isHover: flag,
    });
  }
  handleClick = (e) => {
    const { title_bar_no, name } = e.item.props;
    this.setState({
      current: e.key,
    });
    router.history.push(`${e.key}${title_bar_no}/${title_bar_no}`);
  };

  LoginOut = (e) => {
    store.dispatch(setUserInfo(null, -1));
  };

  openPersonalCenter = () => {
    router.history.push("/PersonalCenter");
    //打开新标签的方式
    // const newLabel = window.open('about:blank');
    // newLabel.location.href="/#/PersonalCenter"
  };
  handleToUser = () => {
    var { userInfo } = store.getState();
    if (userInfo) {
      router.history.push(`/PersonalCenter`)
    } else {
      router.history.push(`/login`)
    }
  }
  handleShow = (item, index) => {
    // 下拉框显示
    this.setState({
      oldIndex: index,
    });
    if (this.state.oldIndex && this.state.oldIndex !== index) {
      this.navChild.style.display = 'none'
      this.navChild.childNodes[this.state.oldIndex].style.display = 'none'
      this.menuItem.querySelectorAll(".ant-menu-item")[this.state.oldIndex].removeAttribute("style");
    }
    if (item.children && item.title_bar_no !== '1001' && item.title_bar_no !== '1006' && item.title_bar_no !== '1005') {
      this.navChild.style.display = 'block'
      this.navChild.childNodes[index].style.display = 'block'
      let itemOffsetWidth = this.menuItem.querySelectorAll(".ant-menu-item")[index].offsetLeft
      let itemWidth = this.menuItem.querySelectorAll(".ant-menu-item")[index].clientWidth / 2
      this.navChild.childNodes[index].style.left = itemOffsetWidth + itemWidth + 'px'
      var currentStyle = "height: 4.9rem;line-height: calc(4.9rem - 1rem);margin-top: 1rem;margin-right: 0.1rem;color: #fff;border-bottom: #ca4f42;background-color: #ca4f42;z-index: 100;"
      this.menuItem.querySelectorAll(".ant-menu-item")[index].setAttribute("style", currentStyle);

    }


  }
  handleHide = () => {
    // 下拉框隐藏
    this.navChild.style.display = 'none'
    this.menuItem.querySelectorAll(".ant-menu-item")[this.state.oldIndex].removeAttribute("style");
  }
  handleShowMenu = () => {
    this.setState({ showMenu: !this.state.showMenu })
  }
  handleGo = (item) => {
    router.history.push(`${item.skip_url}${item.title_bar_no}/${item.title_bar_no}`);
    this.setState({ showMenu: false })
  }
  navChildGo = (item, i) => {
    router.history.push(`/MoreArticles${i.title_bar_no}/0`);
    this.setState({ showMenu: false })
    store.dispatch(
      setBreadcrumbList([
        {
          id: "1",
          name: `${item.name}`,
          path: `${item.skip_url}${item.title_bar_no}/${item.title_bar_no}`,
        },
        {
          id: "2",
          name: `${i.name}`,
          path: `/MoreArticles${i.title_bar_no}/0`,
        }
      ])
    );
  }

  render() {
    var { BrowserType, showMenu, TitleList, showSearchBox, isHover } = this.state
    let showNav = this.state.showSearch;
    if (this.props.showNav !== undefined) {
      showNav = this.props.showNav;
    }

    let showNavMo = this.state.showSearch;
    if (this.props.showNavMo !== undefined) {
      showNavMo = this.props.showNavMo;
    }

    let showSearch = this.state.showSearch;
    if (this.props.showSearch !== undefined) {
      showSearch = this.props.showSearch;
    }
    // let BreadcrumbList = this.state.BreadcrumbList
    // if (this.props.BreadcrumbList !== undefined) {
    //   BreadcrumbList = this.props.BreadcrumbList
    // }

    var { BreadcrumbList = [], userInfo } = store.getState();
    //子页面需要返回首页面包屑20200602
    // console.log(BreadcrumbList);
    if (BreadcrumbList && BreadcrumbList.length > 0) {

      let { TdTitleBar } = store.getState();
      // BreadcrumbList去重，防止多次添加
      if (BreadcrumbList) {
        const res = new Map()
        BreadcrumbList = BreadcrumbList.filter((a) => !res.has(a.name) && res.set(a.name, 1))
      }
      BreadcrumbList.map(item => {
        if (item.name == 1044) {
          item.name = '消防应急救援'
          item.path = 'FireFighting1044/1044'
        }
        if (item.path == null) {
          TdTitleBar.filter(i => {
            if (i.name == item.name) {
              if (i.skip_url !== '') {
                item.path = i.skip_url + i.title_bar_no + '/' + i.title_bar_no
              } else {
                if (i.parent_id == 1044) {
                  i.skip_url = 'FireFighting1044/1044'
                  item.path = i.skip_url
                }
                TdTitleBar.filter(j => {
                  if (i.parent_id == j.title_bar_no) {
                    i.skip_url = j.skip_url
                    item.path = i.skip_url + j.title_bar_no + '/' + j.title_bar_no
                  }
                })
              }
            }
          })
        }
      })
      if (BreadcrumbList[0].name != "首页" && BreadcrumbList[0].name != "1001") {
        BreadcrumbList.unshift({ id: "0", name: "首页", path: "/home1001/1001" })
      }
      if (BreadcrumbList.length == 2 && BreadcrumbList[0].name === "首页" && BreadcrumbList[1].name.indexOf("MoreArticles")) {
        TdTitleBar.forEach((item) => {
          if (BreadcrumbList[1].path !== null && item.title_bar_no === BreadcrumbList[1].path ? BreadcrumbList[1].path.substring(13, 17) : '' && item.name != "首页") {
            var obj = { id: "0", name: item.name, path: null }
            BreadcrumbList.splice(1, 0, obj);
          }
        });
      }

      if (BreadcrumbList.length >= 3 && BreadcrumbList[1].name === "政策跟踪" && BreadcrumbList[1].path === null) {
        BreadcrumbList[1].path = "/home1001/1001"
      }


      if (BreadcrumbList.length >= 3 && BreadcrumbList[1].name === "展会频道" && BreadcrumbList[1].path === null) {
        BreadcrumbList[1].path = "/Exhibition1005/1005"
      }
      if (BreadcrumbList.length >= 3 && BreadcrumbList[1].name === "1036" && BreadcrumbList[1].path === null) {
        BreadcrumbList[1].path = "/Exhibition1005/1005"
        BreadcrumbList[1].name = "展会频道"
      }
      if (BreadcrumbList.length >= 3 && BreadcrumbList[1].name === "1037") {
        BreadcrumbList[1].path = "/Exhibition1005/1005"
        BreadcrumbList[1].name = "展会频道"
      }
      if (BreadcrumbList.length >= 3 && (BreadcrumbList[1].name && BreadcrumbList[1].name.length > 10 || BreadcrumbList[1].name === "专题")) {
        // if (BreadcrumbList.length >= 3 && ( BreadcrumbList[1].name === "专题")) {
        BreadcrumbList[1].path = "/ThematicTemplate20200514094324267240377679876096/ThematicTemplate"
        BreadcrumbList[1].name = "专题"
      }
      if (BreadcrumbList.length >= 2 && (BreadcrumbList[1].name === "消防应急救援")) {
        BreadcrumbList[1].path = "/FireFighting1004/1044"
        BreadcrumbList[1].name = "消防应急救援"
      }
      if (BreadcrumbList.length >= 3 && (BreadcrumbList[1].name === "1052")) {
        BreadcrumbList[1].path = "/home1001/1001"
        BreadcrumbList[1].name = "视频新闻"
      }


      if (BreadcrumbList.length >= 4) {
        BreadcrumbList = BreadcrumbList.filter(function (item, index) {
          // return item.name!=="新闻列表"
          return index !== 2
        })
      }

    }


    let showBreadcrumbList = this.state.showBreadcrumbList;
    if (this.props.showBreadcrumbList !== undefined) {
      showBreadcrumbList = this.props.showBreadcrumbList;
    }
    let breadcrumbListStyle = this.state.breadcrumbListStyle;
    if (this.props.breadcrumbListStyle !== undefined) {
      breadcrumbListStyle = this.props.breadcrumbListStyle;
    }
    let IsDivider = false;
    if (this.props.IsDivider !== undefined) {
      IsDivider = this.props.IsDivider;
    }

    let BreadcrumbListColorE9f1fc = true;
    if (this.props.BreadcrumbListColorE9f1fc !== undefined) {
      BreadcrumbListColorE9f1fc = this.props.BreadcrumbListColorE9f1fc;
    }

    return (
      BrowserType == "PC" ? (
        <div className={styles.HeaderBox}>
          {/* 日期 */}
          <div className={styles.timeDiv} >
            {tools.getCurrentDate()}
            <div className={styles.RightBtn}>
              {!userInfo ? (
                <div
                  className={styles.login}
                >
                  {" "}
                  <div className={isHover ? styles.btnHover : ''} onClick={() => router.history.push(`/register1`)}>
                    <span>注册</span>
                  </div>
                  <div className={isHover ? styles.leftBtnHover : ''} onClick={() => router.history.push(`/login`)}>
                    <span onMouseOver={() => this.setHover(true)} onMouseOut={() => this.setHover(false)}>登录</span>
                  </div>{" "}
                </div>
              ) : (
                <div style={{ display: "flex" }}>
                  <Avatar
                    onClick={this.openPersonalCenter}
                    icon="user"
                    src={base.OssAddress + userInfo.head_url}
                  />
                  <span
                    onClick={this.openPersonalCenter}
                    className={styles.NickName}
                  >
                    {userInfo.user_name ||
                      `${base.NameInitials}${userInfo.id}`}
                  </span>
                  <Popconfirm
                    title="确认退出当前账号吗?"
                    onConfirm={this.LoginOut}
                    okText="退出"
                    cancelText="取消"
                  >
                    <Button type="primary" className={styles.LoginOutBtn}>
                      {" "}
                      退出{" "}
                    </Button>
                  </Popconfirm>
                </div>
              )}
            </div>
          </div>
          {/* 导航栏 */}
          <div className={styles.navDivBox}>
            <div className={styles.navDiv}>

              <a href="#">
                <img
                  className={styles.logo}
                  src={require("../../assets/logo2022.png")}
                  alt="民航安保网"
                />
              </a>

              {/* 导航栏   */}
              {showNav ? (
                <div className={styles.MenuBox} ref={ref => { this.menuItem = ref }}>
                  <Menu
                    onClick={this.handleClick}
                    selectedKeys={[this.props.current]}
                    mode="horizontal"
                    overflowedIndicator="民航安保网"

                  >
                    {this.state.TitleList.length > 0
                      ? this.state.TitleList.map((item, index) => {
                        return (
                          <Menu.Item
                            key={item.skip_url}
                            title_bar_no={item.title_bar_no}
                            name={item.name}
                            title="民航安保网"
                            onMouseEnter={this.handleShow.bind(this, item, index)}
                          >
                            {item.name}

                          </Menu.Item>
                        );
                      })
                      : null}
                  </Menu>
                </div>
              ) : IsDivider ? (
                <Divider className={styles.DividingLine}></Divider>
              ) : null}

              <img
                className={styles.search_img}
                src={require("../../assets/search.png")}
                alt="搜索"
                onClick={() => this.setState({ showSearchBox: !showSearchBox })}
              />

            </div>
            {/* 下拉框 */}
            <div className={styles.nav_child} onMouseLeave={this.handleHide}>
              <div className={styles.item_box} ref={ref => { this.navChild = ref }}>
                {this.state.TitleList.length > 0
                  ? this.state.TitleList.map((item, index) => {
                    return (
                      <div className={styles.itemContainer}>
                        <ul className={styles.item}>
                          {
                            item.children ? item.children.map((i, index) => {

                              return (
                                i.nav_status === 2 ? (
                                  <li onClick={() => { this.navChildGo(item, i) }} key={index}>{i.name}</li>
                                ) : null

                              )
                            }) : null
                          }
                        </ul>
                      </div>

                    );
                  })
                  : null}

              </div>
            </div>
          </div>
          {/* 搜索框 */}
          {
            showSearchBox ? (
              <div className={styles.searchBox}>
                <img
                  className={styles.search_icon}
                  src={require("../../assets/search-icon.png")}
                  alt="搜索"
                />
                <div className={styles.searchContainer}>
                  <Search
                    className={styles.Search}
                    allowClear={true}
                    placeholder=""
                    enterButton="搜索"
                    onSearch={(value) => {
                      if (value === "") {
                        return;
                      }
                      store.dispatch(setBreadcrumbList([]));

                      router.history.push("/MoreArticles0/" + value.trim());
                    }}
                  />
                </div>
                <img
                  className={styles.search_icon}
                  src={require("../../assets/close.png")}
                  alt="关闭"
                  onClick={() => this.setState({ showSearchBox: false })}
                />
              </div>
            ) : null
          }
          {/* 图片 */}
          {
            this.props.current != "/home" && this.props.current != "/AboutUs" ? (
              <div className={styles.topImgContainer}>
                {
                  this.state.backgroundImg ? (
                    <div className={styles.topImgBox} style={{ background: `url('/topImg/${this.state.backgroundImg}') no-repeat center /cover` }}></div>
                  ):
                  (
                    <div className={styles.topImgBox} style={{ background: `url('/topImg/banner01.jpg') no-repeat center /cover` }}></div>
                  )
                }
              </div>
            ) : null
          }





          {/* 面包屑 */}
          {/* Breadcrumb */}
          {showBreadcrumbList && BreadcrumbList && BreadcrumbList.length > 0 ? (
            <div style={{ backgroundColor: '#f3f5f9' }}>
              <div
                style={breadcrumbListStyle ? { padding: "2.13rem 6.31rem 0", maxWidth: "75rem", margin: "0 auto" } : { padding: "1rem 2rem", maxWidth: "75rem", margin: "0 auto" }}
                className={
                  BreadcrumbListColorE9f1fc ? (BrowserType !== "PC" ? styles.Breadcrumb + " " + styles.BreadcrumbListColorE9f1fc : styles.BreadcrumbListColorE9f1fc) : (BrowserType !== "PC" ? styles.Breadcrumb : null)
                }
              >
                <Breadcrumb>
                  {BreadcrumbList.map((item, index) => {
                    let path = null;
                    if (
                      index === 0 ||
                      item.path === null ||
                      item.path === undefined
                    ) {
                      let { TdTitleBar } = store.getState();
                      //   TdTitleBar.forEach((item3) => {
                      //     if (
                      //       item3.title_bar_no === item.name &&
                      //       item3.parent_id !== "1"
                      //     ) {
                      //       item.name = item3.parent_id;
                      //     }
                      //   });

                      TdTitleBar.forEach((item3) => {
                        if (item3.title_bar_no === item.name) {
                          item.name = item3.name;
                        }
                      });

                      path = TdTitleBar.filter((item2) => {
                        return item2.name === item.name;
                      });
                      if (path && path.length > 0) {
                        path =
                          path[0].skip_url +
                          path[0].title_bar_no +
                          "/" +
                          path[0].name;
                      }
                    }
                    return (
                      <Breadcrumb.Item
                        key={item.id}
                        href={`${base.localhost + '/#' + item.path || path}`}
                        onClick={() => {
                          if (index !== BreadcrumbList.length - 1) {
                            let BreadcrumbList2 = BreadcrumbList.slice(
                              0,
                              index + 1
                            );
                            store.dispatch(setBreadcrumbList(BreadcrumbList2));
                          }
                        }}
                      >
                        <span>{item.name}</span>
                      </Breadcrumb.Item>
                    );
                  })}
                </Breadcrumb>
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div className={styles.headerBoxMo}>
          <div className={styles.titBox}>
            <div className={styles.userBox} onClick={this.handleToUser}>
              <img src={require("../../assets/zh/indexMo_user.png")} alt="" />
            </div>
            <div className={styles.logoBox} onClick={() => router.history.push('/')}>
              <img src={require("../../assets/logo.png")} alt="" />
            </div>
            <div className={styles.moreBtn}>
              {
                showNavMo ? <img src={require("../../assets/zh/indexMo_all.png")} alt="" onClick={this.handleShowMenu} /> : null
              }
            </div>
          </div>
          {/* 导航栏   */}
          {showNavMo ? (
            <div className={styles.MenuBox} style={showMenu ? { height: "280px", opacity: "1" } : { height: "0px" }}>
              {this.state.TitleList.length > 0
                ? this.state.TitleList.map((item) => {
                  return (
                    <div className={this.props.current == item.skip_url ? styles.item + " " + styles.active : styles.item} key={item.skip_url} title_bar_no={item.title_bar_no} onClick={() => { this.handleGo(item) }}>
                      {item.name}
                    </div>
                  );
                })
                : null}
            </div>
          ) : null}
          {/* 搜索 */}
          {
            showSearch ? (
              <div className={styles.searchBox}>
                <Search
                  className={styles.searchicon}
                  placeholder="&#xe600; 搜索"
                  onSearch={(value) => {
                    if (value === "") {
                      return;
                    }
                    store.dispatch(setBreadcrumbList([]));

                    router.history.push("/MoreArticles0/" + value);
                  }}
                />
              </div>
            ) : null
          }

          {/* 面包屑Breadcrumb */}
          {showBreadcrumbList && BreadcrumbList && BreadcrumbList.length > 0 ? (
            <div
              style={breadcrumbListStyle ? { padding: "2.13rem 6.31rem 0", position: "static" } : { padding: "1rem 2rem", position: "static" }}
              className={
                BreadcrumbListColorE9f1fc ? (BrowserType !== "PC" ? styles.Breadcrumb + " " + styles.BreadcrumbListColorE9f1fc : styles.BreadcrumbListColorE9f1fc) : (BrowserType !== "PC" ? styles.Breadcrumb : null)
              }
            >
              <Breadcrumb>
                {BreadcrumbList.map((item, index) => {
                  let path = null;
                  if (
                    index === 0 ||
                    item.path === null ||
                    item.path === undefined
                  ) {
                    let { TdTitleBar } = store.getState();
                    TdTitleBar.forEach((item3) => {
                      if (item3.title_bar_no === item.name) {
                        item.name = item3.name;
                      }
                    });

                    path = TdTitleBar.filter((item2) => {
                      return item2.name === item.name;
                    });
                    if (path && path.length > 0) {
                      path =
                        path[0].skip_url +
                        path[0].title_bar_no +
                        "/" +
                        path[0].name;
                    }
                  }
                  return (
                    <Breadcrumb.Item
                      key={item.id}
                      href={`/#${item.path || path}`}
                      onClick={() => {
                        if (index !== BreadcrumbList.length - 1) {
                          let BreadcrumbList2 = BreadcrumbList.slice(
                            0,
                            index + 1
                          );
                          store.dispatch(setBreadcrumbList(BreadcrumbList2));
                        }
                      }}
                    >
                      <span>{item.name}</span>
                    </Breadcrumb.Item>
                  );
                })}
              </Breadcrumb>
            </div>
          ) : null}
        </div>
      )
    );
  }
}

export default PageHeader;
