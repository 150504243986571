import { combineReducers } from "redux";
import userInfo from "./userInfo";
import { TdTitleBar, AllTdTitleBar } from "./TdTitleBar";
import Setting from "./Setting";
import Advertisement from "./Advertisement";
import { AdAroundR, AdAroundL } from "./AdAround";
import ArticleDetails from "./ArticleDetails";
import BreadcrumbList from "./BreadcrumbList";
export default combineReducers({
  userInfo,
  TdTitleBar,
  AllTdTitleBar,
  Advertisement,
  AdAroundR,
  AdAroundL,
  ArticleDetails,
  BreadcrumbList,
  Setting,
});
