
import Home from "../pages/home/home.jsx";
import Login from "../pages/login/login.jsx"; 
import login1 from "../pages/login1/login.jsx";  
import register from "../pages/register/index.jsx"; 
import rule from "../pages/rule/index.jsx"; 
import MoreArticles from "../pages/common/MoreArticles/index.jsx";
import MoreOnlineCourse from "../pages/common/MoreOnlineCourse/index.jsx"; 
import OnlineCourseDetails from "../pages/common/OnlineCourseDetails/index.jsx";  
import ArticleDetails from "../pages/common/ArticleDetails/index.jsx"; 
import Special from "../pages/common/Special/index.jsx"; 


import MHNews from "../pages/MHNews/index.jsx"; 
import ZCdecoding from "../pages/ZCdecoding/index.jsx"; 
import SmartSecurity from "../pages/SmartSecurity/index.jsx";  
import FireFighting from "../pages/FireFighting/index.jsx";  

import ExpertColumn from "../pages/ExpertColumn/index.jsx";  
import Exhibition from "../pages/Exhibition/index.jsx";  
import Member from "../pages/Member/index.jsx"; 
import PersonalInformation from "../pages/PersonalCenter/PersonalInformation/PersonalInformation";  

import OnlineClassroom from "../pages/common/OnlineClassroom/index.jsx";

import PersonalCenter from "../pages/PersonalCenter/index.jsx";
import ExpertList from "../pages/common/ExpertList/index.jsx"; 
import AboutUs from "../pages/AboutUs/index.jsx";
import ContactUs from "../pages/ContactUs/index.jsx"; 
import AdvertisingService from "../pages/AdvertisingService/index.jsx"; 
import ThematicTemplate from "../pages/common/ThematicTemplate/index.jsx";  
import ForgetPwd from "../pages/ForgetPwd/index.jsx";  
import EntryForm from "../pages/EntryForm/index.jsx";   
import entry_form_list from "../pages/EntryFormList/index.jsx";     
import ExhibitionHome from "../pages/zhExhibition/home/home";
import ExhibitionHome2021 from "../pages/zhExhibition/home2021/home";     
import ExhibitionHomeOld from "../pages/zhExhibition/homeold/home";     
import newsdetails from "../pages/zhExhibition/homeold/newsdetails";     
import ExhibitionDetails from "../pages/zhExhibition/exhibitionDetails/index";
import exhibitionDetails2021 from "../pages/zhExhibition/exhibitionDetails2021/index";     
import VideoDetals from "../pages/zhExhibition/video/index";
import VideoDetals2021 from "../pages/zhExhibition/video2021/index";     
import VideoList from "../pages/zhExhibition/video/videoIndex";
import VideoList2021 from "../pages/zhExhibition/video2021/videoIndex";     
import Exhibitor from "../pages/zhExhibition/exhibitor/index";     
import SignIn from "../pages/SignIn/index.jsx"; 
import ClockPdf from "../pages/ClockPdf/index.jsx"; 

const routes = [
  {
    path: "/",
    exact: true,
    component: Home
  },
  {
    path: "/home:title_bar_no/:title_bar_no",
    exact: true,
    component: Home
  }, 
  {
    path: "/Login",
    exact: true,
    component: Login,
  },
  {
    path: "/login1",
    exact: true,
    component: login1,
  },
  {
    path: "/rule",
    exact: true,
    component: rule,
  },
  {
    path: "/register:type",
    exact: true,
    component: register,
  },
  {
    path: "/MoreArticles:title_bar_no/:keywords",
    exact: false,
    component: MoreArticles
  },
  {
    path: "/MoreOnlineCourse:title_bar_no",
    exact: false,
    component: MoreOnlineCourse
  },
  {
    path: "/OnlineCourseDetails:folder_id",
    exact: false,
    component: OnlineCourseDetails
  },
  {
    path: "/ArticleDetails:title_bar_no/:article_no",
    exact: true,
    component: ArticleDetails
  },
  {
    path: "/MHNews:title_bar_no/:title_bar_no",
    exact: false,
    component: MHNews
  },
  {
    path: "/ZCdecoding:title_bar_no/:title_bar_no",
    exact: false,
    component: ZCdecoding
  },
  {
    path: "/Exhibition:title_bar_no/:title_bar_no",
    exact: false,
    component: Exhibition
  },
  {
    path: "/Member:title_bar_no/:title_bar_no",
    exact: false,
    component: Member
  },
  {
    path: "/PersonalInformation",
    exact: false,
    component: PersonalInformation
  },
  {
    path: "/Special:title_bar_no",
    exact: false,
    component: Special
  },
  {
    path: "/AboutUs:title_bar_no",
    exact: false,
    component: AboutUs
  },
  {
    path: "/AdvertisingService:title_bar_no",
    exact: false,
    component: AdvertisingService
  },
  {
    path: "/ContactUs:title_bar_no",
    exact: false,
    component: ContactUs
  },
  {
    path: "/SmartSecurity:title_bar_no/:title_bar_no",
    exact: false,
    component: SmartSecurity
  },
  {
    path: "/FireFighting:title_bar_no/:title_bar_no",
    exact: false,
    component: FireFighting
  },
  {
    path: "/ExpertColumn:title_bar_no/:title_bar_no",
    exact: false,
    component: ExpertColumn
  },
  {
    path: "/OnlineClassroom",
    exact: false,
    component: OnlineClassroom
  },{
    path: "/PersonalCenter",
    exact: false,
    component: PersonalCenter
  },{
    path: "/ExpertList",
    exact: false,
    component: ExpertList
  },
  {
    path: "/ThematicTemplate:title_bar_no",
    exact: false,
    component: ThematicTemplate
  },{
    path: "/ForgetPwd",
    exact: false,
    component: ForgetPwd
  },{
    path: "/EntryForm:number",
    exact: false,
    component: EntryForm
  },{
    path: "/entry_form_list",
    exact: false,
    component: entry_form_list
  },
  {
    path: "/exhibitionHome",
    exact: true,
    component: ExhibitionHome
  },
  {
    path: "/exhibitionHome2021",
    exact: true,
    component: ExhibitionHome2021
  },
  {
    path: "/exhibitionHome2019",
    exact: true,
    component: ExhibitionHomeOld
  },{
    path: "/exhibitionHomedetails",
    exact: true,
    component: newsdetails
  },{
    path: "/exhibitionDetails",
    exact: true,
    component: ExhibitionDetails
  },{
    path: "/exhibitionDetails2021",
    exact: true,
    component: exhibitionDetails2021
  },{
    path:'/videoDetals:pid/:id',
    exact:true,
    component:VideoDetals
  },
  {
    path:'/video2021Detals:pid/:id',
    exact:true,
    component:VideoDetals2021
  },{
    path:'/videoList:pid',
    exact:true,
    component:VideoList
  },
  {
    path:'/video2021List:pid',
    exact:true,
    component:VideoList2021
  },
  {
    path: "/exhibitor",
    exact: true,
    component: Exhibitor
  },
  {
    path: "/SignIn",
    exact: true,
    component: SignIn,
  },
  {
    path: "/ClockPdf",
    exact: true,
    component: ClockPdf,
  },
  
];
export default routes;
