import Server from './server'

class ArticleApi extends Server{

  // 查询我是否点赞过文章接口
  QuerymineArticleLike(data) {
    
    try {
      let result = this.axios('post', '/RequestAddress/v2/api/querymine_article_like', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  // 文章点赞/取消点赞接口
  ArticleLike(data) {
    
    try {
      let result = this.axios('post', '/RequestAddress/v2/api/article_like', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  // 查询我是否收藏过文章接口
  QuerymineArticleCollection(data) {
    
    try {
      let result = this.axios('post', '/RequestAddress/v2/api/querymine_article_collection', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  // 收藏文章/取消收藏文章接口
  ArticleCollection(data) {
    
    try {
      let result = this.axios('post', '/RequestAddress/v2/api/article_collection', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  // 评论
  // 评论文章接口
  CommentArticle(data) {
    
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/comment_article', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  // 查询文章评论接口
  QueryArticleComment(data) {
    
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_article_comment', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }
  
  // 回复评论文章接口
  ReplyCommentArticle(data) {
    
    try {
      let result = this.axios('post', '/RequestAddress/v2/api/reply_comment_article', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  
  // 回复评论文章接口
  QueryReplyCommentArticle(data) {
    
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_reply_comment_article', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  // 根据关键字查询相关文章
  KeysQueryArticle(data) {
    
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/keys_query_article', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  // 根据id查询相关文章
  IdQueryArticle(data) {
    
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/id_query_article', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  // 文章点击接口(后台统计文章点击的总数
  ArticleClick(data) {
    
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/article_click', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  ArticleShareLog(data) {
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/article_share', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

}

export default new ArticleApi()