//home.js
import React, { Component } from 'react';
import styles from './index.module.less';
import { Icon, Tabs, Card, List, Spin, Button, Modal, Form, Input, message , Comment , Avatar , Tooltip } from 'antd';
import VideosApi from '@/src/api/VideosApi';
import PersonalCenterApi from '@/src/api/PersonalCenterApi';
import { tools } from '@/src/lib/tools';
import store from '@/src/redux/store.js';
import { setArticleDetails } from '@/src/redux/actions/ArticleDetails';
import { setBreadcrumbList } from '@/src/redux/actions/BreadcrumbList';
import API from '@/src/api/api';
import { setUserInfo } from '@/src/redux/actions/userInfo';
import InfiniteScroll from 'react-infinite-scroller';
import ImgCard from '@/src/components/TeacherIntroduction';
import { Player } from 'video-react';
import "@/src/pages/common/video-react/video-react.css";
import { base } from '@/src/api/base';
import PageContent from '@/src/components/PageContent';
import { HashRouter } from 'react-router-dom'
import moment from 'moment';

const router = new HashRouter()

const { TabPane } = Tabs;

const QRCode = require('qrcode.react');

export default class OnlineCourseDetails extends Component {

  state = {
    limit: 6,           // 分页每页显示条数
    offset: 0,
    loading: false,
    hasMore: true,
    BrowserType: 'PC', // PC Mobile 浏览器类型 手机或者pc端
    dataSource: [
      // {
      //   title: '第一节课，的简欧风共I还有啥低耦合该发生的房管局很反感好人同意',
      //   time: '45分钟',
      // },
      // {
      //   title: '第二节课，佛我好囧大润发几颗色剂noIP人家是给他的淡饭黄齑讴歌红角色等级和icxhio89uvhr98iygtr发过火发过火佛我好囧大润发几颗色剂noIP人家是给他的淡饭黄齑讴歌红角色等级和icxhio89uvhr98iygtr发过火发过火佛我好囧大润发几颗色剂noIP人家是给他的淡饭黄齑讴歌红角色等级和icxhio89uvhr98iygtr发过火发过火',
      //   time: '55分钟',
      // }
      // ,
      // {
      //   title: '第三节课，已偶手机吊诡的是',
      //   time: '30分钟',
      // }
    ],
    TeacherIntroduction: {
      data: [
        [
          {
            id: 1,
            pic: '',
            name: '宋老师',
            desc: '老师介绍，巴拉巴拉，老师介绍，巴拉巴拉，老师介绍，巴拉巴拉，老师介绍，巴拉巴拉'
          },
          {
            id: 2,
            pic: '',
            name: '李老师',
            desc: '老师介绍，巴拉巴拉，老师介绍，巴拉巴拉，老师介绍，巴拉巴拉，老师介绍，巴拉巴拉老师介绍，巴拉巴拉，老师介绍，巴拉巴拉，老师介绍，巴拉巴拉，老师介绍，巴拉巴拉老师介绍，巴拉巴拉，老师介绍，巴拉巴拉，老师介绍，巴拉巴拉，老师介绍，巴拉巴拉老师介绍，巴拉巴拉，老师介绍，巴拉巴拉，老师介绍，巴拉巴拉，老师介绍，巴拉巴拉老师介绍，巴拉巴拉，老师介绍，巴拉巴拉，老师介绍，巴拉巴拉，老师介绍，巴拉巴拉'
          }
        ],
        [
          {
            id: 3,
            pic: '',
            name: '1宋老师',
            desc: '老师介绍，巴拉巴拉，老师介绍，巴拉巴拉，老师介绍，巴拉巴拉，老师介绍，巴拉巴拉'
          },
          {
            id: 4,
            pic: '',
            name: '李老师',
            desc: '老师介绍，巴拉巴拉，老师介绍，巴拉巴拉，老师介绍，巴拉巴拉，老师介绍，巴拉巴拉'
          }
        ]
      ],
      loading: false,
    },
    video_url: '',
    data: {},
    loginVisible: false,
    PayVisible: false,
    IsQRCode: false,
    pay_code_url: '',
    pay_status: '0', // 付费状态 1未付费 2付费成功
    is_vip: '0', // 会员状态 0 未查询 1非会员号 2是会员

    // 评论
    CommentData: [],
    moreDataLoading: false,
    CommentLimit: 6,           // 分页每页显示条数
    CommentOffset: 0,
    CommentLoading: false,
    priceText: '' ,
    videoData: null ,
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeBind)
    this.resizeBind()
    const video = document.getElementById('video-1')
    video.addEventListener('timeupdate', this.VideoLoad)
    this.QueryIdVideo()
    this.GetData()

    this.MineBuyVideos()
    
    this.VideoClick()

  }

  componentWillUnmount() {

    window.removeEventListener('resize', this.resizeBind)
  }

  VideoLoad = (e) => {
    
    const { player } = this.player.getState();
    const { userInfo } = store.getState()
    if (!userInfo) {
      // this.player.replay(0)
      player.currentTime = 0
      this.player.pause()
      this.setState({ loginVisible: true })
      return
    }
    if (this.state.is_vip === '0' && this.state.pay_status === '0') {
      // this.MineBuyVideos()
      // return
    }
    if (this.state.pay_status === '2') {
      // 已购买
      return
    }
    
    if (this.state.is_vip === '2') {
      // 会员
      if (this.state.data && this.state.data.vip_price > 0 && this.state.pay_status !== '2' && player.currentTime > this.state.videoData.experience_time ) {
        player.currentTime = 0
        this.player.pause()
        this.setState({ PayVisible: true })
        return
      }
    } else if (this.state.is_vip === '1') {
      // 非会员
      if (this.state.data && this.state.data.price > 0 && this.state.pay_status !== '2' && player.currentTime > this.state.videoData.experience_time) {
        player.currentTime = 0
        this.player.pause()
        this.setState({ PayVisible: true })
        return
      }
    }

  }
  // farm_wallet
  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== 'Mobile') {
        this.setState({
          BrowserType: 'Mobile',
        });
      }
    } else {
      if (!this.state.BrowserType !== 'PC') {
        this.setState({
          BrowserType: 'PC',
        });
      }
    }
  }

  GetData = async () => {

    if (this.state.offset < 0 || this.state.loading) {
      return
    }
    const { offset, limit } = this.state;
    let _params = {
      limit,
      offset,
      folder_id: this.props.folder_id,
    }

    await this.setState({ loading: true });
    VideosApi.QueryVideoList(_params).then(res => {

      if (res.status === 200) {
        let dataSource1 = res.data.list.map((item2, index) => {
          item2.id = item2.id + '-' + tools.random(10000, 99999)
          item2.folder_id = _params.folder_id
          return item2
        });

        let dataSource = this.state.dataSource

        // dataSource = dataSource.concat(dataSource1)

        dataSource = [...dataSource1, ...this.state.dataSource]

        this.setState({ dataSource, offset: res.data.offset, loading: false, hasMore: true }, () => {
          if (offset === 0 && dataSource && dataSource.length > 0) {
            this.PlayVideo(dataSource[0].curriculum_id, false)
          }
        });
      } else {
        this.setState({ offset: -1, loading: false, hasMore: false });
        // notification.error({
        //   message: '查询失败！',
        //   description: res.msg,
        // });
      }

    }, () => {
      this.setState({ loading: false, hasMore: false });

    })
  }

  // 视频点击数量
  VideoClick = () => {
    let _params = {
      folder_id: this.props.folder_id,
    }
    VideosApi.VideoClick(_params).then(res => {
      if (res.status === 200) {
        

      } else {

      }

    }, () => {


    })
  }

  QueryIdVideo = () => {
    let _params = {
      folder_id: this.props.folder_id,
    }
    VideosApi.QueryIdVideo(_params).then(res => {
      if (res.status === 200) {
        if (res.data.length > 0) {
          const TeacherIntroduction = {
            loading: false,
            data: [
              [
                {
                  name: res.data[0].author,
                  desc: res.data[0].desc,
                }
              ]
            ]
          }
          
        this.setState({ data: res.data[0], TeacherIntroduction },() => {this.QueryArticleComment()});
        }

      } else {

      }

    }, () => {


    })
  }

  // 详情
  ToArticleDetails = (item) => {
    store.dispatch(setArticleDetails(item))
    let { BreadcrumbList } = store.getState()
    BreadcrumbList.push(
      {
        id: BreadcrumbList.length + 1,
        name: "新闻详情",
        path: '/ArticleDetails' + item.title_bar_no ?item.title_bar_no : '' + '/' + item.article_no
      }
    )
    store.dispatch(setBreadcrumbList(BreadcrumbList))
    router.history.push('/ArticleDetails' + item.title_bar_no ? item.title_bar_no : '' + '/' + item.article_no)
  }

  TabsCallback = key => {
    console.log(key)
  }

  PlayVideo = async (curriculum_id, autoPlay) => {
    const { userInfo } = store.getState()
    if (!userInfo && autoPlay) {
      // this.setState({loginVisible: true})
      return
    }
    const video = this.state.dataSource.filter(item => {
      return item.curriculum_id === curriculum_id
    })
    if (video && video.length > 0) {
      await this.setState({ videoData: video[0], video_url: video[0].media_url })
      if (autoPlay && this.player.load !== undefined) {
        this.player.load()
        this.player.play()
      }

    }

  }

  currentTime = (e) => {
    console.log("播放进度", e)
  }

  // 登录 
  LoginFunc = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);

        this.setState({ loading: true });
        API.accountLogin(values).then(res => {
          debugger
          if (res.status === 200) {

            let exp_time_day = 1
            if (values.remember) {
              // 记住我
              exp_time_day = 3
            }

            store.dispatch(setUserInfo(res.data, exp_time_day))

            // tools.setCookie("super_farm_token", res.data.super_farm_token, 7);
            // tools.setCookie("user_info", res.data, 7);
            this.MineBuyVideos()
            message.success("登录成功！");
            this.setState({ loginVisible: false })
            // router.history.push('/')

          } else {
            message.error(res.msg);
          }
          this.setState({ loading: false });
        }).catch(() => {
          this.setState({ loading: false });
        })

      }
    });

  }

  // 购买课程
  PayFunc = () => {
    if (this.state.IsQRCode) {
      this.MineBuyVideos()
      this.setState({ PayVisible: false })
    } else {

      let price = this.state.data.price
      if (this.state.is_vip === '2') {
        // 会员
        price = this.state.data.vip_price

      }

      this.setState({ loading: true })

      let _params = {
        pay_money: price,
        pay_type: 'wx',// 付款方式，alipay,wx，支付宝还是微信
        phone_sys: 1,//固定值1
        folder_name: this.state.data.folder_name,//目录文件夹名称
        folder_id: this.props.folder_id,//课程视频目录文件夹id
      }
      VideosApi.VideoPay(_params).then(res => {
        if (res.status === 200) {
          this.setState({ pay_code_url: res.data.code_url, IsQRCode: !this.state.IsQRCode, })
        } else {

        }
        this.setState({ loading: false })

      }, () => {


      })

    }


  }

  // 查询我是否买过该课程 和我是否支付过 
  MineBuyVideos = () => {

    let _params = {
      folder_id: this.props.folder_id,//课程视频目录文件夹id
    }
    VideosApi.MineBuyVideos(_params).then(res => {
      let pay_status = this.state.pay_status
      if (res.status === 200) {
        pay_status = res.data.pay_status
        // 1是未购买 2是已购买
        this.setState({ pay_status: res.data.pay_status })
      } else {

      }

      PersonalCenterApi.MineBuyVip(_params).then(res => {
        let is_vip = ''
        
        if (res.status === 200) {
          
          if (moment().format('X') < res.data.end_time) {
            is_vip = '2'
            this.setState({ is_vip: '2' })
          } else {
            is_vip = '1'
            this.setState({ is_vip: '1' })
          }

        } else if (res.status <= 500 && res.status > 200) {
          is_vip = '1'
          this.setState({ is_vip: '1' })
        }
        
        const { data } = this.state

        let priceText = '已购买'
        if (pay_status !== '2') {
          if (is_vip === '2') {
            if (data.vip_price <= 0) {
              priceText = '免费'
            }else {
              priceText = data.vip_price
            }
          }else{
            if (data.price <= 0) {
              priceText = '免费'
            }else {
              priceText = data.price
            }
          }
        }

        this.setState({ loading: false , priceText})

      })

    })

  }

  // 评论
  // 查询评论
  QueryArticleComment = async () => {

    const { data, CommentLimit, CommentOffset, moreDataLoading } = this.state

    if (moreDataLoading) {
      return
    }

    const params = {
      offset: CommentOffset,
      limit: CommentLimit,
      title_bar_no: data.title_bar_no, //标题栏系统生成唯一id
      folder_id: data.folder_id, //文章id(系统生成的唯一id
    }
    
    await this.setState({ moreDataLoading: true })

    VideosApi.QueryVideoComment(params).then(async res => {
      
      if (res.status === 200) {

        const { CommentData } = this.state

        const SeCommentData = await res.data.list.map(item => {
          item = {
            ...item,
            actions: [<span key="comment-list-reply-to-0" onClick={() => this.commentReplyShow(item)} >回复</span>],
            avatar: <Avatar
              icon="user"
              src={base.OssAddress + item.member_icon}
              alt={item.member_nick_name}
            />,
            datetime: (
              <Tooltip
                title={moment(item.creation_time * 1000)
                  .format('YYYY-MM-DD HH:mm:ss')}
              >
                <span>
                  {tools.FormatRemainTimeStr(item.creation_time)}
                </span>
              </Tooltip>
            ),
            isReply: false,
            offset: 0,
            limit: 2,
            loading: true,
            member_nick_name: item.member_nick_name || `${base.NameInitials}${item.account_id}`
          }

          const ReplyComment = this.QueryReplyCommentArticle(item.id, item.offset, item.limit)

          ReplyComment.then(data => {
            if (data.status === 200) {
              let ReplyCommentData = data.data.list.map(item2 => {
                item2 = {
                  ...item2,
                  avatar: <Avatar
                    icon="user"
                    src={base.OssAddress + item2.member_icon}
                    alt={item2.member_nick_name}
                  />,
                  datetime: (
                    <Tooltip
                      title={moment(item2.creation_time * 1000)
                        .format('YYYY-MM-DD HH:mm:ss')}
                    >
                      <span>
                        {tools.FormatRemainTimeStr(item2.creation_time)}
                      </span>
                    </Tooltip>
                  ),
                  member_nick_name: item2.member_nick_name || `${base.NameInitials}${item2.account_id}`
                }

                return item2
              })
              item.ReplyCommentData = ReplyCommentData
              item.offset = data.data.offset
              item.loading = false
            } else {
              item.offset = -1
              item.loading = false
            }
            
            this.setState({
              CommentData: CommentData.concat(SeCommentData),
              CommentOffset: res.data.offset,
              moreDataLoading: false,
            })
          })
          return item
        })
      } else {
        this.setState({
          CommentOffset: -1,
          moreDataLoading: false,
        })
      }
    })

  }

  CommentText = null

  // 发表评论
  SendComment = async (e) => {
    if (e) e.preventDefault();

    const { userInfo } = store.getState()

    if (!userInfo) {
      message.error("请先登录！")
      this.setState({ loginVisible: true })
      return
    }

    if (!this.CommentText.state.value || this.CommentText.state.value === undefined) {
      message.error("您还没填写您的评论内容！")
      return
    }

    const { data, loading } = this.state

    if (loading) {
      return
    }

    const params = {
      title_bar_no: data.title_bar_no, //标题栏系统生成唯一id
      folder_id: data.folder_id, //文章id(系统生成的唯一id
      folder_name: data.folder_name,
      content: this.CommentText.state.value,
    }

    await this.setState({ loading: true })
    VideosApi.CommentVideo(params).then(res => {
      if (res.status === 200) {
        data.comment_count = data.comment_count + 1 
        this.setState({CommentOffset: 0 , CommentData: [] , data} , () => { this.QueryArticleComment() })
        message.success(res.msg)

      } else {
        message.error(res.msg)

      }
      this.setState({
        loading: false
      })
    })

  }

  // 回复评论
  commentReplyShow = (item) => {

    let { CommentData } = this.state
    CommentData.map(item2 => {
      if (item.id === item2.id) {
        item2.isReply = true
      } else {
        item2.isReply = false
      }
      return item2
    })

    this.setState({
      CommentData: CommentData,
    })
  }

  commentReply = async (e, item) => {
    if (e) e.preventDefault();

    const { userInfo } = store.getState()

    if (!userInfo) {
      message.error("请先登录！")
      this.setState({ loginVisible: true })
      return
    }

    if (!item.ReplyText.state.value || item.ReplyText.state.value === undefined) {
      message.error("您还没填写回复内容！")
      return
    }

    const { loading } = this.state

    if (loading) {
      return
    }

    const params = {
      comment_id: item.id,
      content: item.ReplyText.state.value,
    }
    
    await this.setState({ loading: true })
    VideosApi.ReplyCommentVideo(params).then(res => {
      if (res.status === 200) {

        message.success(res.msg)

      } else {
        message.error(res.msg)

      }
      this.setState({
        loading: false
      })
    })

  }

  QueryReplyCommentArticle = async (comment_id, offset, limit) => {

    if (offset < 0) {
      return null
    }

    const params = {
      comment_id,
      offset,
      limit,
    }

    let data
    await VideosApi.QueryReplyCommentVideo(params).then(async res => {
      data = res
    })
    
    return data

  }

  // 查看更多评论回复
  MoreReplyComment = item => {

    if (item.offset < 0) {
      return
    }

    const { CommentData } = this.state

    CommentData.map(item2 => {
      if (item.id === item2.id) {
        item2.loading = true
      }
      return item2
    })

    this.setState({ CommentData })
    const ReplyComment = this.QueryReplyCommentArticle(item.id, item.offset, item.limit)
    ReplyComment.then(data => {
      
      if (data.status === 200) {
        let ReplyCommentData = data.data.list.map(item2 => {
          item2 = {
            ...item2,
            avatar: <Avatar
              icon="user"
              src={base.OssAddress + item2.member_icon}
              alt={item2.member_nick_name}
            />,
            datetime: (
              <Tooltip
                title={moment(item2.creation_time * 1000)
                  .format('YYYY-MM-DD HH:mm:ss')}
              >
                <span>
                  {tools.FormatRemainTimeStr(item2.creation_time)}
                </span>
              </Tooltip>
            ),
          }

          return item2
        })
        CommentData.map(item2 => {
          if (item.id === item2.id) {
            item2.ReplyCommentData = item2.ReplyCommentData.concat(ReplyCommentData)
            item2.loading = false
            item2.offset = data.data.offset
          }
          return item2
        })
        this.setState({
          CommentData: CommentData,
        })

      } else {
        CommentData.map(item2 => {
          if (item.id === item2.id) {
            item2.loading = false
            item2.offset = -1
          }
          return item2
        })

        this.setState({ CommentData })
      }

    })

  }

  render() {
    const {
      loading,
      offset,
      pay_status,
      data,
      dataSource,
      new_data_loading,
      TeacherIntroduction,
      CommentData,
      moreDataLoading,
      CommentOffset ,
      is_vip,
    } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <div className={styles.OnlineCourseDetailsBox + ' Layout'}>
        <PageContent loading={loading} >

          <div className={`${styles.SpecialItem}`}>

            <div style={{ margin: '1rem 0' }} >
              <Player ref={player => {
                this.player = player;
              }} videoId="video-1" playsInline={true} src={`${base.OssAddress}${this.state.video_url}`}

              >

              </Player>
            </div>

            <div className={styles.Desc}>
              <div className={styles.TabLeft}>

                <Tabs defaultActiveKey="1" onChange={this.TabsCallback} >
                  <TabPane tab="课程概述" key="1">

                    <div className={styles.CurriculumOverview}>
                      <div className={styles.ta1}>
                        <h3>老师介绍</h3>
                        <ImgCard dots={false} loading={TeacherIntroduction.loading} ShowHeaderTitle={false} data={TeacherIntroduction.data} />
                        <div className={styles.pic}>
                          <img src={data.promotional_pictures && data.promotional_pictures !== undefined && !data.promotional_pictures.startsWith('http') ? base.OssAddress + data.promotional_pictures : data.promotional_pictures} alt="" />
                          {/* <img src="https://img30.360buyimg.com/sku/jfs/t1/59576/22/4836/357799/5d2fe1fbEc4f5627a/8b9edb2dce25ba9b.jpg" alt="" /> */}
                        </div>
                      </div>
                    </div>

                  </TabPane>
                  <TabPane tab="目录" key="2">
                    <InfiniteScroll
                      className="CourseCatalogue"
                      initialLoad={true}
                      pageStart={0}
                      loadMore={this.GetData}
                      hasMore={!loading && this.state.hasMore}
                      useWindow={false}
                    >

                      <List
                        dataSource={dataSource}
                        renderItem={item => (

                          <a key={item.id}  >
                            <div className={styles.Catalog} onClick={() => {
                              this.PlayVideo(item.curriculum_id, true)
                            }} >
                              <div className={styles.PlaySquare}>
                                <Icon className={styles.TopicItemVideoIco} type="play-square" theme="filled" />
                                <span>{item.directory_name}</span>
                              </div>
                              <div className={styles.time}>
                                {item.duration}
                              </div>
                            </div>
                          </a>

                        )}
                      >

                        {(loading || new_data_loading) && this.state.hasMore ? (
                          <div className={styles.loading} >
                            <Spin />
                          </div>
                        ) : dataSource && dataSource.length === 0 && !loading ?
                            <div style={{ textAlign: 'center', padding: '0.8rem 0' }}>
                              <span> <Icon style={{ marginRight: '0.3rem' }} type="exclamation-circle" /> 暂无数据</span>
                            </div> :
                            offset === -1 ?
                              <div style={{ textAlign: 'center', padding: '0.8rem 0' }}>
                                <span> <Icon style={{ marginRight: '0.3rem' }} type="exclamation-circle" /> 没有更多了 </span>
                              </div>
                              : null
                        }

                      </List>

                    </InfiniteScroll>
                  </TabPane>
                  <TabPane tab={'评论(' + data.comment_count + ')'} key="3">
                    <div className={styles.comtent}>
                      {/* 发布评论 */}
                      <div className={styles.PublishComments} >
                        <div className={styles.ta1} >
                          <h3>会员评论</h3>
                          <span>评论 {data.comment_count}</span>
                        </div>
                        <div className={styles.ta2} >
                          <Input.TextArea ref={input => this.CommentText = input} rows={4} allowClear placeholder="写下您想说的话，最多200字 " maxLength={200} />
                          <Button type="primary" icon="edit" onClick={this.SendComment} >
                            发布评论
                          </Button>

                        </div>
                        <div className={styles.ta3}>
                          <span>网友评论仅供其表达个人看法，并不表明网站立场</span>
                        </div>
                      </div>

                      {/* 用户的评论 */}
                      <div className={styles.UserComment} >
                        <List
                          className="comment-list"
                          itemLayout="horizontal"
                          dataSource={CommentData}
                          loading={moreDataLoading}
                          locale={{
                            emptyText: (<div style={{ textAlign: 'center', padding: '0.8rem 0' }}>
                              <span> <Icon style={{ marginRight: '0.3rem' }} type="exclamation-circle" /> 暂无数据</span>
                            </div>)
                          }}
                          renderItem={item => (
                            <li>
                              <Comment
                                actions={item.actions}
                                author={item.member_nick_name}
                                avatar={item.avatar}
                                content={item.content}
                                datetime={item.datetime}
                              >
                                {
                                  item.isReply ?
                                    <div className={styles.ta2} >
                                      <Input.TextArea ref={input => item.ReplyText = input} rows={4} allowClear placeholder="写下您想说的话，最多200字 " maxLength={200} />
                                      <Button type="primary" icon="edit" onClick={(e) => this.commentReply(e, item)} >
                                        发布评论
                          </Button>
                                    </div> : null
                                }

                                {
                                  item.ReplyCommentData ?
                                    item.ReplyCommentData.map(item2 => {
                                      return (
                                        <Comment
                                          key={item2.id}
                                          author={item2.member_nick_name}
                                          avatar={item2.avatar}
                                          content={item2.content}
                                          datetime={item2.datetime}
                                        />
                                      )
                                    }) : null
                                }
                                {
                                  item.loading ?
                                    <div style={{ textAlign: 'center', padding: '0.8rem 0' }}>
                                      <span><Icon style={{ marginRight: '0.3rem' }} type="sync" spin />加载更多...</span>
                                    </div> : item.ReplyCommentData && item.ReplyCommentData.length > 0 && item.offset >= 0 ?
                                      <div className={styles.ReplyMoreComment} onClick={() => this.MoreReplyComment(item)}>
                                        <div >
                                          查看更多回复<Icon type="down-circle" />
                                        </div>
                                      </div>
                                      : null
                                }


                              </Comment>

                            </li>
                          )}
                        />

                        {
                          moreDataLoading ?
                            <div style={{ textAlign: 'center', padding: '0.8rem 0' }}>
                              <span><Icon style={{ marginRight: '0.3rem' }} type="sync" spin />加载更多...</span>
                            </div> : CommentData && CommentData.length === 0 ?
                              null :
                              CommentOffset === -1 ?
                                <div style={{ textAlign: 'center', padding: '0.8rem 0' }}>
                                  <span> <Icon style={{ marginRight: '0.3rem' }} type="exclamation-circle" /> 没有更多了 </span>
                                </div>
                                : <div className={styles.MoreComment} onClick={this.QueryArticleComment}>
                                  <div >
                                    查看更多
                                  <Icon type="down-circle" />
                                  </div>
                                </div>
                        }


                      </div>

                    </div>
                  </TabPane>
                </Tabs>
              </div>

              <div className={styles.Right}>
                <Card title={<div>课程介绍</div>} style={{ width: '95%', margin: '0 auto' }}>
                  <p>课程简介：{data.course_description}</p>
                  {/* <p>主要内容：大河南GVUI欧迪芬猴哥is饿哦地铁惊魂共I荣猴哥给后台关于活动润体乳</p> */}
                  <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.5rem' }}>
                    {/* {pay_status === '2' ? '已购买' :
                      is_vip === '2' ? (data.vip_price <= 0 ? '免费' : '￥' + data.vip_price)  : (data.price <= 0 ? '免费' : '￥' + data.price)
                    } */}
                    ￥{this.state.priceText}
                    
                  </p>
                  <div style={{ display: 'flex', flexDirection: 'column' }} >
                    {this.state.priceText !== '免费' ? 
                    <Button type="primary" onClick={() => {
                      const { userInfo } = store.getState()
                      if (!userInfo) {
                        this.setState({ loginVisible: true })
                        return
                      }
                      if (data.price <= 0) {
                        return
                      }
                      
                      if (this.state.is_vip === '0' || this.state.pay_status === '0') {
                        this.MineBuyVideos()
                        return
                      }

                      if (this.state.is_vip === '2') {
                        // 会员
                        if (this.state.data && this.state.data.vip_price > 0 && this.state.pay_status !== '2') {

                          this.setState({ PayVisible: true })
                          return
                        }
                      } else if (this.state.is_vip === '1') {
                        // 非会员
                        if (this.state.data && this.state.data.price > 0 && this.state.pay_status !== '2') {

                          this.setState({ PayVisible: true })
                          return
                        }
                      }
                    }} >{this.state.pay_status === '2' ? '已购买' : '立即购买'}</Button>
                    : null}
                    <Button type="primary" style={{ marginTop: '1rem' }} >会员九折</Button>
                  </div>
                </Card>
              </div>

            </div>


          </div >

          {/* 登录框 */}
          <Modal
            okText={'确认'}
            cancelText={'取消'}
            visible={this.state.loginVisible}
            onOk={this.LoginFunc}
            onCancel={() => { this.setState({ loginVisible: false }) }}
          >
            <div className={styles.RightBox} >
              <Form className="login-form" >

                <div style={{ marginBottom: "1.5rem", textAlign: "center" }}>
                  <h2>用户登录</h2>
                </div>

                <Form.Item>
                  {getFieldDecorator('phone', {
                    rules: [{ required: true, message: '请输入手机号!' }],
                  })(
                    <Input
                      type='tel'
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="手机号"
                    />,
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('pass_word', {
                    rules: [{ required: true, message: '请填写密码!' }],
                  })(
                    <Input
                      prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="password"
                      placeholder="密码"
                    />,
                  )}
                </Form.Item>
                <Form.Item>

                  <a href="/#/register1" style={{ float: "right" }} >立即注册</a>
                  {/* <div style={{ marginTop: "1.5rem" }} >
                  <Button type="primary" onClick={this.Login} className="login-form-button" block> 登 录 </Button>
                </div> */}

                </Form.Item>
              </Form>
            </div>
          </Modal>


          {/* 付费框 */}
          <Modal
            title={"购买课程"}
            okText={this.state.IsQRCode ? '完成' : '去付款'}
            cancelText={'取消'}
            visible={this.state.PayVisible}
            onOk={this.PayFunc}
            onCancel={() => {
              this.setState({ PayVisible: false })
            }}
          >

            {
              !this.state.IsQRCode ?
                <div className={styles.PayBox}>
                  <div className={styles.pay_left}>
                    <img src={base.OssAddress + data.cover_pictures} alt={data.folder_name} />
                  </div>
                  <div className={styles.pay_right}>
                    <div className={styles.ta1}>{data.folder_name}</div>
                    <div className={styles.ta2}>
                      课程简介：{data.course_description}
                    </div>
                    <div className={styles.ta3}>¥ { this.state.is_vip === '2' ? data.vip_price : data.price}</div>
                    <div className={styles.ta4}>付款方式 微信支付</div>
                  </div>
                </div>
                :
                <div className={styles.code}>

                  <div>
                    <QRCode value={this.state.pay_code_url} size={120} />
                  </div>
                  <div>
                    使用微信扫描二维码完成支付 (支付完有延迟，请等待并及时刷新页面)
                </div>
                </div>

            }


          </Modal>

        </PageContent>
      </div>
    )
  }

}

OnlineCourseDetails = Form.create({})(OnlineCourseDetails);