import React, { Component } from 'react';
import { Card, Icon, Carousel, Col, Row } from 'antd';
import styles from './index.module.less';
import store from '@/src/redux/store.js';
import { setArticleDetails } from '@/src/redux/actions/ArticleDetails';
import { setBreadcrumbList } from '@/src/redux/actions/BreadcrumbList';

import { HashRouter } from 'react-router-dom'
import { base } from '../../api/base';
import { tools } from "@/src/lib/tools";
const router = new HashRouter()

class ImgCard extends Component {

  state = {
    BrowserType: 'PC', // PC Mobile 浏览器类型 手机或者pc端
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeBind)
    this.resizeBind()
  }

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== 'Mobile') {
        this.setState({
          BrowserType: 'Mobile',
        });
      }
    } else {
      if (!this.state.BrowserType !== 'PC') {
        this.setState({
          BrowserType: 'PC',
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeBind)
  }

  // 详情
  ImgCardContentClick = (item) => {

    if (item.title_bar_no === '1022' || item.title_bar_no === '1036') {
      if (item.skip_url && item.skip_url.length > 3) {
        window.open(item.skip_url);
        return;
      }
      router.history.push('/ThematicTemplate' + item.subject_no + '/ThematicTemplate')
      return
    }

    if (item.title_bar_no === '1038' || item.title_bar_no === '1039') {
      return
    }
    store.dispatch(setArticleDetails(item))
    if (item.title_bar_no === '1033') {
      store.dispatch(setBreadcrumbList(
        [
          {
            id: '1',
            name: this.props.name,
            path: null,
          },
          {
            id: '2',
            name: item.folder_name,
            path: '/OnlineCourseDetails:' + item.folder_id
          }
        ]
      ))
      router.history.push('/OnlineCourseDetails' + item.folder_id)
    } else {
      store.dispatch(setBreadcrumbList(
        [
          {
            id: '1',
            name: this.props.name,
            path: this.props.path,
          },
          {
            id: '2',
            name: "新闻详情",
            path: '/ArticleDetails' + item.title_bar_no + '/' + item.article_no
          }
        ]
      ))
      // router.history.push('/ArticleDetails' + item.title_bar_no + '/' + item.article_no)
      window.open(
        base.localhost +
        "/#/ArticleDetails" +
        item.title_bar_no +
        "/" +
        item.article_no
      );
    }

  }

  // 更多
  MoreArticles = (title_bar_no, title) => {

    if (title_bar_no === '1033') {
      store.dispatch(setBreadcrumbList(
        [
          {
            id: '1',
            name: title_bar_no,
            path: null,
          },
          {
            id: '2',
            name: title,
            path: '/OnlineClassroom'
          }
        ]
      ))

      router.history.push('/OnlineClassroom')

    } else if (title_bar_no === '1022' || title_bar_no === '1036') {
      store.dispatch(setBreadcrumbList(
        [
          {
            id: '1',
            name: title_bar_no,
            path: null,
          },
          {
            id: '2',
            name: "专题",
            path: '/Special' + title_bar_no
          }
        ]
      ))

      router.history.push('/Special' + title_bar_no)
    } else {
      store.dispatch(setBreadcrumbList(
        [
          {
            id: '1',
            name: title_bar_no,
            path: null,
          },
          {
            id: '2',
            name: title,
            path: '/MoreArticles' + title_bar_no + '/0'
          }
        ]
      ))

      router.history.push('/MoreArticles' + title_bar_no + '/0')
    }

  }

  render() {

    let ShowHeaderTitle = true
    if (this.props.ShowHeaderTitle !== undefined) {
      ShowHeaderTitle = this.props.ShowHeaderTitle
    }

    let ShowMore = true
    if (this.props.ShowMore !== undefined) {
      ShowMore = this.props.ShowMore
    }

    let loading = true
    if (this.props.loading !== undefined) {
      loading = this.props.loading
    }
    let ShowMask = true
    if (this.props.ShowMask !== undefined) {
      ShowMask = this.props.ShowMask
    }

    let md = 8
    if (this.props.md && this.props.md !== undefined) {
      md = this.props.md
    }

    const { BrowserType } = this.state

    return (
      <div className={`${BrowserType === 'PC' ? styles.ImgCard : styles.ImgCardMo} ${!ShowHeaderTitle > 0 ? styles.NoHeaderTitle : ''} ${BrowserType === 'PC' ? + ' PCImgCard1' : styles.MobileCard}`}>

        <Card
          title={ShowHeaderTitle ?<div >{this.props.title}</div> : ''}

          extra={ShowMore && ShowHeaderTitle ? (<div className={styles.ImgCardMore} onClick={() => { this.MoreArticles(this.props.title_bar_no, this.props.title) }}>查看更多内容 ></div>) : ''}

          bordered={false}
          loading={loading}
          bodyStyle={{ paddingLeft: '0.2rem', paddingRight: '0.2rem' }} >

          <div className={styles.IcoTa1 + ' ' + styles.IcoTa} onClick={() => this.Carousel.prev()} >
            <Icon type="left" />
          </div>
          <div className={styles.IcoTa2 + ' ' + styles.IcoTa} onClick={() => this.Carousel.next()} >
            <Icon type="right" />
          </div>

          <Carousel className={styles.ImgCardList} {...this.props} ref={dom => { this.Carousel = dom; }} >
            {
              this.props.data && this.props.data.length > 0 ?

                this.props.data.map((item, index) => {
                  return (
                    <div className={styles.ImgCardContent} key={index}  >
                      <Row gutter={this.props.title_bar_no === "1036" ? [16,16] : [16,40]} justify="space-between" type="flex" style={{marginBottom: 0}}>
                      {
                        item && item.length > 0 ?
                          item.map((item2, index) => {
                            return (
                              <Col className={this.state.TopicItemCss} xs={item.length === 1 ? 24 : 12} sm={item.length === 1 ? 24 : 12} md={md} key={item2.id + index}>

                                <div className={this.props.isHover ? `${styles.ImgCardTa1} ${index < item.length - 1 ? styles.paddingRight1 : ''}` : styles.ImgCardTa2 } key={item2.id} >
                                  <div className={styles.ImgCardPic}>
                                    <img src={`${base.OssAddress}${item2.pic}`} alt={item2.title} />
                                    {item2.title && item2.title !== undefined ?
                                      <div className={styles.ImgCardTitle} >
                                        <span>{item2.title}</span>
                                      </div>
                                      : null}
                                  </div>
                                  {
                                    ShowMask === true ? 
                                      <div className={styles.back}>
                                        <div>
                                          <div className={styles.backTitle}>{item2.title}</div>
                                          <div className={styles.backIntroduction}>
                                            {item2.introduction}
                                          </div>
                                          <div className={styles.timeBox}>
                                            <span>{ tools.UnixToStr(item2.update_time,0,1,1)}</span>
                                            <Icon type="arrow-right"onClick={this.ImgCardContentClick.bind(this, item2)}/>
                                          </div>
                                        </div>
                                      </div> : <div className={styles.mask}></div>
                                  }
                                </div>

                              </Col>
                            )
                          }) : null
                      }
                      </Row>
                    </div>
                  )
                }) : null
            }

          </Carousel>


        </Card>

      </div>
    )
  }
}
export default ImgCard;