//home.js
import React, { Component } from 'react';
import styles from './index.module.less';
import { Icon, Card, Row, Col, Badge, List , Spin } from 'antd';
import API from '@/src/api/Home';
import { tools } from '@/src/lib/tools';
import store from '@/src/redux/store.js';
import { setArticleDetails } from '@/src/redux/actions/ArticleDetails';
import { setBreadcrumbList } from '@/src/redux/actions/BreadcrumbList';
// import SpecialItem from '@/src/components/SpecialItem';
import Swiper from '@/src/components/Carousel/swiper';
import InfiniteScroll from 'react-infinite-scroller';

import { HashRouter } from 'react-router-dom'
const router = new HashRouter()

export default class Home extends Component {

  state = {
    dataSource: [],     // 表格数据
    new_data_limit: 6,
    limit: 6,           // 分页每页显示条数
    offset: 0,
    new_data_loading: true,
    loading: false,
    hasMore: true,
    BrowserType: 'PC', // PC Mobile 浏览器类型 手机或者pc端
    data: {
      swiper: [],
    },
    showRedPoint: true,
    HistoryData: [],
    NewSpecialData: [],
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeBind)
    this.resizeBind()

    this.GetNewData(() => this.GetData())

    this.GetSwiper()
  }

  componentWillUnmount() {

    window.removeEventListener('resize', this.resizeBind)
  }

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== 'Mobile') {
        this.setState({
          BrowserType: 'Mobile',
        });
      }
    } else {
      if (!this.state.BrowserType !== 'PC') {
        this.setState({
          BrowserType: 'PC',
        });
      }
    }
  }

  GetNewData = async (callback) => {
    const { new_data_limit } = this.state;
    let _params = {
      limit: new_data_limit,
      offset: 0,
      title_bar_no: this.props.title_bar_no,
    }

    await this.setState({ new_data_loading: true });
    await API.QuerySubject(_params).then(res => {
      console.log(res)
      if (res.status === 200) {
        let dataSource1 = res.data.list.map((item2, index) => {
          item2.id = item2.id + '-' + tools.random(10000, 99999)
          if (index === 0 && item2.title_bar_no === '1001') {
            item2.BoldTitle = true
          }
          item2.title_bar_no = item2.subject_no
          return item2
        });

        let dataSource = this.state.dataSource

        dataSource = dataSource.concat(dataSource1)

        this.setState({ dataSource, NewSpecialData: dataSource.slice(0, new_data_limit), offset: res.data.offset, new_data_loading: false },() => callback());
      } else {
        this.setState({ offset: -1, new_data_loading: false },() => callback());
        // notification.error({
        //   message: '查询失败！',
        //   description: res.msg,
        // });
      }
      
    }, () => {
      this.setState({ new_data_loading: false });
      callback()
    })
  }

  GetData = async () => {
    if (this.state.offset < this.state.new_data_limit || this.state.loading) {
      return
    }
    const { offset, limit } = this.state;
    let _params = {
      limit,
      offset,
      title_bar_no: this.props.title_bar_no,
    }
    // QueryArticle
    await this.setState({ loading: true });
    API.QuerySubject(_params).then(res => {
      console.log(res)
      if (res.status === 200) {
        let dataSource1 = res.data.list.map((item2, index) => {
          item2.id = item2.id + '-' + tools.random(10000, 99999)
          if (index === 0 && item2.title_bar_no === '1001') {
            item2.BoldTitle = true
          }
          item2.title_bar_no = item2.subject_no
          return item2
        });

        // let dataSource = this.state.dataSource

        // dataSource = dataSource.concat(dataSource1)

        // dataSource = [...dataSource , this.state.dataSource]
        let { HistoryData } = this.state
        // HistoryData.push(...dataSource1)
        this.setState({ HistoryData: [...HistoryData, ...dataSource1], offset: res.data.offset, loading: false, hasMore: true });
      } else {
        this.setState({ offset: -1, loading: false, hasMore: false });
        // notification.error({
        //   message: '查询失败！',
        //   description: res.msg,
        // });
      }

    }, () => {
      this.setState({ loading: false, hasMore: false });

    })
  }

  GetSwiper = () => {

    let params = { title_bar_no: this.props.title_bar_no, offset: 0, limit: 50 }
    API.QueryAdvertise(params).then(res => {
      if (res.status === 200) {
        let SwiperDataList = []
        res.data.list.forEach(element => {
          switch (element.advertise_location) {
            case 1:
              SwiperDataList = [...SwiperDataList, element]
              break;

            default:
              break;
          }
        });
        this.setState({
          data: {
            ...this.state.data,
            swiper: SwiperDataList,
          },
        })
      } else {

      }


    })
  }

  // 详情
  ToArticleDetails = (item) => {
    // store.dispatch(setArticleDetails(item))
    // let { BreadcrumbList } = store.getState()
    // BreadcrumbList.push(
    //   {
    //     id: BreadcrumbList.length + 1,
    //     name: "新闻详情",
    //     path: '/ArticleDetails' + item.title_bar_no + '/' + item.article_no
    //   }
    // )
    // store.dispatch(setBreadcrumbList(BreadcrumbList))
    // router.history.push('/ArticleDetails'+ item.title_bar_no + '/' + item.article_no)
    window.open(`${item.skip_url}`, "_blank");
  }

  render() {
    const {
      loading,
      offset,
      data,
      BrowserType,
      showRedPoint,
      NewSpecialData,
      HistoryData,
      new_data_loading,
    } = this.state;

    return (
      <div className={styles.MoreArticleContentBox + ' Layout'}>
        <h1 className={ BrowserType === 'PC' ? styles.title : styles.titleMo} >专题汇总</h1>

        {/* <SpecialItem NewSpecialData={dataSource.slice(0,limit)} HistoryData={dataSource.slice(limit,dataSource.length)} loading={loading} swiper={data.swiper}  /> */}

        <div className={`${styles.SpecialItem}`}>

          <Card title={'最新专题'} bordered={false} loading={new_data_loading} bodyStyle={{ paddingLeft: '0.2rem', paddingRight: '0.2rem' }} >
            <Col xs={24} sm={24} md={data.swiper && data.swiper.length > 0 && BrowserType === 'PC' ? 12 : 24} >
              <div className={BrowserType === 'PC' ? styles.SpecialItemList : ''} >
                {
                  NewSpecialData ?
                    NewSpecialData.map((item) => {

                      return (
                        <div className={styles.SpecialItemContent} key={item.id} onClick={() => this.ToArticleDetails(item)} >

                          <div className={BrowserType === 'PC' ? styles.SpecialItemTitle : styles.SpecialItemTitleMo}>
                            <div>
                              {showRedPoint ? ' | ' : null}
                              <span className={item.BoldTitle ? styles.SpecialItemBoldTitle : ''} >{item.subject_name}</span>
                            </div>
                            {BrowserType === 'PC' ? <div className={styles.time}>
                              {/* {tools.UnixToStr(item.creation_time, 0, 1)} */}
                              {item.release_time ? tools.UnixToStr(item.release_time,0,1) : tools.UnixToStr(item.creation_time,0,1)}
                            </div> : null}
                          </div>
                        </div>
                      )
                    })
                    : null
                }
              </div>
            </Col>

          </Card>

          <Card title={'历史专题汇总'} bordered={false} bodyStyle={{ padding: '0' }} style={{display: 'none'}}>
            <Row justify='space-between' type='flex' >
              <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={this.GetData}
                hasMore={(!loading || new_data_loading) && this.state.hasMore}
                useWindow={true}

                style={{    width: '100%'}}
              >
                <div >

                  {
                    HistoryData ?
                      HistoryData.map((item) => {

                        return (
                          <Col xs={24} sm={24} md={BrowserType === 'PC' ? 12 : 24} key={item.id} >
                            <div className={styles.SpecialItemContent + ' ' + styles.SpecialItemContent2} onClick={() => this.ToArticleDetails(item)} >

                              <div className={styles.SpecialItemTitle}>
                                <div>
                                  {showRedPoint ? <Badge className={styles.Badge} color={'pink'} /> : null}
                                  <span className={item.BoldTitle ? styles.SpecialItemBoldTitle : ''} >{item.title}</span>
                                </div>
                                {BrowserType === 'PC' ? <div className={styles.time}>
                                  {tools.UnixToStr(item.creation_time, 0, 1)}
                                </div> : null}
                              </div>
                            </div>
                          </Col>
                        )
                      })
                      : null
                  }
                </div>

                <List
                  
                  dataSource={HistoryData}
                  renderItem={item => (
                    <Col xs={24} sm={24} md={BrowserType === 'PC' ? 12 : 24} key={item.id} >
                        <div className={styles.SpecialItemContent + ' ' + styles.SpecialItemContent2} onClick={() => this.ToArticleDetails(item)} >

                          <div className={styles.SpecialItemTitle}>
                            <div>
                              {showRedPoint ? ' | ' : null}
                              <span className={item.BoldTitle ? styles.SpecialItemBoldTitle : ''} >{item.subject_name}</span>
                            </div>
                            {BrowserType === 'PC' ? <div className={styles.time}>
                              {/* {tools.UnixToStr(item.creation_time, 0, 1)} */}
                              {item.release_time ? tools.UnixToStr(item.release_time,0,1) : tools.UnixToStr(item.creation_time,0,1)}
                            </div> : null}
                          </div>
                        </div>
                      </Col>
                  )}
                >
                 
                  {(loading || new_data_loading) && this.state.hasMore ? (
                    <div className={styles.loading} >
                      <Spin />
                    </div>
                  ):HistoryData && HistoryData.length === 0 && !loading ?
                  <div style={{ textAlign: 'center', padding: '0.8rem 0' }}>
                    <span> <Icon style={{ marginRight: '0.3rem' }} type="exclamation-circle" /> 暂无数据</span>
                  </div> :
                  offset === -1 ?
                    <div style={{ textAlign: 'center', padding: '0.8rem 0' }}>
                      <span> <Icon style={{ marginRight: '0.3rem' }} type="exclamation-circle" /> 没有更多了 </span>
                    </div>
                    : null
                  }
                  
                </List>

              </InfiniteScroll>
            </Row>
          </Card>


        </div >

        {/* {
          loading ?
            <div style={{ textAlign: 'center', padding: '0.8rem 0' }}>
              <span><Icon style={{ marginRight: '0.3rem' }} type="sync" spin />加载更多...</span>
            </div> : null
        } */}


      </div>
    )
  }

}
