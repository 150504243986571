//home.js
import React, { Component } from "react";
import styles from "./index.module.less";
import API from '@/src/api/api.js';
import { base } from '@/src/api/base';
import store from '@/src/redux/store.js';
import { setUserInfo } from "@/src/redux/actions/userInfo";

import {
    Form,
    Input,
    Button,
    Col,
    Upload,
    message,
    Spin,
    notification,
    Icon,
    Modal
} from "antd";
import UploadAxios from '@/src/api/Upload';
import MD5 from 'crypto-js/md5';
import { HashRouter } from 'react-router-dom'
import { tools } from "@/src/lib/tools";
const router = new HashRouter()
export default class SignIn extends Component {
    state = {
        OSSData: { host: '' }, ImgFileState: false, update_file: null, file_name: '', file_list: [],
        loading: false,
        imageUrl: '',
        modalVisible: false, //vip弹框显示
        uploadLoading: false,
        isOkBtn: true,
        color: '',
        btnLoading: false
    };
    getUserInfo = () => {
        var { userInfo } = store.getState();
        if (userInfo) {
            let params = {
                phone: userInfo.phone
            }
            API.isSign(params).then((res) => {
                if (res.status == 200) {
                    if (res.data > 0) {
                        window.location.href = 'http://www.cacps.cn/#/ArticleDetails9001/20211210112430475638846424354816'
                    }
                }
            })
        }
    }
    
    inputOnBlur = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    isOkBtn: false,
                    color: '#319EF8'
                })
            }
        })
    }
    toClock = async(e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!tools.checkPhone(values.co_contact_phone)) {
                message.error("请输入正确的手机号！");
                return;
            } 
            if (!err) {
                values.co_contact_pic = this.state.imageUrl
                this.uploadSignIn(values)
            }
        })
    }
    uploadSignIn(values) {
        this.setState({
            loading: true
        })
        let params = {
            user_name: values.co_contact_userName,
            work_units: values.co_contact_workUnits,
            phone: values.co_contact_phone,
            pic: values.co_contact_pic,
            pass_word: values.co_contact_passWord
        };
        API.Clock(params).then((res)=>{
            console.log(res)
            if (res.status == 200) {
                let exp_time_day = 1;
                store.dispatch(setUserInfo(res.data, exp_time_day));
                this.setState({
                    modalVisible: true
                })
            }else if(res.status == 301) {
                window.location.href= 'http://www.cacps.cn/#/ArticleDetails9001/20211210112430475638846424354816'
            }else {
                message.info(res.data);
            }
        })
    }
    phoneInputOnBlur = (e) =>{
        let phoneNum = e.target.value
        if (!tools.checkPhone(phoneNum)) {
            message.error("请输入正确的手机号！");
            return;
        }
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    isOkBtn: false,
                    color: '#319EF8'
                })
            }
        })
    }
    clockTrue = () => {
        this.setState({
            modalVisible: false,
            btnLoading: true
        })
        // 跳转pdf页面
        window.location.href= 'http://www.cacps.cn/#/ArticleDetails9001/20211210112430475638846424354816'
    }
    componentWillMount() {
        this.getUserInfo()
        this.init()    
    }
    init = async () => {
        this.GetOSSData(OSSData => {
            if (OSSData === null) {
                return
            }

            this.setState({
                OSSData,
            });
        });
    };
    GetOSSData = (callback) => {
        UploadAxios.GetOsstoken({}).then(res => {
            if (res.status === 200) {
                callback(res.data);
            } else {
                notification.error({
                    message: '失败！',
                    description: res.msg,
                });
            };
            callback(null)
        }).catch(() => {
            notification.error({
                message: '失败！',
                description: "网络超时！",
            });

        });

    };
    beforeUpload = async () => {

        // const { OSSData } = this.state;
        // const expire = OSSData.expire * 1000;

        // if (expire < Date.now()) {
        //     await this.init();
        // }

        return true;
    };
    // 检查图片压缩等工作是否准备完毕 生成图片名称
    CheckBeforeUploadingPictures() {
        if (this.state.ImgFileState) {
            notification.warning({
                message: '提示！',
                description: "图片正在压缩中，请稍后提交！",
            });
            return false;
        }

        if (this.state.file_list.length === 0) {
            notification.warning({
                message: '提示！',
                description: "请选择上传图片！",
            });
            return false;
        }

        let file = this.state.file
        let filename = MD5(file.name + file.uid);
        let fileType = file.name.split(".");
        fileType = `.${fileType[fileType.length - 1]}`;
        filename += fileType;
        const ImgkeyName = `${this.props.folder}/${filename}`;
        this.setState({ file_name: ImgkeyName })

        return ImgkeyName
    }
    // 用户选取图片 将图片进行压缩
    onChange =(e) => {
        this.setState({ file_list: e.fileList })
        if (e.file.status === 'uploading') {
            this.setState({
                uploadLoading: true
            })
            return;
        }
        if (e.file.status === 'done') {
            this.setState({ imageUrl: e.file.url, uploadLoading: false})
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.setState({
                        isOkBtn: false,
                        color: '#319EF8'
                    })
                }
            })
        }
    }
    transformFile = async file => {
        const { OSSData } = this.state;
        const suffix = file.name.slice(file.name.lastIndexOf('.'));
        const filename = MD5(Date.now() + file.name + file.uid) + suffix;
        file.url = OSSData.dir + filename;
        this.setState({ file: file, ImgFileState: true })
        return file;
    };
    fileList = [];
    getExtraData = (file) => {
        const { OSSData } = this.state;
        return {
            key: file.url,
            OSSAccessKeyId: OSSData.accessid,
            policy: OSSData.policy,
            Signature: OSSData.signature,
        };
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
        };
        const { OSSData, imageUrl, uploadLoading, isOkBtn,color } = this.state;
            
        const uploadButton = (
            <div className={styles.Upload}>
                {
                 uploadLoading ? (
                    <Icon type="loading" />
                 ) :(
                    <Icon type="plus" />
                 )   
                }
            </div>    
        );
        return (
            <div>
                <Spin spinning={this.state.loading} delay={10} tip="签到中">
                    <div className="LoginBox box-margin-top">
                        <div className={styles.ContentBox}>
                            <Col xs={23} sm={23} md={20} style={{ margin: "0 auto" }}>
                                <div className={styles.RightBox}>
                                    <Form className="login-form">
                                        <div className={styles.titleText}>签到</div>


                                        <div className={styles.boxBorder}>
                                            <Form.Item
                                                {...formItemLayout}
                                                label="姓名："
                                                hasFeedback
                                            >
                                                {getFieldDecorator("co_contact_userName", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "请填写您的姓名!",
                                                        },
                                                    ],
                                                })(<Input placeholder="请填写您的姓名！" onBlur={this.inputOnBlur} />)}
                                            </Form.Item>
                                            <Form.Item
                                                {...formItemLayout}
                                                label="单位："
                                                hasFeedback
                                            >
                                                {getFieldDecorator("co_contact_workUnits", {
                                                    rules: [
                                                        { required: true, message: "请填写您的单位!" },
                                                    ],
                                                })(<Input placeholder="请填写您的单位!" onBlur={this.inputOnBlur}/>)}
                                            </Form.Item>
                                            <Form.Item
                                                {...formItemLayout}
                                                label="手机号码"
                                                hasFeedback
                                            >
                                                {getFieldDecorator("co_contact_phone", {
                                                    rules: [
                                                        { required: true, message: "请填写您的手机!" },
                                                    ],
                                                })(<Input type="tel" placeholder="请填写您的手机" onBlur={this.phoneInputOnBlur.bind(this) } />)}
                                            </Form.Item>
                                            <Form.Item
                                                {...formItemLayout}
                                                label="登录密码"
                                                hasFeedback
                                            >
                                                {getFieldDecorator("co_contact_passWord", {
                                                    rules: [
                                                        { required: true, message: "请填写登录密码!" },
                                                    ],
                                                })(<Input placeholder="请填写登录密码" onBlur={this.inputOnBlur}/>)}
                                            </Form.Item>
                                            <Form.Item
                                                {...formItemLayout}
                                                label="请上传48小时内核酸证明"
                                                hasFeedback
                                            >
                                                {getFieldDecorator("co_contact_pic", {
                                                    rules: [
                                                        { required: true, message: "请上传核酸证明!" },
                                                    ],
                                                })
                                                    (<div>
                                                        <Upload action={OSSData.host}
                                                            accept="image/*"
                                                            showUploadList={false}
                                                            beforeUpload={this.beforeUpload.bind(this)}
                                                            onChange={this.onChange.bind(this)}
                                                            transformFile={this.transformFile.bind(this)}
                                                            data={this.getExtraData.bind(this)}>
                                                            {imageUrl ? <img className={styles.reportImg} src={base.OssAddress + imageUrl} alt="核酸检测报告" style={{ width: '100%' }} /> : uploadButton}
                                                        </Upload>
                                                    </div>)}
                                            </Form.Item>
                                            <Form.Item>
                                                <div className={styles.registeredBtn}>
                                                    <Button
                                                        style={{background:color}}
                                                        disabled={isOkBtn}
                                                        type="primary"
                                                        onClick={this.toClock.bind(this)}
                                                        block
                                                    >
                                                        确定签到
                                                    </Button>
                                                </div>
                                            </Form.Item>
                                        </div>
                                    </Form>
                                    <Modal
                                        width="200px"
                                        style={{ top: 200 }}
                                        visible={this.state.modalVisible}
                                        footer={null}
                                        closable={false}
                                    >
                                        <Spin spinning={this.state.btnLoading} delay={10} tip="签到中">
                                            <div className={styles.modalContent}>
                                                <p>您已经成功签到</p>
                                                <p>点击确定领取大会议程</p>
                                                <button onClick={this.clockTrue.bind(this)}>确定</button>
                                            </div>
                                        </Spin>
                                        
                                    </Modal>
                                </div>
                            </Col>
                        </div>
                    </div>
                </Spin>
            </div>
        );
    }

    // 获取上传oss文件子组件
    onUploadRef = (ref) => {
        this.children = ref;
    };

    onFileSuccess = (res) => {
        if (res.status === 200) {
        } else {
            message.success("文章上传失败，请重新上传！");
        }
    };
}

SignIn = Form.create({})(SignIn);
