import React, { Component } from 'react';
import { Row, Col , Avatar } from 'antd';
import styles from './index.module.less';
import store from '@/src/redux/store.js';
import { base } from '@/src/api/base';
import { HashRouter } from 'react-router-dom'
const router = new HashRouter()

class PageHeader extends Component {

  state = {

  };

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  openPersonalCenter = () => {
    router.history.push('/PersonalCenter');
  };

  render() {
    const {  userInfo } = store.getState()

    let showUser = false
    if (this.props.showUser && this.props.showUser !== undefined) {
      showUser = this.props.showUser
    }
    // console.log(!userInfo , !showUser)
    // console.log(!userInfo && !showUser)

    return (
      <div className={styles.HeaderBox} >

        <Row justify='space-between' type='flex' >
          <Col xs={0} sm={0} md={24}  >

            <div className={styles.Box}>
              <div className={styles.ta1}>
                <a href="/#/"> <div className={styles.ta1_1} >
                  <img className={styles.logo} src={require('../../assets/logo1.png')} alt="民航安保网" />
                </div>
                </a>
                <div className={styles.Title}>
                  {this.props.title}
                </div>
              </div>

              <div className={styles.ta1}>
                
                  {userInfo && showUser ? 
                    <div>
                    <Avatar onClick={this.openPersonalCenter} icon="user"  src={base.OssAddress + userInfo.head_url}  />
                    <span onClick={this.openPersonalCenter} className={styles.NickName} >{userInfo.user_name || `${base.NameInitials}${userInfo.id}`}</span>
                  </div>
                    :
                    <div>
                      {this.props.title !== '注册' ? '没有' : '已有'}账号?&nbsp;
                      {
                        this.props.title !== '注册' ?
                          <a href="/#/register1"> &nbsp;立即注册</a> :
                          <a href="/#/login">&nbsp; 立即登录</a>
                      }
                    </div>

                  }
                  
                
                <div className={styles.Title}>
                  <a href="/#/">首页</a>
                </div>
              </div>
            </div>


          </Col>

          <Col xs={24} sm={24} md={0} className={styles.modile} >
            <div className={styles.Title}>
              <a href="/#/">首页</a>
            </div>
          </Col>

        </Row>
      </div>
    )
  }
}

export default PageHeader;