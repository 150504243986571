//home.js
import React, { Component } from 'react';
import styles from './index.module.less';
import API from '../../api/api';

import { Form, Input, Button, Col, } from 'antd';
import { message, Spin } from 'antd';
import LoginHeader from '@/src/components/LoginHeader'
import PageFooter from '@/src/components/PageFooter';
import { tools } from '@/src/lib/tools';
import { HashRouter } from 'react-router-dom'
const router = new HashRouter()

export default class Login extends Component {

    state = {
        loading: false,
        codeStr: "发送验证码",
        codeState: false, // 验证码按钮触发状态 
        count_down_time: 60,
        AccountTypeList: [],
        confirmDirty: false,
        IsUploadOriginalArticle: false,
        UploadOriginalArticleUrl: '',
        zj_account_type_no: '',
        register_type: 1 ,
    };

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('pass_word')) {
            callback('两次密码不一致!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['pass_word2'], { force: true });
        }
        callback();
    };

    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
        };

        return (
            <div>
                <Spin spinning={this.state.loading} delay={10} tip="提交中" >

                    <div className="LoginBox box-margin-top">

                        <LoginHeader title="忘记密码" />

                        <div className={styles.ContentBox}>

                            <Col xs={23} sm={23} md={20} style={{ margin: '0 auto' }} >

                                <div className={styles.RightBox} >
                                    <Form className="login-form" >

                                        <div style={{ marginBottom: "1.5rem" }}>
                                            <h2>忘记密码</h2>
                                        </div>

                                        <Form.Item {...formItemLayout} label="手机号" hasFeedback >
                                            {getFieldDecorator('account', {
                                                rules: [{ required: true, message: '请输入手机号!' }],
                                            })(
                                                <Input
                                                    type='tel'
                                                    placeholder="手机号"
                                                />,
                                            )}
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="验证码" className={styles.codeForm} >
                                            {getFieldDecorator('code', {
                                                rules: [{ required: true, message: '请输入验证码!' }],
                                            })(
                                                <Input
                                                    placeholder="请输入验证码!"
                                                />,
                                            )}
                                            <Button type="primary" onClick={this.SendCode} disabled={this.state.codeState} className="login-form-button" > {this.state.codeStr} </Button>
                                        </Form.Item>
                                        <Form.Item label="登录密码" {...formItemLayout} hasFeedback>
                                            {getFieldDecorator('pass_word', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请填写登录密码!',
                                                    },
                                                    {
                                                        validator: this.validateToNextPassword,
                                                    },
                                                ],
                                            })(<Input.Password placeholder="请填写登录密码!" />)}
                                        </Form.Item>
                                        <Form.Item label="确认密码" {...formItemLayout} hasFeedback>
                                            {getFieldDecorator('pass_word2', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请确认登录密码!',
                                                    },
                                                    {
                                                        validator: this.compareToFirstPassword,
                                                    },
                                                ],
                                            })(<Input.Password onBlur={this.handleConfirmBlur} placeholder="请确认登录密码！" />)}
                                        </Form.Item>
                                       

                                        <Form.Item >

                                            <a href="/#/login" style={{ float: "right" }} >返回登录</a>
                                            <div style={{ marginTop: "1.5rem" }} >
                                                <Button type="primary" onClick={this.Register} className="login-form-button" block> 确 认 </Button>
                                            </div>

                                        </Form.Item>
                                    </Form>
                                </div>

                            </Col>
                        </div>


                        <PageFooter IsAdvertisementShow={false} colorE9f1fc={false} />

                    </div>
                </Spin>
            </div>
        )
    }

    componentDidMount() {
        
        
    }

    SendCode = () => {
        
        let phone = this.props.form.getFieldValue('account')
        
        // 检查手机号是否正确
        if (!tools.checkPhone(phone)) {
            message.error('请输入正确的手机号！');
            return
        }

        // 先禁用按钮 避免请求延时 用户点击多次

        var params = {
            account: phone,
            action: "resetPsw",
            country_code: "86",
        };
        
        // 验证码
        this.setState({ loading: true, codeState: true });

        API.GetVerificationCode(params).then(result => {
            this.setState({ loading: false });
            if (result.status === 200) {
                // 禁用发送验证码 开始倒计时 
                this.setState({ codeState: true });
                this.VerificationCodeCountdown()
                // 记录现在获取验证码的手机号
                // this.code_phone = this.phone
            } else {
                this.setState({ codeState: false });
                message.error(result.msg);
            }

        }).catch(res => {
            // console.log(res)
            this.setState({ loading: false });
        })

    }

    VerificationCodeCountdown() {
        const{ count_down_time } = this.state
        let time = count_down_time
        var timer = setInterval(() => {

            time--

            if (time === 0) {

                this.setState({ codeState: false, codeStr: '获取验证码' });

                clearInterval(timer)

            } else {

                this.setState({ codeStr: time + 's' });

            }

        }, 1000)

    }

    Register = async (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);

                this.setState({ loading: true });
                values.password = values.pass_word
                API.FindPassword(values).then(res => {
                    this.setState({ loading: false });
                    if (res.status === 200) {

                        message.success("修改成功！");
                        router.history.push('/login')

                    } else {
                        message.error(res.msg);
                    }

                }).catch(res => {
                    // console.log(res)
                    this.setState({ loading: false });
                })

            }
        });



    }

}

Login = Form.create({})(Login);