import React, { Component } from "react";
import { Icon, List, Col, Button ,message} from "antd";

import styles from "./message.module.less";
import store from "@/src/redux/store.js";
import PersonalCenterApi from "@/src/api/PersonalCenterApi";
import moment from "moment";
import { HashRouter } from "react-router-dom";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
const router = new HashRouter();
/**
 * 我的消息
 */

export default class Message extends Component {
  state = {
    offset: 0,
    limit: 5,
    listData: [],
    isPC: this.props.is_pc,
    UnreadNum:0   //未读消息数
  };
  
  componentWillMount() {
    const { userInfo } = store.getState();

    // is_vip 会员状态 0 未查询 1非会员号 2是会员
    let receiver_type = 2;
    if (
      this.props.is_vip === "2" &&
      userInfo.experts_type_no !== undefined &&
      userInfo.experts_type_no.length > 0
    ) {
      // 即是会员又是专家
      receiver_type = 5;
    } else if (this.props.is_vip === "2") {
      // 只是会员
      receiver_type = 3;
    } else if (
      userInfo.experts_type_no !== undefined &&
      userInfo.experts_type_no.length > 0
    ) {
      // 只是专家
      receiver_type = 4;
    }
    this.setState({ receiver_type }, () => {
      this.QueryMessage();
    });
  }

  // 查询消息
  QueryMessage = () => {
    const { userInfo } = store.getState();
    const { limit, offset, receiver_type } = this.state;
    const vip_start_time = this.props.vip_start_time || 0;
    console.log({ offset, limit, receiver_type, vip_start_time });
    PersonalCenterApi.QueryMessage({
      offset,
      limit,
      receiver_type,
      vip_start_time,
    }).then((res) => {
      if (res.status === 200) {
        // redirect:type="消息内容"&value="跳转链接"
        res.data.list.map((item) => {
          
          // let content = 'redirect:type=消息内容&value=跳转链接'
          let redirect = this.ParsingUrlLikeBannerParameters(item.content);
          if (redirect.type && redirect.type !== undefined) {
            item.content_text = redirect.type;
          } else {
            item.content_text = item.content;
          }
          if (redirect.value && redirect.value !== undefined) {
            item.url = redirect.value;
          } else {
            item.url = "";
          }
          return item;
        });

        //已读消息
        console.log(offset, limit)
        PersonalCenterApi.QueryMessage1({'accountId':userInfo.id,offset, limit:999}).then((sun) => {
          console.log('已读消息',sun)
          if (sun.status === 200) {
            console.log('res',res)
            res.data.list.forEach((item,i) =>{
              sun.data.list.forEach((list,j) =>{
                if(item.id === + list.message_no){
                  res.data.list[i].read = true
                }
              })
            })
            this.setState({
              listData: res.data.list,
              total: res.data.total,
              offset: res.data.offset,
              UnreadNum : res.data.list.length - sun.data.list.length
            });
          } else {
            this.setState({ offset: -1 });
            
          }
        });
        this.setState({
          listData: res.data.list,
          total: res.data.total,
          offset: res.data.offset,
        });
      } else {
        
        this.setState({ offset: -1 });
      }
      
    });
  };

  ParsingUrlLikeBannerParameters(param) {
    var obj = {};
    //判断传入参数，以问号截取，问号后是参数
    // var chars = param.split(':')[1];
    var chars = param.replace("redirect:", "");

    //再截&号
    var arr = chars.split("&");

    //获得截取后的数组为键值对字符串
    for (var i = 0; i < arr.length; i++) {
      //保守一点确定看是否为 name=value形式
      var num = arr[i].indexOf("=");

      if (num > 0) {
        //拼接字符串
        var name = arr[i].substring(0, num);
        var value = arr[i].substr(num + 1);

        //拼接对象，并转码
        obj[decodeURIComponent(name)] = decodeURIComponent(value);
      }
    }

    obj.has = function (key) {
      return this[key] !== undefined;
    };
    //console.log(obj);
    return obj;
  }

  //全部标记为已读
  MarkReadAll = ()=>{
    console.log(this.state.listData)
  }




  render() {
    // for (let i = 0; i < 23; i++) {
    //     listData.push({
    //         name: '推送消息',
    //         time: '2020年03月03日',
    //         content: '消息内容消息内容消容消息内容消息内容',
    //     });
    // }

    // this.state.isPC

    const { limit, listData } = this.state;
    const { userInfo } = store.getState();
    return (
      <>
        <div className={styles.navMO}>
          <span>收信箱（共{this.state.total || 0}条）</span>
          <Button type="primary" onClick={()=>{ 
            let str = ''
            listData.forEach((item,ind) => {
              if(ind == listData.length-1){
                str += item.id
              }else{
                str+=item.id + ','
              }
              // str.push(item.id)
            })
            
            PersonalCenterApi.MarkReadAll({'ids':str}).then((res)=>{
              if(res){
                message.success(res.msg)
                // let dataLiat = JSON.parse(res)
              }else{
                message.success('暂无未读信息')
              }
              
            })  

          }}>
            全部标记为已读
          </Button>
        </div>
        <List
          className={styles.contentMO}
          style={{ padding: "0 2rem" }}
          itemLayout="vertical"
          size="large"
          pagination={{
            onChange: (page) => {
              this.setState({ offset: page * this.state.limit }, () => {
                this.QueryMessage();
              });
            },
            pageSize: limit,
          }}
          dataSource={listData}
          renderItem={(item) => (
            <List.Item
            onClick = {()=>{
              this.props.xiaoxi(item);
              router.history.replace("/PersonalCenter/NewsDetails");
              PersonalCenterApi.ClickRead({'MId':item.id +' ','AId':userInfo.id}).then(res=>{
                console.log(res)
              })
            }}
              className={
                item.url && item.url.length > 0 ? styles.cursorPointer : ""
              }
              key={item.title}
              
            >
              <List.Item.Meta />
              <div
                className={styles.message}
                onClick={() => {
                  if (item.url.length === 0) {
                    return;
                  }
                  // window.location.href = item.url;
                }}
              >
                <div className={styles.div1}>
                  <Col xs={24} sm={24} md={4} style={{width:'100%'}}>
                    {
                      item.read ? (<span>{<Icon type="left" />}</span>):(<span>{<Icon type="mail" />}</span>)
                    }
                    

                    <span className={styles.name}>{item.message_title}</span>
                      <div className={styles.time}>
                        {moment(item.creation_time * 1000).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                      </div>
                  </Col>
                  <Col xs={24} sm={24} md={20}>
                    <span className={styles.content}>{item.content_text}</span>
                  </Col>
                </div>

                {/* {this.state.isPC ? (
                  <div className={styles.time}>
                    {moment(item.creation_time * 1000).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                  </div>
                ) : null} */}
              </div>
            </List.Item>
          )}
        />
      </>
    );
  }
}
