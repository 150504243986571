import React, { Component } from "react";
import { Row, Col, Anchor, Carousel, Modal, Collapse, Divider } from "antd";
import styles from "./home.module.less";
import PageContent from "@/src/components/PageContent";
import store from "@/src/redux/store.js";
import API from "../../../api/exhibition";
import { base } from "@/src/api/base";
import { setAdvertisement } from "@/src/redux/actions/Advertisement";
import { tools } from "@/src/lib/tools";
import swiperPatch from "@/src/components/swiperPatch/swiperPatch";
import { HashRouter } from "react-router-dom";
import moment from "moment";
import PersonalCenterApi from "@/src/api/PersonalCenterApi";
import { setArticleDetails } from "@/src/redux/actions/ArticleDetails";
import { setBreadcrumbList } from "@/src/redux/actions/BreadcrumbList";
// import SwiperCore, { EffectCoverflow, Pagination } from "swiper";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper.less";
// import SwiperFun from "swiper";
import MySwiper from "../components/swiper/swiper";
// import 'swiper/components/pagination/pagination.less';

// SwiperCore.use([EffectCoverflow, Pagination]);

const router = new HashRouter();
const { Link } = Anchor;
const { Panel } = Collapse;

class Content extends Component {
  state = {
    name: "首页",
    speakers: {
      name: "陈金祖",
      work: "总经理",
      describe: "深圳市机场（集团）有限公司",
    },
    awardName: "最受欢迎奖",
    titleArr: [
      "特别贡献奖",
      "最受欢迎奖",
      "最具潜力奖",
      "最具影响力奖",
      "最佳创新奖",
      "新媒体宣传奖",
    ],
    exhbitionData: [], //总数据
    videoList: [], //视频列表
    introductionData: "",
    internationalGuestsList: [], //国际嘉宾
    domesticGuestsList: [], //国内嘉宾
    guestList: [], //嘉宾
    guestActive: "domestic", //选中嘉宾
    sponsorList: [], //赞助商
    agendaList: [], //大会议程列表
    agendaData: [], //大会议程详情
    latestNewsData: "", //最新资讯
    samePeriodList: [], //同期展览
    cooperationUnitList: [], //合作单位9
    cooperationMediaLsit: [], //合作媒体10
    designatedMediaList: [], //指定媒体11
    BrowserType: "PC", // PC Mobile 浏览器类型 手机或者pc端
    isLoad: false,
    showModal: false, //展商目录弹框
    loading: false,
    is_vip: "0",
    showIndex: 0, //大会议程展示索引
  };

  componentDidMount() {
    window.addEventListener("resize", this.resizeBind);
    this.resizeBind();
    this.QueryExhbition();
    setTimeout(() => {
      this.setState({
        isLoad: true,
      });
    }, 500);
    this.MineBuyVip();
    this.swiperInit();
  }
  swiperInit = () => {
    // new SwiperFun(".swiper-container", {
    //   effect: "coverflow",
    //   grabCursor: true,
    //   centeredSlides: true,
    //   slidesPerView: "auto",
    //   coverflowEffect: {
    //     rotate: 50,
    //     stretch: 0,
    //     depth: 100,
    //     modifier: 1,
    //     slideShadows: true,
    //   },
    //   pagination: {
    //     el: ".swiper-pagination",
    //   },
    // });
    // new SwiperFun(".swiper-container",{
    // 	pagination: ".swiper-dots",
    // 	navigator: {
    // 		prev: ".swiper-btn-prev",
    // 		next: ".swiper-btn-next"
    // 	},
    // 	delay: 3000, // 切换的时间 默认2000
    // 	autoPlay: true // 是否自动切换 默认true
    // });
  };

  // 查询是否是会员
  MineBuyVip = () => {
    PersonalCenterApi.MineBuyVip().then((res) => {
      console.log("isVip", res);
      if (res.status === 200) {
        if (moment().format("X") < res.data.end_time) {
          this.setState({ is_vip: "2" });
        } else {
          this.setState({ is_vip: "1" });
        }
      } else {
        // this.setState({ is_vip: "1" });
      }
      // this.setState({ menuKey: "1" });
    });
  };

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== "Mobile") {
        this.setState({
          BrowserType: "Mobile",
        });
        // 主持嘉宾滚动条位置初始化
      }
    } else {
      if (!this.state.BrowserType !== "PC") {
        this.setState({
          BrowserType: "PC",
        });
      }
    }
  };

  componentWillUnmount() {
    // 对 store 变化取消监听
    if (this.state.guest_clear_timeout) {
      this.state.guest_clear_timeout();
    }
    if (this.state.sponsor_clear_timeout) {
      this.state.sponsor_clear_timeout();
    }
    window.removeEventListener("resize", this.resizeBind);
  }
  QueryExhbition = () => {
    let params = {
      limit: 1000,
    };
    this.setState({ loading: true });
    console.log("params", params);
    API.QueryExhbition(params)
      .then((res) => {
        console.log("data", res);
        if (res.status === 200) {
          var exhbitionData = res.data.list;
          var introductionData = exhbitionData.filter((item) => {
            return item.id == 1;
          })[0];
          introductionData = introductionData.content;
          if (this.state.BrowserType !== "PC") {
            introductionData = introductionData.replace(
              /font-size:\w+;?/g,
              "font-size:15px"
            );
            introductionData = introductionData.replace(
              /line-height:\w+;?/g,
              "line-height:1"
            );
          }
          // var re1 = /\<(\p.*)\>/g;
          //  var re1=/<\/(p.*)>/gm;
          // var r = introductionData.replace(re1, '<a href="/#/exhibitionDetails">[查看更多]</a>');
          // console.log(r)
          let videoList = exhbitionData.filter((item) => {
            return item.pid === 47 && item.is_hidden !== 2;
          });
          console.log(videoList, "视频列表1");

          if (videoList.length > 0) {
            let arr = videoList.sort(function (a, b) {
              return a.sort - b.sort;
            });
            this.setState({ videoList, arr });
          }
          var internationalGuestsList = exhbitionData.filter((item) => {
            return item.pid == 2;
          });

          var domesticGuestsList = exhbitionData.filter((item) => {
            return item.pid == 3;
          });
          if (
            internationalGuestsList[0].is_hidden == 1 &&
            domesticGuestsList[0].is_hidden == 2
          ) {
            this.setState({
              guestActive: "international",
              guestList: internationalGuestsList,
            });
          } else if (
            internationalGuestsList[0].is_hidden == 2 &&
            domesticGuestsList[0].is_hidden == 2
          ) {
            this.setState({ guestActive: "", guestList: [] });
          } else {
            this.setState({
              guestActive: "domestic",
              guestList: domesticGuestsList,
            });
          }
          // 大会议程
          var agendaData = exhbitionData.filter((item) => {
            return item.pid == 4 && item.pid == item.id;
          });
          var agendaList = [];
          if (agendaData[0].is_hidden == 1) {
            var agendaShow = exhbitionData.filter((item) => {
              return (
                [19, 20, 21, 22, 23, 100, 176].indexOf(item.pid) != -1 &&
                item.pid == item.id &&
                item.is_hidden == 1
              );
            });
            if (agendaShow.length > 0) {
              agendaShow = this.sortFn(agendaShow);
              agendaShow.forEach((x) => {
                let arr = exhbitionData.filter((item) => {
                  return item.pid == x.id && item.id !== item.pid;
                });
                arr = this.sortFn(arr);
                arr = [x, ...arr];
                agendaList.push(arr);
              });
            }
          }

          var sponsorList = exhbitionData.filter((item) => {
            return item.pid == 5 && item.pid !== item.id;
          });
          var sponsorData = exhbitionData.filter((item) => {
            return item.pid == 5 && item.pid == item.id;
          });
          var latestNewsData = exhbitionData.filter((item) => {
            return item.id == 7;
          });
          var samePeriodList = exhbitionData.filter((item) => {
            return item.pid == 8;
          });
          var cooperationUnitList = exhbitionData.filter((item) => {
            return item.pid == 9;
          });
          var cooperationMediaLsit = exhbitionData.filter((item) => {
            return item.pid == 10;
          });
          var designatedMediaList = exhbitionData.filter((item) => {
            return item.pid == 11;
          });
          internationalGuestsList = this.sortFn(internationalGuestsList);
          domesticGuestsList = this.sortFn(domesticGuestsList);
          sponsorList = this.sortFn(sponsorList);
          samePeriodList = this.sortFn(samePeriodList);
          cooperationUnitList = this.sortFn(cooperationUnitList);
          cooperationMediaLsit = this.sortFn(cooperationMediaLsit);
          designatedMediaList = this.sortFn(designatedMediaList);

          this.setState(
            {
              exhbitionData,
              introductionData,
              internationalGuestsList,
              domesticGuestsList,
              sponsorList,
              sponsorData,
              agendaList,
              agendaData,
              latestNewsData,
              samePeriodList,
              cooperationUnitList,
              cooperationMediaLsit,
              designatedMediaList,
            },
            () => {
              this.sponsorSwiper();
              this.guestSwiper();
            }
          );
        } else {
          // message.error(res.msg);
        }
        this.setState({ loading: false });
      })
      .catch((res) => {
        this.setState({ loading: false });
      });
  };
  // 根据pid查询
  QueryExhbitionByPid = () => {
    var { isYear, exhbitorParams, offset, limit } = this.state;
    let params = {
      limit,
      offset,
      pid: 3,
    };
    this.setState({ loading: true });
    API.QueryExhbitionByPid(params)
      .then((res) => {
        return;
        if (res.status === 200) {
          var exhbitorData = res.data.list;
          this.setState({
            exhbitorData,
            total: res.data.total,
          });
        } else {
          // message.error(res.msg);
        }
        this.setState({ loading: false });
      })
      .catch((res) => {
        this.setState({ loading: false });
      });
  };
  // 排序
  sortFn = (arr) => {
    if (arr && arr.length > 1) {
      arr.sort(function (a, b) {
        if (a.sort < b.sort) {
          return -1;
        } else if (a.sort == b.sort) {
          return 0;
        } else {
          return 1;
        }
      });
    }
    return arr;
  };
  //嘉宾分类切换
  handleChangeGuest = (type) => {
    if (type == "international") {
      this.setState({
        guestList: this.state.internationalGuestsList,
        guestActive: "international",
      });
    } else {
      this.setState({
        guestList: this.state.domesticGuestsList,
        guestActive: "domestic",
      });
    }
  };
  guestSwiper = () => {
    const carousel_autoplay_time = 3000;
    const {
      carousel_autoplay,
      carousel_prev,
      carousel_next,
      clear_timeout,
    } = swiperPatch(this.slider, carousel_autoplay_time);
    this.setState({
      carousel_prev,
      carousel_next,
      guest_clear_timeout: clear_timeout,
    });
    // 启动轮播
    carousel_autoplay();
    // 离开清理定时器
    // return clear_timeout
  };
  sponsorSwiper = () => {
    const carousel_autoplay_time = 2500;
    const { carousel_autoplay, clear_timeout } = swiperPatch(
      this.sponsorCarousel,
      carousel_autoplay_time
    );
    this.setState({
      sponsor_clear_timeout: clear_timeout,
    });
    // 启动轮播
    carousel_autoplay();
    // 离开清理定时器
    // return clear_timeout
  };
  toExhibitor = () => {
    const { userInfo } = store.getState();
    if (!userInfo || this.state.is_vip <= 1) {
      this.setState({ showModal: true });
    } else {
      document.documentElement.scrollTop = document.body.scrollTop =0;
      router.history.push("/exhibitor");
    }
  };

  /**
   * @大会议程点击事件
   */
  agendaClick = (index) => {
    this.setState({ showIndex: index });
  };

  // 视频详情
  ImgCardContentClick = (item) => {
    // router.history.push("/videoDetals" + item.pid + "/" + item.id,'_blank');
    window.open(`/#/videoDetals${item.pid}/${item.id}`);
  };
  changeSpeakers = (name, work, describe) => {
    this.setState({
      speakers: {
        name,
        work,
        describe,
      },
    });
  };
  awardNameChange(e, that) {
    let name = that.state.titleArr[e.activeIndex % 5];
    that.setState({
      awardName: name,
    });
  }

  render() {
    const {
      BrowserType,
      loading,
      exhbitionData,
      introductionData,
      guestList,
      internationalGuestsList,
      domesticGuestsList,
      guestActive,
      sponsorList,
      sponsorData,
      agendaList,
      latestNewsData,
      samePeriodList,
      carousel_prev,
      carousel_next,
      cooperationUnitList, //合作单位9
      cooperationMediaLsit, //合作媒体10
      designatedMediaList, //指定媒体
      isLoad,
      showModal,
      showIndex,
      prizeData,
      videoList,
      speakers,
      awardName,
    } = this.state;
    console.log("视频列表", videoList);
    var md = 12;
    var isPC = true;
    var adMd = 8;
    if (BrowserType !== "PC") {
      md = 24;
      isPC = false;
    }
    if (document.querySelector(".home_host__2ZE4e")) {
      let left = document.querySelector(".home_host__2ZE4e").scrollLeft;
      document.querySelector(".home_host__2ZE4e").scrollLeft = 160;
    }
    //嘉宾
    let guestPageSize = isPC ? 10 : 6;
    var guestPageNum = Math.ceil(guestList.length / guestPageSize);
    let guestHtml = [];
    for (var i = 0; i < guestPageNum; i++) {
      guestHtml.push(
        <div key={i}>
          <div className={styles.swpiperItem}>
            <Row>
              {guestList.map((item, ind) => {
                return ind != 0 &&
                  ind <= (i + 1) * guestPageSize &&
                  ind > i * guestPageSize ? (
                  <Col
                    span={isPC ? 4 : 7}
                    key={ind}
                    offset={ind % (guestPageSize / 2) == 0 ? 0 : 1}
                  >
                    <div className={styles.guestBox}>
                      <div className={styles.imgBox}>
                        <img
                          src={
                            item.pic.startsWith("http")
                              ? item.pic
                              : `${base.OssAddress}${item.pic}`
                          }
                          alt=""
                        />
                      </div>
                      <p className={styles.name}>{item.content_short}</p>
                      <p>{item.content}</p>
                    </div>
                  </Col>
                ) : null;
              })}
            </Row>
          </div>
        </div>
      );
    }

    // 赞助商
    let sponsorPageSize = isPC ? 4 : 4;
    var sponsorPageNum = Math.ceil(sponsorList.length / sponsorPageSize);
    let sponsorHtml = [];
    for (var i = 0; i < sponsorPageNum; i++) {
      sponsorHtml.push(
        <div key={i}>
          <div className={styles.swpiperItem}>
            <Row>
              {sponsorList.map((item, ind) => {
                return ind < (i + 1) * sponsorPageSize &&
                  ind >= i * sponsorPageSize ? (
                  <Col
                    key={ind}
                    span={isPC ? 6 : 12}
                  >
                    <div className={styles.sponsorItem}>
                      <div className={styles.imgBox}>
                        <img
                          src={
                            item.pic.startsWith("http")
                              ? item.pic
                              : `${base.OssAddress}${item.pic}`
                          }
                          alt=""
                        />
                      </div>
                      <p className={styles.name}>{item.content_short}</p>
                      {/* <p className={styles.col999}>{item.address}</p> */}
                    </div>
                  </Col>
                ) : null;
              })}
            </Row>
          </div>
        </div>
      );
    }

    // 大会议程
    let agendaHtml = [];
    let agendaHtmlHeader = [];
    if (agendaList && agendaList.length > 0) {
      console.log("大会议程", agendaList);
      let classArr = [];
      if (isPC) {
        classArr = [
          "nextRight",
          "nextRight",
          "nextBottom",
          "nextBottom",
          "nextLeft",
          "nextLeft",
          "nextRight",
          "nextRight",
        ];
      } else {
        classArr = [
          "nextRight",
          "nextBottom",
          "nextBottom",
          "nextLeft",
          "nextRight",
          "nextBottom",
          "nextBottom",
          "nextRight",
        ];
      }
      agendaList.forEach((item, ind) => {
        let j = ind;
        if (isPC) {
          switch (ind) {
            case 3:
              j = 5;
              break;
            case 5:
              j = 3;
              break;
          }
        } else {
          switch (ind) {
            case 2:
              j = 3;
              break;
            case 3:
              j = 2;
              break;
            case 7:
              j = 8;
              break;
            case 8:
              j = 7;
              break;
          }
        }

        // 获取头部html
        agendaHtmlHeader[j] = (
          <div
            className={j === agendaList.length - 1 ? "" : styles[classArr[j]]}
          >
            <div
              className={
                this.state.showIndex == ind
                  ? styles.itemListActive
                  : styles.itemList
              }
              key={item[0].id}
              onClick={(e) => {
                this.agendaClick(ind);
              }}
            >
              <p>{item[0].category}</p>
              <p>{item[0].content_short}</p>
            </div>
          </div>
        );
      });

      if (isPC) {
        agendaList.forEach((item, ind) => {
          if (ind === showIndex) {
            // 获取当天议程列表
            let html = (
              <div key={ind} className={styles.agendaCon}>
                <div className={styles.header}>
                  <span>{item[0].category}</span>
                  <span className={styles.divider}>|</span>
                  <span>{item[0].content_short}</span>
                </div>
                <table className={styles.agendaList}>
                  {item.map((x, y) => {
                    if (y !== 0) {
                      return (
                        <tr key={x.id} className={styles.item}>
                          <td className={styles.left}>
                            <p className={styles.time}>
                              <img
                                src={require("../assets/image/icon_time.png")}
                                alt=""
                              />
                              <span>{x.category}</span>
                            </p>
                            <p className={styles.tit}>{x.content_short}</p>
                          </td>
                          <td className={styles.right}>{x.address}</td>
                        </tr>
                      );
                    }
                  })}
                </table>
              </div>
            );
            agendaHtml.push(html);
          }
        });
        console.log(agendaHtmlHeader, "dom");
      } else {
        agendaList.forEach((item, ind) => {
          if (ind !== showIndex) {
            return false;
          }
          let html = (
            <div key={ind} className={styles.agendaCon}>
              <div className={styles.header}>
                <span>{item[0].category}</span>
                <span className={styles.divider}>|</span>
                <span>{item[0].content_short}</span>
              </div>
              <Collapse
                className={styles.agendaList}
                expandIcon={({ isActive }) => (
                  <img
                    className={styles.jiantouIcon + " icon"}
                    src={
                      isActive
                        ? require("../assets/image/icon_up.png")
                        : require("../assets/image/icon_down.png")
                    }
                  />
                )}
                expandIconPosition="right"
              >
                {item.map((x, y) => {
                  if (y !== 0) {
                    return (
                      <Panel
                        header={
                          <div className={styles.left}>
                            <p className={styles.time}>
                              <img
                                src={require("../assets/image/icon_time.png")}
                                alt=""
                              />
                              <span>{x.category}</span>
                            </p>
                            <p className={styles.tit}>{x.content_short}</p>
                          </div>
                        }
                        className={styles.item}
                        key={x.id}
                        disabled={x.address ? false : true}
                      >
                        <div className={styles.right}>{x.address}</div>
                      </Panel>
                    );
                  }
                })}
              </Collapse>
            </div>
          );
          agendaHtml.push(html);
        });
      }
    }
    const lunboSetting = {
      dots: true,
      lazyLoad: true,
    };

    //荣誉奖项
    if (prizeData && prizeData.length > 0) {
    }

    // console.log(introductionData)
    return (
      <div className={isPC ? styles.Content : styles.ContentMo}>
        <PageContent loading={loading}>
          {/* 大会介绍 */}
          <div id="introduction_mh_box" className={styles.introductionBox}>
            <div className={styles.container}>
              <div className={styles.aboutTxt}>
                <p className={styles.titleStyle + " " + styles.col000}>
                  大会介绍
                </p>
                {/* <p className={styles.titleEnStyle + " " + styles.col999}>
                  CONGRESS INFORMATION
                </p> */}
                <div
                  className={styles.TextContent}
                  ref={(ref) => {
                    this.TextContent = ref;
                  }}
                  dangerouslySetInnerHTML={{ __html: introductionData }}
                ></div>
              </div>
              {/* <div className={styles.aboutInfo}>
                <div className={styles.item}>
                  <span className={styles.num}>
                    <div className={styles.con}>
                      <div className={isLoad ? styles.numN + " " + styles.num3 : styles.numN}></div>
                      <div className={styles.numN + " " + styles.num0}></div>
                      <div className={styles.numN + " " + styles.num0}></div>
                      <div className={styles.plus}></div>
                    </div>
                  </span>
                  <div className={styles.des}>国内外演讲专家</div>
                </div>
                <div className={styles.item}>
                  <span className={styles.num}>
                    <div className={styles.con}>
                      <div className={isLoad ? styles.numN + " " + styles.num4 : styles.numN}></div>
                      <div className={styles.numN + " " + styles.num0}></div>
                      <div className={styles.numN + " " + styles.num0}></div>
                      <div className={styles.plus}></div>
                    </div>
                  </span>
                  <div className={styles.des}>单位企业</div>
                </div>
                <div className={styles.item}>
                  <span className={styles.num}>
                    <div className={styles.con}>
                      <div className={isLoad ? styles.numN + " " + styles.num1 : styles.numN}></div>
                      <div className={styles.numN + " " + styles.num0}></div>
                      <div className={styles.numN + " " + styles.num0}></div>
                      <div className={styles.plus}></div>
                    </div>
                  </span>
                  <div className={styles.des}>国家及地区</div>
                </div>
                <div className={styles.item}>
                  <span className={styles.num}>
                    <div className={styles.con}>
                      <div className={styles.numN + " " + styles.num1}></div>
                      <div className={isLoad ? styles.numN + " " + styles.numW : styles.numN}></div>
                      <div className={styles.plus}></div>
                    </div>
                  </span>
                  <div className={styles.des}>参会人次</div>
                </div>
              </div> */}
            </div>
          </div>

          {/* 大会议程 */}
          {agendaList.length > 0 ? (
            <div id="agenda_mh_box" className={styles.agendaBox}>
              <div className={styles.container}>
                <p className={styles.titleStyle + " " + styles.col000}>
                  大会议程
                </p>
                {/* <p className={styles.titleEnStyle + " " + styles.col999}>
                  CONFERENCE AGENDA
                </p> */}
                <div className={styles.agendaHeader}>{agendaHtmlHeader}</div>
                {agendaHtml}
              </div>
            </div>
          ) : null}

          {/* 赞助商 */}
          {sponsorData &&
          sponsorData.length > 0 &&
          sponsorData[0].is_hidden == 1 ? (
            <div id="sponsor_mh_box" className={styles.sponsorBox}>
              <div className={styles.container}>
                <p className={styles.titleStyle + " " + styles.col000}>
                  2020年展会展商
                </p>
                {/* <p className={styles.titleEnStyle + " " + styles.col999}>
                  LIST OF EXHIBITORS
                </p> */}

                <div className={styles.swiperBox}>
                
                  <Carousel
                    {...lunboSetting}
                    ref={(el) => (this.sponsorCarousel = el)}
                  >
                    {sponsorHtml}
                  </Carousel>
                </div>
                <div
                  className={styles.btnBox}
                  onClick={() => {
                    this.toExhibitor();
                  }}
                >
                  展商名录>>
                </div>
                <Modal
                  visible={showModal}
                  onOk={() => {
                    router.history.push("/register1");
                  }}
                  onCancel={() => {
                    this.setState({ showModal: false });
                  }}
                  okText="确认"
                  cancelText="取消"
                >
                  <p style={{ padding: "20px 0" }}>只有vip用户才可查看</p>
                </Modal>
              </div>
            </div>
          ) : null}

          {/* 中间图片 */}
          <div className={styles.centerImg}>
            {isPC ? (
              <img src={require("../assets/image/zx2.png")} alt="" />
            ) : (
              <img src={require("../assets/image/zx.png")} alt="" />
            )}
          </div>

          {/* 视频展示 */}
          {videoList && videoList.length > 0 ? (
            <div id="video_mh_box" className={styles.container}>
              <Row>
                <Col span={isPC ? 12 : 24}>
                  <div className={styles.videoMax}>
                    <div
                      className={styles.imgwrap}
                      onClick={() => {
                        this.ImgCardContentClick(videoList[0]);
                      }}
                    >
                      {videoList[0].category ? (
                        <img
                          src={require("../assets/image/play2x.png")}
                          className={styles.playIconMax}
                          alt=""
                        />
                      ) : null}

                      <img
                        src={
                          videoList[0].pic.startsWith("http")
                            ? videoList[0].pic
                            : `${base.OssAddress}${videoList[0].pic}`
                        }
                        alt=""
                        className={styles.imgMax}
                      />
                    </div>

                    <span>{videoList[0].content_short}</span>

                    {/* <p
                      dangerouslySetInnerHTML={{
                        __html: videoList[0].content.replace(/<.*?>/ig,""),
                      }}
                    ></p> */}
                  </div>
                </Col>

                {isPC ? (
                  <>
                    <Col span={isPC ? 12 : 24}>
                      <div className={styles.vidRightWrap}>
                        {videoList.length >= 1 ? (
                          videoList.map((item, index) => {
                            if (index == 0 || index > 3) {
                              return false;
                            }
                            return (
                              <div className={styles.itemWrap}>
                                <Row key={item.id}>
                                  <Col span={10}>
                                    <div
                                      className={styles.imgWrapR}
                                      onClick={() => {
                                        this.ImgCardContentClick(item);
                                      }}
                                    >
                                      {item.category ? (
                                        <img
                                          src={require("../assets/image/play2x.png")}
                                          className={styles.playIconR}
                                          alt=""
                                        />
                                      ) : null}

                                      <img
                                        className={styles.videoImageR}
                                        src={
                                          item.pic.startsWith("http")
                                            ? item.pic
                                            : `${base.OssAddress}${item.pic}`
                                        }
                                        alt=""
                                      />
                                    </div>
                                  </Col>
                                  <Col span={14}>
                                    <span>
                                      {item.content_short}
                                    </span>
                                    {/* <p
                                      dangerouslySetInnerHTML={{
                                        __html: item.content.replace(
                                          /<.*?>/gi,
                                          ""
                                        ),
                                      }}
                                    ></p> */}
                                  </Col>
                                </Row>
                              </div>
                            );
                          })
                        ) : (
                          <div
                            style={{ textAlign: "center", marginTop: "40px" }}
                          >
                            {/* 加载中 */}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col span={24}>
                      <div
                        className={
                          videoList.length == 6
                            ? styles.vidBottomM
                            : styles.vidBottom
                        }
                      >
                        {videoList.length > 4
                          ? videoList.map((item, index) => {
                              if (index < 4 || index > 6) {
                                return false;
                              }
                              return (
                                <div
                                  className={styles.botmItem}
                                  onClick={() => {
                                    this.ImgCardContentClick(item);
                                  }}
                                >
                                  <div className={styles.botmImgWrap}>
                                    {item.category ? (
                                      <img
                                        src={require("../assets/image/play2x.png")}
                                        className={styles.playIconBotm}
                                        alt=""
                                      />
                                    ) : null}

                                    <img
                                      src={
                                        videoList[0].pic.startsWith("http")
                                          ? videoList[0].pic
                                          : `${base.OssAddress}${videoList[index].pic}`
                                      }
                                      alt=""
                                      className={styles.botmImg}
                                    />
                                  </div>
                                  <p className={styles.botmTitle}>
                                    {item.content_short}
                                  </p>
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </Col>
                  </>
                ) : (
                  <Col span={isPC ? 12 : 24}>
                    <div className={styles.videoMin}>
                      <Row>
                        {videoList.map((item, index) => {
                          if (index === 0 || (!isPC && index > 2)) {
                            return false;
                          } else {
                            return (
                              <>
                                <Col span={11} key={item.id}>
                                  <div
                                    className={
                                      index % 2 === 0
                                        ? styles.rightMargin
                                        : styles.noMargin
                                    }
                                  >
                                    <div
                                      className={styles.imgWrap}
                                      onClick={() => {
                                        this.ImgCardContentClick(item);
                                      }}
                                    >
                                      {item.category ? (
                                        <img
                                          src={require("../assets/image/play2x.png")}
                                          className={styles.playIcon}
                                          alt=""
                                        />
                                      ) : null}

                                      <img
                                        className={styles.videoImageMin}
                                        src={
                                          item.pic.startsWith("http")
                                            ? item.pic
                                            : `${base.OssAddress}${item.pic}`
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <p className={styles.videoTitle}>
                                      {item.content_short}
                                    </p>
                                  </div>
                                </Col>

                                {index === 1 ? <Col span={2}></Col> : null}
                              </>
                            );
                          }
                        })}
                      </Row>
                    </div>
                  </Col>
                )}

                <Col span={24}>
                  <div
                    className={styles.moreLine}
                    onClick={() => {
                      window.open(`/#/videoList${videoList[0].pid}`);
                    }}
                  >
                    更多>>
                  </div>
                </Col>
              </Row>
            </div>
          ) : null}

          {/* 主讲嘉宾 */}

          <div id="jiabing_mh_box" className={styles.agendaBoxjb}>
            <div className={styles.container}>
              <p className={styles.titleStyle + " " + styles.col000}>
                主持嘉宾
              </p>
              {/* <p className={styles.titleEnStyle + " " + styles.col999}>
                OFFICIATING GUESTS
              </p> */}

              <Row
                className={styles.host}
                ref="host"
                onScroll={() => {
                  let left = document.querySelector(".home_host__2ZE4e")
                    .scrollLeft;
                  if (left < 50) {
                    this.refs.span1.style.backgroundColor = "#2591ed";
                    this.refs.span2.style.backgroundColor = "#E8E9EA";
                    this.refs.span3.style.backgroundColor = "#E8E9EA";
                  } else if (left > 230) {
                    this.refs.span1.style.backgroundColor = "#E8E9EA";
                    this.refs.span2.style.backgroundColor = "#E8E9EA";
                    this.refs.span3.style.backgroundColor = "#2591ed";
                  } else {
                    this.refs.span1.style.backgroundColor = "#E8E9EA";
                    this.refs.span2.style.backgroundColor = "#2591ed";
                    this.refs.span3.style.backgroundColor = "#E8E9EA";
                  }
                }}
              >
                <Col
                  xs={{ span: 5 }}
                  lg={{ span: 7 }}
                  className={styles.hostItem}
                >
                  <img src={require("../assets/image/guest1.jpg")} alt="" />
                  <span>宿继承</span>
                  <p>中国民用机场协会副秘书长</p>
                </Col>
                <Col
                  xs={{ span: 11, offset: 1 }}
                  lg={{ span: 7, offset: 1.5 }}
                  className={styles.hostItem}
                >
                  <img src={require("../assets/image/guest2.jpg")} alt="" />
                  <span>侯庆平</span>
                  <p style={{ padding: "0 20px" }}>
                    云南机场集团有限责任公司副总裁，中国民用机场协会机场安全（安保）专业委员会主任
                  </p>
                </Col>
                <Col
                  xs={{ span: 5, offset: 1 }}
                  lg={{ span: 7, offset: 1.5 }}
                  className={styles.hostItem}
                >
                  <img src={require("../assets/image/guest3.jpg")} alt="" />
                  <span>张有富</span>
                  <p style={{ padding: "0 20px" }}>
                    东部机场集团有限公司总经理，中国民用机场协会机场安全（安保）专业委员会副主任
                  </p>
                </Col>
              </Row>
              {isPC ? null : (
                <Col span={24} className={styles.boll}>
                  <span
                    ref="span1"
                    onClick={() => {
                      console.log(this.refs.host.style);
                      document.querySelector(
                        ".home_host__2ZE4e"
                      ).scrollLeft = 0;
                      this.refs.span1.style.backgroundColor = "#2591ed";
                      this.refs.span2.style.backgroundColor = "#E8E9EA";
                      this.refs.span3.style.backgroundColor = "#E8E9EA";
                      console.log(this);
                    }}
                  ></span>
                  <span
                    ref="span2"
                    style={{ backgroundColor: "#2591ed" }}
                    onClick={() => {
                      document.querySelector(
                        ".home_host__2ZE4e"
                      ).scrollLeft = 150;
                      this.refs.span1.style.backgroundColor = "#E8E9EA";
                      this.refs.span2.style.backgroundColor = "#2591ed";
                      this.refs.span3.style.backgroundColor = "#E8E9EA";
                      console.log(this);
                    }}
                  ></span>
                  <span
                    ref="span3"
                    onClick={() => {
                      document.querySelector(
                        ".home_host__2ZE4e"
                      ).scrollLeft = 297;
                      this.refs.span1.style.backgroundColor = "#E8E9EA";
                      this.refs.span2.style.backgroundColor = "#E8E9EA";
                      this.refs.span3.style.backgroundColor = "#2591ed";
                      console.log(this);
                    }}
                  ></span>
                </Col>
              )}
            </div>
          </div>

          {/* 开幕致辞嘉宾 */}

          <div className={styles.container}>
            <div id="zhici_mh_box" className={styles.wrapBox}>
              <div className={styles.container}>
                <p className={styles.titleStyle + " " + styles.col000}>
                  开幕致辞嘉宾
                </p>
                {/* <p className={styles.titleEnStyle + " " + styles.col999}>
                  OPENING REMARKS
                </p> */}
                <div className={styles.kaimu}>
                  <div className={styles.leftTit}>
                    <span className={styles.leftName}>{speakers.name}</span>
                  </div>
                  <div className={styles.centerLine}></div>
                  <div className={styles.rightSection}>{speakers.describe}</div>
                  <span className={StyleSheet.leftWork}>{speakers.work}</span>
                </div>
                <div className={styles.imagelist}>
                  <img
                    src={require("../assets/image/kaimu.png")}
                    onClick={() => {
                      this.changeSpeakers(
                        "陈金祖",
                        "总经理",
                        "深圳市机场（集团）有限公司"
                      );
                    }}
                    alt=""
                  />
                  <img
                    src={require("../assets/image/kaimu2.png")}
                    onClick={() => {
                      this.changeSpeakers(
                        "杨金才",
                        "",
                        "深圳市安全防范行业协会党委书记、会长"
                      );
                    }}
                    alt=""
                  />
                  <img
                    src={require("../assets/image/kaimu3.png")}
                    onClick={() => {
                      this.changeSpeakers(
                        "范永平",
                        "副局长",
                        "中国民用航空中南地区管理局"
                      );
                    }}
                    alt=""
                  />
                  <img
                    src={require("../assets/image/kaimu4.png")}
                    onClick={() => {
                      this.changeSpeakers(
                        "王瑞萍",
                        "理事长",
                        "中国民用机场协会"
                      );
                    }}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          {/* 演讲嘉宾 */}

          {guestActive !== "" ? (
            <div id="yanjiang_mh_box" className={styles.guestsBox}>
              <div className={styles.container}>
                <p className={styles.titleStyle + " " + styles.colFFF}>
                  演讲嘉宾
                </p>
                <p className={styles.titleEnStyle + " " + styles.colFFF}>
                  SPEAKERS
                </p>
                <div className={styles.tab}>
                  {domesticGuestsList &&
                  domesticGuestsList.length > 0 &&
                  domesticGuestsList[0].is_hidden == 1 ? (
                    <span
                      className={guestActive == "domestic" ? styles.bg : ""}
                      onClick={() => {
                        this.handleChangeGuest("domestic");
                      }}
                    >
                      国内嘉宾
                    </span>
                  ) : null}
                  {internationalGuestsList &&
                  internationalGuestsList.length > 0 &&
                  internationalGuestsList[0].is_hidden == 1 ? (
                    <span
                      className={
                        guestActive == "international" ? styles.bg : ""
                      }
                      onClick={() => {
                        this.handleChangeGuest("international");
                      }}
                    >
                      国际嘉宾
                    </span>
                  ) : null}
                </div>
                <div className={styles.swiperBox}>
                  <Carousel {...lunboSetting} ref={(el) => (this.slider = el)}>
                    {guestHtml}
                  </Carousel>
                  {guestList && guestPageNum > 1 ? (
                    <div
                      className={styles.jiantouBox + " " + styles.jiantouBox1}
                      onClick={carousel_prev}
                    >
                      <div className={styles.jiantouImgBox1}></div>
                    </div>
                  ) : null}
                  {guestList && guestPageNum > 1 ? (
                    <div
                      className={styles.jiantouBox + " " + styles.jiantouBox2}
                      onClick={carousel_next}
                    >
                      <div className={styles.jiantouImgBox2}></div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}

          {/* 新联体联盟 */}
          <div id="meiti_mh_box" className={styles.agendaBox}>
            <div className={styles.container}>
              <p className={styles.titleStyle + " " + styles.col000}>
                新媒体联盟
              </p>
              {/* <p className={styles.titleEnStyle + " " + styles.col999}>
                THE NEW CONJOINED FORCES
              </p> */}

              <Row className={styles.mediaAlliance}>
                <Col span={isPC ? 12 : 24}>
                  <Carousel autoplay>
                    <div className={styles.mediaAllianceList}>
                      <img
                        className={styles.mediaAllianceImg}
                        src={require("../assets/image/xmt1.jpg")}
                        alt=""
                      />
                    </div>
                    <div className={styles.mediaAllianceList}>
                      <img
                        className={styles.mediaAllianceImg}
                        src={require("../assets/image/xmt2.jpg")}
                        alt=""
                      />
                    </div>
                  </Carousel>
                  <ul className={styles.iconList}>
                    <li>
                      <img
                        src={require("../assets/image/icon2Max.png")}
                        alt=""
                      />
                    </li>
                    <li>
                      <img
                        src={require("../assets/image/icon3Max.png")}
                        alt=""
                      />
                    </li>
                    <li>
                      <img
                        src={require("../assets/image/icon4Max.png")}
                        alt=""
                      />
                    </li>
                    <li>
                      <img
                        src={require("../assets/image/icon5Max.png")}
                        alt=""
                      />
                    </li>
                    <li>
                      <img
                        src={require("../assets/image/icon1Max.png")}
                        alt=""
                      />
                    </li>
                  </ul>
                </Col>

                <Col span={isPC ? 12 : 24}>
                  <ul className={styles.section}>
                    <li className={styles.noWrap}>
                      <span className={styles.fontWeight}>联盟宗旨：</span>
                      <div>
                        <p>扎根民航安保 聚焦行业关切 紧跟政策变化</p>
                        <p>广纳专业声音 彰显企业担当 助力行业发展</p>
                      </div>
                    </li>

                    <li className={styles.noWrap}>
                      <span className={styles.fontWeight}>联盟原则：</span>
                      <div>
                        <p>共享 共建 共赢</p>
                      </div>
                    </li>

                    <li className={styles.textList}>
                      <span className={styles.fontWeight}>联盟宣言：</span>
                      <div>
                        <div className={styles.listMar}>
                          <span>1．群策群力</span>
                          <p>
                            形成解读行业政策变化、分析行业发展趋势、推动行业标准制定的网上宣传主阵地。
                          </p>
                        </div>
                        <div className={styles.listMar}>
                          <span>2．众智众研</span>
                          <p>
                            打造汇聚民航安保行业需求、研究行业难点痛点、发布综合解决方案的交流沟通主平台
                          </p>
                        </div>
                        <div className={styles.listMar}>
                          <span>3．共享共建</span>
                          <p>
                            建立服务民航安保发展、拓展行业交流边界、筑牢民航空防安全的专家团队主力军。
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </div>

          {/* 荣誉奖项 */}
          <div id="jiangxiang_mh_box" className={styles.agendaBox}>
            <div className={styles.container}>
              <p className={styles.titleStyle + " " + styles.col000}>
                荣誉奖项
              </p>
              {/* <p className={styles.titleEnStyle + " " + styles.col999}>
                HONORABLE MENTION
              </p> */}

              <div className={styles.swiper}>
                {/* <Swiper
                  effect="coverflow"
                  slidesPerView={isPC ? 3 : 2}
                  centeredSlides={true}
                  pagination={{ clickable: true }}
                  loop={true}
                  slideShadows={true}
                  coverflowEffect={{
                    rotate: 0,
                    stretch: 100,
                    depth: 200,
                    modifier: 1,
                    slideShadows: false,
                  }}
                  onSlideChange={(e) => {
                    this.awardNameChange(e, this);
                  }}
                >
                  <SwiperSlide
                    onClick={(e) => {
                      console.log("item", e);
                    }}
                  >
                    <img
                      src={require("../assets/image/huojiang1.jpg")}
                      alt=""
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={require("../assets/image/huojiang2.jpg")}
                      alt=""
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={require("../assets/image/huojiang3.jpg")}
                      alt=""
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={require("../assets/image/huojiang4.jpg")}
                      alt=""
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={require("../assets/image/huojiang5.jpg")}
                      alt=""
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={require("../assets/image/huojiang6.jpg")}
                      alt=""
                    />
                  </SwiperSlide>
                </Swiper>
                 */}
                <MySwiper
                  data={{
                    titleList: [
                      "特别贡献奖",
                      "最受欢迎奖",
                      "最具潜力奖",
                      "最具影响力奖",
                      "最佳创新奖",
                      "新媒体宣传奖",
                    ],
                  }}
                />
                {/* <p className={styles.swiperTitle}>{awardName}</p> */}
              </div>

              {isPC ? (
                <div className={styles.prize}>
                  <Row>
                    <Col span={12}>
                      <div className={styles.prizeWrap}>
                        <div className={styles.prizeTitle}>
                          <img
                            src={require("../assets/image/jbMax.png")}
                            alt=""
                          />
                          <span>特别贡献奖</span>
                        </div>

                        <div className={styles.prizeName}>
                          <span>颁奖嘉宾：</span>
                          <p>宿继承，中国民用机场协会副秘书长</p>
                        </div>

                        <div className={styles.prizeEnterprise}>
                          <span className={styles.addMargin}>获奖企业：</span>
                          <p>深圳机场股份公司</p>
                          {/* <p>深圳中集天达空港设备公司</p>
                          <p>北京航星机器制造有限公司</p> */}
                        </div>
                      </div>
                    </Col>

                    <Col span={12}>
                      <div className={styles.prizeWrap}>
                        <div className={styles.prizeTitle}>
                          <img
                            src={require("../assets/image/jbMax.png")}
                            alt=""
                          />
                          <span>最受欢迎奖</span>
                        </div>

                        <div className={styles.prizeName}>
                          <span>颁奖嘉宾：</span>
                          <p>云南机场集团有限责任公司副总裁，中国民用机场协会机场安全（安保）专业委员会主任</p>
                        </div>

                        <div className={styles.prizeEnterprise}>
                          <span className={styles.addMargin}>获奖企业：</span>
                          <p>中海智（北京）科技有限公司</p>
                          <p>深圳中集天达空港设备有限公司</p>
                          <p>北京航星机器制造有限公司</p>
                          <p>北京中兴机场管理有限公司</p>
                          <p>大连迪瑞泰克科技有限公司</p>
                          <p>佳都新太科技股份有限公司</p>
                          <p>驭势科技（北京）有限公司</p>
                          <p>北京华泰诺安探测技术有限公司</p>
                          <p>航大联合航空设备有限公司</p>
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className={styles.prizeWrap}>
                        <div className={styles.prizeTitle}>
                          <img
                            src={require("../assets/image/jbMax.png")}
                            alt=""
                          />
                          <span>最具潜力奖</span>
                        </div>

                        <div className={styles.prizeName}>
                          <span>颁奖嘉宾：</span>
                          <p>段克明，西部机场集团有限公司党委副书记</p>
                        </div>

                        <div className={styles.prizeEnterprise}>
                          <span className={styles.addMargin}>获奖企业：</span>
                          <p>北京市神州恒安科技有限公司</p>
                          <p>北京博宏科元信息科技有限公司</p>
                          <p>深圳汉云科技有限公司</p>
                          <p>北京万里红科技股份有限公司</p>
                          <p>中国民航大学电子信息与自动化学院</p>
                          <p>中国民航科学技术研究院</p>
                          <p>北京金鹏达科技发展有限公司</p>
                          <p>深圳市雄帝科技股份有限公司</p>
                        </div>
                      </div>
                    </Col>

                    <Col span={12}>
                      <div className={styles.prizeWrap}>
                        <div className={styles.prizeTitle}>
                          <img
                            src={require("../assets/image/jbMax.png")}
                            alt=""
                          />
                          <span>最具影响力奖</span>
                        </div>

                        <div className={styles.prizeName}>
                          <span>颁奖嘉宾：</span>
                          <p>郭金平，新疆机场（集团）有限责任公司，副总经理</p>
                        </div>

                        <div className={styles.prizeEnterprise}>
                          <span className={styles.addMargin}>获奖企业：</span>
                          <p>
                            罗德与施瓦茨（中国）科技有限公司
                          </p>
                          <p>深圳市重投华讯太赫兹集团有限公司</p>
                          <p>
                            深圳市赛为智能股份有限公司
                          </p>
                          <p>厦门瑞为信息技术有限公司</p>
                          <p>航天长征火箭技术有限公司</p>
                          <p>北京华力兴科技发展有限责任公司</p>
                          <p>同方威视技术股份有限公司</p>
                          <p>北京空港赛瑞安防科技有限公司</p>
                          <p>新智认知数据服务有限公司</p>
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className={styles.prizeWrap}>
                        <div className={styles.prizeTitle}>
                          <img
                            src={require("../assets/image/jbMax.png")}
                            alt=""
                          />
                          <span>最佳创新奖</span>
                        </div>

                        <div className={styles.prizeName}>
                          <span>颁奖嘉宾：</span>
                          <p>徐越辉，湖北机场集团机场管理公司副总经理</p>
                        </div>

                        <div className={styles.prizeEnterprise}>
                          <span className={styles.addMargin}>获奖企业：</span>
                          <p>深圳市华正联实业有限公司</p>
                          <p>深圳市华付信息技术有限公司</p>
                          <p>北京航安易行科技有限公司</p>
                          <p>北京瑞源华徳科技有限公司</p>
                          <p>欧必翼太赫滋科技（北京）有限公司</p>
                          <p>浙江大华技术股份有限公司</p>
                          <p>广东机场白云信息科技有限公司</p>
                          <p>奇点新源国际技术开发（北京）有限公司</p>
                          <p>北京旷视科技有限公司</p>
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className={styles.prizeWrap}>
                        <div className={styles.prizeTitle}>
                          <img
                            src={require("../assets/image/jbMax.png")}
                            alt=""
                          />
                          <span>新媒体宣传奖</span>
                        </div>

                        <div className={styles.prizeName}>
                          <span>颁奖嘉宾：</span>
                          <p>夏忠宏，江西省机场集团公司安全总监</p>
                        </div>

                        <div className={styles.prizeEnterprise}>
                          <span className={styles.addMargin}>获奖单位：</span>
                          <p>中国民航网</p>
                          <p>中国民航资源网</p>
                          <p>中国机场协会（官网）</p>
                          <p>CPS中安网</p>
                          <p>民航安保网</p>
                        </div>
                      </div>
                    </Col>
                    <Col span={24} style={{ width: "100%" }}>
                      <p className={styles.prizeFooter}>以上排名不分先后</p>
                    </Col>
                  </Row>
                </div>
              ) : (
                <div className={styles.prizeMo}>
                  <Carousel autoplay>
                    <div>
                      <Row>
                        <Col span={24}>
                          <div className={styles.prizeWrap}>
                            <div className={styles.prizeTitle}>
                              <img
                                src={require("../assets/image/jbMax.png")}
                                alt=""
                              />
                              <span>特别贡献奖</span>
                            </div>

                            <div className={styles.prizeName}>
                              <span>颁奖嘉宾：</span>
                              <p>宿继承，中国民用机场协会副秘书长</p>
                            </div>

                            <div className={styles.prizeEnterprise}>
                              <span className={styles.addMargin}>
                                获奖企业：
                              </span>
                              <p>深圳机场股份公司</p>
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <div className={styles.prizeWrap}>
                            <div className={styles.prizeTitle}>
                              <img
                                src={require("../assets/image/jbMax.png")}
                                alt=""
                              />
                              <span>最受欢迎奖</span>
                            </div>

                            <div className={styles.prizeName}>
                              <span>颁奖嘉宾：</span>
                              <p>侯庆平，云南机场集团有限责任公司副总裁，中国民用机场协会机场安全（安保）专业委员会主任</p>
                            </div>

                            <div className={styles.prizeEnterprise}>
                              <span className={styles.addMargin}>
                                获奖企业：
                              </span>
                              <p>中海智（北京）科技有限公司</p>
                              <p>深圳中集天达空港设备有限公司</p>
                              <p>北京航星机器制造有限公司</p>
                              <p>北京中兴机场管理有限公司</p>
                              <p>大连迪瑞泰克科技有限公司</p>
                              <p>佳都新太科技股份有限公司</p>
                              <p>驭势科技（北京）有限公司</p>
                              <p>北京华泰诺安探测技术有限公司</p>
                              <p>航大联合航空设备有限公司</p>
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <p className={styles.ranking}>以上排名不分先后</p>
                        </Col>
                      </Row>
                    </div>

                    <div>
                      <Row>
                        <Col span={24}>
                          <div className={styles.prizeWrap}>
                            <div className={styles.prizeTitle}>
                              <img
                                src={require("../assets/image/jbMax.png")}
                                alt=""
                              />
                              <span>最具潜力奖</span>
                            </div>

                            <div className={styles.prizeName}>
                              <span>颁奖嘉宾：</span>
                              <p>段克明，西部机场集团有限公司党委副书记</p>
                            </div>

                            <div className={styles.prizeEnterprise}>
                              <span className={styles.addMargin}>
                                获奖企业：
                              </span>
                              <p>北京市神州恒安科技有限公司</p>
                              <p>北京博宏科元信息科技有限公司</p>
                              <p>深圳汉云科技有限公司</p>
                              <p>北京万里红科技股份有限公司</p>
                              <p>中国民航大学电子信息与自动化学院</p>
                              <p>中国民航科学技术研究院</p>
                              <p>北京金鹏达科技发展有限公司</p>
                              <p>深圳市雄帝科技股份有限公司</p>
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <div className={styles.prizeWrap}>
                            <div className={styles.prizeTitle}>
                              <img
                                src={require("../assets/image/jbMax.png")}
                                alt=""
                              />
                              <span>最具影响力奖</span>
                            </div>

                            <div className={styles.prizeName}>
                              <span>颁奖嘉宾：</span>
                              <p>
                                郭金平，新疆机场（集团）有限责任公司，副总经理
                              </p>
                            </div>

                            <div className={styles.prizeEnterprise}>
                              <span className={styles.addMargin}>
                                获奖企业：
                              </span>
                              <p>
                              </p>
                              <p>深圳市重投华讯太赫兹集团有限公司</p>
                              <p>
                                深圳市赛为智能股份有限公司
                              </p>
                              <p>厦门瑞为信息技术有限公司</p>
                              <p>航天长征火箭技术有限公司</p>
                              <p>北京华力兴科技发展有限责任公司</p>
                              <p>同方威视技术股份有限公司</p>
                              <p>北京空港赛瑞安防科技有限公司</p>
                              <p>新智认知数据服务有限公司</p>
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <p className={styles.ranking}>以上排名不分先后</p>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Row>
                        <Col span={24}>
                          <div className={styles.prizeWrap}>
                            <div className={styles.prizeTitle}>
                              <img
                                src={require("../assets/image/jbMax.png")}
                                alt=""
                              />
                              <span>最佳创新奖</span>
                            </div>

                            <div className={styles.prizeName}>
                              <span>颁奖嘉宾：</span>
                              <p>徐越辉，湖北机场集团机场管理公司副总经理</p>
                            </div>

                            <div className={styles.prizeEnterprise}>
                              <span className={styles.addMargin}>
                                获奖企业：
                              </span>
                              <p>深圳市华正联实业有限公司</p>
                              <p>深圳市华付信息技术有限公司</p>
                              <p>北京航安易行科技有限公司</p>
                              <p>北京瑞源华徳科技有限公司</p>
                              <p>欧必翼太赫滋科技（北京）有限公司</p>
                              <p>浙江大华技术股份有限公司</p>
                              <p>广东机场白云信息科技有限公司</p>
                              <p>奇点新源国际技术开发（北京）有限公司</p>
                              <p>北京旷视科技有限公司</p>
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <div className={styles.prizeWrap}>
                            <div className={styles.prizeTitle}>
                              <img
                                src={require("../assets/image/jbMax.png")}
                                alt=""
                              />
                              <span>新媒体宣传奖</span>
                            </div>

                            <div className={styles.prizeName}>
                              <span>颁奖嘉宾：</span>
                              <p>夏忠宏，江西省机场集团公司安全总监</p>
                            </div>

                            <div className={styles.prizeEnterprise}>
                              <span className={styles.addMargin}>
                                获奖单位：
                              </span>
                              <p>中国民航网</p>
                              <p>中国民航资源网</p>
                              <p>中国机场协会（官网）</p>
                              <p>CPS中安网</p>
                              <p>民航安保网</p>
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <p className={styles.ranking}>以上排名不分先后</p>
                        </Col>
                      </Row>
                    </div>
                  </Carousel>
                </div>
              )}
            </div>
          </div>

          {/* 最新资讯 */}
          {latestNewsData &&
          latestNewsData.length > 0 &&
          latestNewsData[0].is_hidden == 1 ? (
            <div id="latestNews_mh_box" className={styles.latestNewsBox}>
              {/* <a href={latestNewsData[0].link} target="_blank"> */}
              <a>
                <img
                  src={
                    isPC
                      ? require("../assets/image/news_bg.jpg")
                      : require("../assets/image/news_bgMo.jpg")
                  }
                  alt=""
                />
              </a>
            </div>
          ) : null}

          {/* 同期展览 */}
          {/* {
            samePeriodList && samePeriodList.length > 0 && samePeriodList[0].is_hidden == 1 ? (
              <div id="samePeriod_mh_box" className={styles.samePeriodBox}>
                <div className={styles.container}>
                  <p className={styles.titleStyle + " " + styles.col000}>同期展览</p>
                  <p className={styles.titleEnStyle + " " + styles.col999}>CONCURRENT EXHIBITION</p>
                  <div className={styles.con}>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      {
                        samePeriodList && samePeriodList.length > 0 ?
                          samePeriodList.map((item, ind) => {
                            return ind > 0 && ind < 3 ? (
                              <Col key={ind} className="gutter-row" span={md}>
                                <a className={styles.itemBox} href={item.link} target="_blank">
                                  <div className={styles.imgBox}>
                                    <img src={item.pic.startsWith('http') ? item.pic : `${base.OssAddress}${item.pic}`} alt="" />
                                  </div>
                                  <p>{item.content_short}</p>
                                </a>
                              </Col>
                            ) : null
                          }) : null
                      }
                    </Row>
                  </div>
                </div>
              </div>
            ) : null
          } */}

          {/* 大会地址 */}
          <div id="address_mh_box" className={styles.addressBox}>
            <div className={styles.container}>
              <p className={styles.titleStyle + " " + styles.col000}>
                大会地址
              </p>
              {/* <p className={styles.titleEnStyle + " " + styles.col999}>
                CONGRESS VENUE
              </p> */}
              <div className={styles.addressImgBox}>
                <img src={require("../assets/image/weizhi.png")} alt="" />
              </div>
            </div>
          </div>
          {/* 合作单位 */}
          {/* {
            cooperationUnitList && cooperationUnitList.length > 1 && cooperationUnitList[0].id == cooperationUnitList[0].pid && cooperationUnitList[0].is_hidden == 1 ? (
              <div className={styles.cooperationUnitBox}>
                <div className={styles.container}>
                  <div className={styles.cooperationTit}>合作单位</div>
                  <div className={styles.imgBox}>
                    {
                      cooperationUnitList.map(item => {
                        return item.id != item.pid ? <img key={item.id} src={item.pic.startsWith('http') ? item.pic : `${base.OssAddress}${item.pic}`} alt="" /> : ""
                      })
                    }
                  </div>
                </div>
              </div>
            ) : null
          } */}
          {/* 指定媒体 */}
          {/* {
            designatedMediaList && designatedMediaList.length > 1 && designatedMediaList[0].id == designatedMediaList[0].pid && designatedMediaList[0].is_hidden == 1 ? (
              <div className={styles.cooperationUnitBox}>
                <div className={styles.container}>
                  <div className={styles.cooperationTit}>指定媒体</div>
                  <div className={styles.imgBox}>
                    {
                      designatedMediaList.map(item => {
                        return item.id != item.pid ? <img key={item.id} src={item.pic.startsWith('http') ? item.pic : `${base.OssAddress}${item.pic}`} alt="" /> : ""
                      })
                    }
                  </div>
                </div>
              </div>
            ) : null
          } */}
          {/* 合作媒体 */}
          {cooperationMediaLsit &&
          cooperationMediaLsit.length > 1 &&
          cooperationMediaLsit[0].id == cooperationMediaLsit[0].pid &&
          cooperationMediaLsit[0].is_hidden == 1 ? (
            <div className={styles.cooperationUnitBox}>
              <div className={styles.container}>
                <div className={styles.cooperationTitNew}>合作媒体</div>
                <div className={styles.imgBoxNew}>
                  {cooperationMediaLsit.map((item) => {
                    return item.id != item.pid ? (
                      <img
                        key={item.id}
                        src={
                          item.pic.startsWith("http")
                            ? item.pic
                            : `${base.OssAddress}${item.pic}`
                        }
                        alt=""
                      />
                    ) : (
                      ""
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null}
        </PageContent>
      </div>
    );
  }
}

export default Content;
