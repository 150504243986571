import Server from './server'

class API extends Server {

  // 查询文章
  QueryArticle(data) {

    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_article', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {

        return result || {}
      }
    } catch (err) {
      throw err
    }
  }
  //查询文章根据点击排序
  QueryArticleOrderByClick(data) {
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/QueryArticleOrderByClick', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {

        return result || {}
      }
    } catch (err) {
      throw err
    }
  }
  // 查询文章详情页轮播图
  QueryAdvertiseDetail(data) {

    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_advertise', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {

        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  // 查询轮播图
  QueryAdvertise(data) {

    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_advertise', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {

        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  // 查询友情链接
  QueryFriendlyLink(data) {

    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_friendly_link', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {

        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  // 查询专家用户
  QueryExperts(data) {

    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_experts', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {

        return result || {}
      }
    } catch (err) {
      throw err
    }
  }
  QueryExpertTypes(data) {

    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_expert_type', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {

        return result || {}
      }
    } catch (err) {
      throw err
    }
  }
  // 查询会员活动
  QueryVipActivities(data) {

    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_vip_activities', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {

        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  // 查询会员权限图片
  QueryVipList(data) {

    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_vip_list', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {

        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  // 查询会员体系图片
  QueryMemberype(data) {

    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_member_type', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {

        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  //查询专题
  QuerySubject(data) {

    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_subject', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {

        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  //查询专题下的标题栏
  QuerySubjectTitleBar(data) {

    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_subject_title_bar', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {

        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

}

export default new API()