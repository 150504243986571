import React, { Component } from "react";
import { HashRouter } from "react-router-dom";
import { Row, Col, Anchor, Carousel } from "antd";
import PageContent from "@/src/components/PageContent";
import styles from "./videoList.module.less";
import store from "@/src/redux/store.js";
import API from "../../../api/exhibition";
import { base } from "@/src/api/base";
import { setAdvertisement } from "@/src/redux/actions/Advertisement";
import { tools } from "@/src/lib/tools";
import ArticleApi from "@/src/api/ArticleApi";


const router = new HashRouter();
const { Link } = Anchor;
class Content extends Component {
  state = {
    name: "大会详情",
    exhbitorData: [], //总数据
    BrowserType: "PC", // PC Mobile 浏览器类型 手机或者pc端
    item: {},
    offset: 1,
    limit: 999,
    loading: false,
  };

  componentDidMount() {
    document.querySelector('html').scrollTop = 0
    window.addEventListener("resize", this.resizeBind);
    this.resizeBind();
    this.setState({
      item: JSON.parse(sessionStorage.getItem("item")),
    });
    this.InitData();
    this.QueryExhbitionByPid();
  }
  InitData = () => {
    ArticleApi.IdQueryArticle(
      this.props.data && this.props.data.match.params
    ).then((res) => {
      if (res.status === 200) {
        console.log("详情页数据", res);
        document.title = res.data.title || "民航安保网";
        this.setState({
          data: res.data,
          loading: false,
        });
      } else {
      }
    });
  };

  
sortFun=(attr,rev)=>{
  //第二个参数没有传递 默认升序排列
  if(rev ==  undefined){
      rev = 1;
  }else{
      rev = (rev) ? 1 : -1;
  }
  
  return function(a,b){
      a = a[attr];
      b = b[attr];
      if(a < b){
          return rev * -1;
      }
      if(a > b){
          return rev * 1;
      }
      return 0;
  }
}



  //   根据pid查询数据
  QueryExhbitionByPid = () => {
    var { offset, limit } = this.state;
    let params = {
      limit,
      offset,
      pid: 47,
    };
    this.setState({ loading: true });
    API.QueryExhbitionByPid(params)
      .then((res) => {
        console.log("res", res);
        console.log(res.data.list.sort(this.sortFun(`update_time`,false)))




        if (res.status === 200) {
          var exhbitorData = res.data.list;
          this.setState({
            exhbitorData,
            total: res.data.total,
          });
        } else {
          // message.error(res.msg);
        }
        this.setState({ loading: false });
      })
      .catch((res) => {
        this.setState({ loading: false });
      });
  };

  resizeBind = () => {
    console.log("URL", this);

    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== "Mobile") {
        this.setState({
          BrowserType: "Mobile",
        });
      }
    } else {
      if (!this.state.BrowserType !== "PC") {
        this.setState({
          BrowserType: "PC",
        });
      }
    }
  };
  componentWillUnmount() {
    // 对 store 变化取消监听
    window.removeEventListener("resize", this.resizeBind);
  }
  ImgCardContentClick = (item) => {
    // store.dispatch(setArticleDetails(item));
    window.open(`/#/videoDetals${item.pid}/${item.id}`);
    // router.history.push("/videoDetals" + item.pid + '/' + item.id);
  };

  render() {
    const { BrowserType, item, loading, exhbitorData } = this.state;
    console.log(item);
    var md = 12;
    var isPC = true;
    if (BrowserType !== "PC") {
      md = 24;
      isPC = false;
    }
    let dom = [];
    if (exhbitorData.length > 0) {
      exhbitorData.forEach((item) => {
        dom.push(
          <Col>
            <div className={styles.item}>
              <Col span={5}>
                <div className={styles.imgWrap}
                onClick={()=>{
                  this.ImgCardContentClick(item)
                }}
                >
                  <img
                    src={
                      item.pic.startsWith("http")
                        ? item.pic
                        : `${base.OssAddress}${item.pic}`
                    }
                    alt=""
                  />
                  {
                    item.category?(
                      <img src={require("../assets/image/play.png")} className={styles.play} alt=""/>
                    ):null
                  }
                </div>
              </Col>
              <Col span={19}
              onClick={()=>{
                this.ImgCardContentClick(item)
              }}
             >
                <h3>{item.content_short}</h3>
                <p>{ item.content.replace(/<\/?[^>]*>/g,'').length < 55 ? 
                item.content.replace(/<\/?[^>]*>/g,''):
                item.content.replace(/<\/?[^>]*>/g,'').substring(0,55)+'...'
              }</p>
              {
                isPC ? (
                  <span className={styles.time}>{item.update_time ? tools.UnixToStr(item.update_time,0,1) : tools.UnixToStr(item.creation_time,0,1)}</span>
                ):null
              }
              </Col>
            </div>
          </Col>
        );
      });
    }

    return (
      <div className={isPC ? styles.wrap : styles.wrapMO}>
        <PageContent loading={loading}>
          <Row>{dom}</Row>
        </PageContent>
      </div>
    );
  }
}

export default Content;
