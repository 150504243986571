import React, { Component } from 'react';
import styles from './index.module.less';
import PageHeader from '@/src/components/PageHeader';
import Content from './content.jsx';
import PageFooter from '@/src/components/PageFooter';

export default class ExpertColumn extends Component {

  render() {

    const { title_bar_no , name } = this.props.match.params

    return (
      <div className={styles.Box+' Layout'}>
        
        <PageHeader current='/ExpertColumn' showNav={true} />

        <Content title_bar_no={title_bar_no} name={name} />

        <PageFooter colorE9f1fc={false} />
        
      </div>
    )
  }

}
