import React, { Component } from 'react';
import PageHeader from './PageHeader/index';
import PageFooter from '@/src/components/PageFooter';
import { Avatar, Input, Col, Button, Upload, notification , message } from "antd";
import store from '@/src/redux/store.js';
import MD5 from 'crypto-js/md5';
import UploadAxios from '@/src/api/Upload';
import { setUserInfo } from '@/src/redux/actions/userInfo';
import { base } from '@/src/api/base';
import PersonalCenterApi from '@/src/api/PersonalCenterApi';
import {Redirect} from "react-router-dom";
import styles from './index.module.less'


/**
 * 修改头像昵称
 */

export default class PersonalInformation extends Component {
    state = {
        key:false,
        limit: 6,           // 分页每页显示条数
        offset: -1,
        loading: false,
        hasMore: true,
        dataSource: [],
        OSSData: { host: '' }, ImgFileState: false, update_file: null, file_name: '', file_list: []
    }

    componentWillMount() {

        const { userInfo } = store.getState();
        this.setState({ userInfo})
        this.init()
        // user_name
        console.log(userInfo)
    }

    init = async () => {

        this.GetOSSData(OSSData => {
            if (OSSData === null) {
                return
            }

            this.setState({
                OSSData,
            });
        });
    };

    GetOSSData = (callback) => {
        UploadAxios.GetOsstoken({}).then(res => {
            if (res.status === 200) {
                // return {
                //     dir: 'user-dir/',
                //     expire: '1577811661',
                //     host: '//www.mocky.io/v2/5cc8019d300000980a055e76',
                //     accessId: 'c2hhb2RhaG9uZw==',
                //     policy: 'eGl4aWhhaGFrdWt1ZGFkYQ==',
                //     signature: 'ZGFob25nc2hhbw==',
                // };
                callback(res.data);
            } else {
                notification.error({
                    message: '失败！',
                    description: res.msg,
                });
            };
            callback(null)
        }).catch(() => {
            notification.error({
                message: '失败！',
                description: "网络超时！",
            });

        });

    };

    // 检查图片压缩等工作是否准备完毕 生成图片名称
    CheckBeforeUploadingPictures() {
        if (this.state.ImgFileState) {
            notification.warning({
                message: '提示！',
                description: "图片正在压缩中，请稍后提交！",
            });
            return false;
        }

        if (this.state.file_list.length === 0) {
            notification.warning({
                message: '提示！',
                description: "请选择上传图片！",
            });
            return false;
        }

        let file = this.state.file
        let filename = MD5(file.name + file.uid);
        let fileType = file.name.split(".");
        fileType = `.${fileType[fileType.length - 1]}`;
        filename += fileType;
        const ImgkeyName = `${this.props.folder}/${filename}`;
        this.setState({ file_name: ImgkeyName })

        return ImgkeyName
    }

    // 用户选取图片 将图片进行压缩
    onChange(e) {
        this.setState({ file_list: e.fileList })
        
        if (e.file.status === 'done') {
            const { userInfo } = store.getState();
            userInfo.head_url = e.file.url
            this.setState({ userInfo})
        }
        // if (e.fileList.length !== this.state.file_list.length) {
        //     this.setState({ file_list: e.fileList })
        //     return
        // }

        // let file = e.file
        // this.setState({ file: file, ImgFileState: true, file_list: e.fileList })
        // imgPreview(file, (file) => {
        //     this.setState({ update_file: file, ImgFileState: false })
        // })

    }

    beforeUpload = async () => {

        // const { OSSData } = this.state;
        // const expire = OSSData.expire * 1000;

        // if (expire < Date.now()) {
        //     await this.init();
        // }

        return true;
    };

    transformFile = async file => {

        // this.setState({ ImgFileState: false })
        // await imgPreview(file, (file) => {

        //     return file;
        // })

        const { OSSData } = this.state;

        const suffix = file.name.slice(file.name.lastIndexOf('.'));
        const filename = MD5(Date.now() + file.name + file.uid) + suffix;
        file.url = OSSData.dir + filename;

        this.setState({ file: file, ImgFileState: true })

        return file;

    };

    fileList = [];
    getExtraData = (file) => {
        const { OSSData } = this.state;

        return {
            key: file.url,
            OSSAccessKeyId: OSSData.accessid,
            policy: OSSData.policy,
            Signature: OSSData.signature,
        };
    };

    UpdatesAccount = () => {
        const { userInfo } = this.state 
        userInfo.co_label = userInfo.co_label ? JSON.stringify(userInfo.co_label) : []
        PersonalCenterApi.UpdatesAccount(userInfo).then(res => {
            if (res.status === 200) {
                store.dispatch(setUserInfo(userInfo))
                message.success('修改成功！')
                this.setState({key:true})
            } else {
                notification.error({
                    message: '失败！',
                    description: res.msg,
                });
            };
        }).catch(() => {
            notification.error({
                message: '失败！',
                description: "网络超时！",
            });

        });
    }

    render() {
        // const { userInfo } = store.getState();
        const { OSSData , userInfo } = this.state;
        console.log(OSSData,'====================')
        if(this.state.key){
            return <Redirect to='/PersonalCenter'/>
        }
        return (
            <div style={{ width: '100%' }}>
                <div className={styles.bg1}>
                    {/* <div className={styles.bg} >
                        <img src={require('../../../assets/login/info_bg.png')} alt=""/>
                    </div> */}
                    <div className={styles.bg1ta} >
                        <div style={{display: 'flex' , justifyContent: 'center'}}>
                            <Col xs={22} sm={22} md={20}>
                                <PageHeader current='/PersonalCenter' showNav={false} showBreadcrumbList={true} />
                            </Col>
                        </div>


                        <div className={styles.box} >
                            <Col xs={20} sm={20} md={9} style={{}} >
                                <div className={styles.content}>
                                    <div className={styles.headerImg}>
                                        <div className={styles.Avatar}>
                                            <Avatar
                                                size={64}
                                                onClick={this.openPersonalCenter} icon="user"
                                                src={base.OssAddress + userInfo.head_url} />
                                            <div className={styles.Upload}>
                                                <Upload action={OSSData.host} onChange={this.onChange.bind(this)} beforeUpload={this.beforeUpload.bind(this)} transformFile={this.transformFile.bind(this)} data={this.getExtraData.bind(this)}  >  <Avatar
                                                    size={64}
                                                    onClick={this.openPersonalCenter} icon="user"
                                                    src={userInfo.head_url} />
                                                </Upload>
                                            </div>
                                        </div>
                                        <div className={styles.Tip} >点击更换头像</div>

                                    </div>
                                    <div className={styles.nickName}>
                                        <Col xs={20} sm={20} md={12} >
                                            <div className={styles.ta}>
                                                {/* <div className={styles.tb}>我的昵称：</div> */}
                                                <Input placeholder="请输入昵称！" allowClear={true} value={this.state.userInfo.user_name} onChange={(e) => { 
                                                    userInfo.user_name = e.target.value 
                                                    this.setState({userInfo
                                                    }) 
                                                }} />
                                            </div>
                                        </Col>
                                    </div>

                                    <div className={styles.btn}>
                                        <Col xs={20} sm={20} md={12} >
                                            <Button onClick={this.UpdatesAccount} type="primary">确认</Button>
                                        </Col>
                                    </div>


                                </div>

                            </Col>
                        </div>
                    </div>
                </div>


                <PageFooter style={{ position: 'relative' }} colorE9f1fc={false} />

            </div >
        );
    }
}