import React, { Component } from 'react';
import { Col, Row, BackTop } from 'antd';
import styles from './index.module.less';
import { tools } from '@/src/lib/tools';
import API from '../../../../api/exhibition';
import { HashRouter } from 'react-router-dom'
import { base } from '@/src/api/base'
const router = new HashRouter()

class PageFooter extends Component {

  state = {
    BrowserType: 'PC', // PC Mobile 浏览器类型 手机或者pc端
    contactInformationList: [],//联系方式
    relatedInformationList: [],//相关信息
    contactWebsiteData: {},//民航资源网
  }

  constructor() {
    super()
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeBind)
    this.resizeBind.bind(this)
    this.QueryExhbition()
    if (!tools.IsPC()) {
      this.setState({
        BrowserType: "Mobile"
      });
    }
  }

  componentWillUnmount() {
    // 对 store 变化取消监听
    window.removeEventListener('resize', this.resizeBind)
    this.setState({
      showNav: true,
      TitleList: [],
    })
  }
  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== 'Mobile') {
        this.setState({
          BrowserType: 'Mobile',
        });
      }
    } else {
      if (!this.state.BrowserType !== 'PC') {
        this.setState({
          BrowserType: 'PC',
        });
      }
    }
  }
  QueryExhbition = () => {
    let params = {
      limit: 200,
    }
    API.QueryExhbition(params).then(res => {
      if (res.status === 200) {
        var exhbitionData = res.data.list
        var contactInformationList = exhbitionData.filter(item => {
          return item.pid == 12
        })
        var relatedInformationList = exhbitionData.filter(item => {
          return item.pid == 13
        })
        var contactWebsiteData = exhbitionData.filter(item => {
          return item.pid == 14
        })[0]
        contactInformationList = this.sortFn(contactInformationList)
        relatedInformationList = this.sortFn(relatedInformationList)
        this.setState({
          contactInformationList,
          relatedInformationList,
          contactWebsiteData,
        })
      } else {
        // message.error(res.msg);
      }
      this.setState({ loading: false });
    }).catch(res => {
      // console.log(res)
      this.setState({ loading: false });
    })
  }
  // 点击底部导航栏
  // FooterNavClick = (item) => {
  //   // 调路由 skip_url
  //   router.history.push(`${item.skip_url}${item.title_bar_no}/${item.name}`)
  // }
  // 排序
  sortFn = (arr) => {
    if (arr && arr.length > 1) {
      arr.sort(function (a, b) {
        if (a.sort < b.sort) {
          return -1;
        } else if (a.sort == b.sort) {
          return 0;
        } else {
          return 1;
        }
      });
    }
    return arr
  }

  render() {

    let {
      BrowserType,
      contactInformationList,
      relatedInformationList,
      contactWebsiteData,
    } = this.state
    var md = 8
    var isPC = true
    if (BrowserType !== "PC") {
      md = 24
      isPC = false
    }
    return (
      <div id="contact_mh_box" className={isPC ? styles.FooterBox : styles.FooterBoxMo} >
        <div className={styles.container}>
          <p className={styles.title}>联系我们</p>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={md}>
              <div className={styles.itemBox}>
                <div className={styles.tit}>会务组</div>
                <div className={styles.divider} style={BrowserType !== "PC" ? { width: "70%" } : {}}></div>
                <div className={styles.itemCon} style={{alignItems:"flex-end"}}>
                 <div style={{marginBottom: '20px'}}>谢  佳 祎 : 135-0139-5345</div>
                 <div style={{marginBottom: '20px'}}><span style={{marginRight: '21px'}}>许</span>  颖 : 177-0980-6198</div>
                 <div style={{marginBottom: '20px'}}>贾 明 月 : 177-5060-7070</div>
                </div>
              </div>
            </Col>
            <Col span={md}>
              <div className={styles.itemBox}>
                <div className={styles.tit}>邮箱</div>
                <div className={styles.divider} style={BrowserType !== "PC" ? { width: "70%" } : {}}></div>
                <div className={styles.itemCon} style={{alignItems:"center"}}>
                大会邮箱:mhabkjz@163.com

                </div>
              </div>
            </Col>
            <Col span={md}>
              {contactWebsiteData?(<div className={styles.itemBox}>
                <div className={styles.tit}>{contactWebsiteData.content_short}</div>
                <div className={styles.divider} style={BrowserType !== "PC" ? { width: "70%" } : {}}></div>
                <div className={styles.mhBox}>
                  <div style={{width: '200%',marginRight: '0.6rem'}}>
                    <img src={require("../../../../assets/erweima.png")} alt="" style={{width: '100%', height: '100%'}} />
                  </div>
  {isPC?(
    <div style={{width: '400%',textAlign: 'justify'}}>致力于打造民航安保领域专业服务平台，通过整合机场、航司、行业协会、安防产业、专家团队等多方资源，诚邀您加入民航安保网，共促民航安保事业蓬勃发展。</div>
  ):(
    <div style={{width: '528%',textAlign: 'justify'}}>致力于打造民航安保领域专业服务平台，通过整合机场、航司、行业协会、安防产业、专家团队等多方资源，诚邀您加入民航安保网，共促民航安保事业蓬勃发展。</div>
  )}                

                </div>
              </div>):''}
            </Col>
          </Row>

          <p className={styles.bahTxt}><a style={{color:"#fff"}} href="https://beian.miit.gov.cn/">备案号： 京ICP备20015681号</a></p>
        </div>

        {/* 回到顶部 */}
        <BackTop />

      </div>
    )
  }
}

export default PageFooter;