import { Icon } from "antd";
import React from "react";
import styles from "./swiper.less";
import { base } from "@/src/api/base";

export default class mySwiper extends React.Component {
  constructor(props) {
    super();
    console.log(props);
  }
  state = {
    ind: 0,
    classArr: ["img1", "img2", "img3", "img4", "img5"],
    BrowserType: "PC",
    awardList: []
  };

  componentDidMount() {
    window.addEventListener("resize", this.resizeBind);
    this.resizeBind();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeBind);
  }
  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== "Mobile") {
        this.setState({
          BrowserType: "Mobile",
        });
        // 主持嘉宾滚动条位置初始化
      }
    } else {
      if (!this.state.BrowserType !== "PC") {
        this.setState({
          BrowserType: "PC",
        });
      }
    }
  };

  liClick = (e) => {
    e.persist();
    if (e.target.nodeName !== "IMG") {
      return false;
    }

    this.setState({ ind: e.target.parentNode.className.slice(-1) });
    this.changeClassArr(e.target.parentNode.className.slice(-1));
  };
  changeClassArr = (index) => {
    let arr = [];
    for (let i = 0; i < 5; i++) {
      let j = i + +index;
      if (j >= 6) {
        j = j - 5;
      }
      arr[i] = `img${j}`;
    }

    if (index == 5) {
      this.setState({
        classArr: this.numIndex(),
      });
    } else if (index == 4) {
      this.setState(
        {
          classArr: this.numIndex(),
        },
        () => {
          setTimeout(() => {
            this.setState({
              classArr: this.numIndex(),
            });
          }, 300);
        }
      );
    } else if (index == 2) {
      this.setState({
        classArr: this.numIndex(true),
      });
    } else if (index == 3) {
      this.setState(
        {
          classArr: this.numIndex(true),
        },
        () => {
          setTimeout(() => {
            this.setState({
              classArr: this.numIndex(true),
            });
          }, 300);
        }
      );
    }
    console.log(arr);
  };

  numIndex = (bol) => {
    if (bol) {
      let arr = this.state.classArr;
      let arr1 = arr[4];
      let dataArr = [];
      arr.forEach((item, index) => {
        dataArr[index] = arr[index - 1];
      });
      dataArr[0] = arr1;
      return dataArr;
    } else {
      let arr = this.state.classArr;
      let arr1 = arr[0];
      let dataArr = [];
      arr.forEach((item, index) => {
        dataArr[index] = arr[index + 1];
      });
      dataArr[4] = arr1;
      return dataArr;
    }
  };

  render() {
    const { classArr, ind, BrowserType } = this.state;
    var isPC = true;
    if (BrowserType !== "PC") {
      isPC = false;
    }

    let liList = [];
    let titleList = this.props.titleList;
    
    
      titleList.map((item,index)=>{
        return (
          liList.push(
            <li className={classArr[index]} onClick={this.liClick}>
              <img
                src={item.pic.startsWith("http") ? item.pic : `${base.OssAddress}${item.pic}`}
                alt=""
              />
              {classArr[index] == "img1" ? (
              <p className={isPC ? "swiperTitle" : "swiperTitleMO"}>
                {item.address}
              </p>
            ) : null}
            </li>
          )
        )
      })
    
  
    // for (let index in titleList) {
    //   liList.push(
    //     <li className={classArr[index]} onClick={this.liClick}>
    //       <img
    //         src={require(`../../assets/image/huojiang${+index + 1}.jpg`)}
    //         alt=""
    //       />
    //       {classArr[index] == "img1" ? (
    //         <p className={isPC ? "swiperTitle" : "swiperTitleMO"}>
    //           {titleList[index]}
    //         </p>
    //       ) : null}
    //     </li>
    //   );
    // }

    return (
      <div className="swipWrap">
        <ul className={isPC ? "swip" : "swipMO"}>{liList} </ul>
        {/* <p className={isPC ? 'swiperTitle' : 'swiperTitleMO'}>{this.props.data.titleList[ind]}</p> */}
        <Icon
          type="left"
          className={isPC ? "swipLift":"swipLiftMO"}
          onClick={() => {
            this.setState({
              classArr: this.numIndex(true),
            });
          }}
        />
        <Icon
          type="right"
          className={isPC ? "swipRight" : "swipRightMO"}
          onClick={() => {
            this.setState({
              classArr: this.numIndex(),
            });
          }}
        />
      </div>
    );
  }
}
