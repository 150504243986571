import React, { Component } from "react";
import { Row, Col, Anchor, Carousel } from "antd";
import styles from "./index.module.less";
import store from "@/src/redux/store.js";
import API from "../../../api/exhibition";
import { base } from '@/src/api/base'
import { setAdvertisement } from "@/src/redux/actions/Advertisement";
import { tools } from "@/src/lib/tools";
const { Link } = Anchor;
class Content extends Component {
  state = {
    name: "大会详情",
    exhbitionData: [],//总数据
    BrowserType: "PC", // PC Mobile 浏览器类型 手机或者pc端
    yqhData: [],
    zzjgData: [],
    zwhData: [],
    nyjbData: [],
  };

  componentDidMount() {
    window.addEventListener("resize", this.resizeBind);
    this.resizeBind();
    this.QueryExhbition()
    // this.ListenStore()
  }

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== "Mobile") {
        this.setState({
          BrowserType: "Mobile",
        });
      }
    } else {
      if (!this.state.BrowserType !== "PC") {
        this.setState({
          BrowserType: "PC",
        });
      }
    }
  };
  componentWillUnmount() {
    // 对 store 变化取消监听
    window.removeEventListener("resize", this.resizeBind);
  }
  QueryExhbition = () => {
    let params = {
      limit: 1000,
      year: '2021'
    }
    API.QueryExhbition(params).then(res => {
      debugger
      console.log(res)
      if (res.status === 200) {
        var exhbitionData = res.data.list
        if(this.state.BrowserType=="PC"){
          var yqhData = exhbitionData.filter(item => {
            // return item.id == 24
            return item.config_type === '2021邀请函图片';
          })
          var zzjgData = exhbitionData.filter(item => {
            return item.id == 25
          })
          var zwhData = exhbitionData.filter(item => {
            return item.id == 26
          })
          var nyjbData = exhbitionData.filter(item => {
            return item.id == 27
          })
          this.setState({
            exhbitionData,
            yqhData,
            zzjgData,
            zwhData,
            nyjbData,
          })
        }else{
          var yqhData = exhbitionData.filter(item => {
            // return item.id == 28
            return item.config_type === '2021邀请函图片';
          })
          var zzjgData = exhbitionData.filter(item => {
            return item.id == 29
          })
          var zwhData = exhbitionData.filter(item => {
            return item.id == 30
          })
          var nyjbData = exhbitionData.filter(item => {
            return item.id == 31
          })
          this.setState({
            exhbitionData,
            yqhData,
            zzjgData,
            zwhData,
            nyjbData,
          })
        }
        
      } else {
        // message.error(res.msg);
      }
      this.setState({ loading: false });
    }).catch(res => {
      this.setState({ loading: false });
    })
  }

  render() {
    const {
      BrowserType,
      exhbitionData,
      yqhData,
      zzjgData,
      zwhData,
      nyjbData,
    } = this.state;
    var md = 12
    var isPC = true
    if (BrowserType !== "PC") {
      md = 24
      isPC = false
    }

    return (
      <div className={styles.Content}>
        <div className={styles.yqhBox}>
          <div className={styles.imgBox}>
            {
              yqhData && yqhData.length > 0 ?
                <img className={styles.ewmImg} src={yqhData[0].pic.startsWith('http') ? yqhData[0].pic : `${base.OssAddress}${yqhData[0].pic}`} alt="邀请函" /> : null
            }
          </div>
        </div>
        <div className={styles.organizationBox}>
          {/* <div className={styles.imgBoxBg}>
            <img src={require("../assets/image/zzjgBg.png")} alt="组织机构背景图" />
          </div> */}
          {/* <div className={styles.imgBox}>
          {
              zzjgData && zzjgData.length > 0 ?
                <img className={styles.ewmImg} src={zzjgData[0].pic.startsWith('http') ? zzjgData[0].pic : `${base.OssAddress}${zzjgData[0].pic}`} alt="组织机构" /> : null
            }
          </div> */}
          {/* <div className={styles.OrganizingComBox} style={isPC ? {} : { margin: "0 1rem 6rem" }}>
            <div className={styles.titBox + " " + (isPC ? styles.titBoxPC : styles.titBoxMo)}>组委会</div>
            <div className={styles.imgBox}>
            {
              zwhData && zwhData.length > 0 ?
                <img className={styles.ewmImg} src={zwhData[0].pic.startsWith('http') ? zwhData[0].pic : `${base.OssAddress}${zwhData[0].pic}`} alt="组委会" /> : null
            }
            </div>
          </div> */}
          {/* <div className={styles.OrganizingComBox} style={isPC ? {} : { margin: "0 1rem 6rem" }}>
            <div className={styles.titBox + " " + (isPC ? styles.titBoxPC : styles.titBoxMo)}>各论坛拟邀嘉宾</div>
            <div className={styles.imgBox}>
            {
              nyjbData && nyjbData.length > 0 ?
                <img className={styles.ewmImg} src={nyjbData[0].pic.startsWith('http') ? nyjbData[0].pic : `${base.OssAddress}${nyjbData[0].pic}`} alt="各论坛拟邀嘉宾" /> : null
            }
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default Content;
