import * as actionTypes from '../constants/index';
import { local } from '../../lib/tools'

const setAdAroundR = (data) => {
     local.set(actionTypes.SET_ADAROUNDR,data,1)
    return {
        type: actionTypes.SET_ADAROUNDR,
        data
    }
};
const setAdAroundL = (data) => {
    local.set(actionTypes.SET_ADAROUNDL,data,1)
   return {
       type: actionTypes.SET_ADAROUNDL,
       data
   }
};
export {setAdAroundR,setAdAroundL};
