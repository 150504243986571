import React, { Component } from 'react';
import { Row, Col, Carousel, Icon } from 'antd';
import styles from './index.module.less';
import Swiper from '@/src/components/Carousel/swiper';
import TopicItem from '@/src/components/TopicItem';
import ImgCard from '@/src/components/ImgCard';
import store from '@/src/redux/store.js';
import API from '@/src/api/Home'
import { tools } from '@/src/lib/tools';
import { setAdvertisement } from '@/src/redux/actions/Advertisement';
import { base } from '@/src/api/base';
import AdAround from "@/src/components/AdAround";
import { setAdAroundR, setAdAroundL } from "@/src/redux/actions/AdAround";
import { HashRouter } from 'react-router-dom'
import { setBreadcrumbList } from "@/src/redux/actions/BreadcrumbList";
const router = new HashRouter()

class Content extends Component {

  state = {
    name: '',
    title_bar_no: '',
    TopicItemCss: '',
    SwiperDataList: [],
    MiddlePic: '',
    AdvertisementData: [],
    TwoTitleList: [],
    BrowserType: "PC",
    adAroundRList: [], //右侧广告
    adAroundLList: [], //左侧广告
    onlyOnce: true
  };

  constructor(props) {
    super(props)
    this.state.title_bar_no = props.title_bar_no
    this.state.name = props.name
    this.state.path = props.path
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(this.ListenStore.bind(this))
    window.addEventListener('resize', this.resizeBind)
    // this.ListenStore()
    this.resizeBind()
    this.GetSwiper()

    if (this.state.TwoTitleList.length === 0) {
      this.ListenStore()
    }
  }

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.TopicItemCss !== "") {
        this.setState({
          TopicItemCss: "",
        });
      }
      if (this.state.BrowserType !== "Mobile") {
        this.setState({
          BrowserType: "Mobile",
        });
      }
    } else {
      if (!this.state.TopicItemCss !== "TopicItem") {
        this.setState({
          TopicItemCss: "TopicItem",
        });
      }
      if (!this.state.BrowserType !== "PC") {
        this.setState({
          BrowserType: "PC",
        });
      }
    }
  };

  ListenStore = () => {
    if (!this.state.onlyOnce) return
    let { TdTitleBar } = store.getState()

    if (!TdTitleBar || TdTitleBar === {} || TdTitleBar.length === undefined || TdTitleBar.length === 0) {
      return
    }
    let oneTitleList = TdTitleBar.filter(item => {
      return item.level === 2 && item.show_status === 2
    })

    let TwoTitleList = oneTitleList.filter(item => {
      return item.parent_id === this.state.title_bar_no
    })

    // 添加一个置顶的 和第二个轮播图
    TwoTitleList = [{
      id: 0,
      title_bar_no: this.state.title_bar_no,
      parent_id: '',
      name: "",
      level: 2,
      sort: 1,
      nav_status: 2,
      show_status: 2,
      ShowHeaderTitle: false,
      data: [

      ]
    },
    {
      id: 0,
      title_bar_no: 'swiper',
      parent_id: '',
      name: "",
      level: 2,
      sort: 1,
      nav_status: 2,
      show_status: 2,
      ShowHeaderTitle: false,
      data: [

      ]
    }, ...TwoTitleList]

    this.setState({
      TwoTitleList: TwoTitleList,
    });

    // 查询文章
    this.QueryArticle(TwoTitleList);
    this.setState({
      onlyOnce: false
    });
  }

  QueryArticle = (_TwoTitleList) => {
    // console.log('-----TwoTitleList---',_TwoTitleList)
    // let _TwoTitleList = this.state.TwoTitleList
    if (!_TwoTitleList || _TwoTitleList.length === 0) {
      return
    }
    _TwoTitleList.map((item, index) => {

      if (item.title_bar_no === 'swiper') {
        return
      }

      // 文章
      let params = { title_bar_no: item.title_bar_no, offset: 0, limit: 8 }
      params.limit = 9
      if (item.title_bar_no === "1003") {
        params.limit = 7;
        item.showNum = 7;
      }
      if (item.parent_id === "1003") {
        params.limit = 5;
        item.showNum = 5;
      }

        API.QueryArticle(params).then(res => {

          if (res.status === 200) {
            item.data = res.data.list.map((item2, index) => {
              item2.id = item.title_bar_no + item2.id + '-' + tools.random(10000, 99999)
              if (index === 0 && item.title_bar_no === this.state.title_bar_no) {
                item2.BoldTitle = true
                item2.showPic = false
              }
              item2.title_bar_no = item.title_bar_no

              return item2
            });

            item.loading = false;
          } else {
            item.loading = false;
          }

          _TwoTitleList[index] = item
          this.setState({
            TwoTitleList: _TwoTitleList,
          })
        })
      

      return item
    })
  }

  componentWillUnmount() {
    // 对 store 变化取消监听
    this.unsubscribe();
    window.removeEventListener('resize', this.resizeBind)
  }
  MoreArticles = () => {
    store.dispatch(
      setBreadcrumbList([
        {
          id: "1",
          name: '智慧安保',
          path: "/SmartSecurity1003/1003",
        },
        {
          id: "2",
          name: '更多',
          path: "/MoreArticles1003/0",
        },
      ])
    );
    window.open(`${base.localhost}/#/MoreArticles1003/0`, "_blank");
  }
  GetSwiper = () => {
    let params = { title_bar_no: this.state.title_bar_no, offset: 0, limit: 50 }
    API.QueryAdvertise(params).then(res => {
      if (res.status === 200) {
        let SwiperDataList = []
        let AdvertisementData = []
        let MiddlePic = ''
        res.data.list.forEach(element => {
          switch (element.advertise_location) {
            case 1:
              SwiperDataList = [...SwiperDataList, element]
              break;

            case 3:
              MiddlePic = element.pic
              break;

            case 2:
              AdvertisementData = [...AdvertisementData, element]
              break;

            default:
              break;
          }
        });
        this.setState({
          SwiperDataList,
          MiddlePic,
          AdvertisementData,
        })
        store.dispatch(setAdvertisement(AdvertisementData))

      } else {

      }


    })

    let params1 = {
      title_bar_no: "lrAd1003",
      offset: 0,
      limit: 8,
    };
    API.QueryAdvertise(params1).then((res) => {
      if (res.status === 200) {
        let adAroundRList = [];
        let adAroundLList = [];
        res.data.list.forEach((element) => {
          switch (element.advertise_location) {
            case 2:
              adAroundRList = [...adAroundRList, element];
              break;

            case 4:
              adAroundLList = [...adAroundLList, element];
              break;

            default:
              break;
          }
        });
        store.dispatch(setAdAroundR(adAroundRList));
        store.dispatch(setAdAroundL(adAroundLList));
        this.setState({
          adAroundRList,
          adAroundLList,
        });
      } else {
      }
    });
  }

  render() {
    const { AdvertisementData, BrowserType } = this.state
    var adMd = 8
    if (BrowserType !== "PC") adMd = 24
    return (
      <div>
        <Row justify='space-between' type='flex' >

          <div className={BrowserType !== "PC" ? styles.FirstDivMo : styles.FirstDiv}>
            <div className={styles.FirstBox}>
              {
                this.state.SwiperDataList.length > 0 ?
                  <Col xs={24} sm={24} md={11} style={BrowserType == "PC" ? {} : { padding: "0 16px" }} className={styles.leftSwiper}>
                    <Swiper autoplay SwiperDataList={this.state.SwiperDataList} textPosition={true} isTextShow={true}/>
                  </Col>
                  : null
              }
              {
                this.state.TwoTitleList && this.state.TwoTitleList.length > 0 ?
                  <Col key={this.state.TwoTitleList[0].id + 'aaa'} className={styles.CardOne} xs={24} sm={24} md={(this.state.TwoTitleList[0].SwiperDataList && this.state.TwoTitleList[0].SwiperDataList.length > 0) || (this.state.TwoTitleList[0].ExpertList && this.state.TwoTitleList[0].ExpertList.length > 0) ? 24 : 12} >

                    <TopicItem name={this.state.name} title={this.state.TwoTitleList[0].name} data={this.state.TwoTitleList[0].data} SwiperDataList={this.state.TwoTitleList[0].SwiperDataList} ShowHeaderTitle={this.state.TwoTitleList[0].ShowHeaderTitle}
                      showNum={this.state.TwoTitleList[0].showNum} loading={this.state.TwoTitleList[0].loading} ExpertList={this.state.TwoTitleList[0].ExpertList} title_bar_no={this.state.TwoTitleList[0].title_bar_no} />
                  </Col>
                  : null
              }
            </div>
            {
              <div className={styles.moreBox}>
                <div
                  className={styles.headerTitleMoreBtn}
                  onClick={() => { this.MoreArticles(this.props.title_bar_no); }}>
                  <span>MORE </span>
                  <Icon type="arrow-right" />
                </div>
              </div>
            }

          </div>

          <div className={BrowserType == "PC" ? styles.ModdleBox : styles.ModdleBoxMo}>
            <Col xs={24} sm={24} md={AdvertisementData && AdvertisementData.length > 0 ? 24 : 24}  style={ BrowserType == "PC" ? {padding: "2rem 0 5rem"} : {padding: "2rem 0"} }>
              <Row align='top' type='flex' gutter={16}>
                {this.state.TwoTitleList ? this.state.TwoTitleList.map((item, index) => {
                  return (
                    index > 1 ? item.title_bar_no !== "1029" ?
                      <Col key={item.id + index} className={this.state.TopicItemCss} xs={24} sm={24} md={(item.SwiperDataList && item.SwiperDataList.length > 0) || (item.ExpertList && item.ExpertList.length > 0) ? 24 : 11} >
                        <TopicItem name={this.state.name} title={item.name} data={item.data} SwiperDataList={item.SwiperDataList} ShowHeaderTitle={item.ShowHeaderTitle} showNum={item.showNum} loading={item.loading} ExpertList={item.ExpertList} title_bar_no={item.title_bar_no} />
                      </Col>
                      : <Col key={item.id + index} xs={24} sm={24} md={24} >
                        <ImgCard title={item.name} dots={false} loading={item.loading} data={item.data} title_bar_no={item.title_bar_no} name={this.state.name} />
                      </Col> : null
                  )
                }) : null}
              </Row>
            </Col>

            {/* <Col className={styles.AdvertisementDataBox} xs={0} sm={0} md={AdvertisementData && AdvertisementData.length > 0 ? 5 : 0} >
              {
                AdvertisementData.map((item, index) => {
                  return (
                    <a href={item.url} key={item.id + index + 'A'} >
                      <div className={styles.AdvertisementData}>
                        <div>
                          <img src={`${base.OssAddress}${item.pic}`} alt={item.name} />
                        </div>
                        <div className={styles.Text}>
                          <div>
                            {item.name}
                          </div>
                        </div>
                      </div>
                    </a>

                  )
                })
              }

            </Col> */}

          </div>

          {/* {this.state.TwoTitleList ? this.state.TwoTitleList.map((item) => {
            return (
              item.title_bar_no !== "1029" ?
                item.title_bar_no === 'swiper' ?
                  this.state.SwiperDataList.length > 0 ?
                    <Col className={this.state.TopicItemCss} xs={24} sm={24} md={12} >
                      <Swiper autoplay SwiperDataList={this.state.SwiperDataList} />
                    </Col>
                    : null
                  :
                  <Col key={item.id} className={this.state.TopicItemCss} xs={24} sm={24} md={(item.SwiperDataList && item.SwiperDataList.length > 0) || (item.ExpertList && item.ExpertList.length > 0) ? 24 : 12} >
                    <TopicItem name={this.state.name} title={item.name} data={item.data} SwiperDataList={item.SwiperDataList} ShowHeaderTitle={item.ShowHeaderTitle} showNum={item.showNum} loading={item.loading} ExpertList={item.ExpertList} title_bar_no={item.title_bar_no} />
                  </Col>
                : <Col key={item.id} xs={24} sm={24} md={24} >
                  <ImgCard title={item.name} dots={false} loading={item.loading} data={item.data} title_bar_no={item.title_bar_no} name={this.state.name} />
                </Col>

            )
          }) : null} */}

          {/* <Col   xs={24} sm={24} md={24} >
                  <ImgCard title={'专题'} dots={false} loading={false} data={[{},{}]} />
                </Col> */}

        </Row>

      </div>
    )
  }
}

export default Content;