import React, { Component } from "react";

import PageHeader from "@/src/components/PageHeaderOld";
import HeaderMO from "@/src/components/PageHeader";
import PageFooter from "@/src/components/PageFooter";
// import { Link } from 'react-router-dom'
import {
  Avatar,
  Icon,
  Menu,
  notification,
  Button,
  Modal,
  Form,
  Input,
  message,
  Upload,
} from "antd";
// import { UploadOutlined } from '@ant-design/icons';
import store from "@/src/redux/store.js";
import styles from "./index.module.less";
import Message from "./Message";
import Article from "./Article";
import Comment from "./Comment";
import Like from "./Like";
import Collection from "./Collection";
// import MyClassroom from "./MyClassroom";
import Setup from "./Setup";
import { setBreadcrumbList } from "@/src/redux/actions/BreadcrumbList";
import { base } from "@/src/api/base";
import PersonalCenterApi from "@/src/api/PersonalCenterApi";
import PageContent from "@/src/components/PageContent";
import moment from "moment";
import UploadAxios from "@/src/api/Upload";
import { HashRouter } from "react-router-dom";
import { setUserInfo } from "@/src/redux/actions/userInfo";
import MD5 from "crypto-js/md5";

import { BrowserRouter as Router, Route, Link } from "react-router-dom";
const router = new HashRouter();
const QRCode = require("qrcode.react");
/**
 *
 * 个人中心
 */

export default class PersonalCenter extends Component {
  state = {
    detNew: [],
    BrowserType: "PC", // PC Mobile 浏览器类型 手机或者pc端
    OSSData: { host: "" },
    ImgFileState: false,
    update_file: null,
    file_name: "",
    file_list: [],
    loading: false,
    menuKey: "0",
    perArr: [],
    PayVisible: false,
    IsQRCode: false,
    is_vip: "0", // 会员状态 0 未查询 1非会员号 2是会员
    vipData: {},
    vipParam: {
      pay_amount: 0,
    },
    defaultFileList: [],
    defaultFileList1: [],
    userInfo: {},
    titleMO: "个人中心",
    path:'',
    UnreadNum:0,
    offset: 0,
    limit: 5,
    listData: [],
    receiver_type:2
  };

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== "Mobile") {
        this.setState({
          BrowserType: "Mobile",
        });
      }
    } else {
      if (!this.state.BrowserType !== "PC") {
        this.setState({
          BrowserType: "PC",
        });
      }
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeBind);
  }

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== "Mobile") {
        this.setState({
          BrowserType: "Mobile",
        });
      }
    } else {
      if (!this.state.BrowserType !== "PC") {
        this.setState({
          BrowserType: "PC",
        });
      }
    }
  };

  componentDidMount() {
    
    window.addEventListener("resize", this.resizeBind);
    this.resizeBind();
    let { userInfo } = store.getState();
    if (!userInfo) {
      router.history.push("/login");
      return;
    }
    if(userInfo && typeof(userInfo.co_label) == 'string'){
      userInfo.co_label = userInfo.co_label ?  JSON.parse(userInfo.co_label) : []
    }
    this.setState({ userInfo });
    this.init();
    // user_name
    if (userInfo["co_intro"]) {
      this.state["defaultFileList"] = [
        {
          uid: -99,
          name: "企业简介文件",
          status: "done",
          response: "Server Error 500", // custom error message to show
          url: userInfo.co_certificate,
        },
      ];
    }
    if (userInfo["co_certificate"] && this.state.defaultFileList1.length == 0) {
      this.state["defaultFileList1"] = [
        {
          uid: -9,
          name: "企业资质文件",
          status: "userInfo.file2.status",
          response: "Server Error 500", // custom error message to show
          url: userInfo.co_intro,
        },
      ];
    }
    this.MineBuyVip();
    this.QueryVipActivities();
    this.QueryMemberType();
    this.QueryMessage()

  }
  //监听路由、
  componentDidUpdate () {
    let path = this.props.location.pathname
    if(this.state.path !== path){
      this.setState({path:path})
      if(path == '/PersonalCenter'){
        this.QueryMessage()
      }
    }

    console.log(path)
  }
  

  init = async () => {
    this.GetOSSData((OSSData) => {
      if (OSSData === null) {
        return;
      }
      this.setState({
        OSSData,
      });
    });
  };

  GetOSSData = (callback) => {
    UploadAxios.GetOsstoken({})
      .then((res) => {
        if (res.status === 200) {
          // return {
          //     dir: 'user-dir/',
          //     expire: '1577811661',
          //     host: '//www.mocky.io/v2/5cc8019d300000980a055e76',
          //     accessId: 'c2hhb2RhaG9uZw==',
          //     policy: 'eGl4aWhhaGFrdWt1ZGFkYQ==',
          //     signature: 'ZGFob25nc2hhbw==',
          // };
          callback(res.data);
        } else {
          notification.error({
            message: "失败！",
            description: res.msg,
          });
        }
        callback(null);
      })
      .catch(() => {
        notification.error({
          message: "失败！",
          description: "网络超时！",
        });
      });
  };

  handleClick = (value) => {
    this.setState({ menuKey: value.key });
  };

  // 查询是否是会员
  MineBuyVip = () => {
    PersonalCenterApi.MineBuyVip().then((res) => {
      console.log(res);
      if (res.status === 200) {
        if (moment().format("X") < res.data.end_time) {
          this.setState({ vipData: res.data, is_vip: "2" });
        } else {
          this.setState({ vipData: res.data, is_vip: "1" });
        }
      } else {
        // this.setState({ is_vip: "1" });
      }
      // this.setState({ menuKey: "1" });
    });
  };

  // 查询会员支付配置
  QueryMemberType = () => {
    PersonalCenterApi.QueryMemberType({
      offset: 0,
      limit: 3,
      level: 2,
    }).then((res) => {
      console.log(res);
      if (res.status === 200) {
        if (res.data.list && res.data.list.length > 0) {
          this.setState({ vipParam: res.data.list[0] });
        }
      } else {
        message.error(res.msg);
      }
    });
  };

  // 支付会员
  PayFunc = (e) => {
    if (this.state.IsQRCode) {
      this.MineBuyVip();
      this.setState({ PayVisible: false });
    } else {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) {
          // console.log('Received values of form: ', values);
          const { vipParam } = this.state;

          if (!vipParam) {
            this.QueryMemberType();
            message.error("系统错误，请刷新重试！");
            return;
          }
          let price = 0.01;
          price = vipParam.pay_amount;

          this.setState({ loading: true });

          let _params = {
            pay_money: price,
            pay_type: this.state.pay_type, // 付款方式，alipay,wx，支付宝还是微信
            phone_sys: 1, //固定值1
            account_type_no: vipParam.account_type_no, //
            name: vipParam.name,
            level: vipParam.level,
            recharge_user: values.recharge_user,
            company_name: values.company_name,
          };
          console.log(
            _params,
            "------------------------------------------------"
          );
          if (values.tax_no !== undefined) {
            _params.tax_no = values.tax_no;
          }
          // // 测试代码 正式上线删除
          // if("ttt" !== values.company_name){

          // }
          PersonalCenterApi.VipPay(_params).then(
            (res) => {
              if (res.status === 200) {
                // console.log('===vip:', res);
                if ((res.data.trade_type = "aliweb")) {
                  window.open(res.data.mweb_url);
                } else if (res.data.data === 1) {
                  message.info(res.data.msg);
                  this.setState({ PayVisible: false });
                } else {
                  this.setState({
                    pay_code_url: res.data.code_url,
                    IsQRCode: !this.state.IsQRCode,
                  });
                }
              } else {
                message.error(res.msg);
              }
              this.setState({ loading: false });
            },
            () => {
              message.error("网络超时");
            }
          );
        }
      });
    }
  };

  // 查询会员权限图片
  QueryVipActivities = () => {
    PersonalCenterApi.QueryVipActivities({ offset: 0, limit: 8 }).then(
      (res) => {
        if (res.status === 200) {
          this.setState({ perArr: res.data.list });
        } else {
        }
      }
    );
  };

  //上传回调
  updataChange = (info) => {
    let fileList = [...info.fileList];
    if (info.file.status === "done") {
      this.state.userInfo["co_certificate"] =
        "https://hanganyun.oss-cn-beijing.aliyuncs.com/" + info.file.url;
      fileList[0].url =
        "https://hanganyun.oss-cn-beijing.aliyuncs.com/" + info.file.url;

      message.success(`${info.file.name} file uploaded successfully`);

      fileList = fileList.slice(-1);
      fileList[0].url =
        "https://hanganyun.oss-cn-beijing.aliyuncs.com/" + fileList[0].url;
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }

    this.setState({ defaultFileList: fileList });
  };

  updataChange2 = (info) => {
    let defaultFileList1 = [...info.fileList];

    // if (info.file.status !== 'uploading') {
    // }
    if (info.file.status === "done") {
      defaultFileList1 = defaultFileList1.slice(-1);
      this.state.userInfo["co_intro"] =
        "https://hanganyun.oss-cn-beijing.aliyuncs.com/" + info.file.url;
      defaultFileList1[0].url =
        "https://hanganyun.oss-cn-beijing.aliyuncs.com/" + info.file.url;
      // info.file.url = "https://hanganyun.oss-cn-beijing.aliyuncs.com/" + info.file.url;
      // info.fileList = defaultFileList1;
      message.success(`${info.file.name} 上传成功`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} 上传失败`);
    }

    this.setState({ defaultFileList1 });
  };
  //上传参数
  getExtraData = (file) => {
    const { OSSData } = this.state;

    return {
      key: file.url,
      OSSAccessKeyId: OSSData.accessid,
      policy: OSSData.policy,
      Signature: OSSData.signature,
    };
  };

  transformFile = async (file) => {
    // this.setState({ ImgFileState: false })
    // await imgPreview(file, (file) => {

    //     return file;
    // })
    const { OSSData } = this.state;

    const suffix = file.name.slice(file.name.lastIndexOf("."));
    const filename = MD5(Date.now() + file.name + file.uid) + suffix;
    file.url = OSSData.dir + filename;

    this.setState({ file: file, ImgFileState: true });

    return file;
  };

  UpdatesAccount = (e) => {
    const { userInfo } = this.state;
    if (
      userInfo.type === 2 ||
      (userInfo["co_intro"] &&
        userInfo["co_certificate"] &&
        userInfo.type === 1)
    ) {
      PersonalCenterApi.UpdatesAccount(userInfo)
        .then((res) => {
          if (res.status === 200) {
            store.dispatch(setUserInfo(userInfo));
            // message.success('修改成功！')
            this.setState({ key: true });
          } else {
            notification.error({
              message: "失败！",
              description: res.msg,
            });
          }
        })
        .catch(() => {
          notification.error({
            message: "失败！",
            description: "网络超时！",
          });
        });
      // 提交
      this.PayFunc(e);
    } else {
      message.success("请上传文件");
      return false;
    }
  };
  propxiaoxi = (dataDet) => {
    if (!dataDet) {
      return false;
    }
    this.setState({ detNew: dataDet });
  };
  getChildrenMsg = () => {
    this.setState({
        childrenMsg: this.refs['children'].state.msg
    })
  }
  //未读消息
  // 查询消息
  QueryMessage = () => {
    const { userInfo } = store.getState();
    const { limit, offset, receiver_type } = this.state;
    const vip_start_time = this.props.vip_start_time || 0;
    console.log({ offset, limit, receiver_type, vip_start_time });
    PersonalCenterApi.QueryMessage({
      offset,
      limit,
      receiver_type,
      vip_start_time,
    }).then((res) => {
      if (res.status === 200) {

        PersonalCenterApi.QueryMessage1({accountId:userInfo.id,offset, limit:999}).then((sun) => {
            if(res.data && sun.data && res.data.list && sun.data.list){
              let data1 = res.data.list.length || 0
              let data2 = sun.data.list.length || 0
              this.setState({
                UnreadNum : res.data.list.length - sun.data.list.length || 0
              });
            }else if(res.data && res.data.list){
              this.setState({
                UnreadNum : res.data.list.length
              });
            }
            
        });
      }else{
        this.setState({
          UnreadNum : 0
        });
      }
      
    });
  };
  
  render() {
    let { BrowserType } = this.state;
    let that = this;
    var isPC = true;
    if (BrowserType !== "PC") {
      isPC = false;
    }
    const {
      is_vip,
      loading,
      vipData,
      menuKey,
      vipParam,
      OSSData,
      userInfo,
      path,
    } = this.state;
    

    // console.log('---userInfo----->>>>>', userInfo());
    const { getFieldDecorator } = this.props.form;

    const layout = {
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 12,
      },
    };

     
    // 首页
    function IndexMo() {
      console.log("userInfo", userInfo);
      return (
        <>
          <div
            style={{
              backgroundColor: "rgb(233, 241, 253)",
              borderTop: "1px solid rgb(161, 194, 245)",
              width: "100%",
              margin: "auto",
            }}
          >
            <div style={{ width: "100%" }}>
              <div className={styles.headerMO}>
                <div
                  style={{ width: "100%", background: "#fff" }}
                  className={is_vip === "2" ? styles.showVip : ""}
                >
                  <div
                    className={styles.headerImg}
                    onClick={() => {
                      const BreadcrumbList = [
                        {
                          id: 1,
                          name: "个人中心",
                          path: "/PersonalCenter",
                        },
                        {
                          id: 2,
                          name: "修改信息",
                          path: "/PersonalInformation",
                        },
                      ];
                      store.dispatch(setBreadcrumbList(BreadcrumbList));
                      router.history.push(`/PersonalInformation`);
                    }}
                  >
                    <Avatar
                      style={
                        isPC
                          ? { margin: "6rem auto 0rem" }
                          : { margin: "1.31rem auto .81rem" }
                      }
                      size={isPC ? 130 : 68}
                      icon="user"
                      src={base.OssAddress + userInfo.head_url}
                    />
                    <div className={styles.HeaderName}>
                      <div className={styles.ta1}>
                        {(userInfo &&
                        userInfo.user_name &&
                        userInfo.user_name !== undefined
                          ? userInfo.user_name
                          : "") ||
                          `${base.NameInitials}${userInfo ? userInfo.id : ""}`}
                      </div>
                      {/* <div className={styles.ta2} >
                                                    <img src={require('../../assets/my/vip.png')} alt="" />
                                                </div> */}
                      {/* {is_vip === '2' ? <div className={styles.VipIco + ' ' + styles.HeaderIco}>vip</div>
                                                    : null} */}

                      {userInfo &&
                      userInfo.gender !== undefined &&
                      userInfo.gender === 0 ? (
                        <div
                          className={styles.ExpertIco + " " + styles.HeaderIco}
                        >
                          <img
                            className={styles.gender}
                            src={require("../../assets/my/boy.png")}
                            alt=""
                          />
                        </div>
                      ) : (
                        <div
                          className={styles.ExpertIco + " " + styles.HeaderIco}
                        >
                          <img
                            className={styles.gender}
                            src={require("../../assets/my/girl.png")}
                            alt=""
                          />
                        </div>
                      )}
                      {userInfo &&
                      userInfo.experts_type_no !== undefined &&
                      userInfo.experts_type_no.length > 0 ? (
                        <div
                          className={
                            styles.ExpertIco + " " + styles.HeaderIcoExpert
                          }
                        >
                          <img
                            className={styles.expert}
                            src={require("../../assets/my/expert.png")}
                            alt=""
                          />
                        </div>
                      ) : null}
                    </div>
                    {/* 企业名称 */}
                    {userInfo.work_units ? (
                      <div className={styles.nameTitle}>
                        <div>{userInfo.work_units}</div>
                      </div>):''}

                     {userInfo.co_label ?  (<div className={styles.lable}>
                        {userInfo.co_label.map(item =>{
                          return(<span>{item}</span>)
                        })}
                      </div>):''}
                  </div>

                  <>
                    <ul className={styles.titleList} mode="inline">
                      <li
                        key="1"
                        onClick={() => {
                          that.state.menuKey = "1";
                          that.state.titleMO = "我的消息";
                          that.props.history.replace("/PersonalCenter/news");
                        }}
                      >
                        {/* <Icon type="setting" /> */}
                        <div>
                          <img 
                            src={require("../../assets/my/xiaoximo.png")}
                            alt=""
                          />
                        </div>
                        {/* <span>我的消息</span> */}
                        <span style={{ display: "block", flexGrow: "1" }}>
                          我的消息
                        </span>
                        {that.state.UnreadNum ? (<span className={styles.redCircle}>{that.state.UnreadNum}</span>):''}
                        <Icon
                          type="right"
                          style={{
                            fontSize: ".94rem",
                            marginRight: ".72rem",
                            color: "#ccc",
                          }}
                        />
                       
                      </li>
                      <li
                        key="6"
                        onClick={() => {
                          that.state.titleMO = "充值";
                          that.props.history.replace(
                            "/PersonalCenter/Recharge"
                          );
                        }}
                      >
                        {/* <Icon type="setting" /> */}
                        <div>
                          <img
                            src={require("../../assets/my/vipmo.png")}
                            alt=""
                          />
                        </div>

                        {/* <span>会员充值</span> */}
                        <span style={{ display: "block", flexGrow: "1" }}>
                          会员充值
                        </span>
                        <Icon
                          type="right"
                          style={{
                            fontSize: ".94rem",
                            marginRight: ".72rem",
                            color: "#ccc",
                          }}
                        />
                      </li>
                      <li
                        key="2"
                        onClick={() => {
                          that.state.menuKey = "2";
                          that.state.titleMO = "我的评论";
                          that.props.history.replace("/PersonalCenter/news");
                        }}
                      >
                        <div>
                          <img
                            src={require("../../assets/my/pinglunmo.png")}
                            alt=""
                          />
                        </div>
                        {/* <span>我的评论</span> */}
                        <span style={{ display: "block", flexGrow: "1" }}>
                          我的评论
                        </span>
                        <Icon
                          type="right"
                          style={{
                            fontSize: ".94rem",
                            marginRight: ".72rem",
                            color: "#ccc",
                          }}
                        />
                        <div
                          style={{
                            color: "red",
                          }}
                        ></div>
                      </li>
                      <li
                        key="3"
                        onClick={() => {
                          that.state.menuKey = "3";
                          that.state.titleMO = "我的点赞";
                          that.props.history.replace("/PersonalCenter/news");
                        }}
                      >
                        <div>
                          <img
                            src={require("../../assets/my/dianzanmo.png")}
                            alt=""
                          />
                        </div>
                        {/* <span>我的点赞</span> */}
                        <span style={{ display: "block", flexGrow: "1" }}>
                          我的点赞
                        </span>
                        <Icon
                          type="right"
                          style={{
                            fontSize: ".94rem",
                            marginRight: ".72rem",
                            color: "#ccc",
                          }}
                        />
                      </li>
                      <li
                        key="4"
                        onClick={() => {
                          that.state.menuKey = "4";
                          that.state.titleMO = "我的收藏";
                          that.props.history.replace("/PersonalCenter/news");
                        }}
                      >
                        <div>
                          <img
                            src={require("../../assets/my/shoucangmo.png")}
                            alt=""
                          />
                        </div>
                        {/* <span>我的收藏</span> */}
                        <span style={{ display: "block", flexGrow: "1" }}>
                          我的收藏
                        </span>
                        <Icon
                          type="right"
                          style={{
                            fontSize: ".94rem",
                            marginRight: ".72rem",
                            color: "#ccc",
                          }}
                        />
                      </li>
                      <li
                        key="5"
                        className={styles.shezhi}
                        onClick={() => {
                          that.state.menuKey = "5";
                          that.state.titleMO = "我的收藏";
                          that.props.history.replace("/PersonalCenter/news");
                        }}
                      >
                        <div>
                          <img
                            src={require("../../assets/my/shezhimo.png")}
                            alt=""
                          />
                        </div>
                        {/* <span>设置</span> */}
                        <span style={{ display: "block", flexGrow: "1" }}>
                          设置
                        </span>
                        <Icon
                          type="right"
                          style={{
                            fontSize: ".94rem",
                            marginRight: ".72rem",
                            color: "#ccc",
                          }}
                        />
                      </li>
                    </ul>
                  </>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }

    /**
     * 我的消息
     */
    function News() {
      console.log(that.state.menuKey, is_vip, vipData.start_time);
      return (
        <div style={{ minHeight: "calc( 100vh - 47px - 2.6rem - 10.5rem )" }}>
          {
            that.state.menuKey === "1" ? (
              <Message
                is_vip={is_vip}
                vip_start_time={vipData.start_time}
                is_pc={isPC}
                xiaoxi={that.propxiaoxi}
              />
            ) : that.state.menuKey === "2" ? (
              <Comment />
            ) : that.state.menuKey === "3" ? (
              <Like />
            ) : that.state.menuKey === "4" ? (
              <Collection />
            ) : that.state.menuKey === "5" ? (
              <Setup is_pc={isPC} />
            ) : (
              <Article />
            )
            //this.state.menuKey === '5' ? <MyClassroom /> : <Article />
          }
        </div>
      );
    }

    /**
     * 消息详情
     */
    function NewsDetails() {
      let detNew = that.state.detNew;
      return (
        <div className={styles.detMO}>
          <div className={styles.detTitle}>
            <span>{detNew.message_title}</span>
            <Button
              type="primary"
              onClick={() => {
                that.state.titleMO = "我的消息";
                that.props.history.replace("/PersonalCenter/news");
              }}
            >
              返回上一页
            </Button>
          </div>
          <p className={styles.detContent}>{detNew.content_text}</p>
        </div>
      );
    }

    /**
     * 充值
     */
    function Recharge() {
      return (
        <div style={{ width: "100%", marginTop: "1rem" }}>
          {/*我的权限*/}
          <div className={styles.permissionLayoutMO}>
            <div className={styles.per_info_boxMO}>
              {is_vip === "2" ? (
                <div className={styles.per_infoMO}>
                  <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    我的权限
                  </span>
                  <span className={styles.color_redMO}>{vipData.name}</span>
                  <span className={styles.color_redMO}>
                    到期时间:{" "}
                    {moment(vipData.end_time * 1000).format("YYYY-MM-DD")}
                  </span>
                </div>
              ) : (
                <div className={styles.per_infoMO}>
                  <span>我的权限</span>
                  {/* <span className={styles.color_red} >普通会员</span> */}
                  <span className={styles.color_redMO}>
                    现在点击充值，可免费享受一年vip会员福利
                  </span>
                </div>
              )}
              <div style={{ display: "flex" }} className={styles.chongzhi}>
                {/* <Button
              onClick={() => {
                that.setState({ PayVisible: true });
                that.setState({ visible: true });
              }}
            >
              充值
            </Button> */}
                {/* <Button> */}
                {/* <a href="/#/entry_form_list" >报名</a> */}
                {/* </Button> */}
              </div>
            </div>
            <div className={styles.permissionMO}>
              {that.state.perArr.map((item, index) => {
                return (
                  <div key={item.id + "-" + index}>
                    <img
                      style={{ width: "4.27rem", height: "auto" }}
                      src={base.OssAddress + item.pic}
                      alt={item.activity_name}
                    />
                    <p>{item.activity_name}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.czBtnMo}>
            <Button>
              <Link to="/PersonalCenter/Recharge/details">充值</Link>
            </Button>
          </div>
        </div>
      );
    }

    /**
     * 充值详情
     */
    class RechargeDetails extends Component {
      // function RechargeDetails() {
      // const layout = {
      //   labelCol: {
      //     span: 5,
      //   },
      //   wrapperCol: {
      //     span: 12,
      //   },
      // };
      state = {
        defaultFileList: [],
        defaultFileList1: [],
        OSSData: { host: "" },
        userInfo:that.state.userInfo
      }
      componentDidMount() {
        window.addEventListener("resize", this.resizeBind);
        const { userInfo } = store.getState();
        if (!userInfo) {
          router.history.push("/login");
          return;
        }
        this.setState({ userInfo });
        this.init();
        // user_name
        if (userInfo["co_intro"]) {
          this.state["defaultFileList"] = [
            {
              uid: -99,
              name: "企业简介文件",
              status: "done",
              response: "Server Error 500", // custom error message to show
              url: userInfo.co_certificate,
            },
          ];
        }
        if (userInfo["co_certificate"] && this.state.defaultFileList1.length == 0) {
          this.state["defaultFileList1"] = [
            {
              uid: -9,
              name: "企业资质文件",
              status: "userInfo.file2.status",
              response: "Server Error 500", // custom error message to show
              url: userInfo.co_intro,
            },
          ];
        }      }

      init = async () => {
        this.GetOSSData((OSSData) => {
          if (OSSData === null) {
            return;
          }
          this.setState({
            OSSData,
          });
        });
      };
      GetOSSData = (callback) => {
        UploadAxios.GetOsstoken({})
          .then((res) => {
            if (res.status === 200) {
              // return {
              //     dir: 'user-dir/',
              //     expire: '1577811661',
              //     host: '//www.mocky.io/v2/5cc8019d300000980a055e76',
              //     accessId: 'c2hhb2RhaG9uZw==',
              //     policy: 'eGl4aWhhaGFrdWt1ZGFkYQ==',
              //     signature: 'ZGFob25nc2hhbw==',
              // };
              callback(res.data);
            } else {
              notification.error({
                message: "失败！",
                description: res.msg,
              });
            }
            callback(null);
          })
          .catch(() => {
            notification.error({
              message: "失败！",
              description: "网络超时！",
            });
          });
      };

       //上传回调
      updataChange = (info) => {
        let fileList = [...info.fileList];
        if (info.file.status === "done") {
          this.state.userInfo["co_certificate"] =
            "https://hanganyun.oss-cn-beijing.aliyuncs.com/" + info.file.url;
          fileList[0].url =
            "https://hanganyun.oss-cn-beijing.aliyuncs.com/" + info.file.url;

          message.success(`${info.file.name} file uploaded successfully`);

          fileList = fileList.slice(-1);
          fileList[0].url =
            "https://hanganyun.oss-cn-beijing.aliyuncs.com/" + fileList[0].url;
        } else if (info.file.status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }

        this.setState({ defaultFileList: fileList });
        console.log(this.state.defaultFileList)
      };

      updataChange2 = (info) => {
        let defaultFileList1 = [...info.fileList];

        // if (info.file.status !== 'uploading') {
        // }
        if (info.file.status === "done") {
          defaultFileList1 = defaultFileList1.slice(-1);
          this.state.userInfo["co_intro"] =
            "https://hanganyun.oss-cn-beijing.aliyuncs.com/" + info.file.url;
          defaultFileList1[0].url =
            "https://hanganyun.oss-cn-beijing.aliyuncs.com/" + info.file.url;
          // info.file.url = "https://hanganyun.oss-cn-beijing.aliyuncs.com/" + info.file.url;
          // info.fileList = defaultFileList1;
          message.success(`${info.file.name} 上传成功`);
        } else if (info.file.status === "error") {
          message.error(`${info.file.name} 上传失败`);
        }

        this.setState({ defaultFileList1 });
      };

      //上传参数
      getExtraData = (file) => {
        const { OSSData } = this.state;

        return {
          key: file.url,
          OSSAccessKeyId: OSSData.accessid,
          policy: OSSData.policy,
          Signature: OSSData.signature,
        };
      };

      transformFile = async (file) => {
        // this.setState({ ImgFileState: false })
        // await imgPreview(file, (file) => {
    
        //     return file;
        // })
        const { OSSData } = this.state;
    
        const suffix = file.name.slice(file.name.lastIndexOf("."));
        const filename = MD5(Date.now() + file.name + file.uid) + suffix;
        file.url = OSSData.dir + filename;
    
        this.setState({ file: file, ImgFileState: true });
    
        return file;
      };

      UpdatesAccount = (e) => {
        const { userInfo } = this.state;
        if (
          userInfo.type === 2 ||
          (userInfo["co_intro"] &&
            userInfo["co_certificate"] &&
            userInfo.type === 1)
        ) {
          PersonalCenterApi.UpdatesAccount(userInfo)
            .then((res) => {
              if (res.status === 200) {
                store.dispatch(setUserInfo(userInfo));
                // message.success('修改成功！')
                this.setState({ key: true });
              } else {
                notification.error({
                  message: "失败！",
                  description: res.msg,
                });
              }
            })
            .catch(() => {
              notification.error({
                message: "失败！",
                description: "网络超时！",
              });
            });
          // 提交
          that.PayFunc(e);
        } else {
          message.success("请上传文件");
          return false;
        }
      };


      render() {

        return (
          <div className={styles.rechargeDetails}>
            <div>
              <div className={styles.titleMO}>
                <img src={require("../../assets/my/cztitle.png")} alt="" />
              </div>
              <div className={styles.imageMO}>
                <img src={require("../../assets/my/content.png")} alt="" />
              </div>
            </div>
            <div className={styles.PayBoxMO}>
              <ul className={styles.list}>
                <div className={styles.ta + " " + styles.ta1}>
                  <span className={styles.title1}>付费金额：</span>
                  <span className={styles.color_red}>
                    <span
                      style={{
                        fontSize: ".81rem",
                        color: "rgba(241,32,32,1)",
                      }}
                    >
                      ¥{" "}
                    </span>
                    <em
                      style={{
                        fontSize: "1.34rem",
                        fontStyle: "normal",
                        fontWeight: "500",
                      }}
                    >
                      {vipParam.pay_amount}
                    </em>
                  </span>

                  {vipParam.original_price > 0 ? (
                    <span className={styles.originalPrice}>
                      ¥ {vipParam.original_price}
                    </span>
                  ) : null}
                </div>
                <li className={styles.payment}>
                  <span className={styles.title1}>付费方式：</span>
                  <div
                    onClick={(e) => {
                      that.state.pay_type = "wx";
                      e.target.style.backgroundColor = "#1890ff";
                      this.refs.alidiv.style.backgroundColor = "white";
                    }}
                    // {此处有问题 bug}
                    ref="wxdiv"
                  >
                    微信
                  </div>
                  <div
                    ref="alidiv"
                    onClick={(e) => {
                      e.target.style.backgroundColor = "#1890ff";
                      this.refs.wxdiv.style.backgroundColor = "white";
                      that.state.pay_type = "alipay";
                    }}
                  >
                    支付宝
                  </div>
                  <div
                    onClick={() => {
                      message.error("此功能暂时未开放");
                    }}
                  >
                    银行卡
                  </div>
                </li>

                <Form className="login-form1" {...layout}>
                  <Form.Item label="公司名称::" name="company_name">
                    {getFieldDecorator("company_name", {
                      rules: [{ required: true, message: "请填写公司名称！" }],
                    })(<Input placeholder="请填写公司名称！" />)}
                  </Form.Item>

                  <Form.Item label="税号::" name="tax_no">
                    {getFieldDecorator(
                      "tax_no",
                      {}
                    )(<Input placeholder="请填写税号！" />)}
                  </Form.Item>
                </Form>
                {/* 文件上传1 */}
                {userInfo.type === 1 ? (
                  <>
                    <li className={styles.btn}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        <span className={styles.span}>
                          <em className={styles.red}>*</em>企业简介：
                        </span>
                        {/* <div> */}
                        <Upload
                            action={OSSData.host}
                            multiple={false}
                            fileList={this.state.defaultFileList}
                            onClick={that.openPersonalCenter}
                            onChange={this.updataChange}
                            transformFile={this.transformFile}
                            data={this.getExtraData}
                            // src={userInfo.head_url}
                        >
                          <Button>
                            {/* <UploadOutlined />  */}
                            点击上传
                          </Button>
                        </Upload>
                        {!userInfo["co_intro"] ? (
                          <span className={styles.tishi}>
                            检测到您未上传公司简介
                          </span>
                        ) : (
                          ""
                        )}
                      </div>

                      <p
                        style={{
                          width: "100%",
                          margin: ".5rem 0 0 6rem",
                          fontSize: ".69rem",
                        }}
                      >
                        请上传企业简介(支持格式：jpg、png、pdf、word)
                      </p>
                    </li>
                    <li className={styles.btn}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        <span className={styles.span}>
                          <em className={styles.red}>*</em>企业资质：
                        </span>
                        <Upload
                          action={OSSData.host}
                          fileList={this.state.defaultFileList1}
                          onClick={that.openPersonalCenter}
                          onChange={this.updataChange2}
                          multiple={false}
                          transformFile={this.transformFile}
                          data={this.getExtraData}
                          // src={userInfo.head_url}
                        >
                          <Button>
                            {/* <UploadOutlined />  */}
                            点击上传
                          </Button>
                        </Upload>
                        {!userInfo["co_certificate"] ? (
                          <span className={styles.tishi}>
                            检测到您未上传企业资质
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <p
                        style={{
                          width: "100%",
                          margin: ".5rem 0 0 6rem",
                          fontSize: ".69rem",
                        }}
                      >
                        请上传企业资质(支持格式：jpg、png、pdf、word)
                      </p>
                    </li>
                  </>
                ) : (
                  ""
                )}
              </ul>
              <div
                className={styles.submit}
                style={{ margin: "2rem auto 3.66rem" }}
                onClick={that.UpdatesAccount}
              >
                确认提交
              </div>
            </div>
          </div>
        );
      }
    }


    return !isPC ? (
      <div>
        {/* 移动端 */}
        <HeaderMO isHome={true} showSearch={false} />
        <div className={styles.title}>
          <Link
            to={{
              pathname:
                that.state.titleMO === "个人中心" ? "/" : "/PersonalCenter",
            }}
            replace
            tag="img"
            onClick={() => {
              this.state.titleMO = "个人中心";
            }}
          >
            <img src={require("../../assets/my/prev.png")} alt="" />
          </Link>
          <span> {that.state.titleMO ? that.state.titleMO : "个人中心"}</span>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap"}}>
          <div style={{ flex: "1 1", width: "100%", minHeight:'calc( 100vh - 47px - 2.63rem - 10.41rem )'}}>
            <Route path="/PersonalCenter" exact component={IndexMo} />
            <Route path="/PersonalCenter/news" exact component={News} />
            <Route path="/PersonalCenter/Recharge" exact component={Recharge} />
            <Route
              path="/PersonalCenter/NewsDetails"
              exact
              component={NewsDetails}
            />
            <Route
              path="/PersonalCenter/Recharge/details"
              component={RechargeDetails}
            />
          </div>

          <div className={styles.abuotFooter}>
            <p>Copyright@2007-2020</p>
            <p>地址：北京市顺义区天竺镇润通大厦A座2层</p>
            <p>电话： 010-84456090</p>
            <p>邮箱： anbohuizhan@163.com</p>
            <p>备案号： 京ICP备20015681号</p>
          </div>
        </div>
      </div>
    ) : (
      <div className={styles.PersonalCenterBox}>
        <PageContent loading={loading}>
          <PageHeader current="/PersonalCenter" showNav={false} />
          <div className={styles.content}>
            {/*侧边栏*/}
            <div
              style={{
                backgroundColor: "rgb(233, 241, 253)",
                borderTop: "1px solid rgb(161, 194, 245)",
                width: "100%",
                margin: "auto",
              }}
            >
              <div
                style={
                  isPC
                    ? {
                        width: "84%",
                        margin: "0 auto",
                        backgroundColor: "#fff",
                      }
                    : { width: "100%" }
                }
              >
                <div className={`${isPC ? styles.header : styles.headerMO}`}>
                  <div
                    className={is_vip === "2" ? styles.showVipPC : ""}
                    style={
                      isPC
                        ? {
                            width: "25%",
                            borderTop: "2px solid rgb(253, 161, 52)",
                            marginTop: "-1px",
                            borderRight: "2px solid rgba(217, 217, 217,.7)",
                          }
                        : { width: "100%", background: "#fff" }
                    }
                  >
                    {/* 导航栏 */}
                    {!isPC ? (
                      <div className={styles.title}>
                        <Link to={{ pathname: "/" }} replace tag="img">
                          <img
                            src={require("../../assets/my/prev.png")}
                            alt=""
                          />
                        </Link>
                        <span>个人中心</span>
                      </div>
                    ) : (
                      ""
                    )}

                    <div
                      className={styles.headerImg}
                      onClick={() => {
                        const BreadcrumbList = [
                          {
                            id: 1,
                            name: "个人中心",
                            path: "/PersonalCenter",
                          },
                          {
                            id: 2,
                            name: "修改信息",
                            path: "/PersonalInformation",
                          },
                        ];
                        store.dispatch(setBreadcrumbList(BreadcrumbList));
                        router.history.push(`/PersonalInformation`);
                      }}
                    >
                      <Avatar
                        style={{margin:"6rem auto 0rem"}}
                        size={isPC ? 130 : 68}
                        onClick={this.openPersonalCenter}
                        icon="user"
                        src={base.OssAddress + userInfo.head_url}
                      />
                      <div className={styles.HeaderName}>
                        <div className={styles.ta1}>
                          {(userInfo &&
                          userInfo.user_name &&
                          userInfo.user_name !== undefined
                            ? userInfo.user_name
                            : "") ||
                            `${base.NameInitials}${
                              userInfo ? userInfo.id : ""
                            }`}
                        </div>
                        {/* <div className={styles.ta2} >
                                                    <img src={require('../../assets/my/vip.png')} alt="" />
                                                </div> */}
                        {/* {is_vip === '2' ? <div className={styles.VipIco + ' ' + styles.HeaderIco}>vip</div>
                                                    : null} */}
                        {userInfo &&
                        userInfo.gender !== undefined &&
                        userInfo.gender === 0 ? (
                          <div
                            className={
                              styles.ExpertIco + " " + styles.HeaderIco
                            }
                          >
                            <img
                              className={styles.gender}
                              src={require("../../assets/my/boy.png")}
                              alt=""
                            />
                          </div>
                        ) : (
                          <div
                            className={
                              styles.ExpertIco + " " + styles.HeaderIco
                            }
                          >
                            <img
                              className={styles.gender}
                              src={require("../../assets/my/girl.png")}
                              alt=""
                            />
                          </div>
                        )}
                        {userInfo &&
                        userInfo.experts_type_no !== undefined &&
                        userInfo.experts_type_no.length > 0 ? (
                          <div
                            className={
                              styles.ExpertIco + " " + styles.HeaderIco
                            }
                          >
                            <img
                              className={styles.expert}
                              src={require("../../assets/my/expert.png")}
                              alt=""
                            />
                          </div>
                        ) : null}
                      </div>
                      {/* 企业名称 */}
                      {userInfo.work_units ? (
                      <div className={styles.nameTitle}>
                        <div>{userInfo.work_units}</div>
                      </div>):''}

                     {userInfo.co_label ?  (<div className={styles.lable}>
                        {userInfo.co_label.map((item,ind) =>{
                          return(<span key={ind}>{item}</span>)
                        })}
                      </div>):''}
                    </div>

                    <Menu
                      onClick={this.handleClick}
                      defaultSelectedKeys={["1"]}
                      defaultOpenKeys={["sub1"]}
                      mode="inline"
                    >
                      <Menu.Item key="1">
                        {/* <Icon type="setting" /> */}
                        {menuKey === "1" ? (
                          <div>
                            <img
                              src={require("../../assets/my/xiaoxi.png")}
                              alt=""
                            />
                          </div>
                        ) : (
                          <div>
                            <img
                              src={require("../../assets/my/xiaoxi2.png")}
                              alt=""
                            />
                          </div>
                        )}
                        <span>我的消息</span>
                      </Menu.Item>
                      <Menu.Item key="2">
                        {menuKey === "2" ? (
                          <div>
                            <img
                              src={require("../../assets/my/youxiang2.png")}
                              alt=""
                            />
                          </div>
                        ) : (
                          <div>
                            <img
                              src={require("../../assets/my/youxiang.png")}
                              alt=""
                            />
                          </div>
                        )}
                        <span>我的评论</span>
                      </Menu.Item>
                      <Menu.Item key="3">
                        {menuKey === "3" ? (
                          <div>
                            <img
                              src={require("../../assets/my/dianzan2.png")}
                              alt=""
                            />
                          </div>
                        ) : (
                          <div>
                            <img
                              src={require("../../assets/my/dianzan.png")}
                              alt=""
                            />
                          </div>
                        )}
                        <span>我的点赞</span>
                      </Menu.Item>
                      <Menu.Item key="4">
                        {menuKey === "4" ? (
                          <div>
                            <img
                              src={require("../../assets/my/shoucang2.png")}
                              alt=""
                            />
                          </div>
                        ) : (
                          <div>
                            <img
                              src={require("../../assets/my/shoucang.png")}
                              alt=""
                            />
                          </div>
                        )}
                        <span>我的收藏</span>
                      </Menu.Item>
                      <Menu.Item key="5">
                        {menuKey === "5" ? (
                          <div>
                            <img
                              src={require("../../assets/my/ketang2.png")}
                              alt=""
                            />
                          </div>
                        ) : (
                          <div>
                            <img
                              src={require("../../assets/my/ketang.png")}
                              alt=""
                            />
                          </div>
                        )}
                        <span>设置</span>
                      </Menu.Item>
                      {/* <Menu.Item key="5">
                                                
                                                {
                                                    menuKey === '5'
                                                        ? < div >
                                                            <img src={require('../../assets/my/ketang2.png')} alt="" />
                                                        </div> :
                                                        <div>
                                                            <img src={require('../../assets/my/ketang.png')} alt="" />
                                                        </div>
                                                }
                                                <span>我的课堂</span>
                                            </Menu.Item> */}
                      {userInfo &&
                      userInfo.account_type_no ===
                        "20200114141737223460455832621056" ? (
                        <Menu.Item key="6">
                          {menuKey === "6" ? (
                            <div>
                              <img
                                src={require("../../assets/my/wenzhang2.png")}
                                alt=""
                              />
                            </div>
                          ) : (
                            <div>
                              <img
                                src={require("../../assets/my/wenzhang.png")}
                                alt=""
                              />
                            </div>
                          )}
                          <span>我的文章</span>
                        </Menu.Item>
                      ) : null}
                    </Menu>
                  </div>

                  {/*内容*/}

                  <div style={{ width: "75%", marginTop: "1rem" }}>
                    {/*我的权限*/}
                    <div className={styles.permissionLayout}>
                      <div
                        className={styles.per_info_box}
                        style={{
                          paddingBottom: "1rem",
                          borderBottom: "2px solid #ccc",
                          marginBottom: "1rem",
                        }}
                      >
                        {is_vip === "2" ? (
                          <div className={styles.per_info}>
                            <span
                              style={{ fontWeight: "700", fontSize: "1.5rem" }}
                            >
                              我的权限
                            </span>
                            <span className={styles.color_red}>
                              {vipData.name}
                            </span>
                            <span className={styles.color_red}>
                              到期时间:{" "}
                              {moment(vipData.end_time * 1000).format(
                                "YYYY-MM-DD"
                              )}
                            </span>
                          </div>
                        ) : (
                          <div className={styles.per_info}>
                            <span>我的权限</span>
                            {/* <span className={styles.color_red} >普通会员</span> */}
                            <span className={styles.color_red}>
                              现在点击充值，可免费享受一年vip会员福利
                            </span>
                          </div>
                        )}
                        <div
                          style={{ display: "flex" }}
                          className={styles.chongzhi}
                        >
                          <Button
                            onClick={() => {
                              this.setState({ PayVisible: true });
                              this.setState({ visible: true });
                            }}
                          >
                            充值
                          </Button>
                          {/* <Button> */}
                          {/* <a href="/#/entry_form_list" >报名</a> */}
                          {/* </Button> */}
                        </div>
                      </div>

                      <div className={styles.permission}>
                        {this.state.perArr.map((item, index) => {
                          return (
                            <div key={item.id + "-" + index}>
                              <img
                                style={{ width: "80%", height: "auto" }}
                                src={base.OssAddress + item.pic}
                                alt={item.activity_name}
                              />
                              <div>{item.activity_name}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    {/*菜单对应内容*/}
                    <div>
                      <Route
                        path="/PersonalCenter/NewsDetails"
                        exact
                        component={NewsDetails}
                      />
                      {
                        this.state.menuKey === "1" && (path === '/PersonalCenter' || path === '/PersonalCenter/news')? (
                          <Message
                            is_vip={is_vip}
                            vip_start_time={vipData.start_time}
                            is_pc={isPC}
                            xiaoxi={this.propxiaoxi}
                            // parent={this.unreadNum}
                          />
                        ) : this.state.menuKey === "2" ? (
                          <Comment />
                        ) : this.state.menuKey === "3" ? (
                          <Like />
                        ) : this.state.menuKey === "4" ? (
                          <Collection />
                        ) : this.state.menuKey === "5" ? (
                          <Setup />
                        ) : path === '/PersonalCenter'?(
                          <Article />
                        ) : ''
                        //this.state.menuKey === '5' ? <MyClassroom /> : <Article />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 付费框 */}
          <Modal
            // title={"购买课程"}
            closeIcon={<Icon type="close-circle" theme="filled" />}
            okText={this.state.IsQRCode ? "完成" : "去付款"}
            cancelText="取消"
            visible={this.state.PayVisible}
            onOk={this.PayFunc}
            footer={null}
            onCancel={() => {
              this.setState({ PayVisible: false });
            }}
          >
            {!this.state.IsQRCode ? (
              <div className={styles.PayBox}>
                <div className={styles.title}>
                  <img src={require("../../assets/my/left.png")} alt="" />
                  <span>年度会员，福利升级</span>
                </div>
                <div className={styles.image}>
                  <img src={require("../../assets/my/content.png")} alt="" />
                </div>

                <ul className={styles.list}>
                  <div className={styles.ta + " " + styles.ta1}>
                    <span className={styles.title1}>付费金额：</span>
                    <span className={styles.color_red}>
                      <span
                        style={{
                          fontSize: ".81rem",
                          color: "rgba(241,32,32,1)",
                        }}
                      >
                        ¥{" "}
                      </span>
                      <em
                        style={{
                          fontSize: "1.34rem",
                          fontStyle: "normal",
                          fontWeight: "500",
                        }}
                      >
                        {vipParam.pay_amount}
                      </em>
                    </span>

                    {vipParam.original_price > 0 ? (
                      <span className={styles.originalPrice}>
                        ¥ {vipParam.original_price}
                      </span>
                    ) : null}
                  </div>
                  <li className={styles.payment}>
                    <span className={styles.span}>付费方式：</span>
                    <div
                      onClick={(e) => {
                        this.state.pay_type = "wx";
                        e.target.style.backgroundColor = "#1890ff";
                        this.refs.alidiv.style.backgroundColor = "white";
                      }}
                      ref="wxdiv"
                    >
                      微信
                    </div>
                    <div
                      ref="alidiv"
                      onClick={(e) => {
                        e.target.style.backgroundColor = "#1890ff";
                        this.refs.wxdiv.style.backgroundColor = "white";
                        this.state.pay_type = "alipay";
                      }}
                    >
                      支付宝
                    </div>
                    <div
                      onClick={() => {
                        message.error("此功能暂时未开放");
                      }}
                    >
                      银行卡
                    </div>
                  </li>
                  <Form className="login-form" {...layout}>
                    <Form.Item label="公司名称" name="company_name">
                      {getFieldDecorator("company_name", {
                        rules: [
                          { required: true, message: "请填写公司名称！" },
                        ],
                      })(<Input placeholder="请填写公司名称！" />)}
                    </Form.Item>

                    <Form.Item label="税号" name="tax_no">
                      {getFieldDecorator(
                        "tax_no",
                        {}
                      )(<Input placeholder="请填写税号！" />)}
                    </Form.Item>
                  </Form>
                  {/* 文件上传1 */}
                  {userInfo.type === 1 ? (
                    <>
                      <li className={styles.btn}>
                        <div style={{ width: "100%", display: "flex" }}>
                          <span className={styles.span}>
                            <em className={styles.red}>*</em>企业简介：
                          </span>
                          {/* <div> */}
                          <Upload
                            action={OSSData.host}
                            multiple={false}
                            fileList={this.state.defaultFileList}
                            onClick={this.openPersonalCenter}
                            onChange={this.updataChange}
                            transformFile={this.transformFile}
                            data={this.getExtraData}
                            // src={userInfo.head_url}
                          >
                            <Button>
                              {/* <UploadOutlined />  */}
                              点击上传
                            </Button>
                          </Upload>
                          {!userInfo["co_intro"] ? (
                            <span className={styles.tishi}>
                              检测到您未上传公司简介
                            </span>
                          ) : (
                            ""
                          )}
                        </div>

                        <p style={{ width: "100%", margin: ".5rem 0 0 6rem" }}>
                          支持格式：jpg、png、pdf、word
                        </p>
                      </li>
                      <li className={styles.btn}>
                        <div style={{ width: "100%", display: "flex" }}>
                          <span className={styles.span}>
                            <em className={styles.red}>*</em>企业资质：
                          </span>
                          <Upload
                            action={OSSData.host}
                            fileList={this.state.defaultFileList1}
                            onClick={this.openPersonalCenter}
                            onChange={this.updataChange2}
                            multiple={false}
                            transformFile={this.transformFile}
                            data={this.getExtraData}
                            // src={userInfo.head_url}
                          >
                            <Button>
                              {/* <UploadOutlined />  */}
                              点击上传
                            </Button>
                          </Upload>
                          {!userInfo["co_certificate"] ? (
                            <span className={styles.tishi}>
                              检测到您未上传企业资质
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <p style={{ width: "100%", margin: ".5rem 0 0 6rem" }}>
                          支持格式：jpg、png、pdf、word
                        </p>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                </ul>
                <div className={styles.submit} onClick={this.UpdatesAccount}>
                  确认提交
                </div>
              </div>
            ) : (
              <div className={styles.code}>
                <div>
                  <QRCode value={this.state.pay_code_url} size={120} />
                </div>
                <div>
                  使用微信扫描二维码完成支付
                  (支付完有延迟，请等待并及时刷新页面)
                </div>
              </div>
            )}
          </Modal>
          )
          <PageFooter colorE9f1fc={false} changdu={1} />
        </PageContent>
      </div>
    );
  }

}
PersonalCenter = Form.create({})(PersonalCenter);
