import React, { Component } from 'react';
import { Row, Col } from 'antd';
import styles from './index.module.less';
import Swiper from '@/src/components/Carousel/swiper';
import TopicItem from '@/src/components/TopicItem';
import ImgCard from '@/src/components/ImgCard';
import store from '@/src/redux/store.js';
import API from '@/src/api/Home'
import { tools } from '@/src/lib/tools';
import { base } from '@/src/api/base'
import { setAdvertisement } from '@/src/redux/actions/Advertisement';

class Content extends Component {

  state = {
    name: '',
    title_bar_no: '',
    TopicItemCss: '',
    SwiperDataList: [],
    AdvertisementData: [],
    MiddlePic: '',
  };

  constructor(props) {
    super(props)
    this.state.title_bar_no = props.title_bar_no
    this.state.name = props.name
    this.state.path = props.path
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeBind)
    this.resizeBind()
    this.GetSwiper()

    this.QuerySubjectTitleBar()
  }

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.TopicItemCss !== '') {
        this.setState({
          TopicItemCss: '',
        });
      }
    } else {
      if (!this.state.TopicItemCss !== 'TopicItem') {
        this.setState({
          TopicItemCss: 'TopicItem',
        });
      }
    }
  }

  ListenStore = () => {
    let { TdTitleBar } = store.getState()

    if (!TdTitleBar || TdTitleBar === {} || TdTitleBar.length === undefined || TdTitleBar.length === 0) {
      return
    }
    let oneTitleList = TdTitleBar.filter(item => {
      return item.level === 2 && item.show_status === 2
    })

    let TwoTitleList = oneTitleList.filter(item => {
      return item.parent_id === this.state.title_bar_no
    })

    // 添加一个置顶的 
    TwoTitleList = [{
      id: 0,
      title_bar_no: this.state.title_bar_no,
      parent_id: '',
      name: "",
      level: 2,
      sort: 1,
      nav_status: 2,
      show_status: 2,
      ShowHeaderTitle: false,
      data: [

      ]
    }, ...TwoTitleList]

    this.setState({
      TwoTitleList: TwoTitleList,
    })
    // 查询文章
    this.QueryArticle(TwoTitleList)
  }

  QuerySubjectTitleBar = () => {
    API.QuerySubjectTitleBar({subject_no: this.state.title_bar_no}).then(res => {
      console.log(res)
      if (res.status === 200 ) {
        this.setState({
          TwoTitleList: res.data.list,
        })
        // 查询文章
        this.QueryArticle(res.data.list)
      }
    })
  }

  QueryArticle = (_TwoTitleList) => {
    // let _TwoTitleList = this.state.TwoTitleList
    if (!_TwoTitleList || _TwoTitleList.length === 0) {
      return
    }
    _TwoTitleList.map((item, index) => {
      // 文章
      let params = { title_bar_no: item.title_bar_no, offset: 0, limit: 5 }
      if (item.title_bar_no === '1022') {
        params.limit = 9
        API.QuerySubject(params).then(res => {
          if (res.status === 200) {
            item.data = res.data.list.map((item2, index) => {
              item2.id = item.title_bar_no + item2.id + '-' + tools.random(10000, 99999)
              if (index === 0 && item.title_bar_no === this.state.title_bar_no) {
                item2.BoldTitle = true
                item2.showPic = false
              }
              item2.title = item.subject_name
              item2.title_bar_no = item.title_bar_no
  
              return item2
            });
  
            if (item.title_bar_no === '1022') {
              item.data = tools.sliceArr(item.data, 3)
            }
  
            item.loading = false;
          } else {
            item.loading = false;
          }
  
          _TwoTitleList[index] = item
          this.setState({
            TwoTitleList: _TwoTitleList,
          })
        })
      }else{
        API.QueryArticle(params).then(res => {
          console.log(params,res)
          if (res.status === 200) {
            item.data = res.data.list.map((item2, index) => {
              item2.id = item.title_bar_no + item2.id + '-' + tools.random(10000, 99999)
              if (index === 0 && item.title_bar_no === this.state.title_bar_no) {
                item2.BoldTitle = true
                item2.showPic = false
              }
              item2.title_bar_no = item.title_bar_no
  
              return item2
            });
  
            if (item.title_bar_no === '1022') {
              item.data = tools.sliceArr(item.data, 3)
            }
  
            item.loading = false;
          } else {
            item.loading = false;
          }
  
          _TwoTitleList[index] = item
          this.setState({
            TwoTitleList: _TwoTitleList,
          })
        })
      }
      

      return item
    })

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeBind)
  }

  GetSwiper = () => {
    let params = { title_bar_no: this.state.title_bar_no, offset: 0, limit: 5 }
    API.QueryAdvertise(params).then(res => {
      if (res.status === 200) {
        let SwiperDataList = []
        let AdvertisementData = []
        let MiddlePic = ''
        res.data.list.forEach(element => {
          switch (element.advertise_location) {
            case 1:
              SwiperDataList = [...SwiperDataList, element]
              break;

            case 3:
              MiddlePic = element.pic
              break;

            case 2:
              AdvertisementData = [...AdvertisementData, element]
              break;

            default:
              break;
          }
        });
        this.setState({
          SwiperDataList,
          MiddlePic,
          AdvertisementData,
        })
        store.dispatch(setAdvertisement(AdvertisementData))

      } else {

      }


    })
  }

  render() {
    const { AdvertisementData } = this.state

    return (
      <div className={styles.HomeContent} >

        <Row justify='space-between' type='flex' >



          {/* {
            this.state.SwiperDataList.length > 0 ?
              <Col className={this.state.TopicItemCss} xs={24} sm={24} md={12} >
                <Swiper autoplay SwiperDataList={this.state.SwiperDataList} />
              </Col>
              : null
          } */}

          <div className={styles.FirstDiv}>
            {
              this.state.SwiperDataList.length > 0 ?
                <Col className={this.state.TopicItemCss} xs={24} sm={24} md={24} >
                  <Swiper autoplay SwiperDataList={this.state.SwiperDataList} isTextShow={false}/>
                </Col>
                : null
            }
          </div>
          <div className={styles.adContainer}>
            {AdvertisementData.map((item, index) => {
              return index <= 6 ? (
                <Col className={styles.AdBoxNew} xs={8} sm={8} md={8}>
                  <a href={item.url?item.url:"javascript:void(0)"}>
                    <div className={styles.AdvertisementData}>
                      <div>
                        <img
                          src={`${base.OssAddress}${item.pic}`}
                          alt={item.name}
                        />
                      </div>
                      <div className={styles.Text}>
                        <div>{item.name}</div>
                      </div>
                    </div>
                  </a>{" "}
                </Col>
              ) : null;
            })}
          </div>

          <div className={styles.ModdleBox}>
            <Col xs={24} sm={24} md={AdvertisementData && AdvertisementData.length > 0 ? 24 : 24}  style={{ padding :'2rem 0 0' }}>
              <Row align='top' type='flex' gutter={16}>
                {this.state.TwoTitleList ? this.state.TwoTitleList.map((item , index) => {
                  return (
                    item.title_bar_no !== '1022' ?
                      <Col key={item.id} className={this.state.TopicItemCss} xs={24} sm={24} md={(item.SwiperDataList && item.SwiperDataList.length > 0) || (item.ExpertList && item.ExpertList.length > 0) || item.title_bar_no === "20210426143043393061274147098624" ? 24 : 11} style={{ paddingLeft: '8px', paddingRight: '8px' }}>
                        <TopicItem name={this.state.name} title={item.name} data={item.data} SwiperDataList={item.SwiperDataList} ShowHeaderTitle={item.ShowHeaderTitle} showNum={item.showNum} loading={item.loading} ExpertList={item.ExpertList} title_bar_no={item.title_bar_no} />
                      </Col>
                      : <Col key={item.id} xs={24} sm={24} md={24} >
                        <ImgCard title={'专题'} dots={false} loading={item.loading} data={item.data} title_bar_no={item.title_bar_no} name={this.state.name} />
                      </Col>

                  )
                }) : null}
              </Row>
            </Col>
{/* 
            <Col className={styles.AdvertisementDataBox} xs={0} sm={0} md={AdvertisementData && AdvertisementData.length > 0 ? 4 : 0} >
              {
                AdvertisementData.map((item) => {
                  return (
                      <a href={item.url} >
                        <div className={styles.AdvertisementData}>
                          <div>
                            <img src={`${base.OssAddress}${item.pic}`} alt={item.name} />
                          </div>
                          <div className={styles.Text}>
                            <div>
                              {item.name}
                            </div>
                          </div>
                        </div>
                      </a> 

                  )
                })
              }

            </Col> */}

          </div>


          {/* <Col   xs={24} sm={24} md={24} >
                  <ImgCard title={'专题'} dots={false} loading={false} data={[{},{}]} />
                </Col> */}

        </Row>

      </div>
    )
  }
}

export default Content;