import React, { Component } from 'react';
import {Redirect} from "react-router-dom";

import styles from './Setup.module.less'


/**
 * 设置
 */

export default class MyClassroom extends Component {
    state = {
       key:false
    }


    render() {
        if(this.state.key === 1){
            return <Redirect to='/PersonalInformation' />
        }else if(this.state.key == 2){
            return <Redirect to='/ForgetPwd' />
        }

        return (
            <div className={this.props.is_pc ? styles.wrap : styles.wrapMO}>
                {this.props.is_pc ? <hr className={styles.hr}/> : ''}
               <p className={styles.changeHead} >
                    <img src={require('../../assets/my/changeHead.png')} alt="" />
                    <span onClick = {()=>{
                            this.setState({key:1})
                    }}>修改头像昵称</span>
                </p>
                <p className={styles.changeHead}>
                    <img src={require('../../assets/my/changePassword.png')} alt="" />
                    <span onClick = {()=>{
                            this.setState({key:2})
                    }}>修改密码</span>
                </p>
            </div>
        );
    }
}