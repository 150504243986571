import React, { Component } from "react";
import { Avatar, Col, Row, Popover, Icon, Modal, Button, Carousel } from "antd";
import styles from "./index.module.less";
import { base } from "@/src/api/base";
import swiperPatch from "../swiperPatch/swiperPatch"//轮播定时器
class Expert extends Component {
  state = {
    content: <div></div>,
    ExpertList: [],
    isPC: true,
    visible: false,
  };
  componentDidMount() {
    window.addEventListener("resize", this.resizeBind);
    this.resizeBind();
    var _this = this;
    this.setState({
      ExpertList: _this.props.ExpertList,
    });
    this.guestSwiper()
  }
  componentWillUnmount() {
    //离开页面时清除轮播定时器
    if (this.state.guest_clear_timeout) {
      this.state.guest_clear_timeout();
    }
    window.removeEventListener("resize", this.resizeBind);
  }
  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.isPC) {
        this.setState({
          isPC: false,
        });
      }
    } else {
      if (!this.state.isPC) {
        this.setState({
          isPC: true,
        });
      }
    }
  };
  handleDetail = (item) => { };

  //显示气泡
  handlePopover = (item, type) => {
    // console.log(item,type);
    this.state.ExpertList.forEach((item1) => {
      if (item.id == item1.id) {
        item1.clicked = type;
      }
    });
    this.addDom(item)

    this.setState({
      ExpertList: this.state.ExpertList,
    });
  };
  addDom = (item, show) => {
    this.state.content = (
      <div className={styles.ExperContent2} key={item.id}>
        {
          show ? null : (
            <Icon
              className={styles.icon}
              type="close"
              onClick={this.handlePopover.bind(this, item, false)}
            ></Icon>
          )
        }


        <div className={styles.top}>
          <div className={styles.ExperAvatar}>
            <Avatar
              shape="square"
              size={114}
              src={
                !item.head_url.startsWith("http")
                  ? base.OssAddress + item.head_url
                  : item.head_url
              }
            />
          </div>
          <div className={styles.ExpertDetailItemName}>{item.real_name} </div>
        </div>

        <div className={styles.ExpertDetails}>
          <div className={styles.ExpertDetailItem}>
            {item.resume}
          </div>
          <div className={styles.ExpertDetailItem}>
            { Number(item.publish_article_count) > 0 ?
                <span>发布文章： {item.publish_article_count} 篇</span> : null
            }
          </div>
        </div>
      </div>
    );
  }
  //对话框
  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  //轮播定时器
  guestSwiper = () => {
    const carousel_autoplay_time = 6500;
    const {
      carousel_autoplay,
      carousel_prev,//上一页
      carousel_next,//下一页
      clear_timeout,
    } = swiperPatch(this.slider, carousel_autoplay_time);
    this.setState({
      carousel_prev,
      carousel_next,
      guest_clear_timeout: clear_timeout,
    });
    // 启动轮播
    carousel_autoplay();
    // 离开清理定时器
    // return clear_timeout
  };
  render() {
    let { isPC, ExpertList, content } = this.state;
    let md = 24;
    let _this = this;
    let column = false;
    if (this.props.column !== undefined) {
      column = this.props.column;
    }
    let isExpertColumn = this.props.ExpertList[0].isExpertColumn;
    if (this.props.md && this.props.md !== undefined) {
      md = this.props.md;
    }
    const lunboSetting = {
      dots: true,
      lazyLoad: true,
    };
    // 专栏介绍轮播
    let expertHtml = [];
    // console.log('--------00000000---------'+JSON.stringify(ExpertList))
    if (ExpertList && ExpertList.length > 0) {
      let expertPageSize = isPC ? 2 : 1;
      var expertPageNum = Math.ceil(ExpertList.length / expertPageSize);
      if (this.props.isPage === "ExpertColumn") {
        for (var i = 0; i < expertPageNum; i++) {
          if (isPC) {
            expertHtml.push(
              <div key={i}>
                <Row gutter={8} style={ window.innerWidth > 960 ? {marginRight: "0",padding: "5px"} : {padding: "5px",marginRight: '11%'} }>
                  {ExpertList.map((item, ind) => {
                    return ind < (i + 1) * expertPageSize &&
                      ind >= i * expertPageSize ? (
                        <Col
                          key={ind}
                          span={12}
                        >
                          <Popover
                            autoAdjustOverflow={false}
                            placement="rightTop"
                            visible={item.clicked ? item.clicked : false}
                            onVisibleChange={_this.handlePopover.bind(_this, item)}
                            // onVisibleChange={(visible)=>{item.clicked=visible}}
                            content={content}
                            trigger="click"
                            style={{ border: "2px solid #abbade" }}
                          >
                            <div
                              className={styles.ExperContent1}
                              key={item.id}
                              style={
                                isExpertColumn
                                  ? { marginLeft: "0", marginRight: "0" }
                                  : {}
                              }
                            >
                              <div className={styles.top}>
                                <div
                                  className={styles.ExperAvatar}
                                  onClick={_this.handlePopover.bind(
                                    _this,
                                    item,
                                    true
                                  )}
                                >
                                  <Avatar
                                    shape="square"
                                    size={114}
                                    src={
                                      !item.head_url.startsWith("http")
                                        ? base.OssAddress + item.head_url
                                        : item.head_url
                                    }
                                  />
                                </div>
                                <div className={styles.ExpertDetailItemName}>
                                  {item.real_name}{" "}
                                </div>
                              </div>

                              <div className={styles.ExpertDetails}>
                                <div className={styles.ExpertDetailItem}>
                                  {item.resume}
                                  <div className={styles.detailBtn}>
                                    <span>...</span>

                                    <span className={styles.detailBtnText}>
                                      {" "}
                                      详情>>
                                    </span>
                                    {/*  onClick={this.handleDetail.bind(this,item)} */}
                                  </div>
                                </div>
                                <div className={styles.ExpertDetailItem}>
                                  { Number(item.publish_article_count) > 0 ?
                                      <span>发布文章： {item.publish_article_count} 篇</span> : null
                                  }
                                </div>
                              </div>
                            </div>
                          </Popover>
                        </Col>
                      ) : null;
                  })}
                </Row>
              </div>
            );
          } else {
            expertHtml.push(
              <div key={i}>
                <Row gutter={8}>
                  {ExpertList.map((item, ind) => {
                    return ind < (i + 1) * expertPageSize &&
                      ind >= i * expertPageSize ? (
                        <Col
                          key={ind}
                          span={isPC ? 12 : 24}
                        >
                          <div >
                            <Modal
                              title="专家详情"
                              visible={this.state.visible}
                              onCancel={this.handleCancel}
                              footer={[
                                <Button key="submit" type="primary" onClick={_this.handleCancel}>
                                  关闭
                                </Button>
                              ]}
                            >
                              {content}
                            </Modal>

                            <div
                              onClick={() => {
                                _this.addDom(item, true)
                                _this.setState({ visible: true });
                              }}
                              className={styles.ExperContent1}
                              key={item.id}
                              style={
                                isExpertColumn
                                  ? { marginLeft: "0", marginRight: "0" }
                                  : {}
                              }
                            >
                              <div className={styles.top}>
                                <div
                                  className={styles.ExperAvatar}
                                  onClick={_this.handlePopover.bind(
                                    _this,
                                    item,
                                    true
                                  )}
                                >
                                  <Avatar
                                    shape="square"
                                    size={114}
                                    src={
                                      !item.head_url.startsWith("http")
                                        ? base.OssAddress + item.head_url
                                        : item.head_url
                                    }
                                  />
                                </div>
                                <div className={styles.ExpertDetailItemName}>
                                  {item.real_name}{" "}
                                </div>
                              </div>

                              <div className={styles.ExpertDetails}>
                                <div className={styles.ExpertDetailItem}>
                                  {item.resume}
                                  <div className={styles.detailBtn}>
                                    <span>...</span>

                                    <span className={styles.detailBtnText}>
                                      {" "}
                                      详情>>
                                    </span>
                                    {/*  onClick={this.handleDetail.bind(this,item)} */}
                                  </div>
                                </div>
                                <div className={styles.ExpertDetailItem}>
                                  { Number(item.publish_article_count) > 0 ?
                                      <span>发布文章： {item.publish_article_count} 篇</span> : null
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      ) : null;
                  })}
                </Row>
              </div>
            );
          }

        }
      } else {
        expertPageSize = 2
        expertPageNum = Math.ceil(ExpertList.length / expertPageSize);
        for (var i = 0; i < expertPageNum; i++) {
          if (isPC) {
            expertHtml.push(
              <div key={i}>
                {ExpertList.map((item, ind) => {
                  return ind < (i + 1) * expertPageSize &&
                    ind >= i * expertPageSize ? (
                      <div
                        className={styles.ExperContent}
                        key={item.id}
                        style={
                          isExpertColumn
                            ? { marginLeft: "0", marginRight: "0" }
                            : {}
                        }
                      >
                        <div className={styles.ExperAvatar}>
                          <Avatar
                            shape="square"
                            size={114}
                            src={
                              !item.head_url.startsWith("http")
                                ? base.OssAddress + item.head_url
                                : item.head_url
                            }
                          />
                        </div>
                        <div className={styles.ExpertDetails}>
                          <div className={styles.ExpertDetailItemName}>
                            {" "}
                            {/* 行业专家： */}
                            {item.real_name}{" "}
                          </div>
                          <div className={styles.ExpertDetailItem}>
                            {item.resume}
                          </div>
                          
                          <div className={styles.ExpertDetailItem} >
                          { Number(item.publish_article_count) > 0 ?
                               <span>发布文章： {item.publish_article_count} 篇</span> : null
                          }
                        </div>
                        </div>
                      </div>
                    ) : null;
                })}
              </div>
            );
          } else {
            expertHtml.push(
              <div key={i}>
                <Row gutter={10}>
                  {ExpertList.map((item, ind) => {
                    return ind < (i + 1) * expertPageSize &&
                      ind >= i * expertPageSize ? (
                        <Col
                          key={ind}
                          span={12}
                        >
                          <div
                            className={styles.ExperContentMo}
                            key={item.id}

                          >
                            <div className={styles.ExperAvatar}>
                              <Avatar
                                shape="square"
                                size={69}
                                src={
                                  !item.head_url.startsWith("http")
                                    ? base.OssAddress + item.head_url
                                    : item.head_url
                                }
                              />
                              <div className={styles.ExpertDetailItemName}>
                                {item.real_name}{" "}
                              </div>
                            </div>
                            <div className={styles.ExpertDetails}>
                              <div className={styles.ExpertDetailItem}>
                                <span className={styles.tit}></span>
                                {item.resume}
                              </div>
                              <div className={styles.ExpertDetailItem}>
                              { Number(item.publish_article_count) > 0 ?
                               <span className={styles.tit}>发布文章： {item.publish_article_count} 篇</span> : null
                              }
                               
                        </div>
                            </div>
                          </div>
                        </Col>
                      ) : null;
                  })}
                </Row>
              </div>
            );
          }

        }
      }

    }


    return (
      //  className={column ? styles.ExpertBox + ' ' + styles.column : styles.ExpertBox}
      <div className={styles.ExpertBox}>
        {this.state.ExpertList
          ? <Carousel {...lunboSetting} ref={(el) => (this.slider = el)} style={ window.innerWidth > 960 ? {marginRight: "0"} : {marginRight: '20%'} }>{expertHtml}</Carousel>
          // this.state.ExpertList.map((item, ind) => {
          //   return (
          //     <Col
          //       xs={!isPC && !isExpertColumn ? 12 : 24}
          //       sm={!isPC && !isExpertColumn ? 12 : 24}
          //       md={md}
          //       key={item.id}
          //     >
          //       {
          //         isPC ? (
          //           <div
          //             className={styles.ExperContent}
          //             key={item.id}
          //             style={
          //               isExpertColumn
          //                 ? { marginLeft: "0", marginRight: "0" }
          //                 : {}
          //             }
          //           >
          //             <div className={styles.ExperAvatar}>
          //               <Avatar
          //                 shape="square"
          //                 size={114}
          //                 src={
          //                   !item.head_url.startsWith("http")
          //                     ? base.OssAddress + item.head_url
          //                     : item.head_url
          //                 }
          //               />
          //             </div>
          //             <div className={styles.ExpertDetails}>
          //               <div className={styles.ExpertDetailItemName}>
          //                 {" "}
          //                 专家专栏：{item.real_name}{" "}
          //               </div>
          //               <div className={styles.ExpertDetailItem}>
          //                 专家简介： {item.resume}
          //               </div>
          //               <div className={styles.ExpertDetailItem}>
          //                 发布文章： {item.publish_article_count} 篇
          //           </div>
          //             </div>
          //           </div>
          //         ) : (
          //             <div
          //               className={styles.ExperContentMo}
          //               key={item.id}
          //               style={
          //                 !isPC && !isExpertColumn
          //                   ? ind == 0
          //                     ? { marginRight: "9px" }
          //                     : { marginLeft: "9px" }
          //                   : {}
          //               }
          //             >
          //               <div className={styles.ExperAvatar}>
          //                 <Avatar
          //                   shape="square"
          //                   size={69}
          //                   src={
          //                     !item.head_url.startsWith("http")
          //                       ? base.OssAddress + item.head_url
          //                       : item.head_url
          //                   }
          //                 />
          //                 <div className={styles.ExpertDetailItemName}>
          //                   {item.real_name}{" "}
          //                 </div>
          //               </div>
          //               <div className={styles.ExpertDetails}>
          //                 <div className={styles.ExpertDetailItem}>
          //                   <span className={styles.tit}>专家简介：</span>
          //                   {item.resume}
          //                 </div>
          //                 <div className={styles.ExpertDetailItem}>
          //                   <span className={styles.tit}> 发布文章：</span>
          //                   {item.publish_article_count} 篇
          //           </div>
          //               </div>
          //             </div>
          //           )
          //       }

          //     </Col>
          //   );
          // })

          : null}
      </div>
    );
  }
}
export default Expert;
