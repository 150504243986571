//home.js
import React, { Component } from "react";
import styles from "./index.module.less";
import EntryFormApi from "@/src/api/EntryFormApi";
import store from "@/src/redux/store.js";

import { Form, Input, Button, Col } from "antd";
import { message, Spin } from "antd";
import LoginHeader from "@/src/components/LoginHeader";
import PageFooter from "@/src/components/PageFooter";
import { HashRouter } from "react-router-dom";
const router = new HashRouter();

export default class EntryForm extends Component {
  state = {
    loading: false,
    data: [
      // { id: 1 },
      // { id: 2 },
      // { id: 3 },
    ],
    axiosData: {
      // application_no: '123',
      // application_title: '春季活动报名',
      // content: '姓名|年龄|性别|手机号|原因'
    },
  };

  componentDidMount() {
    this.QueryApplication();
    this.codeInto();
  }

  /**
   * 二维码进入该页面
   */
  codeInto = () => {
    const { userInfo } = store.getState();
    if (userInfo) {
      return false;
    } else {
      message.info("请先登录");
      let url = encodeURIComponent("EntryForm20201230141134350657076554371072");
      // let url = encodeURIComponent("EntryForm20200528144548272389909304578048");
      router.history.push("/login?type=" + url);
    }
  };

  // 查询报名表
  QueryApplication = () => {
    EntryFormApi.QueryApplication({ offset: 0, limit: 5 }).then((res) => {
      if (res.status === 200) {
        let axiosData = res.data.list.filter((item) => {
          return item.application_no === this.props.match.params.number;
        })[0];

        let data = [];
        axiosData.content.split("|").forEach((item2, index) => {
          if (item2.length === 0) {
            return;
          }
          let tips = "";
          if (item2.indexOf("(") >= 0 && item2.indexOf(")") >= 0) {
            let ind1 = item2.indexOf("(");
            let ind2 = item2.indexOf(")");
            tips = item2.substring(1 + ind1, ind2);
            item2 = item2.substring(0, ind1);
          }
          let item3 = {
            id: index,
            name: item2,
            tips: tips,
          };
          console.log(item3);
          data.push(item3);
        });

        this.setState({ axiosData, data });
      } else {
      }
    });
  };

  SubilmtFunc = async (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      for (let item in values) {
        if (values[item] == undefined) {
          err = item;
        }
      }
      if (!err) {
        // console.log('Received values of form: ', values);
        let params = {
          application_no: this.props.match.params.number,
          content: "",
        };
        // 循环所有键
        for (var key in values) {
          var element = values[key];
          // console.log("----eles -->  " + key + ":" + element + " ");
          params.content = `${params.content}${key}=${element}|`;
        }
        // console.log(params)

        this.setState({ loading: true });
        EntryFormApi.SubmitAccountApplication(params)
          .then((res) => {
            this.setState({ loading: false });
            if (res.status === 200) {
              message.success("提交成功！");
              router.history.push("/");
            } else {
              message.error(res.msg);
            }
          })
          .catch((res) => {
            // console.log(res)
            this.setState({ loading: false });
          });
      } else {
        message.error("请填写" + err);
      }
    });
  };

  render() {
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };

    const { data, axiosData } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <Spin spinning={this.state.loading} delay={10} tip="提交中">
          <div className="LoginBox box-margin-top">
            <LoginHeader showUser={true} title={axiosData.application_title} />

            <div className={styles.ContentBox}>
              <Col xs={23} sm={23} md={20} style={{ margin: "0 auto" }}>
                <div className={styles.RightBox}>
                  <Form className="login-form">
                    <div style={{ marginBottom: "1.5rem" }}>
                      <h2>{axiosData.application_title}</h2>
                    </div>
                    {data.map((item) => {
                      console.log(item);
                      return (
                        <Form.Item
                          className={styles.inp}
                          key={item.id}
                          {...formItemLayout}
                          label={item.name}
                          hasFeedback
                        >
                          {/* `${item.id}A` */}
                          {getFieldDecorator(`${item.name}`, {
                            rules: [
                              { required: false, message: "请输入手机号!" },
                            ],
                            myName: item.name,
                          })(<Input type="text" placeholder={item.name} />)}
                          {item.tips ? (
                            <span style={{ lineHeight: "22px" }}>
                              {item.tips}
                            </span>
                          ) : null}
                        </Form.Item>
                      );
                    })}

                    <Form.Item>
                      <div style={{ marginTop: "1.5rem" }}>
                        <Button
                          type="primary"
                          onClick={this.SubilmtFunc}
                          className="login-form-button"
                          block
                        >
                          {" "}
                          提 交{" "}
                        </Button>
                      </div>
                    </Form.Item>
                  </Form>
                </div>
              </Col>
            </div>

            <PageFooter IsAdvertisementShow={false} colorE9f1fc={false} />
          </div>
        </Spin>
      </div>
    );
  }
}

EntryForm = Form.create({})(EntryForm);
