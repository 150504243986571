import * as actionTypes from '../constants/index';
import { local } from '../../lib/tools'
const Advertisement = (state = null, action) => {
	let data = local.get(actionTypes.SET_ADVERTISEMENT)
	switch (action.type) {
		case actionTypes.SET_ADVERTISEMENT:
			return action.data || data  ;
		default:
			return state || data ;
	}
};

export default Advertisement;
