import React, { Component } from 'react';
import { Card  } from 'antd';
import styles from './index.module.less';
import { base } from '@/src/api/base'
import store from '@/src/redux/store.js';
const { Meta } = Card;
class Advertisement extends Component {

  state = {
    IsShow: false ,
    AdvertisementData: [] ,
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(this.ListenStore.bind(this))
    window.addEventListener('resize', this.resizeBind)
    this.resizeBind()
    this.ListenStore()
  }
  componentWillUnmount() {
    // 对 store 变化取消监听
    this.unsubscribe();
    window.removeEventListener('resize', this.resizeBind)
  }

  ListenStore = () => {
    let { Advertisement } =  store.getState()
    if (!Advertisement || Advertisement === {} || Advertisement.length === undefined || Advertisement.length === 0) {
      return
    }
    this.setState({
      AdvertisementData: Advertisement,
    });
  }
  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.IsShow) {
        this.setState({
          IsShow: false,
        });
      }
    }
  }

  Close = () => {
    this.setState({
      IsShow: false ,
    })
  }

  render() {
    let loading = false
    if (this.props.loading !== undefined) {
      loading = this.props.loading
    }

    let IsShow = this.state.IsShow
    if (this.props.IsShow !== undefined) {
      IsShow = this.props.IsShow
    }

    return (
      IsShow && this.state.AdvertisementData.length > 0 ?
       <div className={styles.AdvertisementBox}>
        {/* <div className={styles.AdvertisementBoxCloseIco}>
          <Icon type="close-circle" theme="filled" />
        </div> */}

        {
          this.state.AdvertisementData.map( item => {
            return(
              <a href={item.url?item.url:"javascript:;"} alt={item.name} key={item.id} >
                <Card
                loading={loading}
                hoverable
                style={{ width: 240 }}
                cover={<img src={`${base.OssAddress}${item.pic}`} alt={item.name} />}
              >
                <Meta title={''} description={item.name} />
              </Card>
              </a>
            )
          })
        }
        
        <div className={styles.CloseDiv+ ' ant-card-bordered'} onClick={this.Close.bind(this)} ><div>关闭</div></div>

      </div > : null
    )
  }
}
export default Advertisement;