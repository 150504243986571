import React, { Component } from 'react';
import { Card, Col, Divider } from 'antd';
import styles from './index.module.less';
import API from '@/src/api/Home'
import { Map, Marker } from 'react-amap';
import store from '@/src/redux/store.js';
import { tools } from '@/src/lib/tools';

/*
 ps:{
    react-amap (api地址) : https://elemefe.github.io/react-amap/
 }
*/

class Content extends Component {

  state = {
    name: '',
    title_bar_no: '',
    TopicItemCss: '',
    BrowserType:'PC',
    SwiperDataList: [],
    MiddlePic: '',

    mapZoom: 16, //地图缩放等级 （zoom）
    //https://lbs.amap.com/api/javascript-api/guide/abc/prepare这里有介绍key怎么申请
    mapKey: 'adacd3e265f092f9cac4aa144bd2d33b',//Key就不贴出来了奥
    status: {
      zoomEnable: true,
      dragEnable: true,
    },
    mapCenter: [116.5692973100 , 40.0683180100],//地图中心点
    mapMake: [116.5692973100 , 40.0683180100],//marker标记点

    data: [] ,

  };

  constructor(props) {
    super(props)
    this.state.title_bar_no = props.title_bar_no
    this.state.name = props.name
    this.state.path = props.path
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeBind)
    this.resizeBind()
    this.unsubscribe = store.subscribe(this.ListenStore.bind(this))
    this.ListenStore()
  }

  // resizeBind = () => {
  //   if (window.innerWidth < 768) {
  //     if (this.state.TopicItemCss !== '') {
  //       this.setState({
  //         TopicItemCss: '',
  //       });
  //     }
  //   } else {
  //     if (!this.state.TopicItemCss !== 'TopicItem') {
  //       this.setState({
  //         TopicItemCss: 'TopicItem',
  //       });
  //     }
  //   }

  // }

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== "Mobile") {
        this.setState({
          BrowserType: "Mobile",
        });
      }
    } else {
      if (!this.state.BrowserType !== "PC") {
        this.setState({
          BrowserType: "PC",
        });
      }
    }
  };
  

  ListenStore = () => {
    let { TdTitleBar } = store.getState()

    if (!TdTitleBar || TdTitleBar === {} || TdTitleBar.length === undefined || TdTitleBar.length === 0) {
      return
    }
    let oneTitleList = TdTitleBar.filter(item => {
      return item.level === 2 && item.show_status === 2
    })

    let TwoTitleList = oneTitleList.filter(item => {
      return item.parent_id === this.state.title_bar_no
    })

    // 添加一个置顶的 
    TwoTitleList = [{
      id: 0,
      title_bar_no: this.state.title_bar_no,
      parent_id: '',
      name: "",
      level: 2,
      sort: 1,
      nav_status: 2,
      show_status: 2,
      ShowHeaderTitle: false,
      data: [

      ]
    }, ...TwoTitleList]

    this.setState({
      TwoTitleList: TwoTitleList,
    })

    // 查询文章
    this.QueryArticle(TwoTitleList)
  }

  QueryArticle = (_TwoTitleList) => {
    // let _TwoTitleList = this.state.TwoTitleList

    if (!_TwoTitleList || _TwoTitleList.length === 0) {
      return
    }
    _TwoTitleList.map((item, index) => {

      let params = { title_bar_no: item.title_bar_no, offset: 0, limit: 5 }
      API.QueryArticle(params).then(res => {

        if (res.status === 200) {
          item.data = res.data.list.map((item2, index) => {
            item2.id = item.title_bar_no + item2.id + '-' + tools.random(10000, 99999)
            if (index === 0 && item.title_bar_no === this.state.title_bar_no) {
              item2.BoldTitle = true
              item2.showPic = false
              
            }
            item2.title_bar_no = item.title_bar_no

            return item2
          });

          

          if (item.title_bar_no === this.state.title_bar_no) {
            this.setState({data:res.data.list})
          }

          if (item.title_bar_no === '1022') {
            item.data = tools.sliceArr(item.data, 3)
          }

          item.loading = false;
        } else {
          item.loading = false;
        }

        _TwoTitleList[index] = item
        this.setState({
          TwoTitleList: _TwoTitleList,
        })
      })
      
      return item
    })

  }

  componentWillUnmount() {
    // 对 store 变化取消监听
    this.unsubscribe();
    window.removeEventListener('resize', this.resizeBind)
  }

  render() {
    let { mapCenter, mapMake, mapZoom, mapKey, status , data,BrowserType } = this.state;
    console.log(data);
    
    return (
      <div className={BrowserType == 'PC' ? styles.HomeContent : styles.HomeContentMO} >
        <div className={styles.content1}>
          <div className={styles.title} >
            联系我们
          </div>

          <Card >
            <div>
              <Col xs={24} sm={24} md={24} >
                <div className={styles.RightBox} >
                  {/* <span>民航安保网</span> */}
                  {/* <span>公司地址：xxxxxxxx</span>
                  <span>邮政编码：xxxxxx</span> */}
                  {
                    data.map( item => {
                      console.log(item.title.match(/([^:：]+)[:：]/));
                      
                      return(
                        item.title.indexOf("：")?
                       (<div className={styles.item} key={item.id}>
                          <div className={styles.tit}>{item.title.match(/([^:：]+)[:：]/)[1]} <span></span></div>
                          <span className={styles.span1} style={{fontWeight:"bold",fontSize:"1.2rem"}}>：</span>
                          <span className={styles.con}>{item.title.match(/[:：]([^:：]+)/)[1]}</span>
                      </div>) :<span  className={styles.con}>{item.title}</span>
                      )
                    })
                  }
                </div>
                {/* <div className={styles.RightBox} >
                  <span>客服电话：xxxxxxx</span>
                  <span>客服邮箱：xxxxx@xxx.xx</span>
                </div>
                <div className={styles.RightBox} >
                  <span>投资电话：xxxxxxx</span>
                  <span>投资邮箱：xxxxx@xxx.xx</span>
                </div> */}
              </Col>
              <Col xs={24} sm={24} md={24} >
                <div style={{ width: '100%', height: 400 }} >
                  {/*地图创建必有参数 （key，中心点，zoom等级）*/}
                  <Map amapkey={mapKey} center={mapCenter} zoom={mapZoom} status={status}>
                    {/*marker标记点创建必有参数 （position中心点）*/}
                    <Marker position={mapMake} />
                  </Map>
                </div>

              </Col>
            </div>

          </Card>

        </div>
      </div>
    )
  }
}

export default Content;