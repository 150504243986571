import Server from './server'

/**
 * 个人中心相关接口
 */
class PersonalCenterApi extends Server {

    //查询我的文章
    queryMineArticle(data) {
        try {
            let result = this.axios('post', '/RequestAddress/v2/api/mine_article', data);
            if (result.status !== 0 && (result instanceof Object)) {
                return result || {}
            } else {

                return result
            }
        } catch (err) {
            throw err
        }
    }

    //查询我的点赞
    queryMineLike(data) {
        try {
            let result = this.axios('post', '/RequestAddress/v2/api/mine_like', data);
            if (result.status !== 0 && (result instanceof Object)) {
                return result || {}
            } else {

                return result
            }
        } catch (err) {
            throw err
        }
    }

    //查询我的收藏
    queryMineCollection(data) {
        try {
            let result = this.axios('post', '/RequestAddress/v2/api/mine_collection', data);
            if (result.status !== 0 && (result instanceof Object)) {
                return result || {}
            } else {

                return result
            }
        } catch (err) {
            throw err
        }
    }

    //查询我的评论
    queryMineComment(data) {
        try {
            let result = this.axios('post', '/RequestAddress/v2/api/mine_comment', data);
            if (result.status !== 0 && (result instanceof Object)) {
                return result || {}
            } else {

                return result
            }
        } catch (err) {
            throw err
        }
    }

    //删除我的评论
    deleteMineComment(data) {
        try {
            let result = this.axios('post', '/RequestAddress/v2/api/delete_mine_comment', data);
            if (result.status !== 0 && (result instanceof Object)) {
                return result || {}
            } else {

                return result
            }
        } catch (err) {
            throw err
        }
    }

    //我是否付费vip会员
    MineBuyVip(data) {
        try {
            let result = this.axios('post', '/RequestAddress/v2/api/mine_buy_vip', data);
            if (result.status !== 0 && (result instanceof Object)) {
                return result || {}
            } else {

                return result
            }
        } catch (err) {
            throw err
        }
    }

    //充值成为vip
    VipPay(data) {
        try {
            let result = this.axios('post', '/RequestAddress/v2/api/vip_pay', data);
            if (result.status !== 0 && (result instanceof Object)) {
                return result || {}
            } else {

                return result
            }
        } catch (err) {
            throw err
        }
    }

    //查询会员权限图片
    QueryVipList(data) {
        try {
            let result = this.axios('post', '/RequestAddress/v1/api/query_vip_list', data);
            if (result.status !== 0 && (result instanceof Object)) {
                return result || {}
            } else {

                return result
            }
        } catch (err) {
            throw err
        }
    }

    //  修改用户信息
    UpdatesAccount(data) {
        try {
            let result = this.axios('post', '/RequestAddress/v2/api/updates_account', data);
            if (result.status !== 0 && (result instanceof Object)) {
                return result || {}
            } else {

                return result
            }
        } catch (err) {
            throw err
        }
    }
    
    // 查询个人中心会员权限图片
    QueryVipActivities(data) {
        try {
            let result = this.axios('post', '/RequestAddress/v1/api/query_vip_activities', data);
            if (result.status !== 0 && (result instanceof Object)) {
                return result || {}
            } else {

                return result
            }
        } catch (err) {
            throw err
        }
    }
    
    // 我买过的课程
    MineAlreadyBuyVideos(data) {
        try {
            let result = this.axios('post', '/RequestAddress/v2/api/mine_already_buy_videos', data);
            if (result.status !== 0 && (result instanceof Object)) {
                return result || {}
            } else {

                return result
            }
        } catch (err) {
            throw err
        }
    }

    // 查询消息
    QueryMessage(data) {
        try {
            let result = this.axios('post', '/RequestAddress/v2/api/query_message', data);
            if (result.status !== 0 && (result instanceof Object)) {
                return result || {}
            } else {

                return result
            }
        } catch (err) {
            throw err
        }
    }

     // 查询已读消息
    QueryMessage1(data) {
        try {
            let result = this.axios('post', '/RequestAddress/v2/api/query_alreadyMessage', data);
            if (result.status !== 0 && (result instanceof Object)) {
                return result || {}
            } else {
                return result
            }
        } catch (err) {
            throw err
        }
    }
    //点击已读
    ClickRead(data) {
        try {
            let result = this.axios('post', '/RequestAddress/v2/api/add_alreadyMessage', data);
            if (result.status !== 0 && (result instanceof Object)) {
                return result || {}
            } else {
                return result
            }
        } catch (err) {
            throw err
        }
    }




    //查询注册会员类型
    QueryMemberType(data) {
        try {
            let result = this.axios('post', '/RequestAddress/v1/api/query_member_type', data);
            if (result.status !== 0 && (result instanceof Object)) {
                return result || {}
            } else {

                return result
            }
        } catch (err) {
            throw err
        }
    }
    
    //查询注册会员类型
    MarkReadAll(data) {
        try {
            let result = this.axios('post', '/RequestAddress/v2/api/add_alreadyMessages', data);
            if (result.status !== 0 && (result instanceof Object)) {
                return result || {}
            } else {
                return result
            }
        } catch (err) {
            throw err
        }
    }
}

export default new PersonalCenterApi()