import React, { Component } from 'react';
import styles from './index.module.less';
import PageHeader from '@/src/components/PageHeader';
import PageFooter from '@/src/components/PageFooter';
import Content from './Content.jsx';

export default class MoreArticles extends Component {

  state = {
    title_bar_no: '',
  }

  componentDidMount() {

  }

  render() {

    return (
      <div className={styles.HomeBox + ' Layout'}>

        <PageHeader showNav={false} showBreadcrumbList={true} />

        <Content data={this.props} />

        <PageFooter colorE9f1fc={true} />

      </div>
    )
  }

}
