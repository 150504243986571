import Server from './server'

/**
 * 报名
 */
class EntryFormApi extends Server {

    //查询报名表
    QueryApplication(data) {
        try {
            let result = this.axios('post', '/RequestAddress/v1/api/query_application', data);
            if (result.status !== 0 && (result instanceof Object)) {
                return result || {}
            } else {

                return result
            }
        } catch (err) {
            throw err
        }
    }

    //查询报名表
    SubmitAccountApplication(data) {
        try {
            let result = this.axios('post', '/RequestAddress/v2/api/submit_account_application', data);
            if (result.status !== 0 && (result instanceof Object)) {
                return result || {}
            } else {

                return result
            }
        } catch (err) {
            throw err
        }
    }


}

export default new EntryFormApi()