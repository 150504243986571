import * as actionTypes from '../constants/index';
import { local } from '../../lib/tools'
const AdAroundR = (state = null, action) => {
	let data = local.get(actionTypes.SET_ADAROUNDR)
	switch (action.type) {
		case actionTypes.SET_ADAROUNDR:
			return action.data || data  ;
		default:
			return state || data ;
	}
};
const AdAroundL = (state = null, action) => {
	let data = local.get(actionTypes.SET_ADAROUNDL)
	switch (action.type) {
		case actionTypes.SET_ADAROUNDL:
			return action.data || data  ;
		default:
			return state || data ;
	}
};
export {AdAroundR,AdAroundL};