import * as actionTypes from '../constants/index';
import { local } from '../../lib/tools'
const setBreadcrumbList = (data) => {

    // sessionStorage.setItem(actionTypes.SET_BREADCRUMBLIST,JSON.stringify(data))
    local.set(actionTypes.SET_BREADCRUMBLIST,data,1)

    return {
        type: actionTypes.SET_BREADCRUMBLIST,
        data
    }
};
export {setBreadcrumbList};
