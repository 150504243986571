import React, { Component } from 'react';
import { Card, Icon, Carousel, Col, Input } from 'antd';
import styles from './index.module.less';
import store from '@/src/redux/store.js';
import { setArticleDetails } from '@/src/redux/actions/ArticleDetails';
import { setBreadcrumbList } from '@/src/redux/actions/BreadcrumbList';

import { HashRouter } from 'react-router-dom'
import { base } from '../../api/base';
const router = new HashRouter()
const { Search } = Input;

class OnlineClassroomItem extends Component {

  state = {
    BrowserType: 'PC', // PC Mobile 浏览器类型 手机或者pc端
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeBind)
    this.resizeBind()
  }

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== 'Mobile') {
        this.setState({
          BrowserType: 'Mobile',
        });
      }
    } else {
      if (!this.state.BrowserType !== 'PC') {
        this.setState({
          BrowserType: 'PC',
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeBind)
  }

  // 详情
  ImgCardContentClick = (item) => {
    let { BreadcrumbList } = store.getState()
    if (BreadcrumbList && BreadcrumbList.length > 1) {
      BreadcrumbList.push({
        id: BreadcrumbList.length,
        name: item.folder_name,
        path: '/OnlineCourseDetails:' + item.folder_id
      })

    }
    store.dispatch(setBreadcrumbList(BreadcrumbList))
    router.history.push('/OnlineCourseDetails' + item.folder_id)
  }

  // 更多
  MoreOnlineCourse = (title_bar_no, title) => {
    let { BreadcrumbList } = store.getState()

    if (BreadcrumbList && BreadcrumbList.length >= 2) {
      BreadcrumbList[2] = {
        id: '2',
        name: title,
        path: '/MoreOnlineCourse:' + title_bar_no
      }

    }
    store.dispatch(setBreadcrumbList(BreadcrumbList))

    router.history.push('/MoreOnlineCourse:' + title_bar_no)

  }

  render() {

    let ShowHeaderTitle = true
    if (this.props.ShowHeaderTitle !== undefined) {
      ShowHeaderTitle = this.props.ShowHeaderTitle
    }

    let ShowHeaderMore = true
    if (this.props.ShowHeaderMore !== undefined) {
      ShowHeaderMore = this.props.ShowHeaderMore
    }

    let loading = true
    if (this.props.loading !== undefined) {
      loading = this.props.loading
    }

    let showSearch = false
    if (this.props.showSearch !== undefined) {
      showSearch = this.props.showSearch
    }

    const { BrowserType } = this.state

    return (
      <div className={`${styles.ImgCard} ${!ShowHeaderTitle > 0 ? styles.NoHeaderTitle : ''} ${BrowserType === 'PC' ? styles.PCImgCard : styles.MobileCard}`}>

        <Card title={ShowHeaderTitle ? <div className={styles.title} > <div className={styles.titleText}>{this.props.title}</div> <div className={styles.titleIco}></div> </div> : ''} extra={ShowHeaderTitle && ShowHeaderMore ? <div className={styles.ImgCardMore} onClick={() => { this.MoreOnlineCourse(this.props.title_bar_no, this.props.title) }} > {showSearch ?
          <div>
            <Search
              allowClear={true}
              placeholder="请输入关键字"
              enterButton="搜索"
              onSearch={value => {
                if (value === '') {
                  return
                }

              }}
            />
          </div> : <div> 更多  <Icon type="right-circle" theme="filled" /> </div>}  </div> : ''} bordered={false} loading={loading} bodyStyle={{ paddingLeft: '0.2rem', paddingRight: '0.2rem' }} >
          <div className={styles.IcoTa1 + ' ' + styles.IcoTa} onClick={() => this.Carousel.prev()} >
            <Icon type="left" />
          </div>
          <div className={styles.IcoTa2 + ' ' + styles.IcoTa} onClick={() => this.Carousel.next()} >
            <Icon type="right" />
          </div>

          <Carousel className={styles.ImgCardList} {...this.props} ref={dom => { this.Carousel = dom; }} >
            <div className={styles.ImgCardContent}  >

              {
                this.props.data && this.props.data.length > 0 ?

                  this.props.data.map((item, index) => {
                    return (

                      <Col key={item.id} className={this.state.TopicItemCss} xs={12} sm={12} md={8} >

                        <div className={`${styles.ImgCardTa1} ${index < item.length - 1 ? styles.paddingRight1 : ''}`} key={item.id} onClick={this.ImgCardContentClick.bind(this, item)} >
                          <div className={styles.ImgCardPic}>
                            <img src={`${base.OssAddress}${item.cover_pictures}`} alt={item.folder_name} />
                          </div>
                          <div className={styles.ImgCardTitle} >
                            <div className={styles.ta1}>{item.folder_name}</div>
                            <div className={styles.ta2}>共{item.courses_number}节 | 主持人： {item.author}</div>
                            <div className={styles.ta3}>
                              <pre>
                                <span className={styles.ColorRed}> {item.price > 0 ? '¥ ' + item.price : '免费'} </span>  {item.courses_people_number}人观看
                                      </pre>
                            </div>
                          </div>
                        </div>

                      </Col>

                    )
                  }) : null
              }
            </div>
          </Carousel>


        </Card>

      </div>
    )
  }
}
export default OnlineClassroomItem;