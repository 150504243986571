import React, { Component } from 'react';
import styles from './index.module.less';
import { Col, Spin , Icon } from 'antd';
import VideosApi from '@/src/api/VideosApi';
import { base } from '@/src/api/base';
import { tools } from '@/src/lib/tools';
import store from '@/src/redux/store.js';
import PageContent from '@/src/components/PageContent';
import Swiper from '@/src/components/Carousel/swiper';
import OnlineClassroomItem from '@/src/components/OnlineClassroomItem';
import Item from 'antd/lib/list/Item';
import InfiniteScroll from 'react-infinite-scroller';

export default class OnlineClassroom extends Component {

  state = {
    hasMore: true,
    limit: 6,           // 分页每页显示条数
    offset: 0,
    BrowserType: 'PC', // PC Mobile 浏览器类型 手机或者pc端
    SwiperDataList: [
      {
        id: 1,
        name: "新闻标题会丢失法估算的新闻标题会丢失法估算的新闻标题会丢失法估算的新闻标题会丢失法估算的",
        pic: 'http://www.sinoavsec.cn/templets/1/anbao//images/001.jpg',
        url: 'https://baidu.com',
      },
      {
        id: 2,
        name: "2新闻标题会丢失法估算的新闻标题会丢失法估算的新闻标题会丢失法估算的新闻标题会丢失法估算的",
        pic: 'http://www.sinoavsec.cn/templets/1/anbao//images/001.jpg',
        url: 'https://www.hao123.com/',
      }
    ],
    data: [
      {
        loading: false,
        title: "推荐主题" ,
        id: 1 ,
        data: [
          // {
          //   title: '21324hiusdfhiguhsii 好的师傅 iu 好贵哦当然会给 i 哦还记得日哦链接狗ID认同is 活动日哦 hi 哦而生',
          //   pic: 'http://cms-bucket.ws.126.net/2020/0214/86061257j00q5obwr000sc000550038c.jpg?imageView&thumbnail=185y116&quality=85&type=webp',
          // },
          // {
          //   title: '21324hiusdfhiguhsii 好的师傅 iu 好贵哦当然会给 i 哦还记得日哦链接狗ID认同is 活动日哦 hi 哦而生',
          //   pic: 'http://cms-bucket.ws.126.net/2020/0214/86061257j00q5obwr000sc000550038c.jpg?imageView&thumbnail=185y116&quality=85&type=webp',
          // },
          // {
          //   title: '21324hiusdfhiguhsii 好的师傅 iu 好贵哦当然会给 i 哦还记得日哦链接狗ID认同is 活动日哦 hi 哦而生',
          //   pic: 'http://cms-bucket.ws.126.net/2020/0214/86061257j00q5obwr000sc000550038c.jpg?imageView&thumbnail=185y116&quality=85&type=webp',
          // }
        ]
      }
    ]
  }

  async componentDidMount() {
    this.InitData()
    window.addEventListener('resize', this.resizeBind)
    this.resizeBind()

  }



  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeBind)
  }

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== 'Mobile') {
        this.setState({
          BrowserType: 'Mobile',
        });
      }
    } else {
      if (!this.state.BrowserType !== 'PC') {
        this.setState({
          BrowserType: 'PC',
        });
      }
    }
  }

  InitData = () => {
    if (this.state.offset < 0 || this.state.loading) {
      return
    }
    const { offset, limit } = this.state;
    const params = {
      limit,
      offset,
    }
    this.setState({ loading: true });
    VideosApi.QueryVideosList(params).then( res => {
      console.log("查询列表",res)
      if (res.status === 200) {
        let {data} = this.state
      //  res.data.list.map(async ( item , index ) => {
      //   item.loading = false
      //   // 查询具体视频
      //   this.QueryVideoList(item.folder_id, data1 => {
      //     item.data = data1
      //     data[index] = item
      //     this.setState({
      //       data,
      //     })
         
      //   })
      //   return item
      //  })
      data[0].data = [...data[0].data,...res.data.list]
      console.log(data)
      this.setState({
              data,
              offset: res.data.offset ,
              loading: false ,
            })
      } else {
        this.setState({ offset: -1, loading: false, hasMore: false });
        // notification.error({
        //   message: '查询失败！',
        //   description: res.msg,
        // });
      }
    })


  }

  QueryVideoList = (folder_id , callback) => {
    let params1 = {
      offset: 0,
      limit: 6 ,
      folder_id:  folder_id,
    }
    console.log(params1)
    VideosApi.QueryVideoList(params1).then(res1 => {
      console.log("文章详情",res1)
      callback(res1)  
    })
  }


  render() {
    const {
      data,
      loading,
      offset,
    } = this.state;

    return (
      <div className={styles.OnlineClassroomBox + ' Layout'}>

        <PageContent className={styles.OnlineClassroomBoxPageContent} loading={loading} >

          <Col className={this.state.TopicItemCss} xs={24} sm={24} md={24} >
            <Swiper autoplay SwiperDataList={this.state.SwiperDataList} />
          </Col>

          <div className={styles.Content}>
          <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={this.InitData}
                hasMore={(!loading) && this.state.hasMore}
                useWindow={true}
              >
            {
              data && data.length > 0 ? 
              data.map( item => {
                return(
                  <OnlineClassroomItem key={item.id} title={item.title} dots={false} loading={item.loading} data={item.data} title_bar_no={'1001'} name={this.state.name} ShowHeaderMore={false} />
                )
              }) : null
            }
            {(loading ) && this.state.hasMore ? (
                    <div className={styles.loading} >
                      <Spin />
                    </div>
                  ):data[0].data && data[0].data.length === 0 && !loading ?
                  <div style={{ textAlign: 'center', padding: '0.8rem 0' , background: '#fff' }}>
                    <span> <Icon style={{ marginRight: '0.3rem' }} type="exclamation-circle" /> 暂无数据</span>
                  </div> :
                  offset === -1 ?
                    <div style={{ textAlign: 'center', padding: '0.8rem 0' , background: '#fff' }}>
                      <span> <Icon style={{ marginRight: '0.3rem' }} type="exclamation-circle" /> 没有更多了 </span>
                    </div>
                    : null
                  }
             </InfiniteScroll>
          </div>

        </PageContent>

      </div>
    )
  }

}
