//home.js
import React, { Component } from 'react';
import styles from './home.module.less';
// import API from '../../api/api';
import PageHeader from '@/src/components/PageHeader';
import HomeContent from '@/src/pages/home/content.jsx';
import PageFooter from '@/src/components/PageFooter';

export default class Home extends Component {

  render() {

    return (
      <div className={styles.HomeBox+' Layout'}>

        <PageHeader current='/home' showNav={true} />

        <HomeContent />

        <PageFooter colorE9f1fc={false} />

      </div>
    )
  }

}
