//home.js
import React, { Component } from 'react';
import styles from './index.module.less';
import { Icon, Card, Row, List , Spin } from 'antd';
import API from '@/src/api/Home';
import { tools } from '@/src/lib/tools';
import store from '@/src/redux/store.js';
import { setArticleDetails } from '@/src/redux/actions/ArticleDetails';
import { setBreadcrumbList } from '@/src/redux/actions/BreadcrumbList';
import OnlineClassroomItem from '@/src/components/OnlineClassroomItem';
import InfiniteScroll from 'react-infinite-scroller';

import { HashRouter } from 'react-router-dom'
const router = new HashRouter()

export default class MoreOnlineCourse extends Component {

  state = {
    limit: 6,           // 分页每页显示条数
    offset: -1,
    loading: false,
    hasMore: true,
    BrowserType: 'PC', // PC Mobile 浏览器类型 手机或者pc端
    dataSource: [{
      data: [
        {
          id: 1 ,
          title: '21324hiusdfhiguhsii 好的师傅 iu 好贵哦当然会给 i 哦还记得日哦链接狗ID认同is 活动日哦 hi 哦而生',
          pic: 'http://cms-bucket.ws.126.net/2020/0214/86061257j00q5obwr000sc000550038c.jpg?imageView&thumbnail=185y116&quality=85&type=webp' ,
        },
        {
          id: 2 ,
          title: '21324hiusdfhiguhsii 好的师傅 iu 好贵哦当然会给 i 哦还记得日哦链接狗ID认同is 活动日哦 hi 哦而生',
          pic: 'http://cms-bucket.ws.126.net/2020/0214/86061257j00q5obwr000sc000550038c.jpg?imageView&thumbnail=185y116&quality=85&type=webp' ,
        },
        {
          id: 3 ,
          title: '21324hiusdfhiguhsii 好的师傅 iu 好贵哦当然会给 i 哦还记得日哦链接狗ID认同is 活动日哦 hi 哦而生',
          pic: 'http://cms-bucket.ws.126.net/2020/0214/86061257j00q5obwr000sc000550038c.jpg?imageView&thumbnail=185y116&quality=85&type=webp' ,
        }
      ]
    }],
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeBind)
    this.resizeBind()
  }

  componentWillUnmount() {

    window.removeEventListener('resize', this.resizeBind)
  }

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== 'Mobile') {
        this.setState({
          BrowserType: 'Mobile',
        });
      }
    } else {
      if (!this.state.BrowserType !== 'PC') {
        this.setState({
          BrowserType: 'PC',
        });
      }
    }
  }

  GetData = async () => {
    if (this.state.offset < 0 || this.state.loading) {
      return
    }
    const { offset, limit } = this.state;
    let _params = {
      limit,
      offset,
      title_bar_no: this.props.title_bar_no,
    }
    return
    await this.setState({ loading: true });
    API.QueryArticle(_params).then(res => {
      if (res.status === 200) {
        let dataSource1 = res.data.list.map((item2, index) => {
          item2.id = item2.id + '-' + tools.random(10000, 99999)
          if (index === 0 && item2.title_bar_no === '1001') {
            item2.BoldTitle = true
          }
          item2.title_bar_no = _params.title_bar_no
          return item2
        });

        // let dataSource = this.state.dataSource

        // dataSource = dataSource.concat(dataSource1)

        // dataSource = [...dataSource , this.state.dataSource]
        let { HistoryData } = this.state
        // HistoryData.push(...dataSource1)
        this.setState({ HistoryData: [...HistoryData, ...dataSource1], offset: res.data.offset, loading: false, hasMore: true });
      } else {
        this.setState({ offset: -1, loading: false, hasMore: false });
        // notification.error({
        //   message: '查询失败！',
        //   description: res.msg,
        // });
      }

    }, () => {
      this.setState({ loading: false, hasMore: false });

    })
  }

  // 详情
  ToArticleDetails = (item) => {
    store.dispatch(setArticleDetails(item))
    let { BreadcrumbList } = store.getState()
    BreadcrumbList.push(
      {
        id: BreadcrumbList.length + 1,
        name: "新闻详情",
        path: '/ArticleDetails' + item.title_bar_no ? item.title_bar_no :'' + '/' + item.article_no
      }
    )
    store.dispatch(setBreadcrumbList(BreadcrumbList))
    router.history.push('/ArticleDetails'+ item.title_bar_no ? item.title_bar_no : ''  + '/' + item.article_no)
  }

  render() {
    const {
      loading,
      offset,
      BrowserType,
      showRedPoint,
      dataSource,
      new_data_loading,
    } = this.state;

    return (
      <div className={styles.MoreArticleContentBox + ' Layout'}>

        <div className={`${styles.SpecialItem}`}>

        <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={this.GetData}
                hasMore={(!loading || new_data_loading) && this.state.hasMore}
                useWindow={true}
              >

                <List
                  dataSource={dataSource}
                  renderItem={item => (
                    
                    <OnlineClassroomItem title={'推荐'} dots={false} loading={loading} data={item.data} title_bar_no={'1001'} name={this.state.name} ShowHeaderMore={true} showSearch={true} />

                  )}
                >
                 
                  {(loading || new_data_loading) && this.state.hasMore ? (
                    <div className={styles.loading} >
                      <Spin />
                    </div>
                  ):dataSource && dataSource.length === 0 && !loading ?
                  <div style={{ textAlign: 'center', padding: '0.8rem 0' }}>
                    <span> <Icon style={{ marginRight: '0.3rem' }} type="exclamation-circle" /> 暂无数据</span>
                  </div> :
                  offset === -1 ?
                    <div style={{ textAlign: 'center', padding: '0.8rem 0' }}>
                      <span> <Icon style={{ marginRight: '0.3rem' }} type="exclamation-circle" /> 没有更多了 </span>
                    </div>
                    : null
                  }
                  
                </List>

              </InfiniteScroll>

        </div >
 

      </div>
    )
  }

}
