import * as actionTypes from '../constants/index';
import { local } from '../../lib/tools'
const BreadcrumbList = (state = null, action) => {
	let data = local.get(actionTypes.SET_BREADCRUMBLIST)
	switch (action.type) {
		case actionTypes.SET_BREADCRUMBLIST:
			return action.data || data ;
		default:
			return state || data ;
	}
};

export default BreadcrumbList;
