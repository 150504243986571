import React, { Component } from "react";
import { Carousel, Icon } from "antd";
import styles from "./index.module.less";
import { base } from "@/src/api/base";
import store from "@/src/redux/store.js";
import { setBreadcrumbList } from "@/src/redux/actions/BreadcrumbList";

// 轮播图组件 需要传递图片数组数据
// 例：
// 引入本插件
// import Swiper from '@/src/components/Carousel/swiper';
// 定义数据
// const SwiperDataList = [
//   {
//     id: 1 ,
//     name: "新闻标题会丢失法估算的新闻标题会丢失法估算的新闻标题会丢失法估算的新闻标题会丢失法估算的" ,
//     pic: 'https://www.xn--ruq67zzlnjfscid.cn/img/civilAviation/9717e8a084f04e2392da9a60103c592f.png' ,
//     url: 'https://baidu.com',
//   },
//   {
//     id: 2 ,
//     name: "2新闻标题会丢失法估算的新闻标题会丢失法估算的新闻标题会丢失法估算的新闻标题会丢失法估算的" ,
//     pic: 'https://www.xn--ruq67zzlnjfscid.cn/img/civilAviation/e6a1c7630aff4f7697572e977b952aa3.jpeg' ,
//     url: 'https://www.hao123.com/',
//   }
// ]
// 使用本插件
/* <Swiper autoplay SwiperDataList={SwiperDataList} / > */
// 完成

class Swiper extends Component {
  state = {
    BrowserType: "PC", // PC Mobile 浏览器类型 手机或者pc端
  };

  componentDidMount() {
    window.addEventListener("resize", this.resizeBind);
    this.resizeBind();
  }

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== "Mobile") {
        this.setState({
          BrowserType: "Mobile",
        });
      }
    } else {
      if (!this.state.BrowserType !== "PC") {
        this.setState({
          BrowserType: "PC",
        });
      }
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeBind);
  }
  // 轮播点击事件 跳转页面
  ToSwoperLink = (item) => {
    console.log(item);
    if (item.title_bar_no === "1007") {
      return;
    }
    // window.location.href = item.url?item.url:"javascript:;"
    if (item.url) {
      if (item.title_bar_no === "1004") {
        store.dispatch(
          setBreadcrumbList([
            {
              id: "2",
              name: "详情",
              path: `/ArticleDetails${item.title_bar_no}/${item.article_no}`,
            },
          ])
        );
      }
      window.open(item.url);
    }else {
      store.dispatch(
        setBreadcrumbList([
          {
            id: "2",
            name: "详情",
            path: `/ArticleDetails${item.title_bar_no}/${item.article_no}`,
          },
        ])
      );
      window.open(`${base.localhost}/#/ArticleDetails${item.title_bar_no}/${item.article_no}`, "_blank");
    }
   
  };

  render() {
    var isTextShow = true;
    let { BrowserType } = this.state;
    if (this.props.isTextShow != undefined) {
      isTextShow = this.props.isTextShow;
    }
    var isMask = true;
    if (this.props.isMask !== undefined) {
      isMask = this.props.isMask;
    }
    var isShowBtn = true;
    if (this.props.isShowBtn !== undefined) {
      isShowBtn = this.props.isShowBtn;
    }
    var textPosition = false;
    if (this.props.textPosition !== undefined) {
      textPosition = this.props.textPosition;
    }
    var imgSize = false;
    if (this.props.textPosition !== undefined) {
      imgSize = this.props.imgSize;
    }
    var description = false;
    if (this.props.description !== undefined) {
      description = this.props.textPosition;
    }


    // var titFontsize="1rem"
    // if(this.props.titFontsize!==undefined){
    //   titFontsize=this.props.titFontsize
    // }
    // console.log('prpos',this.props.SwiperDataList)
    return (
      <div className={styles.SwiperBox}>
        {this.props.SwiperDataList && this.props.SwiperDataList.length > 1 ? (

          <div className={isShowBtn ? '' : styles.noClickBtn}>
            <Icon
              type="left-circle"
              theme="filled"
              className={styles.leftClick}
              style={{
                fontSize: '22px',
              }}
              onClick={() => {
                this.Carousel.prev();
              }}
            />
            <Icon
              type="right-circle"
              theme="filled"
              className={styles.rightClick}
              style={{
                fontSize: '22px',
              }}
              onClick={() => this.Carousel.next()}
            />
            <div
              className={styles.IcoTa1 + " " + styles.IcoTa}
              onClick={() => this.Carousel.prev()}
            >
              <Icon type="left-circle" theme="filled" />
            </div>
            <div
              className={styles.IcoTa2 + " " + styles.IcoTa}
              onClick={() => this.Carousel.next()}
            >
              <Icon type="right-circle" theme="filled" />
            </div>
          </div>
        ) : null}

        <Carousel

          className={styles.SwiperDiv + " " + this.props.className}
          {...this.props}
          ref={(dom) => {
            this.Carousel = dom;
          }}
        >
          {this.props.SwiperDataList
            ? this.props.SwiperDataList.map((item) => {
              return (
                <div
                  className={styles.SwiperContent}
                  key={item.id}
                  onClick={this.ToSwoperLink.bind(this, item)}
                >
                  <div className={styles.SwiperImg}>
                    <img
                      className={imgSize ? styles.imgSize : ''}
                      src={
                        item.pic.startsWith("http")
                          ? item.pic
                          : `${base.OssAddress}${item.pic}`
                      }
                      alt={item.name}
                    />
                  </div>
                  {isMask === true ? (
                    <div
                      className={styles.SwiperTitle}
                      style={BrowserType == "PC" ? {} : { opacity: 1 }}
                    ></div>
                  ) : null}
                  {isTextShow ? (
                    description ? (
                      <div className={BrowserType == "PC" ? (textPosition ? styles.desPosition : styles.desText) : styles.desPositionMo}>
                        <div className={styles.title}>{item.title || item.name}</div>
                        <div className={styles.des}>{item.introduction}</div>
                      </div>
                    ) : (
                      <div className={BrowserType == "PC" ? (textPosition ? styles.textPosition : styles.Text) : styles.textPositionMo}>
                        <div>{item.title || item.name}</div>
                      </div>
                    )

                  ) : null}
                </div>
              );
            })
            : null}
        </Carousel>
      </div>
    );
  }
}
export default Swiper;
