
import React, { Component } from 'react';
import './App.css';
import routes from './router/index'
import {  HashRouter as Router } from "react-router-dom";
import { setTdTitleBar , setAllTdTitleBar, setSetting } from '@/src/redux/actions/TdTitleBar';
import { Provider } from 'react-redux'
import store from '@/src/redux/store.js';
import API from '@/src/api/TdTitleBar'
import {renderRoutes} from 'react-router-config';

export default class App extends Component {


  render() {

    return (
      <Provider store={store}>
        <Router>
          <div>
            {/* child routes won't render without this */}
            {/* 渲染匹配路径的路由组件 */}
            {renderRoutes(routes)}
          </div>
        </Router>
      </Provider>
    );

  }
  componentDidMount() {
    // console.log('初始化标题栏和底部关于我们我们标题栏')

    API.QueryTitleBar({ parent_id: '' }).then(res => {
      if (res.status === 200) {
        // console.log('res.data.list',res.data.list);
        store.dispatch(setTdTitleBar(res.data.list))
      }
    })
    API.QueryTitleBarChildren({ parent_id: '' }).then(res => {
      if (res.status === 200) {
        // console.log('res.data.list',res.data.list);
        store.dispatch(setAllTdTitleBar(res.data.list))
      }
    })
    // 查询大标题颜色配置
    API.QuerySetting({id: 12}).then(res => {
      if (res.status === 200) {
        // config_name: "大标题颜色配置"
// config_value: "["#555555"]"
        // console.log('res.data.list',res.data.list);
        store.dispatch(setSetting({
          // key: res.data.config_name,
          key: 12,
          val: res.data.config_value
        }))
      }
    })

    // 查询小标题颜色配置
    API.QuerySetting({id: 13}).then(res => {
      if (res.status === 200) {
        // 
        store.dispatch(setSetting({
          // key: res.data.config_name,
          key: 13,
          val: res.data.config_value
        }))
      }
    })
// 标题条数  
    API.QuerySetting({id: 14}).then(res => {
      if (res.status === 200) {
        // 
        store.dispatch(setSetting({
          // key: res.data.config_name,
          key: 14,
          val: res.data.config_value
        }))
      }
    })
    
  }

  componentWillUnmount() {
    
  }

}
