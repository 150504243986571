import React, {Component} from 'react';
import {List, message, Pagination} from "antd";

import styles from './like.module.less'
import API from "../../api/PersonalCenterApi";
import store from '@/src/redux/store.js';
import {setArticleDetails} from '@/src/redux/actions/ArticleDetails';
import {HashRouter} from "react-router-dom";
import { tools } from '@/src/lib/tools';

/**
 * 我的点赞
 */
const router = new HashRouter();
export default class Like extends Component {

    state = {
        loading: false,
        cur_page: 1,
        current_page: 1,
        page_zise: 5,
        dataTotal: 0,
        listData: []
    };

    componentDidMount() {
        this.getMineArticle();
    }

    getMineArticle() {
        this.setState({loading: true});
        const params = {
            limit: this.state.page_zise,
            offset: this.state.cur_page - 1,
        };
        API.queryMineLike(params).then(res => {
            
            if (res.status === 200) {
                this.setState({
                    listData: res.data.list
                });
                this.setState({
                    dataTotal: res.data.total
                });
                
            } else {
                message.error(res.msg);
            }
            this.setState({loading: false});
        }).catch(() => {
            this.setState({loading: false});
        })

    }

    // 详情
    ImgCardContentClick = (item) => {
        if (item.title_bar_no === '') {
            message.error('找不到该文章');
            return
        }
        store.dispatch(setArticleDetails(item));
        router.history.push('/ArticleDetails' + item.title_bar_no + '/' + item.article_no)
    };

    onChangePage = (value) => {
        let _this = this;
        this.setState({
            cur_page: (value - 1) * this.state.page_zise + 1,
        });
        this.setState({
            current_page: value,
        }, function () {
            _this.getMineArticle();
        });
    };

    render() {
        const {listData, loading} = this.state;
        return (
            <div>
                <List className={styles.comment}
                      loading={loading}
                      itemLayout="vertical"
                      size="default"
                      dataSource={listData}
                      renderItem={item => (
                          <List.Item
                              key={item.title}>
                              <List.Item.Meta/>
                              <a className={styles.title}
                                 onClick={this.ImgCardContentClick.bind(this, item)}>
                                  {item.title}</a>
                              <div className={styles.content}>{item.content}</div>
                              <div className={styles.div1}>
                                  {/*<span>转自网易新闻</span>*/}
                                  {/*<span>点赞 (20)</span>*/}
                                  {/*<span>评论 (100)</span>*/}
                                  <span>{tools.FormatRemainTimeStr(item.creation_time)}</span>
                              </div>
                          </List.Item>
                      )}
                />
                <Pagination className={styles.pagination}
                            size="small"
                            pageSize={this.state.page_zise}
                            current={this.state.current_page}
                            total={this.state.dataTotal}
                            onChange={this.onChangePage}
                            showQuickJumper/>
            </div>
        );
    }
}
