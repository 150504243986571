import React, { Component } from "react";
import {
  Row,
  Col,
  Anchor,
  Carousel,
  Modal,
  Collapse,
  Divider,
  Icon,
} from "antd";
import styles from "./newsdetails.module.less";
import PageContent from "@/src/components/PageContent";
import store from "@/src/redux/store.js";
import API from "../../../api/exhibition";
import { base } from "@/src/api/base";
import { setAdvertisement } from "@/src/redux/actions/Advertisement";
import { tools } from "@/src/lib/tools";
import swiperPatch from "@/src/components/swiperPatch/swiperPatch";
import { HashRouter,withRouter } from "react-router-dom";
const router = new HashRouter();
const { Link } = Anchor;
const { Panel } = Collapse;
class Content extends Component {
  state = {
    name: "19消息详情",
  }
  componentWillUnmount() {
    sessionStorage.removeItem('data');
  }
  render() {
    let data = JSON.parse(sessionStorage.getItem("data"))
    let isPC = data.isPC
    let item = data.item
    let list = item.link.split(',')
    console.log(list)
    return(
      <div className={isPC ? styles.wrap :styles.wrapMO} >
        <div className={styles.title}>
           <div className={styles.center}>
              首页 / 领导致辞 /   
              <span style={{fontWeight:'700'}}>  查看更多</span>
           </div>
        </div>    
        <div className={styles.container}>
          <div className={styles.section1}>
            <p>
              {
                item.category ?(<span>{item.category}</span>):''
              }
              <span>{item.address}</span>
              <span>{item.content_short}</span>
            </p>
          </div>
          <div className={styles.section2}>

            <div className={styles.left}>
              
              <span>{list[0]}</span>

              {list[1]?(<span>来源：{list[1]}</span>):''}
              {list[2]?(<span>作者：{list[2]}</span>):''}

            </div>

            <div className={styles.right} >字体：
              <span ref='fontMaxColor' onClick={()=>{
                this.refs.fontSizeChange.style.fontSize = '18px'
                this.refs.fontMaxColor.style.color = '#1F76EC'
                this.refs.fontColor.style.color = '#666'
                this.refs.fontMinColor.style.color = '#666'
              }}>大</span>
              <span ref='fontColor' onClick={()=>{
                this.refs.fontSizeChange.style.fontSize = '16px'
                this.refs.fontMaxColor.style.color = '#666'
                this.refs.fontColor.style.color = '#1F76EC'
                this.refs.fontMinColor.style.color = '#666'
              }}>中</span>
              <span  ref='fontMinColor' onClick={()=>{
                this.refs.fontSizeChange.style.fontSize = '14px'
                this.refs.fontMaxColor.style.color = '#666'
                this.refs.fontColor.style.color = '#666'
                this.refs.fontMinColor.style.color = '#1F76EC'
              }}>小</span>          
            </div>
          </div>
          <div  className={styles.section3}
            ref='fontSizeChange'
            dangerouslySetInnerHTML={{ __html: item.content }}
          >
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Content);
