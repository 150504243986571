import React, { Component } from "react";
import { Row, Col, Anchor, Input, Select, Carousel, Pagination, message } from "antd";
import styles from "./index.module.less";
import store from "@/src/redux/store.js";
import API from "../../../api/exhibition";
import API1 from "../../../api/api";
import { base } from '@/src/api/base'
import { setAdvertisement } from "@/src/redux/actions/Advertisement";
import { tools } from "@/src/lib/tools";
import { HashRouter } from 'react-router-dom'
const router = new HashRouter()
const { Link } = Anchor;
const { Option } = Select;
class Content extends Component {
  state = {
    name: "展商名录",
    exhbitorList: [],//总数据
    vipExhibitorList: [],//vip展商列表
    exhbitorData: {},//单条展商详情
    total: 0,
    offset: 0,
    limit: 36,
    BrowserType: "PC", // PC Mobile 浏览器类型 手机或者pc端
    isYear: "2021",//展商分类
    exhibitorId: 0,//展商id
    thisLetter: "",
    selectXingZhi: [],
    selectFanWei: [],
    selectZhanguan: [],
    exhbitorParams: {
      // Place: "",//展馆
      // Scope: "",//范围
      // Type: "",//性质
      // Initial: "",//首字母
      // Name: "",//名字
    },
    pageNum: 1,//当前页数
    letterArr: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
    isDetail: false,//查看详情
  };

  componentDidMount() {
    window.addEventListener("resize", this.resizeBind);
    const { userInfo } = store.getState()
    //  || userInfo && userInfo.vip_type_no.length <= 0
    if (!userInfo) {
      router.history.push('/exhibitionHome')
    }
    this.resizeBind();
    this.handleSort('2021')
    // this.QueryExhbitor()
    // this.QueryVipExhibitor()

    // this.QueryFirmTab(5)
    // this.ListenStore()
  }

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== "Mobile") {
        this.setState({
          BrowserType: "Mobile",
        });
      }
    } else {
      if (!this.state.BrowserType !== "PC") {
        this.setState({
          BrowserType: "PC",
        });
      }
    }
  };
  componentWillUnmount() {
    // 对 store 变化取消监听
    window.removeEventListener("resize", this.resizeBind);
  }
  // 搜索列表
  QuerySysConfigFanWei = (year) => {
    let _this = this
    let params = {
      config_name: '展商参展范围',
      operate_note: year
    }
    API1.QueryFirmTab(params).then(res => {
      // console.log(res)
      if (res.status === 200) {
        debugger
        _this.setState({
          selectFanWei: res.data.config_value ? JSON.parse(res.data.config_value) : [],
        })
      } else {
        message.error(res.msg);
      }
      this.setState({ loading: false });
    }).catch(res => {
      this.setState({ loading: false });
    })
  }
  QuerySysConfigXinzhi = (year) => {
    let _this = this
    let params = {
      config_name: '展商性质',
      operate_note: year
    }
    API1.QueryFirmTab(params).then(res => {
      // console.log(res)
      if (res.status === 200) {
        debugger
        _this.setState({
          selectXingZhi: res.data.config_value ? JSON.parse(res.data.config_value) : [],
        })
      } else {
        message.error(res.msg);
      }
      this.setState({ loading: false });
    }).catch(res => {
      this.setState({ loading: false });
    })
  }
  QueryFirmTab = (id) => {
    let _this = this
    let params = {
      id: id
    }
    API1.QueryFirmTab(params).then(res => {
      // console.log(res)
      if (res.status === 200) {
        if (id == 5) {
          _this.setState({
            selectZhanguan: res.data.config_value ? JSON.parse(res.data.config_value) : [],
          })
        }
      } else {
        message.error(res.msg);
      }
      this.setState({ loading: false });
    }).catch(res => {
      this.setState({ loading: false });
    })
  }
  QueryExhbitor = () => {
    var { isYear, exhbitorParams, offset, limit } = this.state
    if (offset < 0) {
      offset = 0
    }
    let params = {
      limit,
      offset,
      period: isYear,
      ...exhbitorParams
    }
    this.setState({ loading: true });
    API.QueryExhbitor(params).then(res => {
      // console.log("全部展商", res, params);
      if (res.status === 200) {
        var exhbitorList = res.data.list
        this.setState({
          exhbitorList,
          total: res.data.total,
          offset: res.data.offset,
        })
      } else {
        // message.error(res.msg);
        this.setState({
          exhbitorList: [],
          total: 0
        })
      }
      this.setState({ loading: false });
    }).catch(res => {
      this.setState({ loading: false });
    })
  }
  // 推荐展商
  QueryVipExhibitor = () => {
    var { isYear } = this.state
    let params = {
      limit: 200,
      offset: 0,
      vip_type: 1,
      Period: isYear,
    }
    API.QueryVipExhibitor(params).then(res => {
      console.log("推荐展商", res, params);
      if (res.status === 200) {
        var vipExhibitorList = res.data.list
        this.setState({ vipExhibitorList })
      } else {
        // message.error(res.msg);
        this.setState({ vipExhibitorList: [] })
      }
      this.setState({ loading: false });
    }).catch(res => {
      this.setState({ loading: false });
    })
  }
  // 根据id查询展商
  QueryExhibitorById = () => {
    this.setState({ loading: true });
    var { exhibitorId } = this.state
    let params = {
      id: exhibitorId
    }
    API.QueryExhibitorById(params).then(res => {
      console.log(params, res);
      if (res.status === 200) {
        var exhbitorData = res.data
        this.setState({ exhbitorData })
      } else {
        // message.error(res.msg);
      }
      this.setState({ loading: false });
    }).catch(res => {
      this.setState({ loading: false });
    })
  }
  // 查看展商详情or跳转官网
  onExhibitorDetail = (obj) => {
    if (obj.skip_type == 2 && obj.company_web) {
      window.open(obj.company_web);
    } else {
      this.setState(
        { exhibitorId: obj.id, isDetail: true, },
        () => {
          console.log(this.state.exhibitorId, obj.id)
          this.QueryExhibitorById()
        })
    }
  }
  //  按年份查询
  handleSort = (type) => {
    // if(type == '2022'){
    this.QuerySysConfigFanWei(type);
    this.QuerySysConfigXinzhi(type);
    document.getElementById('recommendBox').style.display = 'block'
    this.setState({
      isYear: type,
      offset: 0,
      pageNum: 1,
      exhbitorParams: {},
      isDetail: false,
    },
      () => {
        this.QueryExhbitor()
        this.QueryVipExhibitor()
      }
    )

  }
  //条件查询
  handleSearch = () => {
    document.getElementById('recommendBox').style.display = 'none'

    this.setState({
      offset: 0,
      pageNum: 1,
      isDetail: false,
    }, () => {
      this.QueryExhbitor()
    })
  }
  //按首字母查询
  handleSearchLetter = (letter) => {
    if (letter == this.state.exhbitorParams.Initial) {
      this.state.exhbitorParams.Initial = ""
    } else {
      this.state.exhbitorParams.Initial = letter
    }
    this.setState(
      { exhbitorParams: this.state.exhbitorParams, pageNum: 1, offset: 0 },
      () => { this.QueryExhbitor() })
  }

  // 分页
  onPageNumChange = (e) => {
    this.setState(
      {
        pageNum: e,
        offset: (e - 1) * this.state.limit
      },
      () => { this.QueryExhbitor() })
  }
  // 条件查询--输入框
  nameChange = (e) => {
    e.persist();
    console.log(e.target.value)
    this.state.exhbitorParams.Name = e.target.value
    this.setState({
      exhbitorParams: this.state.exhbitorParams
    })
  }
  selectChangeXz = (e) => {
    if (e !== "0") {
      this.state.exhbitorParams.Type = e
      this.setState({
        exhbitorParams: this.state.exhbitorParams
      })
    }
  }
  selectChangeFw = (e) => {
    if (e !== "0") {
      this.state.exhbitorParams.Scope = e
      this.setState({
        exhbitorParams: this.state.exhbitorParams
      })
    }
  }
  selectChangeZg = (e) => {
    if (e !== "0") {
      this.state.exhbitorParams.Place = e
      this.setState({
        exhbitorParams: this.state.exhbitorParams
      })
    }
  }
  render() {
    const {
      BrowserType,
      isYear,
      exhbitorParams,
      exhbitorList,
      vipExhibitorList,
      exhbitorData,
      selectXingZhi,
      selectFanWei,
      selectZhanguan,
      total,
      limit,
      letterArr,
      isDetail,
      pageNum,
    } = this.state;
    var isPC = true
    if (BrowserType !== "PC") { isPC = false }

    // 全部展商
    let allHtml = []
    if (exhbitorList.length > 0) {
      let allPageSize = isPC ? 3 : 2
      let allPageNum = Math.ceil(exhbitorList.length / allPageSize)
      for (var i = 0; i < allPageNum; i++) {
        allHtml.push(
          <div key={i} className={styles.listItem}>
            <Row gutter={40}>
              {exhbitorList.map((item, ind) => {
                return ind < (i + 1) * allPageSize && ind >= (i * allPageSize) ? (
                  <Col key={ind} span={isPC ? 8 : 12} onClick={() => { this.onExhibitorDetail(item) }}>
                    <span className={styles.name}>{item.company_name}</span>
                  </Col>
                ) : null
              })
              }
            </Row>
          </div>
        );
      }
    } else {
      allHtml.push(<div className={styles.Not}>暂无记录</div>)
    }

    // 推荐展商
    let vipHtml = []
    if (vipExhibitorList.length > 0) {
      let vipPageSize = isPC ? 3 : 2
      let vipPageNum = Math.ceil(vipExhibitorList.length / vipPageSize)
      for (var i = 0; i < vipPageNum; i++) {
        vipHtml.push(
          <div key={i} className={styles.listItem}>
            <Row gutter={40}>
              {vipExhibitorList.map((item, ind) => {
                return ind < (i + 1) * vipPageSize && ind >= (i * vipPageSize) ? (
                  <Col key={ind} span={isPC ? 8 : 12} onClick={() => { this.onExhibitorDetail(item) }}>
                    <span className={styles.name}>{item.company_name}</span>
                  </Col>
                ) : null
              })
              }
            </Row>
          </div>
        );
      }
    } else {
      vipHtml.push(<div className={styles.Not}>暂无记录</div>)
    }

    return (
      <div className={styles.Wrap}>
        <div className={styles.BreadCrumbsBox}>
          <div className={styles.container + " " + (isPC ? styles.conPC : styles.conMo)}>
            <a href="/#/exhibitionHome">首页</a> / <a>展商名录</a> / <a>{isYear}展商名录</a>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.container + " " + (isPC ? styles.conPC : styles.conMo)}>
            <Row gutter={16}>
              <Col span={isPC ? 5 : 24}>
                <div className={styles.sortBox}>
                  <Row>
                    {/* <Col span={isPC ? 24 : 12}>
                      <div className={styles.sort + " " + (isPC ? styles.sortPC : styles.sortMo) + " " + (isYear == "2022" ? styles.active : "")} onClick={() => { this.handleSort("2022") }}>2022展商名录 ></div>
                    </Col> */}
                    <Col span={isPC ? 24 : 12}>
                      <div className={styles.sort + " " + (isPC ? styles.sortPC : styles.sortMo) + " " + (isYear == "2021" ? styles.active : "")} onClick={() => { this.handleSort("2021") }}>2021展商名录 ></div>
                    </Col>
                    <Col span={isPC ? 24 : 12}>
                      <div className={styles.sort + " " + (isPC ? styles.sortPC : styles.sortMo) + " " + (isYear == "2020" ? styles.active : "")} onClick={() => { this.handleSort("2020") }}>2020展商名录 ></div>
                    </Col>
                    <Col span={isPC ? 24 : 12}>
                      <div className={styles.sort + " " + (isPC ? styles.sortPC : styles.sortMo) + " " + (isYear == "2019" ? styles.active : "")} onClick={() => { this.handleSort("2019") }}>2019展商名录 ></div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col span={isPC ? 19 : 24}>
                {/* 展商条件查询 */}
                <div className={isPC ? styles.searchWinCss + " " + styles.searchBox : styles.searchBox}>
                  {
                    isDetail && !isPC ? (
                      <Row gutter={16}>
                        <Col span={isPC ? isDetail ? 13 : 10 : 20}>
                          <Row gutter={16}>
                            <Col span={isPC ? 12 : 24}>
                              <Input placeholder="公司名称" value={exhbitorParams.Name ? exhbitorParams.Name : ""} onChange={this.nameChange} />
                            </Col>
                            <Col span={12}>
                              <Select
                                defaultValue=""
                                value={exhbitorParams.Type ? exhbitorParams.Type : ""}
                                onChange={this.selectChangeXz}>
                                <Option value="">参展形式</Option>
                                {selectFanWei && selectFanWei.length ?
                                  selectFanWei.map((item, ind) => {
                                    return <Option key={ind} value={item}>{item}</Option>
                                  }) : null
                                }
                              </Select>
                            </Col>
                            <Col span={isPC ? isDetail ? 24 : 12 : 12}>
                              <Select
                                defaultValue=""
                                value={exhbitorParams.Scope ? exhbitorParams.Scope : ""}
                                onChange={this.selectChangeFw}>
                                <Option value="">专业领域</Option>
                                {selectXingZhi && selectXingZhi.length ?
                                  selectXingZhi.map(item => {
                                    return <Option value={item}>{item}</Option>
                                  }) : null
                                }
                              </Select>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={4}>
                          <div className={styles.searchBtn + " " + (isPC ? styles.searchBtnPC : styles.searchBtnMo)} onClick={() => { this.handleSearch() }}>查询</div>
                        </Col>
                      </Row>
                    ) : (
                      <Row gutter={16}>
                        <Col span={isPC ? isDetail ? 13 : 10 : 10}>
                          <Row gutter={16}>
                            <Col span={isPC ? 12 : 24}>
                              <Input placeholder="公司名称" value={exhbitorParams.Name ? exhbitorParams.Name : ""} onChange={this.nameChange} />
                            </Col>
                            <Col span={isPC ? 12 : 24}>
                              <Select
                                defaultValue=""
                                value={exhbitorParams.Type ? exhbitorParams.Type : ""}
                                onChange={this.selectChangeXz}>
                                <Option value="">参展形式</Option>
                                {selectFanWei && selectFanWei.length ?
                                  selectFanWei.map((item, ind) => {
                                    return <Option key={item} value={item}>{item}</Option>
                                  }) : null
                                }
                              </Select>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={isPC ? isDetail ? 7 : 5 : 10}>
                          <Row gutter={16}>
                            <Col span={24}>
                              {/* <Col span={isPC ? isDetail ? 24 : 12 : 24}> */}
                              <Select
                                defaultValue=""
                                value={exhbitorParams.Scope ? exhbitorParams.Scope : ""}
                                onChange={this.selectChangeFw}>
                                <Option value="">专业领域</Option>

                                {selectXingZhi && selectXingZhi.length ?
                                  selectXingZhi.map(item => {
                                    return <Option value={item}>{item}</Option>
                                  }) : null
                                }
                              </Select>
                            </Col>
                            {
                              // isDetail ? null : (<Col span={isPC ? 12 : 24}>
                              //   <Select defaultValue="0" value={exhbitorParams.Place ? exhbitorParams.Place : "0"} onChange={this.selectChangeZg}>
                              //     <Option value="0">展馆</Option>
                              //     {selectZhanguan && selectZhanguan.length ?
                              //       selectZhanguan.map(item => {
                              //         return <Option value={item}>{item}</Option>
                              //       }) : null
                              //     }
                              //   </Select>
                              // </Col>)
                            }
                          </Row>
                        </Col>
                        <Col span={4}>
                          <div className={styles.searchBtn + " " + (isPC ? styles.searchBtnPC : styles.searchBtnMo)} onClick={() => { this.handleSearch() }}>
                            <span>查询</span>
                          </div>
                        </Col>
                      </Row>
                    )
                  }

                </div>

                {/* 展商列表or详情 */}
                {isDetail ? (
                  <div className={styles.exhbitorDetailBox}>
                    {
                      exhbitorData && exhbitorData.company_detail ? (
                        <div dangerouslySetInnerHTML={{ __html: exhbitorData.company_detail }}></div>
                      ) : (
                        <div>暂无详情~</div>
                      )
                    }
                  </div>
                ) : (
                  <div className={isPC ? styles.exhibitorBox : styles.exhibitorBoxMo}>
                    <div className={styles.recommendBox} id='recommendBox'>
                      <div className={styles.tit}>推荐展商</div>
                      <div className={styles.listBox}>
                        {vipHtml}
                      </div>
                    </div>
                    <div className={styles.recommendBox}>
                      <div className={styles.tit}>更多展商</div>
                      <div className={styles.letterSearch}>
                        {letterArr ? letterArr.map(item => {
                          return <div key={item.id} className={styles.letterItem + " " + (exhbitorParams && exhbitorParams.Initial && item == exhbitorParams.Initial ? styles.active : "")} onClick={() => { this.handleSearchLetter(item) }}>{item}</div>
                        }) : null}
                      </div>
                      <div className={styles.listBox}>
                        {allHtml}
                        <div className={styles.pageBox}>
                          <Pagination defaultCurrent={1} current={pageNum} hideOnSinglePage={false} defaultPageSize={limit} onChange={this.onPageNumChange} total={total} />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default Content;
