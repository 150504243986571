import React, { Component } from 'react';
import styles from './index.module.less';
import PageHeader from '@/src/components/PageHeader';
import PageFooter from '@/src/components/PageFooter';
import Content from './Content1.jsx';

export default class MoreArticles extends Component {

  state = {
    title_bar_no: '',
    BrowserType: 'PC', // PC Mobile 浏览器类型 手机或者pc端
  }
  componentDidMount() {
    window.addEventListener("resize", this.resizeBind);
    this.resizeBind();
  }

  resizeBind = () => {
    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== 'Mobile') {
        this.setState({
          BrowserType: 'Mobile',
        });
      }
    } else {
      if (!this.state.BrowserType !== 'PC') {
        this.setState({
          BrowserType: 'PC',
        });
      }
    }
  }

  render() {

    const { title_bar_no } = this.props.match.params

    return (
      <div className={styles.HomeBox + ' Layout'}>

        <PageHeader showNav={false} showBreadcrumbList={true}/>

        <Content title_bar_no={title_bar_no}/>
        <div style={this.state.BrowserType == 'Mobile' ? {position: 'fixed', bottom: 0, width: '100%'} : {}}>
          <PageFooter showFooterNavList={true} colorE9f1fc={false}/>
        </div>
        

      </div>
    )
  }

}
