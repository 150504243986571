import React, { Component } from "react";
import { HashRouter } from 'react-router-dom'
import { Row, Col, Anchor, Carousel} from "antd";
import PageContent from "@/src/components/PageContent";
import styles from "./index.module.less";
import store from "@/src/redux/store.js";
import API from "../../../api/exhibition";
import { base } from "@/src/api/base";
import { setAdvertisement } from "@/src/redux/actions/Advertisement";
import { tools } from "@/src/lib/tools";
import ArticleApi from '@/src/api/ArticleApi';
// const router = new HashRouter()
const router = new HashRouter()
const { Link } = Anchor;



class Content extends Component {
  state = {
    name: "视频详情",
    exhbitionData: [], //总数据
    BrowserType: "PC", // PC Mobile 浏览器类型 手机或者pc端
    item: {},
    par:{...this.props.data.match.params},
    loading:false
  };

  componentDidMount() {
    document.querySelector('html').scrollTop = 0
    window.addEventListener("resize", this.resizeBind);
    this.resizeBind();
    // this.setState({
    //   item: JSON.parse(sessionStorage.getItem("item")),
    // });
    this.InitData()
  }
  InitData = () => {
    let params = {
      limit:999,
      offset:1,
      pid:+this.state.par.pid,
    };
    this.setState({ loading: true });
    API.QueryExhbitionByPid(params)
      .then((res) => {
        console.log('返回数据',res)
        if (res.status === 200) {
          res.data.list.forEach(item =>{
            if(+this.state.par.id === item.id){
              this.setState({item})
            }
          })
        } else {
          
          // message.error(res.msg);
        }
        this.setState({ loading: false });
      })
      .catch((res) => {
        this.setState({ loading: false });
      });
  }


  resizeBind = () => {
    console.log('URL',this)

    if (window.innerWidth < 768) {
      if (this.state.BrowserType !== "Mobile") {
        this.setState({
          BrowserType: "Mobile",
        });
      }
    } else {
      if (!this.state.BrowserType !== "PC") {
        this.setState({
          BrowserType: "PC",
        });
      }
    }
  };
  componentWillUnmount() {
    // 对 store 变化取消监听
    window.removeEventListener("resize", this.resizeBind);
  }

  render() {
    const { BrowserType, item ,loading} = this.state;
    console.log(item);
    var md = 12;
    var isPC = true;
    if (BrowserType !== "PC") {
      md = 24;
      isPC = false;
    }

    return (
      
      <div className={isPC ? styles.wrap : styles.wrapMO}>
        <PageContent loading={loading}>
          <div className={styles.content}>
          {item && item.content_short ? (
            <div className={styles.title}>{item.content_short}</div>
          ) : null}
          {item && item.category ? (
            <div className={styles.vid}>
              <video src={base.OssAddress + item.category} controls />
            </div>
          ) : null}
          {item && item.content ? (
            <p
              className={styles.section}
              dangerouslySetInnerHTML={{
                __html: item.content,
              }}
            ></p>
          ) : null}
        </div>
        </PageContent>
      </div>
    );
  }
}

export default Content;
