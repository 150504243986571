import Server from './server'
class API extends Server{

   /**
   *  用途：账号密码登录
   *  @url http://cangdu.org:8001/v2/login
   *  @method post
   *  @return {promise}
   */
  Register (data) { 
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/register', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }
    /**
   *  用途：查询是否签到
   *  @url http://cangdu.org:8001/SignIn
   *  @method post
   *  @return {promise}
   */
  
    isSign(data) { 
      try {
        let result = this.axios('post', '/RequestAddress/v1/api/query_sign_byphone', data)
        if (result.status !== 0 && (result instanceof Object)) {
          return result || {}
        } else {
          return result || {}
        }
      } catch (err) {
        throw err
      }
    }
  /**
   *  用途：签到
   *  @url http://cangdu.org:8001/SignIn
   *  @method post
   *  @return {promise}
   */
  
  Clock(data) { 
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/sign', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }
  /**
   *  用途：查询注册页面-企业标签
   *  @url http://cangdu.org:8001/v2/login
   *  @method post
   *  @return {promise}
   */
  QueryFirmTab(data) {
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_system_config', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

    /**
   *  用途：账号密码登录
   *  @url http://cangdu.org:8001/v2/login
   *  @method post
   *  @return {promise}
   */
  accountLogin (data) {
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/login', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result || {}
      }
    } catch (err) {
      throw err
    }
  }

  /**
   *  静默登录
   *  @url /v2/api/login_without
   *  @method post
   *  @return {promise}
   */
  accountLoginWithout (data) {
    try {
      let result = this.axios('post', '/RequestAddress/v2/api/login_without', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        let err = {
          tip: '登录失败',
          response: result,
        }
        return err
      }
    } catch (err) {
      throw err
    }
  }

  /**
   *  查询注册会员类型
   *  @url /v2/api/query_member_type
   *  @method post
   *  @return {promise}
   */
  QueryMemberType (data) {
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/query_member_type', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result
      }
    } catch (err) {
      throw err
    }
  }

    /**
   *  查询oss上传参数
   *  @url /v1/api/get_osstoken
   *  @method post
   *  @return {promise}
   */
  GetOssToken (data) {
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/get_osstoken', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result
      }
    } catch (err) {
      throw err
    }
  }

  
   /**
   *  找回密码接口
   *  @url /v1/api/find_password
   *  @method post
   *  @return {promise}
   */
  FindPassword (data) {
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/find_password', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result
      }
    } catch (err) {
      throw err
    }
  }

  /**
   *  获取验证码
   *  @url /v1/api/find_password
   *  @method post
   *  @return {promise}
   */
  GetVerificationCode(data) {
    try {
      let result = this.axios('post', '/RequestAddress/v1/api/get_verification_code', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result
      }
    } catch (err) {
      throw err
    }
  }

      /**
   *  查询FARM金币
   *  @url /v2/api/farm_query_gckbalance
   *  @method post
   *  @return {promise}
   */
  FarmQueryGoldbalance (data) {
    try {
      let result = this.axios('post', '/RequestAddress/v2/api/farm_query_goldbalance', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result
      }
    } catch (err) {
      throw err
    }
  }

        /**
   *  查询FARM金币
   *  @url /v2/api/farm_withdrawal
   *  @method post
   *  @return {promise}
   */
  FarmWithdrawal (data) {
    try {
      let result = this.axios('post', '/RequestAddress/v2/api/farm_withdrawal', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } else {
        
        return result
      }
    } catch (err) {
      throw err
    }
  }

  
  /**
   *  查询FARM金币
   *  @url /v2/api/farm_withdrawal
   *  @method post
   *  @return {promise}
   */
  FarmQueryGoldrecords (data) {
    try {
      let result = this.axios('post', '/RequestAddress/v2/api/farm_query_goldrecords', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } 
    } catch (err) {
      throw err
    }
  }


   /**
   *  修改交易密码
   *  @url /v2/api/modify_tradingpwd
   *  @method post
   *  @return {promise}
   */
  ModifyTradingpwd (data) {
    try {
      let result = this.axios('post', '/RequestAddress/v2/api/modify_tradingpwd', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } 
    } catch (err) {
      throw err
    }
  }

  /**
   *  完善个人信息
   *  @url /v2/api/modify_profile
   *  @method post
   *  @return {promise}
   */
  ModifyProfile (data) {
    try {
      let result = this.axios('post', '/RequestAddress/v2/api/modify_profile', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } 
    } catch (err) {
      throw err
    }
  }

  /**
   *  查询sdt 1FARM=多少USDT
   *  @url /v2/api/query_sdt
   *  @method post
   *  @return {promise}
   */
  QuerySdt (data) {
    try {
      let result = this.axios('post', '/RequestAddress/v2/api/query_sdt', data)
      if (result.status !== 0 && (result instanceof Object)) {
        return result || {}
      } 
    } catch (err) {
      throw err
    }
  }
}
export default new API()